import React, { useEffect } from 'react';
// import { useAuthDataContext } from './components/auth-provider';
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollTopArrow';
import withAuthentication from './components/auth-provider';
import Authenticated from './containers/AuthenticatedPage';
import BlogPage from './containers/BlogPage';
import CanGoBacks from './containers/CanGoBacks';
import CanManager from './containers/CanManager';
import Contact from './containers/Contact';
import DemoPage from './containers/DemoPage';
import HomePage from './containers/HomePage';
import LoginPage from './containers/LoginPage';
import PasswordResetPage from './containers/LoginPage/PasswordReset';
import NotFoundPage from './containers/NotFoundPage';
import PrivacyPolicyPage from './containers/PrivacyPolicyPage/PrivacyPolicyPage';
import ResourcesPage from './containers/ResourcesPage';
import ResourcePage from './containers/ResourcesPage/resource';
import ReviewsPage from './containers/Reviews';
import Services from './containers/Services';
import WhyJoe from './containers/WhyJoe';
import AdminCompany from './containers/admin/Company';
import AdminCustomers from './containers/admin/Customers';
import DashboardPage from './containers/admin/Dashboard';
import AdminMaps from './containers/admin/Maps';
import AdminPosts from './containers/admin/Posts';
import AdminPrices from './containers/admin/Prices';
import AdminServices from './containers/admin/Services';
import AdminUsers from './containers/admin/Users';
import CanManagerRegistration from './containers/can-manager';
import HaulerPage from './containers/hauler';
import { HaulerRoute, HomeRoute, PrivateRoute, ReviewsRoute, WhyJoeRoute } from './routes';

const AuthenticatedWithContext = withAuthentication(Authenticated);
const CustomersWithAuthentication = withAuthentication(AdminCustomers);
const UsersWithAuthentication = withAuthentication(AdminUsers);
const ServicesWithAuthentication = withAuthentication(AdminServices);
const CompanyWithAuthentication = withAuthentication(AdminCompany);
const MapsWithAuthentication = withAuthentication(AdminMaps);
const LoginPageWithContext = withAuthentication(LoginPage);
const PasswordResetPageWithContext = withAuthentication(PasswordResetPage);
const HaulerPageWithContext = withAuthentication(HaulerPage);
const PostsWithAuthentication = withAuthentication(AdminPosts);
const PricesWithAuthentication = withAuthentication(AdminPrices);
const CanManagerWithAuthentication = withAuthentication(CanManager);
const CanGoBacksWithAuthentication = withAuthentication(CanGoBacks);

// const PrivateRoute = ({ component, ...options }) => {
//    const { user } = useAuthDataContext();
//    const finalComponent = user ? component : SignInPage;

//    return <Route {...options} component={finalComponent} />;
// };

const Ga = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		try {
			window.gtag('event', 'page_view', { page_path: pathname });
		} catch (e) {
			console.log({ e });
		}
	}, [pathname]);

	return null;
};

const App = () => {
	return (
		<Router>
			<Ga />

			<React.Fragment>
				<ScrollToTop />

				<Switch>
					<HomeRoute exact path="/" component={HomePage} />
					<WhyJoeRoute exact path="/Demo" component={DemoPage} />
					<WhyJoeRoute exact path="/Demo/:hauler" component={DemoPage} />
					<WhyJoeRoute exact path="/Why-Joe" component={WhyJoe} />
					<WhyJoeRoute exact path="/Contact" component={Contact} />
					<WhyJoeRoute exact path="/Services" component={Services} />
					<ReviewsRoute exact path="/Reviews/:hauler" component={ReviewsPage} />
					<WhyJoeRoute exact path="/Privacy-policy" component={PrivacyPolicyPage} />
					<WhyJoeRoute exact path="/Can-Manager/register" component={CanManagerRegistration} />
					<WhyJoeRoute exact path="/Can-Manager/register/:codes" component={CanManagerRegistration} />
					<HaulerRoute exact path="/h/:hauler" component={HaulerPageWithContext} />
					<WhyJoeRoute exact path="/login" component={LoginPageWithContext} />
					<WhyJoeRoute exact path="/password-reset/:code" component={PasswordResetPageWithContext} />
					<PrivateRoute exact path="/authenticated" component={AuthenticatedWithContext} />
					<PrivateRoute exact path="/admin/can-manager" component={CanManagerWithAuthentication} />
					<PrivateRoute exact path="/admin/can-go-backs" component={CanGoBacksWithAuthentication} />
					<PrivateRoute exact path="/admin/users/e/:userId" component={UsersWithAuthentication} />
					<PrivateRoute exact path="/admin/users" component={UsersWithAuthentication} />
					<PrivateRoute exact path="/admin/customers/e/:userId" component={CustomersWithAuthentication} />
					<PrivateRoute exact path="/admin/customers" component={CustomersWithAuthentication} />
					<PrivateRoute exact path="/admin/company/e/:userId" component={CompanyWithAuthentication} />
					<PrivateRoute exact path="/admin/company" component={CompanyWithAuthentication} />
					<PrivateRoute exact path="/admin/maps/e/:mapId" component={MapsWithAuthentication} />
					<PrivateRoute exact path="/admin/maps/add" component={MapsWithAuthentication} />
					<PrivateRoute exact path="/admin/maps" component={MapsWithAuthentication} />
					<PrivateRoute exact path="/admin/posts" component={PostsWithAuthentication} />
					<PrivateRoute exact path="/admin/posts/e/:postId" component={PostsWithAuthentication} />
					<PrivateRoute exact path="/admin/services/e/:serviceId" component={ServicesWithAuthentication} />
					<PrivateRoute exact path="/admin/services" component={ServicesWithAuthentication} />
					<PrivateRoute exact path="/admin/dashboard" component={DashboardPage} />
					<PrivateRoute exact path="/admin/prices/e/:priceId" component={PricesWithAuthentication} />
					<PrivateRoute exact path="/admin/prices" component={PricesWithAuthentication} />
					<PrivateRoute path="/admin" component={CompanyWithAuthentication} />
					{/* <WhyJoeRoute path="/test" component={TestPage} /> */}
					<WhyJoeRoute exact path="/resources" component={ResourcesPage} />
					<WhyJoeRoute exact path="/resources/:url" component={ResourcePage} />
					<HomeRoute exact path="/blog" component={BlogPage} />
					<HomeRoute exact path="/blog/:url" component={BlogPage} />
					<Route component={NotFoundPage} />
				</Switch>
			</React.Fragment>
		</Router>
	);
};

export default App;
