import React, { useState, useRef, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import MapContainer, { SearchBarWrapper } from './cardAddressMap';
import serviceService from '../../services/services';
import mapService from '../../services/maps';
import { SignUpContext } from './context/signup';
import { useStyles } from './elementStyles';
import { googleApiKey } from '../../config';

const CardAddress = ({ step, goToStep, stepIndex, goToNextStep, goToPreviousStep }) => {
	const { hauler, stepAnswers, allServices, setAnswer, setAvailableServices, setAllServices, getStepAnswers } = useContext(SignUpContext);

	const { styles } = useStyles();

	const initialState = {
		location: getStepAnswers('address') && getStepAnswers('address').address && 'name' in getStepAnswers('address').address ? getStepAnswers('address').address.name : '',
		serviceAvailable: getStepAnswers('services') && getStepAnswers('services').length > 0 ? true : false,
	};

	const serviceType = getStepAnswers('intro');

	const [q, setQ] = useState(initialState.location);
	const [serviceAvailable, setServiceAvailable] = useState(initialState.serviceAvailable);
	const [searched, setSearched] = useState(false);
	const [place, setPlace] = useState();

	useEffect(() => {
		let rendered = true;

		const loadLayers = (hauler) => {
			if (rendered) {
				// const promises = [];

				new serviceService()
					.getServicesByHaulerId(hauler.id)
					.then((allServs) => {
						if (allServs) setAllServices({ allServs });
						return allServs;
					})
					.then((allServs) => {
						const promises = [];

						for (let i = 0; i < allServs.length; i++) {
							promises.push(
								new mapService().getMap(allServs[i].mapid).then((map) => {
									try {
										allServs[i].map = map;
										return map;
									} catch (e) {}
								})
							);
						}

						Promise.all(promises).then((data) => {
							// let layerz = [];

							setAllServices({ allServices: allServs });

							// for (let x = 0; x < data.length; x++) {
							// 	if (data[x].id && data[x].id > 0) {
							// 		layerz.push(data[x]);
							// 	}
							// }

							// setLayers(layerz);
						});
					});
			}
		};

		loadLayers(hauler);

		return () => {
			rendered = false;
		};
	}, [hauler]);

	const onAddressSearch = (place) => {
		setQ(place.formatted_address);
		setPlace(place);
		setSearched(true);
	};

	const onMapLoad = async (loc) => {
		const currentServices = getStepAnswers('intro').toLowerCase() === 'roll-offs' ? [getStepAnswers(8)] : getStepAnswers('intro').toLowerCase() === 'residential' ? getStepAnswers('services') : getStepAnswers('commercialservices');

		if (getStepAnswers('intro').toLowerCase() === 'residential') {
			// residential
		}
		if (getStepAnswers('intro').toLowerCase() === 'commercial') {
			// residential
		}

		// if (q !== initialState.location || !currentServices) {
		const availableServices = await allServices.filter((service) => {
			try {
				const point = loc && {
					lat: loc.lat(),
					lng: loc.lng(),
				};

				const google = window.google;

				const polygon = new google.maps.Polygon({
					paths: JSON.parse(service.map.geojson).features[0].geometry.coordinates[0].map((el) => ({ lat: el[1], lng: el[0] })),
				});

				return stepAnswers[0] === service.type && google.maps.geometry.poly.containsLocation(loc, polygon);
			} catch (e) {
				return false;
			}
		});

		const available = availableServices.length > 0;

		setServiceAvailable(available);

		setAvailableServices({ availableServices: availableServices });

		setAnswer({ stepIndex, answer: { available, address: { ...loc, name: q } } });
		setAnswer({ stepIndex: stepIndex + 1, answer: null });
		setAnswer({ stepIndex: 8, answer: null });

		// } else {
		// 	currentServices && currentServices.length > 0 && setServiceAvailable(true);
		// }
	};

	const { primaryColor, primaryTextColor } = hauler.theme;

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: 'white',
	};

	return (
		<div className="card container">
			<div className="card-content" style={{ maxHeight: '600px' }}>
				<span style={{ ...styles.title, margin: 0 }} className="card-title z-depth-1 m0">
					{step.title}
				</span>
				<div className="row" style={{ margin: 0 }}>
					{/* <SearchBarWrapper apiKey={googleApiKey} onAddressSearch={onAddressSearch} /> */}
					<SearchBarWrapper apiKey="AIzaSyC64v9vpx23NRqv-ALlGVLwi8AprYzfcdg" onAddressSearch={onAddressSearch} />
					{/* <div className="input-field col s9 m10 l11" style={{ margin: 0 }}>
							<img src={Map} alt="" className="prefix" style={{ marginTop: '-6px' }} />
							<input ref={register({ required: 'Enter an address' })} defaultValue={q} name="address" placeholder="enter your address here" style={styles.input} />
						</div> */}
					{/* <div className="input-field col s3 m2 l1" style={{ margin: 0 }}>
							<button type="submit" className="btn" style={{ ...primaryBgColor, height: '46px' }}>
								FIND
							</button>
						</div> */}

					{searched && allServices.length === 0 ? (
						<>
							<div className="row">
								<div className="col s12 green-text">
									<b>Searching for services. . .</b>
								</div>
							</div>
						</>
					) : (
						<>
							{serviceAvailable && (
								<div className="row">
									<div className="col s12 green-text">
										<b>Service is available for your location</b>
									</div>
								</div>
							)}

							{serviceAvailable.length === 0 && searched && (
								<div className="row">
									<div className="col s12 red-text">No services for your location</div>
								</div>
							)}

							{!serviceAvailable && !searched && (
								<div className="row">
									<div className="col s12">Enter your full address and click "find"</div>
								</div>
							)}

							{!serviceAvailable && searched && (
								<div className="row">
									<div className="col s12 red-text">No Services found at your location</div>
								</div>
							)}
						</>
					)}

					{q && q.length > 0 && allServices.length > 0 && <MapContainer place={place} address={q} onMapLoad={onMapLoad} apiKey={googleApiKey} />}
				</div>
			</div>
			<div className="card-action">
				<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => goToPreviousStep(stepIndex)}>
					Previous Step
				</button>
				{serviceAvailable && serviceType === 'Commercial' && (
					<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => goToStep('commercialservices')}>
						Next Step
					</button>
				)}
				{serviceAvailable && serviceType === 'Residential' && (
					<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => goToStep('services')}>
						Next Step
					</button>
				)}
				{serviceAvailable && serviceType === 'Roll-Offs' && (
					<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => goToStep('rolloffservices')}>
						Next Step
					</button>
				)}
				{!serviceAvailable && (
					<button style={styles.button.enabled} className="btn btn-small grey themedButton" disabled>
						Next Step
					</button>
				)}
			</div>
		</div>
	);
};

// export default CardAddress;
export default CardAddress;
