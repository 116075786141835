import Cookies from 'js-cookie';
import config from './config';

export default class Data {
	api(path, method = 'GET', body = null, requiresAuth = false, credentials = null) {
		const url = config.apiBaseUrl + path;

		const options = {
			method,
			headers: {
				'Content-Type': 'application/json',
				// 'Access-Control-Allow-Origin': '*',
			},
		};

		if (body !== null) {
			options.body = JSON.stringify(body);
		}
		if (requiresAuth) {
			if ('username' in credentials && 'password' in credentials) {
				const encodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
				options.headers['Authorization'] = ` Basic ${encodedCredentials}`;
			} else if ('token' in credentials) {
				const encodedCredentials = btoa(`${credentials.token}`);
				options.headers['Authorization'] = ` Bearer ${encodedCredentials}`;
			}
		}

		const response = fetch(url, options);

		try {
			response
				.then((json) => {
					if (!json || json.statusText === 'Unauthorized') {
						Cookies.remove('authToken');
					}
				})
				.catch((err) => console.error(err));
		} catch (e) {
			console.log(e);
		}
		return response;
	}

	async getCompany(company, token) {
		// API call to retrieve User Data
		return await this.api(`/companies.cfc?method=get&i=${company}`, 'GET', null, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async getCompanyByUrl(company) {
		// API call to retrieve User Data
		return await this.api(`/companies.cfc?method=getByUrl&i=${company}&z`, 'GET')
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async getCompanies(token) {
		return await this.api(`/companies.cfc?method=getCompanies`, 'GET', null, true, {
			token,
		})
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					return response.json();
				} else {
					return [];
				}
			})
			.catch((err) => console.log({ err }));
	}

	async update(company, token) {
		// API call to retrieve User Data
		return await this.api(`/companies.cfc?method=update`, 'POST', company, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async updateDisabledDays(company, token) {
		return await this.api(`/companies.cfc?method=updateDisabledDays`, 'POST', company, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}
}
