import React from 'react';

const PrivacyPolicyPage = () => {
	return (
		<div className="container privacy-policy">
			<div className="section section-404 p-0 m-0 height-100vh">
				<div className="row">
					<div style={{ padding: '20px' }}>
						<h1>Privacy Policy</h1>
						<p>
							<strong>Last updated:</strong> Aug 28, 2024
						</p>
						<p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
						<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

						<h2>Interpretation and Definitions</h2>
						<h3>Interpretation</h3>
						<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

						<h3>Definitions</h3>
						<ul>
							<li>
								<strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.
							</li>
							<li>
								<strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.
							</li>
							<li>
								<strong>Company:</strong> (referred to as either “the Company,” “We,” “Us,” or “Our” in this Agreement) refers to 74 Buffalo Inc DBA Trash Joes, 1815 North Union, Fremont, NE 68025.
							</li>
							<li>
								<strong>Cookies:</strong> Small files that are placed on Your computer, mobile device, or any other device by a website, containing the details of Your browsing history on that website among its many uses.
							</li>
							<li>
								<strong>Country:</strong> Refers to the United States.
							</li>
							<li>
								<strong>Device:</strong> Any device that can access the Service, such as a computer, a cellphone, or a digital tablet.
							</li>
							<li>
								<strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.
							</li>
							<li>
								<strong>Service:</strong> Refers to the Website.
							</li>
							<li>
								<strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service, or to assist the Company in analyzing how the Service is used.
							</li>
							<li>
								<strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
							</li>
							<li>
								<strong>Website:</strong> Refers to 74 Buffalo Inc DBA Trash Joes at <a href="http://www.trashjoes.com">www.trashjoes.com</a>.
							</li>
							<li>
								<strong>You:</strong> The individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
							</li>
						</ul>

						<h2>Collecting and Using Your Personal Data</h2>
						<h3>Types of Data Collected</h3>
						<h4>Personal Data</h4>
						<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
						<ul>
							<li>Email address</li>
							<li>First name and last name</li>
							<li>Phone number</li>
							<li>Address, State, Province, ZIP/Postal code, City</li>
						</ul>

						<h4>Usage Data</h4>
						<p>Usage Data is collected automatically when using the Service.</p>
						<p>Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>

						<h4>Tracking Technologies and Cookies</h4>
						<p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
						<ul>
							<li>
								<strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our
								Service may use Cookies.
							</li>
							<li>
								<strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording
								the popularity of a certain section and verifying system and server integrity).
							</li>
						</ul>

						<h4>Use of Your Personal Data</h4>
						<p>The Company may use Personal Data for the following purposes:</p>
						<ul>
							<li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
							<li>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
							<li>For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.</li>
							<li>
								To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
							</li>
							<li>To provide You with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
							<li>To manage Your requests: To attend and manage Your requests to Us.</li>
						</ul>

						<h4>Retention of Your Personal Data</h4>
						<p>
							The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and
							policies.
						</p>

						<h4>Transfer of Your Personal Data</h4>
						<p>
							Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection
							laws may differ than those from Your jurisdiction.
						</p>
						<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
						<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

						<h4>Delete Your Personal Data</h4>
						<p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
						<p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
						<p>You may update, amend, or delete Your information at any time by signing in to Your Account if you have one and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
						<p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>

						<h4>Security of Your Personal Data</h4>
						<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

						<h4>Children’s Privacy</h4>
						<p>
							Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without
							verification of parental consent, We take steps to remove that information from Our servers.
						</p>

						<h4>Links to Other Websites</h4>
						<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
						<p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

						<h4>Changes to this Privacy Policy</h4>
						<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
						<p>We will let You know via email and/or a prominent notice on Our Service prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.</p>
						<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

						<h4>Contact Us</h4>
						<p>If you have any questions about this Privacy Policy, You can contact us:</p>
						<ul>
							<li>
								Email us at <a href="mailto:nate@trashjoes.com">nate@trashjoes.com</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicyPage;
