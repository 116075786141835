import M from 'materialize-css';
import React from 'react';
import NavLinks from '../Header/navlinks';
import logoWhite from './TrashJoes-logo-white.png';

const topStyle = {};

export default class Home extends React.Component {
	componentDidMount() {
		M.Sidenav.init(document.getElementById('mobile-nav'));
	}

	render() {
		const closeMobileNav = () => {
			let elem = document.getElementById('mobile-nav');
			let instance = M.Sidenav.getInstance(elem);
			instance.close();
		};
		return (
			<div className="home">
				<div className="red">
					<div className="container center white-text" style={{ position: 'relative' }}>
						<img className="responsive-img hide-on-small-only" src={logoWhite} alt="" style={{ position: 'absolute', left: '30px', top: '15px' }} />
						<footer className="row" style={{ padding: '5px 0', margin: '0' }}>
							<ul className="hide-on-med-and-down" style={{}}>
								<NavLinks closeMobileNav={closeMobileNav} />
							</ul>
							<p>
								<a href="tel:9128007474" className="white-text" style={{ fontSize: '22px' }}>
									912-800-7474
								</a>
							</p>
							<p>
								<a href="/Privacy-Policy" className="white-text" style={{ fontSize: '18px' }}>
									<small>Privacy Policy</small>
								</a>
							</p>
							<p>
								<span className="hide-on-large-only">
									&copy; 2020-2023 Trash Joes
									<br />
									Managed and Hosted by 3dsanity.com
								</span>
								<span className="hide-on-med-and-down">&copy; 2020-2023 Trash Joes | Managed and Hosted by 3dsanity.com</span>
							</p>
						</footer>
					</div>
				</div>
			</div>
		);
	}
}
