import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import RegisterForm from './register';
import LoginForm from './login';
import ForgotPasswordForm from './forgotpassword';

import './login.scss';

const FormPlain = ({ auth }) => {
	const [state, setState] = useState('login');

	return (
		<div className="home">
			<div className="container">
				<div className="row" style={{ marginTop: '90px' }}>
					<div className="col s10 offset-s1 center titleStyle">
						<h1 className="red-text">Dashboard Login</h1>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m8 offset-m2 l6 offset-l3 center-align">
						<p>Log in to access your Trash Joe's dashboard</p>
					</div>
				</div>
			</div>
			<div className="container row">
				<div className="col s12 m8 offset-m2 l6 offset-l3">
					<div className="leaveComment card">
						<div className="card-content">
							{state === 'login' && <LoginForm setState={setState} auth={auth} />}
							{state === 'forgotpassword' && <ForgotPasswordForm setState={setState} auth={auth} />}
							{state === 'login' && (
								<p className="center-align" style={{ marginTop: '10px' }}>
									If you need assistance with can managing and go backs,{' '}
									<Link style={{ fontWeight: 'bold', textDecoration: 'underline' }} to="/can-manager/register">
										register
									</Link>{' '}
									now for our free "Can Manager" Service!
								</p>
							)}
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<br />
		</div>
	);
};

export default FormPlain;
