import React from 'react';
import config from '../../services/config';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import M from 'materialize-css';
// import { AuthContext } from '../../components/auth-provider';

const LeaveComment = ({ post, comment, refresh }) => {
	// const { user } = React.useContext(AuthContext);
	const { register, handleSubmit } = useForm({
		mode: 'onChange',
	});

	const onSubmit = (values) => {
		Axios({
			method: 'POST',
			url: `${config.apiBaseUrl}/comments.cfc?method=create`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				name: values.name,
				email: values.email,
				comment: values.comment,
				post: post.id,
				parent: comment && comment.id > 0 ? comment.id : null,
			},
		})
			.then((res) => res.status === 200 && res.data)
			.then((data) => data.success && M.toast({ html: 'Thanks for your comment!' }))
			.finally((res) => {
				refresh();
			});
	};

	const name_v4 = uuidv4();
	const email_v4 = uuidv4();
	const comment_v4 = uuidv4();

	return (
		<div>
			<div className="clearfix"></div>
			<div className="leaveComment card">
				<div className="card-content" style={{ paddingBottom: '0' }}>
					<p>Your Email is never published nor shared. (Required fields are marked *)</p>
					<form id="customerForm" onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="input-field col s12 m6 l6 ">
								<input id={`name${name_v4}`} name="name" type="text" ref={register()} />
								<label htmlFor={`name${name_v4}`}>Your Name</label>
							</div>
							<div className="col s12 m6 l6 input-field">
								<input id={`email${email_v4}`} name="email" type="text" ref={register()} />
								<label htmlFor={`email${email_v4}`}>Your Email *</label>
							</div>
						</div>
						<div className="row">
							<div className="col s12 m12 l12 input-field">
								<textarea id={`comment${comment_v4}`} name="comment" className="materialize-textarea" ref={register()}></textarea>
								<label htmlFor={`comment${comment_v4}`}>Your Comment *</label>
							</div>
						</div>
						<p style={{ textAlign: 'right', padding: '0 0 25px 0' }}>
							<button type="submit" name="saveComment" className="btn">
								Post Comment
							</button>
						</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LeaveComment;
