import React, { useContext } from 'react';
import logo from '../../components/Header/TrashJoes-logo.png';
import { SignUpContext } from './context/signup';

const Header = () => {
	const { hauler } = useContext(SignUpContext);

	if (!hauler) return <div>Loading . . .</div>;

	const { primaryColor, primaryTextColor } = hauler.theme;

	return (
		<nav className="white-text" style={{ backgroundColor: primaryColor, color: primaryTextColor }}>
			<div className="nav-wrapper" style={{ overflow: 'hidden' }}>
				<div className="container">
					<ul className="navbar-list left">
						<li className="center-align">
							{/* <a style={{ maxHeight: '64px' }} className="waves-effect waves-block waves-light search-button" href="#!"> */}
							{/* <img alt="" style={{ maxHeight: '64px' }} src={logo} /> */}
							<img alt="" style={{ maxHeight: '64px' }} src={`${hauler.logo}`} />
							{/* </a> */}
						</li>
						<li>
							<h4 style={{ paddingLeft: '20px' }}>{hauler.name && hauler.name.toUpperCase()}</h4>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Header;
