import React, { useState, useRef, useContext, useEffect } from 'react';
import M from 'materialize-css';
import { SignUpContext } from './context/signup';
import { useStyles } from './elementStyles';

const CardCans = ({ cans, onSelect = () => console.log('onSelect on cans') }) => {
	const { hauler, availableServices } = useContext(SignUpContext);
	const { primaryColor, primaryTextColor } = hauler.theme;

	const [form, setForm] = useState({ cans: 1 });
	const { styles } = useStyles();

	const maxCans = availableServices.reduce((ret, el) => {
		return Math.max(
			el.prices.reduce((xret, xel) => {
				return Math.max(xel.cans, xret);
			}, 0),
			ret
		);
	}, 0);

	useEffect(() => {
		setForm({ ...form, cans });
	}, [cans]);

	useEffect(() => {
		const newForm = {
			...form,
			total: parseInt(form.cans) || 1,
			max: maxCans,
		};
		setForm(newForm);

		// if (form.total) setAnswer({ stepIndex, answer: newForm });
	}, [form.cans]);

	useEffect(() => M.AutoInit(), []);

	return (
		<React.Fragment>
			<div>
				<div style={{ flex: '0 0 80%' }}>
					<h5 style={{ marginTop: '0', color: primaryColor }}>{`CANS NEEDED`.toUpperCase()}</h5>
					<br />
					<div className="row">
						<div className="number-input">
							<button onClick={() => setForm({ ...form, cans: form.cans === 1 ? 1 : form.cans - 1 })}></button>
							<input className="quantity" min="1" name="quantity" value={parseInt(form.cans) || 1} onChange={(el) => setForm({ ...form, cans: el.target.value === '' ? 1 : el.target.value })} type="number" />
							<button onClick={() => setForm({ ...form, cans: form.cans + 1 })} className="plus"></button>
						</div>
					</div>

					<div className="row" style={{ margin: '0' }}>
						<div className="col s10 offset-s1">
							{form.total > form.max && <p className="red-text">I'm sorry, we do not offer that many cans for this service.</p>}
							<p>
								You will have a total of <b>{`${form.total ? form.total : '1'}`}</b> can{form.cans > 1 ? 's' : ''}
							</p>
							<br />
							{parseInt(form.total) <= parseInt(form.max) ? (
								<button style={styles.button.enabled} onClick={() => onSelect(true, form)} className="btn">
									Continue
								</button>
							) : (
								<button style={styles.button.enabled} onClick={() => onSelect(true, form)} className="btn" disabled>
									Continue
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CardCans;
