import React, { useContext, useState, useEffect } from 'react';
import M from 'materialize-css';
import { SignUpContext } from './context/signup';
import { useStyles } from './elementStyles';
import useLocalStorage from '../../hooks/useLocalStorage';
import serviceService from '../../services/services';

const defaultDetails = {
	firstname: '',
	lastname: '',
	email: '',
	cellphone: '',
	address: '',
	city: '',
	state: '',
	zip: '',
};

const usStates = [
	{ name: 'ALABAMA', abbreviation: 'AL' },
	{ name: 'ALASKA', abbreviation: 'AK' },
	{ name: 'AMERICAN SAMOA', abbreviation: 'AS' },
	{ name: 'ARIZONA', abbreviation: 'AZ' },
	{ name: 'ARKANSAS', abbreviation: 'AR' },
	{ name: 'CALIFORNIA', abbreviation: 'CA' },
	{ name: 'COLORADO', abbreviation: 'CO' },
	{ name: 'CONNECTICUT', abbreviation: 'CT' },
	{ name: 'DELAWARE', abbreviation: 'DE' },
	{ name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
	{ name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
	{ name: 'FLORIDA', abbreviation: 'FL' },
	{ name: 'GEORGIA', abbreviation: 'GA' },
	{ name: 'GUAM', abbreviation: 'GU' },
	{ name: 'HAWAII', abbreviation: 'HI' },
	{ name: 'IDAHO', abbreviation: 'ID' },
	{ name: 'ILLINOIS', abbreviation: 'IL' },
	{ name: 'INDIANA', abbreviation: 'IN' },
	{ name: 'IOWA', abbreviation: 'IA' },
	{ name: 'KANSAS', abbreviation: 'KS' },
	{ name: 'KENTUCKY', abbreviation: 'KY' },
	{ name: 'LOUISIANA', abbreviation: 'LA' },
	{ name: 'MAINE', abbreviation: 'ME' },
	{ name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
	{ name: 'MARYLAND', abbreviation: 'MD' },
	{ name: 'MASSACHUSETTS', abbreviation: 'MA' },
	{ name: 'MICHIGAN', abbreviation: 'MI' },
	{ name: 'MINNESOTA', abbreviation: 'MN' },
	{ name: 'MISSISSIPPI', abbreviation: 'MS' },
	{ name: 'MISSOURI', abbreviation: 'MO' },
	{ name: 'MONTANA', abbreviation: 'MT' },
	{ name: 'NEBRASKA', abbreviation: 'NE' },
	{ name: 'NEVADA', abbreviation: 'NV' },
	{ name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
	{ name: 'NEW JERSEY', abbreviation: 'NJ' },
	{ name: 'NEW MEXICO', abbreviation: 'NM' },
	{ name: 'NEW YORK', abbreviation: 'NY' },
	{ name: 'NORTH CAROLINA', abbreviation: 'NC' },
	{ name: 'NORTH DAKOTA', abbreviation: 'ND' },
	{ name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
	{ name: 'OHIO', abbreviation: 'OH' },
	{ name: 'OKLAHOMA', abbreviation: 'OK' },
	{ name: 'OREGON', abbreviation: 'OR' },
	{ name: 'PALAU', abbreviation: 'PW' },
	{ name: 'PENNSYLVANIA', abbreviation: 'PA' },
	{ name: 'PUERTO RICO', abbreviation: 'PR' },
	{ name: 'RHODE ISLAND', abbreviation: 'RI' },
	{ name: 'SOUTH CAROLINA', abbreviation: 'SC' },
	{ name: 'SOUTH DAKOTA', abbreviation: 'SD' },
	{ name: 'TENNESSEE', abbreviation: 'TN' },
	{ name: 'TEXAS', abbreviation: 'TX' },
	{ name: 'UTAH', abbreviation: 'UT' },
	{ name: 'VERMONT', abbreviation: 'VT' },
	{ name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
	{ name: 'VIRGINIA', abbreviation: 'VA' },
	{ name: 'WASHINGTON', abbreviation: 'WA' },
	{ name: 'WEST VIRGINIA', abbreviation: 'WV' },
	{ name: 'WISCONSIN', abbreviation: 'WI' },
	{ name: 'WYOMING', abbreviation: 'WY' },
];

const Card1 = ({ step, stepIndex, goToStep }) => {
	const { stepAnswers, hauler, setAnswer, getStepAnswers, getOrderTotal } = useContext(SignUpContext);
	const [lead, setLead] = useLocalStorage('lead', false);

	const answer = stepAnswers[stepIndex] ? stepAnswers[stepIndex] : defaultDetails;

	const { styles } = useStyles();
	const [form, setForm] = useState(answer);

	useEffect(() => {
		console.log('settinglooping', { form }, setAnswer, stepIndex);
		setAnswer({ stepIndex, answer: form });
		M.AutoInit();
		M.updateTextFields();
	}, [form, setAnswer, stepIndex]);

	useEffect(() => {
		M.AutoInit();
		M.updateTextFields();
	}, []);

	const { primaryColor, primaryTextColor } = hauler.theme;

	const serviceType = getStepAnswers('intro');

	const handleNextStep = (step) => {
		// we need to create a lead, if no lead exists

		if (!lead) {
			const purchaseItemsStep = getStepAnswers('intro') === 'Commercial' ? 'commercialservices' : getStepAnswers('intro') === 'Roll-Offs' ? 'rolloffservices' : 'services';

			const order = {
				user: getStepAnswers('customerdetails'),
				billing: { ...getStepAnswers('billingdetails'), total: getOrderTotal() },
				services: (getStepAnswers('intro') === 'Commercial') === 'Roll-Offs' ? [getStepAnswers(purchaseItemsStep)] : getStepAnswers(purchaseItemsStep),
				address: { ...getStepAnswers('address'), type: getStepAnswers('intro') },
				company: hauler,
				purchaseItemsStep,
			};

			new serviceService()
				.initCustomerLead(order)
				.then((resp) => {
					console.log({ resp });
					try {
						if (resp.success && resp.post) {
							setLead(resp.post);
						}
					} catch (e) {
						console.log('try lead save', { e });
					}
				})
				.catch((e) => {
					console.log({ e });
				})
				.finally((e) => console.log(false));
		}

		goToStep(step);
	};

	const handlePrevStep = (step) => {
		goToStep(step, -1);
	};

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content" style={{ maxHeight: '600px', maxHeight: '100%' }}>
					<span style={styles.title} className="card-title z-depth-1">
						{step.title}
					</span>
					<div style={{ padding: '0 0' }}>
						<form autoComplete="off" method="post" action="">
							<input autoComplete="off" name="hidden" type="text" style={{ display: 'none' }} />
							<div className="row">
								<div className="col s12 m8 offset-m2 l6 offset-l3">
									<div className="row" style={{ margin: '0' }}>
										<div className="col s12 input-field" style={{ marginBottom: '5px' }}>
											<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} id="email" type="email" defaultValue={answer.email} onChange={(e) => setForm({ ...form, email: e.target.value })} />
											<label style={{ left: '1.5rem' }} htmlFor="email">
												Email Address
											</label>
										</div>
									</div>
									<div className="row" style={{ margin: '0' }}>
										<div className="col s6 input-field" style={{ marginBottom: '5px' }}>
											<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} id="firstname" type="text" defaultValue={answer.firstname} onChange={(e) => setForm({ ...form, firstname: e.target.value })} />
											<label style={{ left: '1.5rem' }} htmlFor="firstname">
												First Name
											</label>
										</div>
										<div className="col s6 input-field" style={{ marginBottom: '5px' }}>
											<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} id="lastname" type="text" defaultValue={answer.lastname} onChange={(e) => setForm({ ...form, lastname: e.target.value })} />
											<label style={{ left: '1.5rem' }} htmlFor="lastname">
												Last Name
											</label>
										</div>
									</div>
									<div className="row" style={{ margin: '0' }}>
										<div className="col s12 input-field" style={{ marginBottom: '5px' }}>
											<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} id="address" type="text" defaultValue={answer.address} onChange={(e) => setForm({ ...form, address: e.target.value })} />
											<label style={{ left: '1.5rem' }} htmlFor="address">
												Address
											</label>
										</div>
									</div>
									<div className="row" style={{ margin: '0' }}>
										<div className="col s4 input-field" style={{ marginBottom: '5px' }}>
											<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} id="city" type="text" defaultValue={answer.city} onChange={(e) => setForm({ ...form, city: e.target.value })} />
											<label style={{ left: '1.5rem' }} htmlFor="city">
												City
											</label>
										</div>
										<div className="col s4 input-field" style={{ marginBottom: '5px' }}>
											<label className="active" style={{ left: '1.5rem' }} htmlFor="state">
												State
											</label>
											<select style={{ height: '2.5rem' }} className="browser-default" name="state" defaultValue={form.state} selected={answer.state} onChange={(e) => setForm({ ...form, state: e.target.value })}>
												<option disabled value={0}>
													SELECT
												</option>
												{usStates.map((state, i) => (
													<option key={state.abbreviation} value={state.abbreviation}>
														{state.name}
													</option>
												))}
											</select>
										</div>
										<div className="col s4 input-field" style={{ marginBottom: '5px' }}>
											<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} id="zip" type="text" defaultValue={answer.zip} onChange={(e) => setForm({ ...form, zip: e.target.value })} />
											<label style={{ left: '1.5rem' }} htmlFor="zip">
												Zip
											</label>
										</div>
									</div>
									<div className="row" style={{ margin: '0' }}>
										<div className="col s12 input-field" style={{ marginBottom: '5px' }}>
											<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} id="cellphone" type="text" defaultValue={answer.cellphone} onChange={(e) => setForm({ ...form, cellphone: e.target.value })} />
											<label style={{ left: '1.5rem' }} htmlFor="cellphone">
												Cell Phone
											</label>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="card-action">
					{serviceType === 'Commercial' && (
						<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => handlePrevStep('commercialservices')}>
							Previous Step
						</button>
					)}
					{serviceType === 'Residential' && (
						<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => handlePrevStep('services')}>
							Previous Step
						</button>
					)}
					{serviceType === 'Roll-Offs' && (
						<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => handlePrevStep('rolloffservices')}>
							Previous Step
						</button>
					)}
					{form.firstname.length > 0 && form.email.length > 0 && form.cellphone.length > 0 ? (
						<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => handleNextStep('billingdetails')}>
							Next Step
						</button>
					) : (
						<button style={styles.button.enabled} className="btn btn-small grey themedButton" disabled>
							Next Step
						</button>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Card1;
