import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './hero.scss';
import NavLinks from './navlinks';
import M from 'materialize-css';
import LadyTrash from './TrashJoes-hero.jpg';
import logo from './TrashJoes-logo.png';
import { AuthContext } from '../auth-provider';

const Page = (props) => {
	const context = React.useContext(AuthContext);

	const topStyle = {
		position: 'absolute',
		border: '0',
		boxShadow: '0 0 0',
	};

	const heroImage = {
		backgroundImage: 'url(' + LadyTrash + ')',
		height: '750px',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
	};

	const [top, setTop] = React.useState(0);
	const [style, setStyle] = React.useState({
		logo: { margin: '20px 0 0 130px', color: 'white' },
		footer: { backgroundColor: 'blue', color: 'white' },
		header2: { backgroundColor: 'blue', color: 'white' },
		header3: { backgroundColor: 'blue', color: 'white' },
	});

	useEffect(() => {
		window.addEventListener('scroll', (e) => {
			setTop(e.view.scrollY);
		});

		return () => window.removeEventListener('scroll');
	}, []);

	useEffect(() => {
		if (top > 80) {
			setStyle({ ...style, logo: { ...style.header, margin: '0' } });
		} else {
			setStyle({ ...style, logo: { ...style.header, margin: '20px 0 0 130px' } });
		}
	}, [top]);

	return (
		<div className="home">
			<div className="container" style={style.header}>
				<div className="hero-image" style={heroImage}>
					<div className="nav-wrapper">
						<header className="row" style={{ position: 'relative', zIndex: '100' }}>
							<nav className="transparent" style={topStyle}>
								<Link className="btn right btn white-text signup" to="/login" style={{ marginRight: '50px' }}>
									DASHBOARD LOGIN
								</Link>
								<ul className="right hide-on-med-and-down" style={{ margin: '25px 35px 0 0' }}>
									<NavLinks />
								</ul>
								<a href="#!" data-target="mobile-nav" className="sidenav-trigger">
									<i className="material-icons">menu</i>
								</a>

								<Link className="" to="/">
									<img alt="" src={logo} style={style.logo} />
								</Link>
							</nav>
						</header>
						{/* <header className="row" style={{ position: 'relative' }}>
							<nav className="transparent" style={topStyle}>
								<ul className="right hide-on-med-and-down" style={{ margin: '25px 35px 0 0' }}>
									<NavLinks />
								</ul>
							</nav>
						</header> */}
						{/* <div className="hero-text">
							<h1 className="red-text">YOU WANT TO DO BETTER. JOE IS HERE TO HELP</h1>
							<p>You have the need, want and desire to improve. We are your partner for information plus new industry tools that will make you a better businessperson and more profitable. Let’s work to get you an enviable P/L and better sleep at night...</p>
							<br />
							<Link to="/login" className="btn-large">
								SEE JOE WORK
							</Link>
						</div> */}
					</div>
				</div>
				<ul className="sidenav" id="mobile-nav">
					<NavLinks />
					<li>
						<Link className="red-text" to="/login">
							DASHBOARD LOGIN
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
};
export default Page;
