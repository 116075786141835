export const BlogReducer = (state, action) => {
	switch (action.type) {
		case 'SET':
			return {
				...state,
				...action.payload,
			};
		default:
			return { ...state };
	}
};
