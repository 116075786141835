import React, { useMemo, useEffect, useState } from 'react';
import M from 'materialize-css';
import userService from '../../../services/users';
import Table from './table';
import { Link } from 'react-router-dom';

export default function List({ auth }) {
	const [users, setUsers] = useState([]);
	const { token } = auth.user;

	const columns = useMemo(
		() => [
			{
				Header: 'Users',
				columns: [
					{
						Header: 'ID',
						accessor: 'id',
					},
					{
						Header: 'Username',
						accessor: 'username',
					},
					{
						Header: 'Name',
						accessor: 'name',
					},
					{
						Header: 'Verified',
						accessor: 'verified',
					},
					{
						Header: 'Role',
						accessor: 'role',
					},
					{
						Header: 'Status',
						accessor: 'status',
					},
					{
						Header: 'Edit',
						accessor: 'edit',
					},
					{
						Header: 'Delete',
						accessor: 'delete',
					},
				],
			},
		],
		[]
	);

	useEffect(() => {
		let rendered = true;
		function getUsers() {
			new userService()
				.getUsers(token)
				.then((users) => {
					if (rendered && users) {
						users.forEach((el) => {
							el.name = `${el.firstname} ${el.middlename} ${el.lastname}`;
							el.verified = el.verified ? 'YES' : 'NO';
						});
						setUsers(users);
					}
				})
				.catch((e) => {});
		}
		getUsers();
		return () => (rendered = false);
	}, [token]);

	useEffect(() => {
		M.AutoInit();
	});
	return (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Users List</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<a href="/">Home</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#!">User</a>
								</li>
								<li className="breadcrumb-item active">Users List</li>
							</ol>
						</div>
						<div className="col s2 m6 l6">
							<Link className="btn waves-effect waves-light breadcrumbs-btn right" to="/admin/users/e/0">
								<i className="material-icons hide-on-med-and-up">add</i>
								<span className="hide-on-small-onl">Add</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<section className="users-list-wrapper section">
					<div className="users-list-filter">
						<div className="card-panel">
							<div className="row">
								<form>
									<div className="col s12 m6 l3">
										<label htmlFor="users-list-verified">Verified</label>
										<div className="input-field">
											<select className="form-control" id="users-list-verified">
												<option value="">Any</option>
												<option value="Yes">Yes</option>
												<option value="No">No</option>
											</select>
										</div>
									</div>
									<div className="col s12 m6 l3">
										<label htmlFor="users-list-role">Role</label>
										<div className="input-field">
											<select className="form-control" id="users-list-role">
												<option value="">Any</option>
												<option value="User">User</option>
												<option value="Staff">Staff</option>
											</select>
										</div>
									</div>
									<div className="col s12 m6 l3">
										<label htmlFor="users-list-status">Status</label>
										<div className="input-field">
											<select className="form-control" id="users-list-status">
												<option value="">Any</option>
												<option value="Active">Active</option>
												<option value="Close">Close</option>
												<option value="Banned">Banned</option>
											</select>
										</div>
									</div>
									<div className="col s12 m6 l3 display-flex align-items-center show-btn">
										<button type="submit" className="btn btn-block indigo waves-effect waves-light">
											Show
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="users-list-table">
						<div className="card">
							<div className="card-content">
								<div className="responsive-table">{users ? <Table columns={columns} data={users} /> : <div>EMPTY</div>}</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
}
