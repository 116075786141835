import React, { useContext, useState } from 'react';
import CansCard from '../../containers/hauler/cardCansSingle';
import FrequencyCard from '../../containers/hauler/cardFrequency';
import date from '../../containers/hauler/TJ-Date.svg';
import trashCan from '../../containers/hauler/TJ-Can.svg';
import { useStyles } from '../../containers/hauler/elementStyles';
import { SignUpContext } from '../../containers/hauler/context/signup';

const Item = ({ stepIndex, selected = '', theme, onSelect = () => console.log('default action'), ...serv }) => {
	// const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	const { stepAnswers } = useContext(SignUpContext);
	const [wanted, setWanted] = useState(null);
	const [stage, setStage] = useState(0);
	const [cans, setCans] = useState(null);
	// const [frequency, setFrequency] = useState(null);

	const { styles } = useStyles();

	const applyCans = (wanted, cans) => {
		setCans(cans);
		if (parseInt(cans.cans) >= 0) setStage(1);
		// setWanted(null);
	};

	const applyFrequency = (freq) => {
		// setFrequency(freq);

		if (freq) {
			onSelect(true, {
				...cans,
				...freq,
			});

			setWanted(null);
			setStage(0);
		}
	};
	const { primaryColor, primaryTextColor } = theme;

	const showStage = (stage) => {
		if (stage === 0) return <CansCard stepIndex={stepIndex} cans={cans} onSelect={applyCans} />;
		if (stage === 1) return <FrequencyCard cans={parseInt(cans.cans)} service={serv} stepIndex={stepIndex} onSelect={applyFrequency} />;
	};

	const getImg = (wanted, stage) => {
		if (!wanted) return <img src={date} style={{ width: 'auto', height: '100%' }} />;

		switch (stage) {
			// case 'Residential':
			// 	return <img src={img01} style={{ width: 'auto', height: '100%' }} />;
			// case 'Recycling':
			// 	return <img src={img02} style={{ width: 'auto', height: '100%' }} />;
			// case 'Commercial':
			// 	return <img src={img02} style={{ width: 'auto', height: '100%' }} />;
			default:
				return <img src={trashCan} style={{ width: 'auto', height: '100%' }} />;
		}
	};

	const s = {
		selection_content: {
			borderRadius: '25px',
			border: `1px solid ${primaryColor}`,
			padding: '10px 20px 20px',
		},
	};

	return (
		<div className="col s12 m10 offset-m1 l8 offset-l2" style={{ float: 'none' }}>
			<div className="card animate fadeLeft" style={{ maxHeight: 'unset', boxShadow: 'none' }}>
				<div className="card-content" style={{ padding: '0', borderRadius: '2px', marginBottom: '10px' }} onClick={() => onSelect()}>
					<div className="valign-wrapper center-align" style={{ maxHeight: '100px', alignContent: 'space-around' }}>
						<span style={{ height: '100px', width: '100%', textAlign: 'right' }}>{getImg(wanted, stage)}</span>
						<span className="black-text" style={{ paddingLeft: '10px', width: '100%', textAlign: 'left' }}>
							{wanted === null ? stepAnswers[0] : serv.name}
						</span>
					</div>
				</div>
				<div className="center-align" style={s.selection_content}>
					{wanted === null && (
						<>
							<div className="center-align">
								<h5 style={{ marginTop: '0', color: primaryColor }}>{serv.name.toUpperCase()}</h5>
								<div className="valign-wrapper" style={{ margin: '0' }}>
									<div style={{ width: '100%', textAlign: 'right' }}>
										<button style={styles.button.enabled} onClick={() => onSelect(false)} className="btn grey darken-2">
											No Thanks
										</button>
									</div>
									<div style={{ width: '100%', textAlign: 'left' }}>
										<button style={styles.button.enabled} onClick={() => setWanted(true)} className="btn">
											Add Service
										</button>
									</div>
								</div>
							</div>
						</>
					)}
					{wanted === true && showStage(stage)}
				</div>
				<p className="center-align">{serv.description}</p>
			</div>
		</div>
	);
};

export default Item;
