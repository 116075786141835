import React, { useMemo, useEffect, useState } from 'react';
import M from 'materialize-css';
import mapService from '../../../services/maps';
import Table from './table';
import Loading from '../../../components/Loader';
import { useCompanies, Select } from '../../../hooks/useCompanies';

export default function List({ auth }) {
	const [maps, setMaps] = useState({ data: [], fetching: false });
	const { token } = auth.user;
	const [companyId, setCompanyId] = useState(auth.user.user.company.id);

	const [companies] = useCompanies({ token });
	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	const columns = useMemo(
		() => [
			{
				Header: 'Maps',
				columns: [
					{
						Header: 'ID',
						accessor: 'id',
					},
					{
						Header: 'Map Name',
						accessor: 'name',
					},
				],
			},
		],
		[]
	);

	useEffect(() => {
		let rendered = true;

		function getMaps() {
			setMaps({ ...maps, fetching: true });
			new mapService()
				.getMaps(token, companyId)
				.then((json) => {
					if (rendered) {
						setMaps({ ...maps, data: json, fetching: false });
					}
				})
				.catch((e) => {
					setMaps({ ...maps, fetching: false });
				});
		}

		getMaps();

		return () => (rendered = false);
	}, [companyId, token]);

	useEffect(() => {
		M.AutoInit();
	});

	return (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Your Maps List</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<a href="/">Home</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#!">Maps</a>
								</li>
								<li className="breadcrumb-item active">Maps List</li>
							</ol>
						</div>
						<div className="col s2 m6 l6">
							<a className="btn waves-effect waves-light breadcrumbs-btn right" href="/admin/maps/add">
								<i className="material-icons hide-on-med-and-up">add</i>
								<span className="hide-on-small-onl">Add</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<section className="users-list-wrapper section">
					<div className="users-list-filter">
						<div className="card-panel">
							<div className="row">
								<div className="card-content">{auth.user.user.role === 'admin' && <Select onChange={(e) => setCompanyId(e.target.value)} options={adminCompanySelect} value={companyId} />}</div>
							</div>
						</div>
					</div>
					<div className="users-list-table">
						<div className="card">
							<div className="card-content" style={{ minHeight: '400px' }}>
								<div className="responsive-table">{!maps.fetching ? <Table columns={columns} data={maps.data} /> : <Loading />}</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
}
