import React, { useContext, useState, useRef, useEffect } from 'react';
import CansCard from '../../containers/hauler/cardCansSingle';
import FrequencyCard from '../../containers/hauler/cardFrequency';
import date from '../../containers/hauler/TJ-Date.svg';
import trashCan from '../../containers/hauler/TJ-CommContainer.svg';
import { useStyles } from '../../containers/hauler/elementStyles';
import { SignUpContext } from '../../containers/hauler/context/signup';
import M from 'materialize-css';

const Select = ({ children, ...props }) => {
	const selectRef = useRef();

	useEffect(() => {
		if (selectRef.current) M.FormSelect.init(selectRef.current, {});
	}, [children, selectRef]);

	return (
		<select {...props} ref={selectRef}>
			{children}
		</select>
	);
};

const Textarea = (props) => {
	return <textarea {...props}></textarea>;
};

const defaultNotes = {
	notes: '',
	fullname: '',
	email: '',
	phone: '',
};

const NotesCard = ({ notes: initialNotes = defaultNotes, onSelect = () => console.log('submit') }) => {
	const [notes, setNotes] = useState({ ...initialNotes });

	const { styles } = useStyles();

	const handleFieldChange = (e) => {
		e.persist();
		setNotes({ ...notes, [e.target.name]: e.target.value });
	};

	return (
		<>
			<div className="row" style={{ margin: 0 }}>
				<p>Please tell us who to contact for placement of the container and any other important notes</p>
				<div className="col s12 m10 offset-m1 input-field" style={{ marginBottom: '5px' }}>
					<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} name="fullname" type="text" defaultValue={notes.fullname} onChange={handleFieldChange} />
					<label style={{ left: '1.5rem' }} htmlFor="fullname">
						Full Name
					</label>
				</div>
				<div className="col s12 m5 offset-m1 input-field" style={{ marginBottom: '5px' }}>
					<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} name="phone" type="text" defaultValue={notes.phone} onChange={handleFieldChange} />
					<label style={{ left: '1.5rem' }} htmlFor="phone">
						Phone
					</label>
				</div>
				<div className="col s12 m5 input-field" style={{ marginBottom: '5px' }}>
					<input autoComplete="off" style={{ ...styles.input, height: '2.5rem' }} name="email" type="text" defaultValue={notes.email} onChange={handleFieldChange} />
					<label style={{ left: '1.5rem' }} htmlFor="email">
						Email
					</label>
				</div>
				<div className="col s12 m10 offset-m1 input-field" style={{ marginBottom: '5px', paddingRight: 0 }}>
					<Textarea onChange={handleFieldChange} name="notes" value={notes.notes} style={{ ...styles.input, fontSize: '14px', height: '4rem' }} />
					<p style={{ lineHeight: '14px' }}>
						<small>For example - when looking at my house from the street, place the container next to the basketball hoop, and keep 10 feet away from the garage door.</small>
					</p>
				</div>
				<div className="col s10 offset-s1">
					<button style={styles.button.enabled} onClick={() => onSelect(notes)} className="btn">
						Confirm
					</button>
				</div>
			</div>
		</>
	);
};

const Item = ({ stepIndex, selected = '', theme, onSelect = () => console.log('default action'), ...serv }) => {
	// const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	const { stepAnswers, availableServices } = useContext(SignUpContext);
	const [wanted, setWanted] = useState(true);
	const [stage, setStage] = useState(0);
	const [cans, setCans] = useState(null);
	const [frequency, setFrequency] = useState(null);

	const applyCans = (wanted, cans) => {
		setCans(cans);
		if (parseInt(cans.cans) >= 0) setStage(1);
		// setWanted(null);
	};

	const applyNotes = (notes) => {
		onSelect(true, {
			...cans,
			...frequency,
			notes,
		});
	};

	const applyFrequency = (freq) => {
		setFrequency(freq);
		setStage(2);

		// if (freq) {
		// 	onSelect(true, {
		// 		...cans,
		// 		...freq,
		// 	});

		// 	setWanted(null);
		// 	setStage(0);
		// }
	};

	const showStage = (stage) => {
		if (stage === 0) return <CansCard stepIndex={stepIndex} cans={cans} onSelect={applyCans} />;
		if (stage === 1) return <FrequencyCard cans={parseInt(cans.cans)} service={serv} stepIndex={stepIndex} onSelect={applyFrequency} />;
		if (stage === 2) return <NotesCard notes={parseInt(cans.notes)} onSelect={applyNotes} />;
	};

	const getImg = (wanted, stage) => {
		if (!wanted) return <img src={date} style={{ width: 'auto', height: '100%' }} />;

		switch (stage) {
			// case 'Residential':
			// 	return <img src={img01} style={{ width: 'auto', height: '100%' }} />;
			// case 'Recycling':
			// 	return <img src={img02} style={{ width: 'auto', height: '100%' }} />;
			// case 'Commercial':
			// 	return <img src={img02} style={{ width: 'auto', height: '100%' }} />;
			default:
				return <img src={trashCan} style={{ width: 'auto', height: '100%' }} />;
		}
	};

	const { primaryColor, primaryTextColor } = theme;

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: primaryTextColor,
	};
	const s = {
		selection_content: {
			borderRadius: '25px',
			border: `1px solid ${primaryColor}`,
			padding: '10px 20px 20px',
		},
	};
	const { styles } = useStyles();
	return (
		<div className="col s12 m10 offset-m1 l8 offset-l2" style={{ float: 'none' }}>
			<div className="card animate fadeLeft" style={{ maxHeight: 'unset', boxShadow: 'none' }}>
				<div className="card-content" style={{ padding: '0', borderRadius: '2px' }} onClick={() => onSelect()}>
					<div className="valign-wrapper center-align" style={{ maxHeight: '100px', alignContent: 'space-around' }}>
						<span style={{ height: '100px', width: '100%', textAlign: 'right' }}>{getImg(wanted, stage)}</span>
						<span className="black-text" style={{ paddingLeft: '10px', width: '100%', textAlign: 'left' }}>
							{wanted === null ? stepAnswers[0] : serv.name}
						</span>
					</div>
				</div>
				<div className="center-align" style={s.selection_content}>
					{wanted === null && (
						<div className="center-align">
							{/* <h5 style={{ marginTop: '0', color: primaryColor }}>{serv.name.toUpperCase()}</h5> */}
							<h5 style={{ marginTop: '0', color: primaryColor }}>CHOOSE YOUR SIZE</h5>
							<Select>
								{availableServices.map((el, i) => (
									<option key={i}>{el.name.toUpperCase()}</option>
								))}
							</Select>
							<div className="valign-wrapper" style={{ margin: '0' }}>
								<div style={{ width: '100%', textAlign: 'right' }}>
									<button style={styles.button.enabled} onClick={() => onSelect(false)} className="btn grey darken-2">
										No Thanks
									</button>
								</div>
								<div style={{ width: '100%', textAlign: 'left' }}>
									<button style={styles.button.enabled} onClick={() => setWanted(true)} className="btn">
										Add Service
									</button>
								</div>
							</div>
						</div>
					)}
					{wanted === true && showStage(stage)}
				</div>
			</div>
		</div>
	);
};

export default Item;
