import React, { useEffect, useContext } from 'react';
import M from 'materialize-css';
import PerfectScrollbar from 'perfect-scrollbar';
import { Link } from 'react-router-dom';
import { AuthContext } from '../auth-provider';

import './perfect-scrollbar.css';

const links = [
	{
		to: '/admin/users',
		allowed: ['admin'],
		icon: 'face',
		label: 'Users',
	},
	{
		to: '/admin/posts',
		allowed: ['admin'],
		icon: 'business',
		label: 'Posts',
	},
	{
		to: '/admin/customers',
		allowed: ['admin', 'hauler'],
		preview: ['can-manager'],
		icon: 'face',
		label: 'Customers',
	},
	{
		to: '/admin/services',
		allowed: ['admin', 'hauler'],
		icon: 'add_shopping_cart',
		preview: ['can-manager'],
		label: 'Services',
	},
	{
		to: '/admin/prices',
		allowed: ['admin', 'hauler'],
		icon: 'add_shopping_cart',
		preview: ['can-manager'],
		label: 'Prices',
	},
	{
		to: '/admin/company',
		allowed: ['admin', 'hauler'],
		icon: 'person_outline',
		preview: ['can-manager'],
		label: 'Company',
	},
	{
		to: '/admin/maps',
		allowed: ['admin', 'hauler'],
		preview: ['can-manager'],
		icon: 'map',
		label: 'Maps',
	},
	{
		to: '/admin/can-manager',
		allowed: ['admin', 'can-driver', 'can-manager'],
		icon: 'local_shipping',
		label: 'Can Manager',
	},
	{
		to: '/admin/can-go-backs',
		allowed: ['admin', 'can-driver', 'can-manager'],
		icon: 'local_shipping',
		label: 'Can Go Backs',
	},
];

export default function AdminSideNav(props) {
	const { logout, user } = useContext(AuthContext);

	useEffect(() => {
		new PerfectScrollbar('.leftside-navigation', {
			wheelSpeed: 1,
			wheelPropagation: false,
			minScrollbarLength: 20,
		});
		M.AutoInit();
	}, []);

	const routeContains = (el) => {
		return props.history.location.pathname.includes(el) ? 'active' : '';
	};

	const doLogout = () => {
		logout();

		props.history.push('/');
	};

	return (
		<aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light sidenav-active-square">
			<div className="brand-sidebar hide-on-med-and-down">
				<h1 className="logo-wrapper">
					<Link className={`brand-logo darken-1 waves-effect waves-cyan`} to="/admin" style={{ height: 'auto' }}>
						<img className="hide-on-med-and-down" src="/assets/images/logo/trashjoes-logo.png" alt="materialize logo" />
						<img className="show-on-medium-and-down hide-on-med-and-up" src="/assets/images/logo/trashjoes-logo.png" alt="materialize logo" />
						{/* <span className="logo-text hide-on-med-and-down">Trash Joes</span> */}
					</Link>
					{/* <a className="navbar-toggler" href="#!">
						<i className="material-icons">radio_button_checked</i>
					</a> */}
				</h1>
			</div>
			<ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow ps ps--active-y" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion" style={{ transform: 'translateX(0px)' }}>
				<li>
					<Link className={`waves-effect waves-cyan`} to="/admin" style={{ height: 'auto', marginTop: '10px', marginBottom: '10px' }}>
						<img style={{ margin: '0 auto' }} className="show-on-medium-and-down hide-on-med-and-up" src="/assets/images/logo/trashjoes-logo.png" alt="materialize logo" />
					</Link>
				</li>
				{/* {
					!['can-manager', 'can-driver'].includes(
						user.user.role.toLowerCase() && (
							<li className="active bold open">
								<Link className={`${routeContains('/admin/users')} waves-effect waves-cyan`} to="/admin">
									<i className="material-icons">settings_input_svideo</i>
									<span className="menu-title" data-i18n="Dashboard">
										Dashboard
									</span>
								</Link>
							</li>
						)
					)
				} */}
				<li className="navigation-header">
					<a href="#!" className="navigation-header-text">
						Applications
					</a>
					<i className="navigation-header-icon material-icons">more_horiz</i>
				</li>
				{links.map((link) => {
					if (link.preview && link.preview.includes(user.user.role.toLowerCase())) {
						return (
							<li key={link.to} className={`preview bold`}>
								<a className="waves-effect waves-cyan grey-text text-lighten-2">
									<i className="material-icons grey-text text-lighten-2">{link.icon}</i>
									<span className="menu-title" data-i18n="Users">
										{link.label}
									</span>
								</a>
							</li>
						);
					}

					if (!link.allowed.includes(user.user.role.toLowerCase())) return;

					return (
						<li key={link.to} className={`${routeContains(link.to)} bold`}>
							<Link className={`${routeContains(link.to)} waves-effect waves-cyan`} to={link.to}>
								<i className="material-icons">{link.icon}</i>
								<span className="menu-title" data-i18n="Users">
									{link.label}
								</span>
							</Link>
						</li>
					);
				})}

				<li className={`bold`}>
					<a href="#!" className="waves-effect waves-cyan" onClick={() => doLogout()}>
						<i className="material-icons">power_settings_new</i>
						<span className="menu-title" data-i18n="power">
							Logout
						</span>
					</a>
				</li>
				{!['can-manager', 'can-driver'].includes(user.user.role.toLowerCase()) && (
					<li className={`bold`}>
						<br />
						<br />
						<br />
						<a href="https://app.snapdesk.app/" target="_blank" className="waves-effect waves-cyan">
							<img src="https://messagedesk-strapi-storage.s3.us-west-2.amazonaws.com/L_and_M_x_Message_Desk_wordmark_horizontal_purple_c75261bf78.svg" alt="" />
						</a>
					</li>
				)}
			</ul>
			<div className="navigation-background"></div>
			<a className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only" href="#!" data-target="slide-out">
				<i className="material-icons" style={{ lineHeight: '40px' }}>
					menu
				</i>
			</a>
		</aside>
	);
}
