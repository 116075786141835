import React, { useState, useRef, useContext, useEffect } from 'react';
import M from 'materialize-css';
import moment from 'moment';
import { SignUpContext } from './context/signup';

const CardCans = ({ stepIndex, datestart, onSelect = () => console.log('onSelect on cans') }) => {
	const { hauler, steps, stepAnswers, availableServices, setAnswer, setAvailableServices, setAllServices } = useContext(SignUpContext);
	const { primaryColor, primaryTextColor } = hauler.theme;

	const [form, setForm] = useState({ datestart: '', ampm: '' });

	useEffect(() => {
		setForm({ ...form, datestart });
	}, [datestart]);

	useEffect(() => {
		const newForm = {
			...form,
			datestart: form.datestart,
		};
		setForm(newForm);

		// if (form.total) setAnswer({ stepIndex, answer: newForm });
	}, [form.datestart]);

	useEffect(() => {
		var elems = document.querySelectorAll('.datepicker');
		var instances = M.Datepicker.init(elems, {
			format: 'mm/dd/yyyy',
			onSelect: (val) => {
				setForm({ ...form, datestart: val === '' ? '2020-01-01' : val });
			},
			disableDayFn: (d) => {
				const isBetween = moment(d).isBetween(moment(d).subtract(1, 'm'), moment());
				return isBetween;
			},
		});
	}, []);

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: primaryTextColor,
	};

	return (
		<React.Fragment>
			<div style={{ flex: '0 0 80%' }}>
				<h5 style={{ marginTop: '0', color: primaryColor }}>When do you want to start this service?</h5>
				<br />
				<div className="row">
					<div className="col s12 m6 date-input">
						<input style={{ marginTop: '12px', textAlign: 'center' }} className="datepicker datestart" name="datestart" onChange={(el) => setForm({ ...form, datestart: el.target.value === '' ? '2020-01-01' : el.target.value })} type="text" />
					</div>
					<div className="col s12 m6 input-field">
						<select className="browser-default" name="ampm" onChange={(e) => setForm({ ...form, ampm: e.target.value })}>
							<option value="">SELECT</option>
							<option>AM</option>
							<option>PM</option>
						</select>
						<label className="active" htmlFor="ampm">
							Preferred Drop-Off
						</label>
					</div>
				</div>

				<div className="row" style={{ margin: '0' }}>
					{form.datestart && form.ampm && (
						<div className="col s10 offset-s1">
							<button style={primaryBgColor} onClick={() => onSelect(form)} className="btn">
								Confirm
							</button>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default CardCans;
