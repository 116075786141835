import React from 'react';
import List from './list';
import Edit from './edit';
import './user.css';

export default function UserList({ match, ...rest }) {
	if (match.params.userId) {
		return <Edit u={match.params.userId} {...rest} />;
	}
	return <List {...rest} />;
}
