//useFetch.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

function useFetch(url) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (url === '') return;
		setLoading('loading...');
		setData(null);
		setError(null);
		const source = axios.CancelToken.source();
		axios
			.get(url, { cancelToken: source.token, 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' })
			.then((res) => {
				setLoading(false);
				//checking for multiple responses for more flexibility
				//with the url we send in.
				// res.data.content && setData(res.data.content);
				res.data.data && setData(res.data.data);
				res.data && setData(res.data);
			})
			.catch((err) => {
				setLoading(false);
				setError('An error occurred. Awkward..');
			});
		return () => {
			source.cancel();
		};
	}, [url]);

	return { data, loading, error };
}

export const useFormSubmit = (url, q) => {
	const [status, setStatus] = useState('idle');
	const [data, setData] = useState([]);

	const postData = async (formData) => {
		setStatus('fetching');

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});

			const responseData = response.json();

			setData(responseData);
			setStatus('fetched');
			return responseData;
		} catch (e) {
			setData(e);
			setStatus('failed');
		}
	};

	return { postData, status, data };
};

export default useFetch;
