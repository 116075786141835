import React, { useMemo, useEffect, useState } from 'react';
import M from 'materialize-css';
import priceService from '../../../services/prices';
import Table from './table';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../../../components/Loader';
import { useCompanies, Select } from '../../../hooks/useCompanies';

export default function List({ auth }) {
	const [prices, setPrices] = useState({ data: [], fetching: false });
	const [filter, setFilter] = useState({});
	const { token } = auth.user;

	const [companyId, setCompanyId] = useState(auth.user.user.company.id);

	const [companies] = useCompanies({ token });
	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	const columns = useMemo(
		() => [
			{
				Header: 'Prices',
				columns: [
					{
						Header: 'ID',
						accessor: 'uid',
					},
					{
						Header: 'Price Name',
						accessor: 'name',
					},
				],
			},
		],
		[]
	);

	useEffect(() => {
		let rendered = true;

		function getPrices() {
			setPrices({ ...prices, fetching: true });
			new priceService()
				.getPrices(token, companyId)
				.then((json) => {
					if (rendered) {
						setPrices({ ...prices, data: json, fetching: false });
					}
				})
				.catch((e) => {
					setPrices({ ...prices, fetching: false });
				});
		}

		getPrices();

		return () => (rendered = false);
	}, [companyId, token]);

	useEffect(() => {
		M.AutoInit();
	});

	const unique = (a) => {
		let seen = {};

		return a.filter((item) => (seen.hasOwnProperty(item.name) ? false : (seen[item.name] = true)));
	};

	const tableData = unique(prices.data);

	return (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Your Prices List</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<a href="/">Home</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#!">Prices</a>
								</li>
								<li className="breadcrumb-item active">Prices List</li>
							</ol>
						</div>
						<div className="col s2 m6 l6">
							<a className="btn waves-effect waves-light breadcrumbs-btn right" href={`/admin/prices/e/${uuidv4()}`}>
								<i className="material-icons hide-on-med-and-up">add</i>
								<span className="hide-on-small-onl">Add</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<section className="users-list-wrapper section">
					<div className="users-list-filter">
						<div className="card-panel">
							<div className="row">
								<div className="col s12 m6 l3">
									<div className="input-field">
										<label htmlFor="users-list-verified" className="active">
											Search
										</label>
										<input placeholder="Search here" onChange={(e) => setFilter({ ...filter, query: e.target.value })} name="query" id="query" defaultValue={filter.query} />
									</div>
								</div>
								{auth.user.user.role === 'admin' && (
									<div className="col s12 m6 l9">
										<div className="input-field">
											<Select onChange={(e) => setCompanyId(e.target.value)} options={adminCompanySelect} value={companyId} />
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="users-list-table">
						<div className="card">
							<div className="card-content">
								<div className="responsive-table">{!prices.fetching ? <Table columns={columns} data={tableData} /> : <Loading />}</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
}
