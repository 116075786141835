import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/materializeOverride.scss';
import './styles/materialize.modern.admin.scss';
import App from './app';
import { Provider } from './components/auth-provider';

ReactDOM.render(
	<React.StrictMode>
		<Provider>
			<Router>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
