import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ArrowToTop from '../ScrollTopArrow';
import './hero.css';
import NavLinks from './navlinks';
import M from 'materialize-css';
import LadyTrash from './TrashJoes-hero.jpg';
import logo from './TrashJoes-logo.png';
import logoInteri from './TJ-Interior-LOGO.png';
import { AuthContext } from '../auth-provider';

const Page = ({ home = false, ...props }) => {
	const { user, logout } = React.useContext(AuthContext);
	const history = useHistory();

	const heroImage = {
		// backgroundImage: 'url(' + LadyTrash + ')',
		// height: '750px',
		// backgroundPosition: 'center',
		// backgroundRepeat: 'no-repeat',
		// backgroundSize: 'cover',
		// position: 'relative',
	};

	const doLogout = () => {
		logout();

		history.push('/');
	};

	useEffect(() => {
		M.Sidenav.init(document.getElementById('mobile-nav'));
	}, []);

	const closeMobileNav = () => {
		let elem = document.getElementById('mobile-nav');
		let instance = M.Sidenav.getInstance(elem);
		instance.close();
	};

	return (
		<div className="home">
			{home && (
				<div className="container">
					<div className={home && `hero-image`} style={home ? heroImage : {}}>
						<div className="nav-wrapper">
							<header className="row" style={{ position: 'relative', zIndex: '100' }}>
								<ul className="hide-on-med-and-down" style={{ position: 'absolute', right: 0, top: 0, margin: 0 }}>
									<li className="right">
										{user && user.user && user.user.id ? (
											<Link onClick={doLogout} className=" white-text btn signup hide-on-small-only" to="/">
												LOGOUT
											</Link>
										) : (
											<>
												<Link className="white-text btn signup hide-on-small-only" to="/login" style={{ marginRight: 10 }}>
													Can Manager Login
												</Link>
												<Link className="white-text btn signup hide-on-small-only" to="/login">
													Online Sales Dashboard login
												</Link>
											</>
										)}
									</li>
									{user && user.user && user.user.id && (
										<li className="right">
											<Link className="white-text btn" to="/admin/company" style={{ paddingLeft: '20px', paddingRight: '20px', marginRight: '10px' }}>
												Dashboard
											</Link>
										</li>
									)}
								</ul>
								<nav className="transparent" style={home ? { boxShadow: 'none' } : { height: 'auto', boxShadow: 'none' }}>
									<ul className="right hide-on-med-and-down" style={{ position: 'absolute', right: '0', top: '45px' }}>
										<NavLinks closeMobileNav={closeMobileNav} />
									</ul>
									<a href="#!" data-target="mobile-nav" className="right sidenav-trigger" style={{ top: '25px' }}>
										<i className="material-icons">menu</i>
									</a>

									<Link className="" to="/">
										<img className={home ? 'logoPoHome' : 'logoPoInter'} alt="" src={logo} />
									</Link>
									{/* <Link className="" to="/">
										<img className={home ? 'logoPoHome' : 'logoPoInter'} alt="" style={home ? { margin: '20px 0 0 130px', color: 'white' } : { height: '100px', margin: '20px 0 0 130px' }} src={logo} />
									</Link> */}
								</nav>
							</header>
						</div>
					</div>
				</div>
			)}
			{!home && (
				<div style={{ backgroundColor: '#ed1b24' }}>
					<div className="row container">
						<div className="col s12 m12 l12">
							<header className="interior" style={{ position: 'relative', zIndex: '100' }}>
								<ul className="hide-on-med-and-down" style={{ position: 'absolute', right: 0, top: '-20px', margin: 0 }}>
									<li className="right">
										{user && user.user && user.user.id ? (
											<Link onClick={doLogout} className="white red-text btn signup hide-on-small-only" to="/">
												LOGOUT
											</Link>
										) : (
											<>
												<Link className="red-text white btn signup hide-on-small-only" to="/login" style={{ marginRight: 10 }}>
													Can Manager Login
												</Link>
												<Link className="red-text white btn signup hide-on-small-only" to="/login">
													Online Sales Dashboard login
												</Link>
											</>
										)}
									</li>
									{user && user.user && user.user.id && (
										<li className="right">
											<Link className="white red-text btn" to="/admin/company" style={{ paddingLeft: '20px', paddingRight: '20px', marginRight: '10px' }}>
												Dashboard
											</Link>
										</li>
									)}
								</ul>
								<nav className="transparent" style={home ? { boxShadow: 'none' } : { boxShadow: 'none' }}>
									<ul className="right hide-on-med-and-down" style={{ position: 'absolute', right: '0px', bottom: 0 }}>
										<NavLinks closeMobileNav={closeMobileNav} />
									</ul>
									<a href="#!" data-target="mobile-nav" className="sidenav-trigger" style={{ position: 'absolute', right: '-10px', top: '10px' }}>
										<i className="material-icons white-text">menu</i>
									</a>

									<Link className="" to="/">
										<img className="" alt="" src={logoInteri} />
									</Link>
								</nav>
							</header>
						</div>
					</div>
				</div>
			)}
			<ul className="sidenav" id="mobile-nav">
				<li>
					<Link className="hvr-underline-reveal111" to="/" onClick={() => closeMobileNav()}>
						HOME
					</Link>
				</li>
				<NavLinks closeMobileNav={closeMobileNav} />
				<li>
					<Link className="hvr-underline-reveal111" to="/login" onClick={() => closeMobileNav()}>
						DASHBOARD LOGIN
					</Link>
				</li>
			</ul>
			<ArrowToTop />
		</div>
	);
};
export default Page;
