import React, { useEffect, useState, useRef } from 'react';
import mapService from '../../../services/maps';
import M from 'materialize-css';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/Loader';
import { SketchPicker } from 'react-color';
import MapContainer from './map';
import { kml } from '@tmcw/togeojson';
import Axios from 'axios';
import { Select, useCompanies } from '../../../hooks/useCompanies';
import { googleApiKey } from '../../../config';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const Edit = (props) => {
	const initialForm = {
		name: '',
		geojson: '',
		active: 1,
		companyid: 0,
		color: '#4fc3f7',
	};

	const { register, handleSubmit, errors, setValue, getValues } = useForm({
		mode: 'onChange',
	});
	const [form, setForm] = useState(initialForm);
	const [displayPicker, setDisplayPicker] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [mapGeoJSON, setMapGeoJSON] = useState([]);
	const { u, auth } = props;
	const hiddenFileInput = useRef(null);
	const history = useHistory();
	const [companyId, setCompanyId] = useState(auth.user.user.company.id);
	const { token } = auth.user;

	const [companies] = useCompanies({ token });
	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	useEffect(() => {
		let rendered = true;

		function getMap() {
			setFetching(true);

			const a = () =>
				new mapService().getMap(u || 0, auth.user.token).then((json) => {
					if (json && rendered) {
						setForm(json);
						auth.user.user.role === 'admin' && setCompanyId(json.companyid);
					}
				});

			Promise.all([a()])
				// .then((response) => response))
				.then((json) => {
					if (json && rendered) {
						setFetching(false);
					}
				})
				.catch((e) => {
					if (e && rendered) {
						setFetching(true);
					}
				});
		}

		getMap();
		return () => (rendered = false);
	}, [auth.user.token, u, auth.user.user.role]);

	useEffect(() => {
		function getJSON() {
			try {
				const json = JSON.parse(form.geojson);

				setMapGeoJSON(json.features[0].geometry.coordinates[0]);
			} catch (e) {}
		}

		getJSON();
	}, [form.geojson]);

	useEffect(() => {
		if (!fetching) {
			M.AutoInit();
			M.updateTextFields();
		}
	}, [fetching]);

	if (fetching) {
		return <Loading />;
	}

	const onSubmit = (values) => {
		values.id = Number(u);
		values.companyid = Number(companyId);

		new mapService().update(values, auth.user.token).then((json) => {
			if (json) {
				M.toast({ html: 'Map Saved!', classes: 'green white-text' });
				history.push('/admin/maps');
			}
		});
	};

	const deleteMap = (id) => {
		const Authorization = ` Bearer ${btoa(auth.user.token)}`;
		Axios({
			url: `${URL}maps.cfc?method=deleteMap&id=${id}`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: Authorization,
			},
		})
			.then((d) => d.status === 200 && d.data)
			.then((d) => d.success && M.toast({ html: 'Map was deleted' }))
			.catch((e) => M.toast({ html: 'Error delete the service' }))
			.finally((e) => history.push('/admin/maps'));
	};

	const openPicker = () => {
		setDisplayPicker(true);
	};

	const closePicker = () => {
		setDisplayPicker(false);
	};

	const onJSONFileUpload = (e) => {
		try {
			const file = e.target.files[0];

			const parseGeoJSON = (data) => {
				const res = JSON.parse(data);
				res.name = getValues('name');
				delete res['crs'];
				return res;
			};
			const parseKML = (data) => {
				const res = kml(new DOMParser().parseFromString(data, 'text/xml'));
				res.name = getValues('name');
				delete res['crs'];
				return res;
			};
			if (file) {
				const fileTypes = ['json', 'geojson', 'kml'];
				const ext = file.name.split('.').pop().toLowerCase();
				const isAccepted = fileTypes.indexOf(ext) > -1;

				if (isAccepted) {
					const fileReader = new FileReader();
					fileReader.readAsText(file, 'UTF-8');
					fileReader.onload = (e) => {
						let json = {};
						switch (ext) {
							case 'json':
							case 'geojson':
								json = parseGeoJSON(e.target.result);
								break;
							case 'kml':
								json = parseKML(e.target.result);
								break;
							default:
								M.toast({ html: 'Unable to parse document', classes: 'red white-text' });
								break;
						}
						setForm({ ...form, geojson: JSON.stringify(json) });
						setValue(
							'geojson',
							JSON.stringify(json)
								.replace(/[\t\r\n]/gi, '')
								.replace(/ {1,}/g, ' ')
						);
					};
				} else {
					M.toast({ html: 'Invalid format', classes: 'red white-text' });
				}
			}
		} catch (e) {
			M.toast({ html: 'Error applying map, please check format, geojson or kml', classes: 'red white-text' });
		}
	};

	const cover = {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	};

	const popover = {
		position: 'absolute',
		zIndex: '2',
	};

	return (
		<React.Fragment>
			{/* <input style={{ display: 'none' }} type="file" onChange={(e) => handleImageUpload(e)} ref={(fi) => setFileInput(fi)} /> */}
			<div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Map edit</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<Link to="/admin">Home</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/admin/maps">Maps</Link>
								</li>
								<li className="breadcrumb-item active">Map Edit</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<div className="container">
					<div className="section users-edit">
						<div className="card">
							<div className="card-content">
								<ul className="tabs mb-2 row">
									<li className="tab">
										<a className="display-flex align-items-center active" id="account-tab" href="#account">
											{' '}
											<i className="material-icons mr-1">person_outline</i>
											<span>Details</span>{' '}
										</a>
									</li>
								</ul>
								<div className="divider mb-3"></div>
								<div className="row">
									<div className="col s12" id="account">
										<form id="customerForm" onSubmit={handleSubmit(onSubmit)}>
											<div className="row">
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">
															<input defaultValue={form.name} id="name" name="name" type="text" ref={register({ required: 'Map Name is required!' })} />
															<label htmlFor="name">Map Name</label>
															<span className="red-text helper-text">{errors.name && errors.name.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">{auth.user.user.role === 'admin' && <Select label="Select Company Owner" firstOption="Select Company" onChange={(e) => setCompanyId(e.target.value)} options={adminCompanySelect} value={companyId} />}</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">
															<i onClick={openPicker} className="material-icons prefix">
																format_color_fill
															</i>
															<input defaultValue={form.color} id="color" name="color" type="text" ref={register({ required: 'Map Color is required!' })} />
															{displayPicker && (
																<div style={popover}>
																	<div style={cover} onClick={closePicker} />
																	<SketchPicker
																		color={form.color}
																		onChangeComplete={(c) => {
																			setValue('color', c.hex);
																			setForm({ ...form, color: c.hex });
																		}}
																	/>
																</div>
															)}
															<label htmlFor="color">Map Overlay Color</label>
															<span className="red-text helper-text">{errors.name && errors.name.message}</span>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col s12">
														<div className="row">
															<div className="col s12 input-field">
																<i onClick={() => hiddenFileInput.current.click()} style={{ cursor: 'pointer' }} className="material-icons prefix">
																	cloud_upload
																</i>
																<input ref={hiddenFileInput} type="file" onChange={onJSONFileUpload} style={{ position: 'fixed', left: '9000px' }} />
																<input defaultValue={form.geojson} type="hidden" name="geojson" id="geojson" ref={register({ required: 'GeoJSON is required!' })} />
																<textarea defaultValue={form.geojson.length > 0 && 'uploaded'} placeholder="click icon to left to upload your kml file" name="d_geojson" id="geojson" className="materialize-textarea" ref={register({ required: 'GeoJSON is required!' })}></textarea>
																<label htmlFor="geojson">GeoJSON Data</label>
																<div style={{ position: 'relative', height: '300px' }}>
																	<MapContainer color={getValues('color')} layers={[mapGeoJSON]} markers={[]} onMarkerClick={() => true} apiKey={googleApiKey} />
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col s12 display-flex justify-content-end mt-3">
													<Link to="/admin/maps" style={{ marginRight: 'auto' }} className="btn btn-light">
														Cancel
													</Link>
													<button type="submit" className="btn indigo">
														Save changes
													</button>
													<button type="button" onClick={() => deleteMap(form.id)} className="btn red white-text">
														Delete
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-overlay"></div>
			</div>
		</React.Fragment>
	);
};

export default Edit;
