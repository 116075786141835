import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import serviceService from '../../../services/services';
import mapService from '../../../services/maps';
import priceService from '../../../services/prices';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import MapContainer from '../Maps/map';
import Loading from '../../../components/Loader';
import PricingTable from './edit.pricingTable';
import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';
import percentIco from './percent-512.png';
import { Select, useCompanies } from '../../../hooks/useCompanies';
import { googleApiKey } from '../../../config';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const ChangingSelect = ({ children, ...props }) => {
	const selectRef = useRef();

	useEffect(() => {
		if (selectRef.current) M.FormSelect.init(selectRef.current, {});
	}, [children, selectRef]);

	return (
		<select {...props} ref={selectRef}>
			{children}
		</select>
	);
};

const Edit = (props) => {
	const { register, handleSubmit, errors, setValue, getValues, watch } = useFormContext();
	const [maps, setMaps] = useState({ data: [] });
	const [companyPrices, setCompanyPrices] = useState(null);
	const [selectedMap, setSelectedMap] = useState([{ color: '#333', data: [] }]);
	const { u, auth, serviceData, setServiceData, setCompanyId, companyId } = props;
	const history = useHistory();

	const { token } = auth.user;

	const {
		user: {
			user: { company },
		},
	} = auth;

	const [companies] = useCompanies({ token });
	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	const [type, setType] = useState('Residential');
	const watchedType = watch('type');
	// const watchedPaymentType = watch('paymentType');
	// const watchedPricingRelation = watch('pricing_relation');

	const fetchMaps = () => {
		const fetch = new mapService()
			.getMaps(auth.user.token, companyId)
			.then((json) => json)
			.catch((e) => {
				setMaps({ ...maps });
			});

		return fetch;
	};

	useEffect(() => {
		const doFetch = async () => {
			const fetchedMaps = await fetchMaps();

			setMaps({ data: fetchedMaps });
		};

		const getPrices = async () => {
			const prices = await new priceService().getPrices(auth.user.token, companyId);

			setCompanyPrices(prices);
		};

		getPrices();
		doFetch();
	}, [companyId]);

	useEffect(() => {
		const _service = serviceData.data;
		const _maps = maps.data;

		if (_service.mapid && _maps.length > 0) {
			doMapUpdate(_maps, _service.mapid);
			setValue('mapid', _service.mapid);
		}
	}, [maps, serviceData]);

	// useEffect(() => {
	// 	const getPrices = async () => {
	// 		const prices = await new priceService().getPrices(auth.user.token, auth.user.user.company.id);

	// 		setCompanyPrices(prices);
	// 	};

	// 	getPrices();
	// }, []);

	useEffect(() => {
		let rendered = true;

		const getService = async () => {
			setServiceData({ ...serviceData, fetching: true });

			const service = await new serviceService()
				.getService(u)
				// .then((response) => response))
				.then((json) => {
					if (rendered && json) {
						return json;
					}
				})
				.catch((e) => {
					setServiceData({ ...serviceData, fetching: true });
				});

			try {
				if (rendered) {
					setServiceData({ ...serviceData, data: service, fetching: false });
					setValue('name', service.name);
					setValue('label', service.label);
					setValue('type', service.type);
					setValue('allowedTons', service.allowedtons);
					setValue('price', service.price);
					setValue('daysavailable', service.daysavailable);
					setValue('cansoffered', service.cansoffered);
					setValue('mapid', service.mapid);
					setValue('tax', service.tax);
					setValue('rollOff_proRateDays', service.rolloff_proratedays);
					setValue('rollOff_proRate', service.rolloff_prorate);
					setValue('rollOff_perDayRate', service.rolloff_perdayrate);
					setValue('rollOff_tonOverageCharge', service.rolloff_tonoveragecharge);
					setValue('rollOff_size', service.rolloff_size);
					setValue('description', service.description);

					auth.user.user.role === 'admin' && setCompanyId(service.companyid);

					// doMapUpdate(allMaps, service.mapid);
				}
			} catch (e) {}

			// setTimeout(() => {
			// 	updateMapData(getValues('mapid'));
			// }, 1000);
		};

		getService();

		return () => (rendered = false);
	}, [u]);

	const doMapUpdate = (_maps, sel) => {
		try {
			const d = _maps.filter((el) => {
				return el.id == sel;
			});
			const json = JSON.parse(d[0].geojson);
			setSelectedMap({ ...d[0], color: d[0].color, data: json.features[0].geometry.coordinates[0] });
		} catch (e) {
			setSelectedMap({ color: '#333', data: [] });
		}
	};

	const updateMapData = (i) => {
		doMapUpdate(maps.data, i);
	};

	useEffect(() => {
		window.setTimeout(() => {
			M.AutoInit();
			M.updateTextFields();
		}, 500);
	}, [serviceData]);

	if (serviceData.fetching) {
		return <Loading />;
	}

	const onSubmit = (values) => {
		values.id = Number(u);
		values.companyid = Number(auth.user.user.company.id);
		values.service_prices = serviceData.data.prices;

		return;

		new serviceService().update(values, auth.user.token).then((json) => {
			if (json && json.success) {
				M.toast({ html: 'Service Saved!', classes: 'green white-text' });
				history.push(`/admin/services/e/${json.service.id}`);
			}
		});
	};

	const addPricing = (newPricing) => setServiceData({ ...serviceData, data: { ...serviceData.data, prices: [...serviceData.data.prices, { ...newPricing, id: uuidv4() }] } });

	const removePricing = (id) => {
		const newPricing = serviceData.data.prices.filter((el) => el.id !== id);

		setServiceData({ ...serviceData, data: { ...serviceData.data, prices: newPricing } });
	};

	const updatePricing = (pricing) => {
		const newPricing = serviceData.data.prices.reduce((end, el) => {
			if (el.id === pricing.id) {
				end = end.concat([pricing]);
			} else {
				end = end.concat([el]);
			}
			return end;
		}, []);

		setServiceData({ ...serviceData, data: { ...serviceData.data, prices: newPricing } });
	};

	const deleteService = (id) => {
		const Authorization = ` Bearer ${btoa(auth.user.token)}`;
		Axios({
			url: `${URL}services.cfc?method=deleteService&id=${id}`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: Authorization,
			},
		})
			.then((d) => d.status === 200 && d.data)
			.then((d) => {
				M.toast({ html: d.success ? 'Service deleted' : 'Technical error, unable to delete, please contact Trash Joes' });
				if (d.success) {
					history.push('/admin/services');
				}
			})
			.catch((e) => M.toast({ html: 'Technical error, unable to delete, please contact Trash Joes' }));
	};

	const pricingTableAttributes = {
		setValue,
		getValues,
		companyPrices,
	};

	return (
		<>
			<div className="row">
				<div className="col s12 m6">
					<div className="row">
						<div className="col s12 input-field">
							<input id="name" name="name" type="text" ref={register({ required: 'Name is required!' })} />
							<label htmlFor="name">Public Name</label>
							<span className="red-text helper-text">{errors.name && errors.name.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m6">
					<div className="row">
						<div className="col s12 input-field">
							<input id="label" name="label" type="text" ref={register({ required: 'Label is required!' })} />
							<label htmlFor="label">Hauler Label</label>
							<span className="red-text helper-text">{errors.label && errors.label.message}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m6">
					<div className="row">
						<div className="col s12 input-field" style={{ marginBottom: 0 }}>
							<select name="type" id="type" onChange={(e) => setType(e.target.value)} ref={register({ required: 'Service Type is required!' })}>
								{company.servicesallowed.includes('Commercial') && <option value="Commercial">Commercial</option>}
								{company.servicesallowed.includes('Residential') && <option value="Residential">Residential</option>}
								{company.servicesallowed.includes('Roll-Offs') && <option value="Roll-Offs">Roll-Offs</option>}
							</select>
							{/* <input id="name" name="name" type="text" ref={register({ required: 'Name is required!' })} /> */}
							<label htmlFor="type">Type</label>
							<span className="red-text helper-text">{errors.type && errors.type.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m6">
					<div className="row">
						<div className="col s12 input-field" style={{ marginBottom: 0 }}>
							{auth.user.user.role === 'admin' && <Select label="Select Company Owner" firstOption="Select Company" onChange={(e) => setCompanyId(e.target.value)} options={adminCompanySelect} value={companyId} />}
						</div>
					</div>
				</div>
			</div>
			{(watchedType || '').toLowerCase() === 'residential' && (
				<div className="row">
					<div className="col s12 input-field">
						<textarea style={{ padding: 10 }} ref={register} name="description" id="description"></textarea>
						<label htmlFor="description" style={{ marginLeft: 20 }}>
							Service Description <small>(Small Residential Trash Bin that holds ~6 Trash Bags)</small>
						</label>
					</div>
				</div>
			)}
			{(watchedType || '').toLowerCase() === 'roll-offs' && (
				<div className="row">
					{/* add days hauler can block out */}
					{/* disable all sat and/or sun */}
					<div className="col s12">
						<p>This service package includes the following</p>
					</div>
					<div className="col s12 m4 input-field">
						<input id="rollOff_proRateDays" name="rollOff_proRateDays" type="number" step="1" min="0" ref={register} />
						<label htmlFor="rollOff_proRateDays">Total Days</label>
						<span className="red-text helper-text">{errors.rollOff_proRateDays && errors.rollOff_proRateDays.message}</span>
					</div>
					<div className="col s12 m4 input-field">
						<input id="rollOff_proRate" name="rollOff_proRate" type="number" step="0.01" min="0" ref={register} />
						<label htmlFor="rollOff_proRate">Base Charge</label>
						<span className="red-text helper-text">{errors.rollOff_proRate && errors.rollOff_proRate.message}</span>
					</div>
					<div className="col s12 m4 input-field">
						<input id="rollOff_perDayRate" name="rollOff_perDayRate" type="number" step="0.01" min="0" ref={register} />
						<label htmlFor="rollOff_perDayRate">Additional Days Charge</label>
						<span className="red-text helper-text">{errors.rollOff_perDayRate && errors.rollOff_perDayRate.message}</span>
					</div>
					<div className="col s12 m4 input-field">
						<input id="rollOff_size" name="rollOff_size" type="number" step="1" min="0" ref={register} />
						<label htmlFor="rollOff_size">Roll Off Size ( 10 for "10 Yard Roll Off" )</label>
						<span className="red-text helper-text">{errors.rollOff_size && errors.rollOff_size.message}</span>
					</div>
					<div className="col s12 m4 input-field">
						<input id="allowedTons" name="allowedTons" type="number" step="1" min="0" ref={register} />
						<label htmlFor="allowedTons">Tons Allowed</label>
						<span className="red-text helper-text">{errors.allowedTons && errors.allowedTons.message}</span>
					</div>
					<div className="col s12 m4 input-field">
						<input id="rollOff_tonOverageCharge" name="rollOff_tonOverageCharge" type="number" step="0.01" min="0" ref={register} />
						<label htmlFor="rollOff_tonOverageCharge">Charge Per Ton Over</label>
						<span className="red-text helper-text">{errors.rollOff_tonOverageCharge && errors.rollOff_tonOverageCharge.message}</span>
					</div>
				</div>
			)}
			<div className="row">
				{(watchedType || '').toLowerCase() !== 'roll-offs' && (
					<>
						<div className="col s12 m4">
							<div className="row">
								<div className="col s12 input-field">
									<select multiple={true} name="daysavailable" id="daysavailable" ref={register({ required: 'Service Day is required!' })}>
										<option value="1">Sunday</option>
										<option value="2">Monday</option>
										<option value="3">Tuesday</option>
										<option value="4">Wednesday</option>
										<option value="5">Thursday</option>
										<option value="6">Friday</option>
										<option value="7">Saturday</option>
									</select>
									{/* <input id="name" name="name" Day="text" ref={register({ required: 'Name is required!' })} /> */}
									<label htmlFor="daysavailable">Service Day</label>
									<span className="red-text helper-text">{errors.daysavailable && errors.daysavailable.message}</span>
								</div>
							</div>
						</div>
						<div className="col s12 m4 hide">
							<div className="row">
								<div className="col s12 input-field">
									<input id="cansoffered" name="cansoffered" type="number" step="1" min="0" ref={register({ required: 'You must tell us max can you offer!' })} />
									<label htmlFor="cansoffered">Maximum Cans Offered</label>
									<span className="red-text helper-text">{errors.cansoffered && errors.cansoffered.message}</span>
								</div>
							</div>
						</div>
					</>
				)}
				<div className="col s12 m4">
					<div className="row">
						<div className="col s12 input-field">
							<select name="mapid" id="mapid" ref={register({ required: 'What is the map this service uses?!' })} onChange={(e) => updateMapData(e.target.value)} onLoad={(e) => updateMapData(e.target.value)}>
								<option>SELECT MAP</option>
								{maps.data.map((m, i) => (
									<option key={i} value={m.id}>
										{m.name}
									</option>
								))}
							</select>
							<label htmlFor="mapid">Select a Map Route</label>
							<span className="red-text helper-text">{errors.mapid && errors.mapid.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m6">
					<div className="row">
						<div className="col s12 input-field">
							<select name="active" id="active" ref={register}>
								<option value="1">Yes</option>
								<option value="0">No</option>
							</select>
							{/* <input id="name" name="name" type="text" ref={register({ required: 'Name is required!' })} /> */}
							<label htmlFor="type">Active</label>
							<span className="red-text helper-text">{errors.active && errors.active.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m6">
					<div className="row">
						<div className="col s12 input-field">
							<i className="material-icons prefix center-align">
								<img src={percentIco} style={{ height: '30px' }} alt="" />
							</i>
							<input id="tax" name="tax" step="1" min="0" type="number" ref={register({ required: 'Tax percent is required!' })} />
							<label htmlFor="tax">Tax ( #'s only )</label>
							<span className="red-text helper-text">{errors.active && errors.active.message}</span>
						</div>
					</div>
				</div>
			</div>
			{(watchedType || '').toLowerCase() !== 'roll-offs' && (
				<div className="row">
					<PricingTable {...pricingTableAttributes} type={getValues('type')} prices={serviceData.data.prices} addPricing={addPricing} removePricing={removePricing} updatePricing={updatePricing} serviceid={serviceData.data.id} />
				</div>
			)}
			<div className="row" style={{ position: 'relative', height: '300px' }}>
				<MapContainer color={selectedMap.color} layers={[selectedMap.data]} markers={[]} onMarkerClick={() => true} apiKey={googleApiKey} />
			</div>
			<div className="col s12 display-flex justify-content-end mt-3">
				<Link to="/admin/services" style={{ marginRight: 'auto' }} className="left btn btn-light">
					Cancel
				</Link>
				<button type="submit" className="btn indigo">
					Save changes
				</button>
				<button onClick={() => deleteService(serviceData.data.id)} type="button" className="btn red white-text btn-light">
					Delete
				</button>
			</div>
		</>
	);
};

const SelectBox = ({ register, setValue, errors }) => {
	useEffect(() => {
		M.AutoInit();
	}, []);

	return (
		<div className="col s12 input-field">
			<select name="paymentType" id="paymentType" onChange={(e) => setValue('paymentType', e.target.value)} ref={register}>
				<option value="Fixed">Fixed</option>
				<option value="Per Day">Per Day</option>
			</select>
			{/* <input id="name" name="name" type="text" ref={register({ required: 'Name is required!' })} /> */}
			<label htmlFor="paymentType">Payment Type</label>
			<span className="red-text helper-text">{errors.paymentType && errors.paymentType.message}</span>
		</div>
	);
};

const RollOffDaysPricing = ({ getValues, register, errors }) => {
	const [rows, setRows] = useState([]);

	const addRow = () => setRows((r) => [...r, { id: uuidv4() }]);
	const removeRow = (i) => setRows((r) => r.filter((el) => el.id !== i));

	return (
		<div className="row" style={{ marginBottom: '20px' }}>
			<fieldset style={{ border: '1px solid lightgrey' }}>
				<div className="col s12">
					<button type="button" className="btn btn-small green white-text" onClick={addRow}>
						Add Option
					</button>
				</div>
				{rows.map((e) => (
					<div className="col s12" key={e.id}>
						<div className="row" style={{ marginBottom: 0 }}>
							{(getValues('paymentType') || '').toLowerCase() === 'fixed' && (
								<div className="col s12 m3 input-field">
									<input id="days" name="days" type="number" step="1" min="1" ref={register} />
									<label htmlFor="days">Days</label>
									<span className="red-text helper-text">{errors.days && errors.days.message}</span>
								</div>
							)}
							<div className="col s12 m3 input-field">
								<input id="amount" name="amount" type="number" step="0.01" min="0" ref={register} />
								<label htmlFor="amount">Amount</label>
								<span className="red-text helper-text">{errors.amount && errors.amount.message}</span>
							</div>
							<div className="col s12 m3 input-field">
								<input id="amount" name="amount" type="number" step="0.01" min="0" ref={register} />
								<label htmlFor="amount">Per Day Over Additional Charge</label>
								<span className="red-text helper-text">{errors.amount && errors.amount.message}</span>
							</div>
							{(getValues('paymentType') || '').toLowerCase() === 'per day' && (
								<div className="col s12 m3 input-field">
									<button className="btn btn-small red white-text" onClick={() => removeRow(e.id)}>
										Delete
									</button>
								</div>
							)}
						</div>
					</div>
				))}
			</fieldset>
		</div>
	);
};

const EditWrapper = (props) => {
	const { u, auth } = props;

	const methods = useForm();
	const history = useHistory();
	const [serviceData, setServiceData] = useState({ data: {}, fetching: false });
	const [companyId, setCompanyId] = useState(auth.user.user.company.id);

	const onSubmit = (values) => {
		values.id = Number(u);
		values.companyid = Number(companyId);
		values.service_prices = serviceData.data.prices;

		new serviceService().update(values, auth.user.token).then((json) => {
			if (json && json.success) {
				M.toast({ html: 'Service Saved!', classes: 'green white-text' });
				history.push(`/admin/services/e/${json.service.id}`);
			}
		});
	};

	return (
		<>
			{/* <input style={{ display: 'none' }} type="file" onChange={(e) => handleImageUpload(e)} ref={(fi) => setFileInput(fi)} /> */}
			<div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Service edit</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<Link to="/admin">Home</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/admin/services">Services</Link>
								</li>
								<li className="breadcrumb-item active">Service Edit</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<div className="container">
					<div className="section users-edit">
						<div className="card">
							<div className="card-content">
								<div className="row">
									<div className="col s12" id="account">
										<FormProvider {...methods}>
											<form onSubmit={methods.handleSubmit(onSubmit)}>
												<Edit {...{ ...props, serviceData, setServiceData, companyId, setCompanyId }} />
											</form>
										</FormProvider>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-overlay"></div>
			</div>
		</>
	);
};

export default EditWrapper;
