import React, { useContext, useEffect, useState } from 'react';
import { SignUpContext } from './context/signup';
import ServiceItem from '../../components/ServiceListItem/clean';
import ServiceItemRollOff from '../../components/ServiceListItem/rolloff';
import M from 'materialize-css';
import CurrencyFormat from 'react-currency-format';
import { useStyles } from './elementStyles';
import { v4 as uuidv4 } from 'uuid';

import { SvgRollOffs } from './svgs';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

const CardAddress = ({ step, goToStep, stepIndex }) => {
	const { availableServices, getStepAnswers, setAvailableServices, setAnswer, stepAnswers, hauler } = useContext(SignUpContext);
	const { primaryColor, primaryTextColor } = hauler.theme;

	const { styles } = useStyles();

	const ApplyService = (service, add, cans) => {
		if (add) {
			setAnswer({ stepIndex, answer: [...choosenServices, { ...service, cans }] });

			M.toast({ html: `${service.name} added` });
		}

		const updatedServices = {
			availableServices: availableServices.reduce((arr, el) => arr.concat([el.id === service.id ? { ...el, requested: add } : { ...el }]), []),
		};

		setAvailableServices(updatedServices);
	};

	useEffect(() => {
		if (stepAnswers[stepIndex]) {
		}
	}, [stepAnswers[stepIndex], stepAnswers, stepIndex]);

	const choosenServices = stepAnswers[stepIndex] || [];

	const serviceOptions = availableServices.filter((el) => {
		return el.requested === undefined;
	});

	const allServicesRequested = availableServices.reduce((tot, x) => (x.requested === undefined ? tot : tot + 1), 0) === availableServices.length;

	const resetRequestedServices = () => {
		setAvailableServices(
			availableServices.reduce((arr, el) => {
				delete el['requested'];
				return arr.concat([el]);
			}, [])
		);
		setAnswer({ stepIndex, answer: null });
	};

	const showService = serviceOptions[0];

	const requestedServices = getStepAnswers('services');

	const removeServiceFromRequested = (i) => {
		setAnswer({ stepIndex, answer: choosenServices.filter((el) => el.id !== i) });
		const updatedService = availableServices.reduce((arr, el) => {
			if (el.id === i) delete el['requested'];
			return arr.concat([el]);
		}, []);
		setAvailableServices(updatedService);
	};
	const editServiceFromRequested = (i) => {
		setAnswer({ stepIndex, answer: choosenServices.filter((el) => el.id !== i) });
		const updatedService = availableServices.reduce((arr, el) => {
			if (el.id === i) delete el['requested'];
			return arr.concat([el]);
		}, []);
		setAvailableServices(updatedService);
	};

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content">
					<span style={styles.title} className="card-title z-depth-1">
						{step.title}
					</span>
					{serviceOptions.length > 0 && (
						<div className="valign-wrapper row" style={{ margin: 0, height: '85%', padding: '0' }}>
							<ServiceItem theme={hauler.theme} stepIndex={stepIndex} key={0} {...showService} onSelect={(add, cans) => ApplyService(showService, add, cans)} />
						</div>
					)}
					{serviceOptions.length === 0 && (
						<div>
							{!requestedServices && <>You need to select a service to continue.</>}
							{requestedServices && (
								<>
									Please review the service(s) you added <span style={{ whiteSpace: 'nowrap' }}>to your location.</span>
								</>
							)}
							<br />
							<br />
							{requestedServices &&
								requestedServices.map((service, i) => (
									<div className="row" key={i}>
										<div className="col s12" style={{ border: '1px solid lightgrey', borderRadius: '25px', paddingBottom: '10px' }}>
											<div className="row" style={{ margin: 0 }}>
												<div className="col s12 hide-on-small-only">
													<div className="card fadeLeft" style={{ marginBottom: 0, boxShadow: 'none' }}>
														<div className="card-content left-align" style={{ paddingBottom: 0 }}>
															<span className="right right-align">
																<span className="row" style={{ margin: '0' }}>
																	<button style={{ ...styles.button.enabled, margin: '0 5px', textAlign: 'left' }} onClick={() => editServiceFromRequested(service.id)} className="right btn ">
																		<i className="material-icons left">edit</i>
																		Edit
																	</button>

																	<button onClick={() => removeServiceFromRequested(service.id)} className="push-right left red white-text btn grey darken-2" style={styles.button.enabled}>
																		<i className="material-icons left">delete</i>
																		Remove
																	</button>
																</span>
															</span>
															<div>
																<p className="red-text">{service.name}</p>
																<p>
																	{service.cans.cans} Cans - {service.cans.frequency.frequency} Pickup - <CurrencyFormat {...currencyAttr} value={round(service.cans.frequency.price, 2)} /> per month
																</p>
															</div>
														</div>
													</div>
												</div>
												<div className="col s12 hide-on-med-and-up">
													<div className="card fadeLeft" style={{ marginBottom: 0, boxShadow: 'none' }}>
														<div className="card-content left-align" style={{ paddingBottom: 0 }}>
															<span className="right-align right">
																<span className="row" style={{ margin: '0' }}>
																	<button style={{ ...styles.button.enabled, margin: '0 5px 10px', textAlign: 'left' }} onClick={() => editServiceFromRequested(service.id)} className="btn ">
																		<i className="material-icons left">edit</i>
																		Edit
																	</button>
																	<br />
																	<button onClick={() => removeServiceFromRequested(service.id)} className="red white-text btn grey darken-2" style={styles.button.enabled}>
																		<i className="material-icons">delete</i>
																	</button>
																</span>
															</span>
															<p className="red-text">{service.name}</p>
															<p>
																{service.cans.cans} Cans
																<br />
																{service.cans.frequency.frequency} Pickup
																<br />
																<CurrencyFormat {...currencyAttr} value={round(service.cans.frequency.price, 2)} /> per month
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					)}
				</div>
				<div className="card-action" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
					<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => goToStep('address', -1)}>
						Previous Step
					</button>
					{allServicesRequested && (
						<>
							<button style={styles.button.enabled} className="btn btn-small themedButton grey darken-2" onClick={resetRequestedServices}>
								Change
							</button>
						</>
					)}
					{allServicesRequested && serviceOptions.length === 0 && requestedServices ? (
						<>
							<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => goToStep('customerdetails')}>
								Next Step
							</button>
						</>
					) : (
						<button style={styles.button.enabled} className="btn btn-small grey themedButton" disabled>
							Next Step
						</button>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

const CardRollOff = ({ step, goToStep, stepIndex }) => {
	const { availableServices, getStepAnswers, setAnswer, stepAnswers, hauler } = useContext(SignUpContext);

	const getInitialValues = (type) => {
		const answer = getStepAnswers(8);
		if (type === 'requestedSize') {
			return answer
				? {
						id: uuidv4(),
						name: `${answer.service.rolloff_size} Yard Roll Off`,
						rolloff_size: answer.service.rolloff_size,
				  }
				: null;
		}
		if (type === 'availableServicesFromSize') {
			return answer ? availableServices.filter((el) => el.rolloff_size === answer.service.rolloff_size) : [];
		}
	};

	const [state, setState] = React.useState({
		requestedSize: getInitialValues('requestedSize'),
		availableServicesFromSize: getInitialValues('availableServicesFromSize'),
	});

	const handleUserRequestedSize = (e) => {
		setState({
			...state,
			requestedSize: e,
			availableServicesFromSize: availableServices.filter((el) => el.rolloff_size === e.rolloff_size),
		});
	};

	const [requestedService, setRequestedService] = React.useState(getStepAnswers['services']);
	// const allServicesRequested = availableServices.reduce((tot, x) => (x.requested === undefined ? tot : tot + 1), 0) === availableServices.length;

	const serviceOptions = availableServices.filter((el) => {
		return el.requested === undefined;
	});

	const ApplyService = (service) => {
		setAnswer({ stepIndex: 8, answer: service });
		M.toast({ html: `${service.service.name} added` });
		// goToNextStep();
	};

	const serviceType = stepAnswers[0];

	const { primaryColor } = hauler.theme;

	const { styles } = useStyles();

	const resetServices = () => {
		setAnswer({ stepIndex, answer: null });
		setState({ ...state, requestedSize: null, availableServicesFromSize: [] });
	};

	const themedClass = {
		color: 'black',
		lineHeight: '50px',
		marginBottom: '10px',
		borderRadius: '20px',
		border: '1px solid black',
	};

	const themedClass_svg = {
		color: 'black',
		float: 'left',
		height: '50px',
	};

	const s = {
		square_container: {
			position: 'relative',
			width: '30px',
			height: '30px',
			marginTop: '10px',
			border: `1px solid ${primaryColor}`,
			borderRadius: '50%',
		},
		square_container_active: {
			position: 'relative',
			width: '30px',
			height: '30px',
			marginTop: '10px',
			border: `1px solid ${primaryColor}`,
			borderRadius: '50%',
			background: primaryColor,
		},
	};

	const activeThemeClass = {
		...themedClass,
	};

	const availableRollOffSizes = availableServices.reduce((arr, service) => {
		if (!arr.find((findService) => findService.rolloff_size === service.rolloff_size)) {
			arr = [
				...arr,
				{
					id: uuidv4(),
					name: `${service.rolloff_size} Yard Roll Off`,
					rolloff_size: service.rolloff_size,
				},
			];
		}
		return arr;
	}, []);

	const [slideTitle, setSlideTitle] = useState(step.title);
	const steps = ['Available sizes for your location', 'Choose your service dates', '', 'Please confirm your order'];

	useEffect(() => {
		!state.requestedSize && setSlideTitle('Available sizes for your location');
	}, [state.requestedSize]);

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content" style={{ maxHeight: '600px', maxHeight: '100%' }}>
					<span style={styles.title} className="card-title z-depth-1" id="card-title-area">
						{slideTitle}
					</span>
					<div className="row" style={{ minHeight: `${serviceOptions.length * 50}px` }}>
						<div className="s12">
							{/* <DateRange editableDateInputs={false} onChange={(item) => setState([item.selection])} moveRangeOnFirstSelection={false} ranges={state} /> */}
							{!state.requestedSize ? (
								<div className="valign-wrapper" style={{ height: '85%', width: '90%', maxWidth: '400px', padding: '20px 0', margin: '0 auto' }}>
									<div className="collection" style={{ width: '100%', margin: '0 auto', border: '0' }}>
										{availableRollOffSizes.length > 0 && serviceType === 'Roll-Offs' && (
											<>
												{availableRollOffSizes.map((el) => (
													<a key={el.id} style={activeThemeClass} href="#!" className="collection-item left-align" onClick={() => handleUserRequestedSize(el)}>
														<span style={{ paddingRight: '20px' }}>
															<SvgRollOffs style={themedClass_svg} />
														</span>
														{el.name.toUpperCase()}
														<span style={s.square_container} className="right selected-indicator"></span>
													</a>
												))}
												{/* <DD serviceOptions={serviceOptions} label={`SELECT : ${requestedService ? requestedService.name : `THE ROLL-OFF FOR YOU`}`} theme={primaryBgColor} onClick={setRequestedService} /> */}
											</>
										)}
									</div>
								</div>
							) : (
								<div className="row">
									<div className="col s12">
										<ServiceItemRollOff setSlideTitle={setSlideTitle} selected={getStepAnswers(8)} reset={() => setState({ ...state, requestedSize: null, availableServicesFromSize: [] })} theme={hauler.theme} stepIndex={stepIndex} key={0} services={state.availableServicesFromSize} onSelect={ApplyService} />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="card-action">
					{!state.requestedSize && (
						<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => goToStep('address', -1)}>
							Previous Step
						</button>
					)}
					{getStepAnswers(stepIndex) ? (
						<>
							<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => goToStep('address', -1)}>
								Previous Step
							</button>
							<button
								style={styles.button.enabled}
								className="btn btn-small themedButton"
								onClick={() => {
									setRequestedService(null);
									resetServices();
								}}
							>
								Change
							</button>
							<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => goToStep('customerdetails')}>
								Next Step
							</button>
						</>
					) : (
						<button style={styles.button.disabled} className="btn btn-small grey themedButton" disabled>
							Next Step
						</button>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

const DD = ({ serviceOptions, label, theme, onClick }) => {
	useEffect(() => {
		let elems = document.querySelectorAll('.dropdown-trigger');

		const instances = M.Dropdown.init(elems, {});
	}, []);
	return (
		<>
			<span id="dd_trigger" style={{ width: '400px', maxWidth: '100%', ...theme }} className="dropdown-trigger btn" data-target="dropdown_sel">
				{label}
			</span>
			<ul id="dropdown_sel" className="dropdown-content">
				{/* {serviceOptions.map((el, i) => (
					<li className="valign-wrapper" key={i}>
						<span onClick={() => onClick(el)} className="center-align">
							{el.name}
						</span>
					</li>
				))} */}
			</ul>
		</>
	);
};

const Page = (props) => {
	const { stepAnswers, getStepAnswers } = useContext(SignUpContext);

	if (stepAnswers[0] === 'Roll-Offs') return <CardRollOff {...props} />;

	return <CardAddress {...props} />;
};

export default Page;
