import React from 'react';

const LoaderCSS = {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   backgroundColor: '#eee',
   position: 'absolute',
   zIndex: '100',
   top: '0',
   left: '0',
   right: '0',
   bottom: '0',
};

const Loader = () => {
   return (
      <div>
         <div style={LoaderCSS} className="preloader-background">
            <div className="preloader-wrapper big active">
               <div className="spinner-layer spinner-blue-only">
                  <div className="circle-clipper left">
                     <div className="circle"></div>
                  </div>
                  <div className="gap-patch">
                     <div className="circle"></div>
                  </div>
                  <div className="circle-clipper right">
                     <div className="circle"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Loader;
