import React, { useEffect, useState, useMemo } from 'react';
import Loading from '../../../components/Loader';
import API from '../../../services/api';
import Table from './table';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const ShowList = ({ posts }) => {
	const columns = useMemo(
		() => [
			{
				Header: 'Posts',
				columns: [
					{
						Header: 'ID',
						accessor: 'id',
					},
					{
						Header: 'Posted',
						accessor: 'publishdate',
					},
					{
						Header: 'Title',
						accessor: 'title',
					},
					{
						Header: 'Status',
						accessor: 'state',
					},
				],
			},
		],
		[]
	);

	return (
		<React.Fragment>
			<Table columns={columns} data={posts} />
		</React.Fragment>
	);
};

const List = ({ auth }) => {
	const [data, setData] = useState({ posts: [], fetching: false });

	useEffect(() => {
		//load posts
		setData({ ...data, fetching: true });
		Axios.get(`${URL}posts.cfc?method=getPosts`)
			.then((res) => res.data)
			.then((posts) => {
				if (posts && posts.length) {
					posts = posts.reduce((tot, el) => {
						tot = tot.concat([{ ...el, publishdate: moment(el.publishdate).format('l') }]);
						return tot;
					}, []);
					setData({ ...data, posts, fetching: false });
				} else {
					setData({ ...data, posts: [], fetching: false });
				}
			});
	}, []);

	return data.fetching ? (
		<Loading />
	) : (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Posts List</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<span className="white-text">Home</span>
								</li>
								<li className="breadcrumb-item">
									<span className="white-text">Posts</span>
								</li>
							</ol>
						</div>
						<div className="col s2 m6 l6">
							<Link className="btn waves-effect waves-light breadcrumbs-btn right" to="/admin/posts/e/0">
								<i className="material-icons hide-on-med-and-up">add</i>
								<span className="hide-on-small-onl">Add</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<section className="users-list-wrapper section">
					<div className="users-list-table">
						<div className="card">
							<div className="card-content">
								<div className="responsive-table">
									<ShowList posts={data.posts} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
};

export default List;
