import React, { useContext } from 'react';
import M from 'materialize-css';
import { SignUpContext } from './context/signup';
import img02 from './TJ-CommContainer.svg';
import { SvgResidential, SvgCommercial } from './svgs';
import { useStyles } from './elementStyles';
import Joe from './demo-final-image.png';
import trashMan from './LilNate-FINAL.gif';

const Card1 = ({ step, stepIndex, goToNextStep, goToPreviousStep }) => {
	const { stepAnswers, setAnswer, hauler } = useContext(SignUpContext);

	const answer = stepAnswers[stepIndex];
	const _hauler = hauler;

	const { styles } = useStyles();

	const { primaryColor, primaryTextColor } = hauler.theme;
	console.log(hauler.theme.primaryColor);

	// const primaryColor = '#6DB262';

	// const secondaryColor = 'blue';

	const primaryBgColor = {
		backgroundColor: primaryColor,
	};

	const themedClass = {
		background: '#f1f1f1',
		color: 'black',
		lineHeight: '50px',
	};

	const activeThemeClass = {
		background: primaryColor,
		color: primaryTextColor,
		lineHeight: '50px',
	};

	const themedClass_svg = {
		color: 'black',
		float: 'left',
		height: '50px',
	};

	const activeThemeClass_svg = {
		color: primaryTextColor,
		float: 'left',
		height: '50px',
	};

	console.log({ primaryColor });

	localStorage.removeItem('signup');

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content" style={{ maxHeight: '600px', maxHeight: '100%' }}>
					{hauler.id !== 10 && (
						<span style={styles.title} className="card-title z-depth-1">
							{step.title}
						</span>
					)}
					<div className="valign-wrappers" style={{ height: '85%', width: '90%', maxWidth: '600px', padding: '20px 0', margin: '0 auto' }}>
						<h5 style={styles.title}>
							{/* {hauler.id === 10 && <>Thank you for doing the Trash Joes demo!</>} */}
							{hauler.id !== 10 && <>Thank you for Signing up for our service(s)!</>}
						</h5>
						{hauler.id !== 10 && <p>We will contact you in 1-5 business days to finalize your purchase for ongoing transactions.</p>}
						{hauler.id === 10 && (
							<div className="center-align">
								<img src={trashMan} alt="" style={{ width: '70%', maxWidth: '400px' }} />
							</div>
						)}
						{hauler.id === 10 && <>Thank you for your interest in Trash Joe's! Please contact us to ask questions or become a Trash Joe's hauler!</>}
						<br />
						<br />
						{hauler.id !== 10 && <p>Would you like to get an additional service?</p>}
						<div className="center-align">
							{hauler.id === 10 && (
								<a
									// onClick={() => {
									// 	window.parent.postMessage(
									// 		{
									// 			func: 'parentFunc',
									// 			message: 'push-contact',
									// 		},
									// 		'*'
									// 	);
									// }}
									className="btn btn-small"
									style={styles.button.enabled}
									href={`https://www.trashjoes.com/`}
								>
									Back To Trash Joe's
								</a>
							)}
							{hauler.id !== 10 && (
								<a className="btn btn-small" style={styles.button.enabled} href={`/h/${hauler.slug}`}>
									New Service Order
								</a>
							)}
						</div>
					</div>
				</div>
				<div className="card-action">
					{/* <button style={primaryBgColor} className="btn btn-small themedButton" onClick={() => goToNextStep(stepIndex)}>
						Next Step
					</button> */}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Card1;
