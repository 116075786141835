import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export default ({ auth, setState }) => {
	// const { handleSubmit, register, setError, errors } = useForm();
	const { handleSubmit, register, errors } = useForm();
	const [postErrors, setPostErrors] = useState('');
	const [postSuccess, setPostSuccess] = useState('');
	const [processing, setProcessing] = useState(false);
	// const [password, setPassword] = useState("");
	const history = useHistory();

	const onSubmit = (values) => {
		if (processing) return;

		setProcessing(true);
		const { username } = values;
		// const { from } = this.auth.location.state || {
		// 	from: { pathname: '/authenticated' },
		// };

		auth.initPasswordReset(username).then((response) => {
			setProcessing(false);

			if (!response.success) {
				setPostErrors(response.message);
				return false;
			}
			setPostSuccess(response.message);
			return;
		});
	};

	useEffect(() => {
		if (postErrors.length > 0) {
			setTimeout(() => setPostErrors(''), 4000);
		}
	}, [postErrors]);

	useEffect(() => {
		if (postSuccess.length > 0) {
			setTimeout(() => setPostSuccess(''), 4000);
		}
	}, [postSuccess]);

	return (
		<form name="login-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="form-holder">
				<div className="center-align helper-text red-text">{postErrors && postErrors}</div>
				<div className="center-align helper-text green-text">{postSuccess && postSuccess}</div>
				<div className="input-field col s12">
					<input
						type="text"
						ref={register({
							required: 'Username is required!',
							pattern: {
								value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
								message: 'Invalid Email Address',
							},
						})}
						className="input"
						name="username"
						id="username"
						placeholder="email@domain.com"
					/>
					<span style={{ float: 'right' }}>
						<button type="button" style={{ textDecoration: 'underline', cursor: 'pointer', background: 'transparent', border: 0, boxShadow: 'none' }} onClick={() => setState('login')}>
							return to login
						</button>
					</span>
					<span style={{ pointerEvents: 'none' }} className="helper-text red-text">
						{errors.username && errors.username.message}
					</span>
				</div>
			</div>
			<p className="center-align">
				<button disabled={processing} className="btn submit-btn">
					Reset
				</button>
			</p>
		</form>
	);
};
