import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import withAuthentication, { useAuthentication } from './components/auth-provider';

import AdminLayout from './Layout/admin';
import DefaultLayout from './Layout/default';
import HaulerLayout from './Layout/hauler';
import HomePageLayout from './Layout/homePage';
import LoginPageLayout from './Layout/login';
import ReviewsLayout from './Layout/reviews';
import WhyJoeLayout from './Layout/whyjoe';
import LoginPage from './containers/LoginPage';

//

export function LoginRoute({ component: Component, ...rest }) {
	const auth = useAuthentication();
	const history = useHistory();

	if (auth.user) {
		history.push('/authenticated');
	}

	return (
		<Route
			{...rest}
			render={(props) => (
				<LoginPageLayout>
					<Component {...props} />
				</LoginPageLayout>
			)}
		/>
	);
}

export function HomeRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (
				<HomePageLayout>
					<Component {...props} />
				</HomePageLayout>
			)}
		/>
	);
}

export function WhyJoeRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (
				<WhyJoeLayout {...props}>
					<Component {...props} />
				</WhyJoeLayout>
			)}
		/>
	);
}

export function HaulerRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (
				<HaulerLayout>
					<Component {...props} />
				</HaulerLayout>
			)}
		/>
	);
}

export function AdminRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (
				<AdminLayout history={props.history}>
					<Component {...props} />
				</AdminLayout>
			)}
		/>
	);
}

export function PrivateRoute({ component: Component, ...rest }) {
	const auth = useAuthentication();
	const Output = auth.user ? Component : withAuthentication(LoginPage);

	if (auth.user) {
		return <AdminRoute {...rest} component={(props) => <Output {...props} />} />;
	}

	return <LoginRoute exact path={rest.path} component={(props) => <Output {...props} />} {...rest} />;
}

export function ReviewsRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (
				<ReviewsLayout {...props}>
					<Component {...props} />
				</ReviewsLayout>
			)}
		/>
	);
}

export default ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<DefaultLayout>
					<Component {...props} />
				</DefaultLayout>
			)}
		/>
	);
};
