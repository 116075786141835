import React from 'react';
import { Calendar } from 'react-date-range';
import moment from 'moment';
// import { isSameDay, format } from 'date-fns';
import companyService from '../../../services/companies';
import M from 'materialize-css';

const Wrapper = ({ disabledDates, data, props }) => {
	const [days, setDays] = React.useState(disabledDates || []);
	const [date, setDate] = React.useState(null);

	const { auth } = props;

	React.useEffect(() => {
		const strDate = moment(date).format('DD/MM/yyyy');

		if (strDate !== 'Invalid date' && days.includes(strDate) === false) setDays((current) => [...current, strDate]);
	}, [date]);

	const handleRemoveDay = (date) => {
		setDays((current) => current.filter((d) => d !== date));
	};

	const handleSaveDates = () => {
		const values = {
			id: data.id,
			days,
		};

		new companyService()
			.updateDisabledDays(values, auth.user.token)
			.then((json) => {
				if (json) {
					M.toast({ html: 'Dates Saved!', classes: 'green white-text' });
				}
			})
			.catch((e) => console.log({ e }));
	};

	return (
		<div className="row">
			<div className="col s12 m6 list">
				<div style={{ width: '100%' }} className="center-align">
					<button className="btn btn-small green white-text" onClick={handleSaveDates}>
						SAVE
					</button>
				</div>
				<ul className="collection">
					{days.map((date) => (
						<li key={date} className="collection-item">
							<div>
								{date}
								<span className="secondary-content" style={{ cursor: 'pointer' }} onClick={() => handleRemoveDay(date)}>
									<i className="red-text material-icons">delete</i>
								</span>
							</div>
						</li>
					))}
				</ul>
			</div>
			<div className="disableWeekends col s12 m6 calendar">
				<Calendar onChange={(item) => setDate(item)} date={date} />
			</div>
		</div>
	);
};

export default Wrapper;
