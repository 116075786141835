import React from 'react';
import { Link } from 'react-router-dom';
import monitor from './TrashJoes-monitor.png';
import trashMan from './TrashJoes-man.png';
import pic1 from './TJ-aquisition.jpg';
import pic2 from './TJ-retention.jpg';
import pic3 from './TJ-payment.jpg';
import { Helmet } from 'react-helmet';

const Sevices = () => {
	const gradient = {
		background: 'white',
		// background: 'rgba(78, 78, 78, 1.0)',
		// background: '-webkit-linear-gradient(bottom right, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
		// background: '-moz-linear-gradient(bottom right, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
		// background: 'linear-gradient(to top left, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
	};
	return (
		<div className="home">
			<Helmet>
				<title>Services : Trash Joes</title>
			</Helmet>
			{/* <div className="titleStyle white-text" style={gradient}>
				<div className="container">
					<div className="row">
						<div className="col s12 right-align">
							<h1 style={{ padding: '30px 40px 10px 10px' }}>&nbsp;</h1>
						</div>
					</div>
				</div>
			</div> */}
			<div className="container">
				<div className="row" style={{ marginTop: '90px' }}>
					<div className="col s12 m8 offset-m2 center titleStyle">
						<h1 className="red-text">Services</h1>
						<p>Trash Joes has the tools to get the route density you want. Through enhanced communication practices and better business practices, we will help you acquire new customers. Your online onboarding system will allow your customers to choose their trash day, select options for services and pay you in just a few clicks.</p>
						<p>
							<Link className="btn-large" to="/Demo">
								VIEW DEMO
							</Link>
						</p>
					</div>
				</div>
			</div>
			{/* <div className="titleStyle bgWhy">
				<div className="container row">
					<div className="col s12 m6 l5 whiteBluryBg">
						<h1>Services</h1>
						<p>Trash Joes has the tools to get the route density you want. Through enhanced communication practices and better business practices, we will help you acquire new customers. Your online onboarding system will allow your customers to choose their trash day, select options for services and pay you in just a few clicks.</p>
						<p>
							<Link className="btn-large" to="/login">
								VIEW DEMO
							</Link>
						</p>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="s12 center"></div>
				</div>
			</div> */}
			{/* <div className="grey white-text demoRibon">
				<div className="container">
					<div className="row displayAdj">
						<div className="col s12 m6 l6 push-m6 push-l6 padAdj center-align animatedParent animateOnce">
							<img className="responsive-img" src={monitor} alt="" style={{ marginTop: '20px' }} />
						</div>
						<div className="col s12 m6 l6 pull-m6 pull-l6 padAdj alignAdj">
							<p>Trash Joes helps any hauler no matter the stage of their business by sharing critical and insightful information to take the next step. Our goal is to help you “win” the battle against the big guys or the guy across town that seems to always have your number. Our customers get the information, guidance and products they need to move forward.</p>
							<Link className="btn-large" to="/login">
								TAKE THE TOUR
							</Link>
						</div>
					</div>
				</div>
			</div> */}
			<br />
			<br />

			<div className="row container wrap-contact displayAdj">
				<div className="col s12 m6 l6 padAdj left-align animatedParent animateOnce">
					<img src={pic1} alt="" className="responsive-img card animated fadeInUpShort" />
				</div>
				<div className="col s12 m6 l6 padAdj left-align-adj">
					<h4 className="red-text">{'Acquisition'.toUpperCase()}</h4>
					<p style={{ fontSize: '18px' }}>We make you available 24-7-365 for new service sign ups. Prospective customers can search their address and see if their home or office is on your route. No phone calls. No hassle. It couldn’t be any easier to sign up for service.</p>
				</div>
			</div>
			<br />
			<br />

			<div className="row container wrap-contact displayAdj">
				<div className="col s12 m6 l6 push-m6 push-l6 padAdj right-align animatedParent animateOnce">
					<img src={pic2} alt="" className="responsive-img card animated fadeInUpShort" />
				</div>
				<div className="col s12 m6 l6 pull-m6 pull-l6 padAdj right-align-adj">
					<h4 className="red-text">{'Retention'.toUpperCase()}</h4>
					<p style={{ fontSize: '18px' }}>
						Once you’ve got them, you need to keep them. You will receive access to our direct-to-consumer texting platform that enables you to text your customers the night before trash day to remind them to put their cans out. And you can text reminders about holiday pick ups, schedule changes and unpaid invoices. Even better – your customers can reply with questions that you can
						answer from anywhere without being tied to an office phone.
					</p>
				</div>
			</div>
			<br />
			<br />

			<div className="row container wrap-contact displayAdj">
				<div className="col s12 m6 l6 padAdj left-align animatedParent animateOnce">
					<img src={pic3} alt="" className="responsive-img card animated fadeInUpShort" />
				</div>
				<div className="col s12 m6 l6 padAdj left-align-adj">
					<h4 className="red-text">{'Payment'.toUpperCase()}</h4>
					<p style={{ fontSize: '18px' }}>Trash Joe’s gives you the ability to text customers with account and payment info so they can easily submit payments to directly to you. Customer payment information can easily be imported into your existing operational system – TrashFlow, WAM, CRO and many others! Trash Joe’s is just a conduit – the money comes directly to you.</p>
				</div>
			</div>
			<br />
			<br />
			<br />
		</div>
	);
};

export default Sevices;
