import React, { createContext, useReducer } from 'react';
import { BlogReducer } from './blogReducer.js';
import axios from 'axios';

export const BlogContext = createContext();

// const storage = localStorage.getItem('signup') ? JSON.parse(localStorage.getItem('signup')) : [];

const jsonServer = axios.create({
	baseURL: 'http://localhost',
});

const initialState = {
	posts: [],
	// ...storage,
};

const BlogContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(BlogReducer, initialState);

	const getPosts = (payload) => {
		const run = async () => {
			const response = await jsonServer.get('cfc/api/posts.cfc?method=getPosts');

			dispatch({ type: 'SET', payload: { posts: response.data } });
		};

		run();
	};

	const getPost = (payload) => {
		const run = async () => {
			const response = await jsonServer.get(`cfc/api/posts.cfc?method=get&i=${payload.id}`);

			dispatch({ type: 'SET', payload: response.data.id ? { post: response.data } : { post: { id: 0 } } });
		};

		run();
	};

	const getPostByUrl = (payload) => {
		const run = async () => {
			const response = await jsonServer.get(`cfc/api/posts.cfc?method=getPostByUrl&i=${payload}`);

			dispatch({ type: 'SET', payload: response.data.id ? { post: response.data } : { post: { id: 0 } } });
		};

		run();
	};

	const contextValues = {
		getPosts,
		getPost,
		getPostByUrl,
		...state,
	};

	return <BlogContext.Provider value={contextValues}>{children}</BlogContext.Provider>;
};

export default BlogContextProvider;
