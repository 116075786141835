import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import { Link, useLocation } from 'react-router-dom';
import Loading from '../../components/Loader';
import config from '../../services/config';
import './app.css';

const PostIndex = ({ title, excerpt, featureimage, url, history }) => (
	<div className="row">
		<div className="col s12 m4">
			<img className="responsive-img" src={featureimage} alt="" />
		</div>
		<div className="col s12 m8">
			<h2 className="red-text" style={{ marginTop: 0 }}>
				{title}
			</h2>
			<p>{excerpt}</p>
			<Link to={`/Resources/${url}`} className="btn">
				View Post
			</Link>
		</div>
	</div>
);

function App(props) {
	const [offset, setOffset] = useState(0);
	const [data, setData] = useState([]);
	const [perPage] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const { pathname } = useLocation();

	const getData = async () => {
		const res = await axios.get(`${config.apiBaseUrl}/posts.cfc?method=getPosts&state=published`);
		const data = res.data;
		const slice = data.slice(offset, offset + perPage);
		const postData = slice.map((pd) => (
			<div key={pd.id} style={{ marginBottom: '60px' }}>
				<PostIndex {...pd} {...props} />
			</div>
		));
		setData(postData);
		setPageCount(Math.ceil(data.length / perPage));
	};

	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		setOffset(selectedPage + 1);
	};

	useEffect(() => {
		getData();
	}, [offset]);

	const gradient = {
		background: 'white',
	};

	return (
		<div className="home">
			{pathname !== '/' && (
				<Helmet>
					<title>Resources : Trash Joes</title>
				</Helmet>
			)}

			{pathname !== '/' && (
				<div className="container">
					<div className="row" style={{ marginTop: '90px' }}>
						<div className="col s10 offset-s1 center titleStyle">
							<h1 className="red-text">Resources</h1>
						</div>
					</div>
				</div>
			)}
			<div className="container grey lighten-3">
				<div className="section mt-2" id="blog-list" style={{ position: 'relative' }}>
					{data.length > 20 && <ReactPaginate previousLabel={'prev'} nextLabel={'next'} breakLabel={'...'} breakClassName={'break-me'} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} activeClassName={'active'} />}
					{data && data.length > 0 && data}
					{!data || (data.length === 0 && <Loading />)}
					{data.length > 20 && <ReactPaginate previousLabel={'prev'} nextLabel={'next'} breakLabel={'...'} breakClassName={'break-me'} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} activeClassName={'active'} />}
				</div>
			</div>
		</div>
	);
}

export default App;
