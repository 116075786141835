import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
//import { FaArrowCircleUp } from "react-icons/fa";
import './App.css';

const ScrollArrow = () => {
	const [showScroll, setShowScroll] = useState(false);

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 400) {
			setShowScroll(true);
		} else if (showScroll && window.pageYOffset <= 400) {
			setShowScroll(false);
		}
	};

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	window.addEventListener('scroll', checkScrollTop);

	return (
		<i className="scrollTop fas fa-angle-up" onClick={scrollTop} style={{ display: showScroll ? 'flex' : 'none' }}></i>
		// <FaArrowCircleUp
		// 	className="scrollTop"
		// 	onClick={scrollTop}
		// 	style={{ height: 40, display: showScroll ? "flex" : "none" }}
		// />
	);
};

const ScrollToTopComponent = ({ children }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname !== '/aaaaa') window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

export const ScrollToTop = withRouter(ScrollToTopComponent);

export default ScrollArrow;
