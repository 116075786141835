import React, { useEffect, useState } from 'react';
import userService from '../../../services/users';
import M from 'materialize-css';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/Loader';
import FileUploadComp from './fileUpload';
import withAuthentication from '../../../components/auth-provider';

const FileUpload = withAuthentication(FileUploadComp);

const Edit = (props) => {
	const { handleSubmit, register, errors, setValue } = useForm();
	const [userData, setUserData] = useState({ data: {}, fetching: false });
	const { u, auth } = props;
	const history = useHistory();

	useEffect(() => {
		let rendered = true;

		function getUser() {
			setUserData({ ...userData, fetching: true });
			new userService()
				.getUser(u, auth.user.token)
				// .then((response) => response))
				.then((json) => {
					if (rendered) {
						setUserData({ ...userData, data: json, fetching: false });
						setValue('username', json.username);
						setValue('firstname', json.firstname);
						setValue('middlename', json.middlename);
						setValue('lastname', json.lastname);
						setValue('email', json.email);
						setValue('role', json.role.toLowerCase());
						setValue('status', json.status.toLowerCase());
						setValue('verified', json.verified);
					}
				})
				.catch((e) => {
					setUserData({ ...userData, fetching: false });
				});
		}

		getUser();

		return () => {
			rendered = false;
		};
	}, [auth.user.token, u, setValue]);

	useEffect(() => {
		window.setTimeout(() => {
			M.AutoInit();
			M.updateTextFields();
		}, 500);
	}, [userData]);

	if (userData.fetching) {
		return <Loading />;
	}

	const onSubmit = (values) => {
		values.id = Number(u);
		values.verified = Number(values.verified);

		if (values.id) {
			new userService()
				.update(values, auth.user.token)
				.then((json) => {
					if (json) {
						M.toast({ html: 'User Saved!', classes: 'green white-text' });
					}
				})
				.catch((e) => console.log({ e }))
				.finally((e) => history.push('/admin/users'));
		} else {
			new userService()
				.insert(values, auth.user.token)
				.then((json) => {
					if (json) {
						M.toast({ html: 'User Saved!', classes: 'green white-text' });
					}
				})
				.catch((e) => console.log({ e }))
				.finally((e) => history.push('/admin/users'));
		}
	};

	return (
		<React.Fragment>
			{/* <input style={{ display: 'none' }} type="file" onChange={(e) => handleImageUpload(e)} ref={(fi) => setFileInput(fi)} /> */}
			<div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Users edit</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<Link to="/admin">Home</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/admin/users">User</Link>
								</li>
								<li className="breadcrumb-item active">User Edit</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<div className="container">
					<div className="section users-edit">
						<div className="card">
							<div className="card-content">
								<ul className="tabs mb-2 row">
									<li className="tab">
										<a className="display-flex align-items-center active" id="account-tab" href="#account">
											{' '}
											<i className="material-icons mr-1">person_outline</i>
											<span>Account</span>{' '}
										</a>
									</li>
									<li className="tab">
										<a className="display-flex align-items-center" id="information-tab" href="#information">
											{' '}
											<i className="material-icons mr-2">error_outline</i>
											<span>Information</span>{' '}
										</a>
									</li>
								</ul>
								<div className="divider mb-3"></div>
								<div className="row">
									<div className="col s12" id="account">
										<FileUpload />
										{/* <div className="media display-flex align-items-center mb-2">
											<a className="mr-2" href="#!">
												<img src={user.profileimage ? user.profileimage : `/assets/images/avatar/avatar-${getRandomInt(1, 17)}.png`} alt="users avatar" className="z-depth-4 circle" height="64" width="64" />
											</a>
											<div className="media-body">
												<h5 className="media-heading mt-0">Avatar{'' && user.firstname}</h5>
												<div className="user-edit-btns display-flex">
													<button type="button" onClick={() => fileInput.click()} href="#!" className="btn-small indigo">
														Change
													</button>
													<a href="#!" className="btn-small btn-light-pink">
														Reset
													</a>
												</div>
											</div>
										</div> */}
										<form id="accountForm" onSubmit={handleSubmit(onSubmit)}>
											<div className="row">
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">
															<input id="username" name="username" type="text" ref={register({ required: 'Username is required!' })} />
															<label htmlFor="username">Username</label>
															<span className="red-text helper-text">{errors.username && errors.username.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">
															<input id="password" name="password" type="password" ref={register} />
															<label htmlFor="password">Password</label>
															<span className="red-text helper-text">{errors.password && errors.password.message}</span>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col s12 m4">
													<div className="row">
														<div className="col s12 input-field">
															<input id="firstname" name="firstname" type="text" className="validate" ref={register} />
															<label htmlFor="firstname">First Name</label>
															<span className="red-text helper-text">{errors.firstname && errors.firstname.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 m4">
													<div className="row">
														<div className="col s12 input-field">
															<input id="middlename" name="middlename" type="text" className="validate" ref={register} />
															<label htmlFor="middlename">Middle Name</label>
															<span className="red-text helper-text">{errors.middlename && errors.middlename.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 m4">
													<div className="row">
														<div className="col s12 input-field">
															<input id="lastname" name="lastname" type="text" className="validate" ref={register} />
															<label htmlFor="lastname">Last Name</label>
															<span className="red-text helper-text">{errors.lastname && errors.lastname.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12">
													<div className="row">
														<div className="col s12 input-field">
															<input id="email" name="email" type="email" className="validate" ref={register} />
															<label htmlFor="email">E-mail</label>
															<span className="red-text helper-text">{errors.email && errors.email.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">
															<select id="role" name="role" ref={register}>
																<option>admin</option>
																<option>hauler</option>
																<option>customer</option>
																<option>can-manager</option>
																<option>can-driver</option>
															</select>
															<label>Role</label>
															<span className="red-text helper-text">{errors.role && errors.role.message}</span>
														</div>
														<div className="col s12 input-field">
															<select id="status" name="status" ref={register}>
																<option>active</option>
																<option>disabled</option>
															</select>
															<label>Status</label>
															<span className="red-text helper-text">{errors.name && errors.name.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">
															<select name="verified" id="verified" ref={register}>
																<option value="1">YES</option>
																<option value="0">NO</option>
															</select>
															<label>Verified</label>
															<span className="red-text helper-text">{errors.verified && errors.verified.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 display-flex justify-content-end mt-3">
													<button type="submit" className="btn indigo">
														Save changes
													</button>
													<button type="button" className="btn btn-light">
														Cancel
													</button>
												</div>
											</div>
										</form>
									</div>
									<div className="col s12" id="information">
										<form id="infotabForm">
											<div className="row">
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12">
															<h6 className="mb-2">
																<i className="material-icons mr-1">link</i>Social Links
															</h6>
														</div>
														<div className="col s12 input-field">
															<input className="validate" type="text" defaultValue="https://www.twitter.com/" />
															<label>Twitter</label>
														</div>
														<div className="col s12 input-field">
															<input className="validate" type="text" defaultValue="https://www.facebook.com/" />
															<label>Facebook</label>
														</div>
														<div className="col s12 input-field">
															<input className="validate" type="text" />
															<label>Google+</label>
														</div>
														<div className="col s12 input-field">
															<input id="linkedin" name="linkedin" className="validate" type="text" />
															<label htmlFor="linkedin">LinkedIn</label>
														</div>
														<div className="col s12 input-field">
															<input className="validate" type="text" defaultValue="https://www.instagram.com/" />
															<label>Instagram</label>
														</div>
													</div>
												</div>
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12">
															<h6 className="mb-4">
																<i className="material-icons mr-1">person_outline</i>Personal Info
															</h6>
														</div>
														<div className="col s12 input-field">
															<input id="datepicker" name="datepicker" type="text" className="birthdate-picker datepicker" placeholder="Pick a birthday" data-error=".errorTxt4" />
															<label htmlFor="datepicker">Birth date</label>
															<small className="errorTxt4"></small>
														</div>
														<div className="col s12 input-field">
															<select id="accountSelect">
																<option>USA</option>
																<option>India</option>
																<option>Canada</option>
															</select>
															<label>Country</label>
														</div>
														<div className="col s12">
															<label>Languages</label>
															<select className="browser-default" id="users-language-select2" multiple="multiple">
																<option value="English">English</option>
																<option value="Spanish">Spanish</option>
																<option value="French">French</option>
																<option value="Russian">Russian</option>
																<option value="German">German</option>
																<option value="Arabic">Arabic</option>
																<option value="Sanskrit">Sanskrit</option>
															</select>
														</div>
														<div className="col s12 input-field">
															<input id="phonenumber" type="text" className="validate" defaultValue="(+656) 254 2568" />
															<label htmlFor="phonenumber">Phone</label>
														</div>
														<div className="col s12 input-field">
															<input id="address" name="address" type="text" className="validate" data-error=".errorTxt5" />
															<label htmlFor="address">Address</label>
															<small className="errorTxt5"></small>
														</div>
													</div>
												</div>
												<div className="col s12">
													<div className="input-field">
														<input id="websitelink" name="websitelink" type="text" className="validate" />
														<label htmlFor="websitelink">Website</label>
													</div>
													<label>Favourite Music</label>
													<div className="input-field">
														<select className="browser-default" id="users-music-select2" multiple="multiple">
															<option value="Rock">Rock</option>
															<option value="Jazz">Jazz</option>
															<option value="Disco">Disco</option>
															<option value="Pop">Pop</option>
															<option value="Techno">Techno</option>
															<option value="Folk">Folk</option>
															<option value="Hip hop">Hip hop</option>
														</select>
													</div>
												</div>
												<div className="col s12">
													<label>Favourite movies</label>
													<div className="input-field">
														<select className="browser-default" id="users-movies-select2" multiple="multiple">
															<option value="The Dark Knight">The Dark Knight </option>
															<option value="Harry Potter">Harry Potter</option>
															<option value="Airplane!">Airplane!</option>
															<option value="Perl Harbour">Perl Harbour</option>
															<option value="Spider Man">Spider Man</option>
															<option value="Iron Man">Iron Man</option>
															<option value="Avatar">Avatar</option>
														</select>
													</div>
												</div>
												<div className="col s12 display-flex justify-content-end mt-1">
													<button type="submit" className="btn indigo">
														Save changes
													</button>
													<button type="button" className="btn btn-light">
														Cancel
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-overlay"></div>
			</div>
		</React.Fragment>
	);
};

export default Edit;
