import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export default ({ auth, setState }) => {
	// const { handleSubmit, register, setError, errors } = useForm();
	const { handleSubmit, register, errors } = useForm();
	const [postErrors, setPostErrors] = useState('');
	// const [password, setPassword] = useState("");
	const history = useHistory();

	const onSubmit = (values) => {
		const { username, password } = values;
		// const { from } = this.auth.location.state || {
		// 	from: { pathname: '/authenticated' },
		// };

		auth.login(username, password).then((response) => {
			if (!response) {
				setPostErrors('Invalid Credentials');
				return false;
			}

			if (['can-manager', 'can-driver'].includes(response.user.role)) {
				history.push('/admin/can-manager');
			} else {
				history.push('/admin');
			}
		});
	};

	return (
		<form name="login-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="form-holder">
				<div className="center-align helper-text red-text">{postErrors && postErrors}</div>
				<div className="input-field col s12">
					<input
						type="text"
						ref={register({
							required: 'Username is required!',
							pattern: {
								value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
								message: 'Invalid Email Address',
							},
						})}
						className="input"
						name="username"
						id="username"
						placeholder="email@domain.com"
					/>
					<span className="helper-text red-text">{errors.username && errors.username.message}</span>
				</div>
				<div className="input-field col s12">
					<input type="password" ref={register({ required: 'Password is required!' })} className="input" name="password" id="password" placeholder="Password" />
					<span style={{ float: 'right' }}>
						<button type="button" style={{ textDecoration: 'underline', cursor: 'pointer', background: 'transparent', border: 0, boxShadow: 'none' }} onClick={() => setState('forgotpassword')}>
							forgot password?
						</button>
					</span>
					<span style={{ pointerEvents: 'none' }} className="helper-text red-text">
						{errors.password && errors.password.message}
					</span>
				</div>
			</div>
			<p className="center-align">
				<button className="btn submit-btn">Log in</button>
			</p>
		</form>
	);
};
