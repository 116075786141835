import React, { useEffect, useState, useRef } from 'react';
import M from 'materialize-css';
import customerService from '../services/companies';

export const useCompanies = ({ token }) => {
	const [companies, setCompanies] = useState([]);
	const [fetching, setFetching] = useState(false);

	useEffect(() => {
		function getCompanies() {
			setFetching(true);
			new customerService()
				.getCompanies(token)
				.then((json) => {
					setCompanies(json);
				})
				.catch((e) => console.log(e, token))
				.finally(() => setFetching(false));
		}

		getCompanies();
	}, []);

	return [companies, fetching];
};

export const Select = ({ onChange, options = [], value = '', label = 'Showing For', firstOption }) => {
	const ref = useRef();

	useEffect(() => {
		if (ref.current) {
			// M.FormSelect([ref.current], {});
			M.AutoInit();
		}
	}, [ref, options]);

	return (
		<>
			<label className="active">{label}</label>
			<select ref={ref} onChange={onChange} value={value} className="browser-default-">
				<option value="" disabled>
					{firstOption && firstOption}
					{!firstOption && `Viewing as ${value.name}`}
				</option>
				{options.map((opt) => (
					<option key={opt.value} value={opt.value}>
						{opt.name}
					</option>
				))}
			</select>
		</>
	);
};
