import React, { useEffect, useState } from 'react';
import Header from './header';
// import { motion, AnimatePresence } from 'framer-motion';
import Loading from '../../components/Loader';
import companyService from '../../services/companies';
import SignUpContextProvider from './context/signup';
import SignUp from './signup';

import { useParams } from 'react-router';
import FacebookPixel from '../../utils/FacebookPixels';
import './company/index.scss';

export const DispSlider = ({ hauler, show, ...props }) => {
	return (
		<div className={`repeat-bg ${hauler.slug}`}>
			<SignUpContextProvider hauler={hauler}>
				{show && <Header />}
				<SignUp {...props} hauler={hauler} />
			</SignUpContextProvider>
		</div>
	);
};

const useFetch = (slug) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [initialFetch, setInitialFetch] = useState(false);
	const [errors, setErrors] = useState([]);

	useEffect(() => {
		if (!slug || loading) return;

		setLoading(true);

		const doFetch = async () => {
			const hauler = await new companyService.getCompanyByUrl(slug);

			if (hauler) {
				setData({
					...hauler,
					theme: JSON.parse(hauler.theme),
					payfrequency: JSON.parse(hauler.payfrequency),
				});
			}
			setInitialFetch(true);
			setLoading(false);
		};

		doFetch();
	}, [slug]);

	return { data, loading, errors, initialFetch };
};

const useHauler = (slug) => {
	const [hauler, setHauler] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (loading) return;

		setLoading(true);

		new companyService()
			.getCompanyByUrl(slug)
			.then((h) => {
				if (h) {
					const theme = JSON.parse(h.theme);
					const payfrequency = JSON.parse(h.payfrequency);
					setHauler({ ...h, theme, payfrequency });
				} else {
					setError('Unable to load hauler');
				}
			})
			.catch((er) => setError('Unable to load hauler'))
			.finally((e) => setLoading(false));
	}, [slug]);

	return {
		hauler,
		loading,
		error,
	};
};

const HaulerPage = ({ demo = false, ...props }) => {
	const { hauler: urlHauler } = useParams();
	const { hauler, error } = useHauler(urlHauler || props.hauler);

	console.log('loaded update');

	if (error) {
		const centerAlign = {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		};

		return (
			<div className="repeat-bg">
				<div className="card-slider-wrapper" style={{ minHeight: '100vh', height: '100vh' }}>
					<div className="scroller" style={{ minHeight: '100vh', height: '100vh', ...centerAlign }}>
						<div className="card container" style={{ ...centerAlign }}>
							<div className="card-content" style={{ maxHeight: '600px', height: '100%', ...centerAlign }}>
								<h5>Unable to load this hauler, please close this page</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return hauler && hauler.id ? (
		<>
			<FacebookPixel />
			<DispSlider {...props} hauler={hauler} show={!demo} />
		</>
	) : (
		<Loading />
	);
};

export default HaulerPage;
