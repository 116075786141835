import React from 'react';
import List from './list';
import Edit from './edit';
import { AuthContext } from '../../../components/auth-provider';

export default function Posts({ match, ...rest }) {
	const { user } = React.useContext(AuthContext);

	if (user.user.role.indexOf('admin') === -1) rest.history.push('/admin');

	if (match.params.postId) {
		return <Edit {...rest} id={match.params.postId} />;
	}
	return <List {...rest} />;
}
