import React from 'react';
import Header from '../components/Header/admin';
import SideNav from '../components/Header/adminSideNav';
import withAuthentication from '../components/auth-provider';

const AdminLayout = (props) => {
	const HeaderWithAuth = withAuthentication(Header);
	const SideWithAuth = withAuthentication(SideNav);

	return (
		<div id="wrapper" className="admin">
			<HeaderWithAuth {...props} />
			<SideWithAuth {...props} />

			<div id="main">
				<div className="row">
					<div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
					<div id="content">{props.children}</div>
				</div>
			</div>
		</div>
	);
};

export default AdminLayout;
