import React from 'react';
import { Link } from 'react-router-dom';
import hero_bg from './TJ-whyjoe-hero.jpg';
import thumbsup from './thumbsup.png';
import { Helmet } from 'react-helmet';

const WhyJoe = () => {
	const hero = {
		backgroundImage: `url(${hero_bg})`,
		// backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${hero_bg})`,
		height: '50%',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
	};
	return (
		<div className="home">
			<Helmet>
				<title>Why work with Trash Joes : Trash Joes</title>
			</Helmet>
			<div className="titleStyle bgWhy hide-on-small-only" style={hero}>
				<div className="container row">
					<div className="col s12 m6 l5 whiteBluryBg" style={{ width: '60%' }}>
						<h1>Why work with Trash Joe&apos;s?</h1>
						<p>
							We understand the art and science of the trash business. Running a trash business takes constant choreographing and coordination between management,office personnel, people in the field and your customers.Picking up trash is easy,picking up the checks is hard.Let us help you train your customers to get their cans out and pay you on time.
							{/* If you are a hauler that can take on more customers, we have the tools to get the route density you want. Trash Joes will help you acquire new customers using technology and strategies that will get you the information you need to deliver the can. If you can take on new customers, your new online portal will tell your customers their trash day, give them the options for
							services and put the customer's payment/deposit in your bank account. */}
						</p>
						<p className="center-align">
							<Link className="btn-large" to="/Contact">
								Contact Joe
							</Link>
						</p>
					</div>
				</div>
			</div>
			<div className="container hide-on-med-and-up">
				{/* <div className="row" style={{ marginTop: '90px' }}> */}
				<div className="row" style={{ marginTop: '20px' }}>
					<div className="col s12 left-align titleStyle">
						<h1 className="red-text">&nbsp;</h1>
					</div>
				</div>
			</div>
			<div className="container" style={{ marginTop: '10px' }}>
				<div className="row">
					<div className="col s12 m10 offset-m1 l4 center" style={{ marginBottom: '40px' }}>
						<h4 style={{ textTransform: 'uppercase' }} className="red-text">
							Do you want more customers?
						</h4>
						<p>
							Our sophisticated approach to online customer acquisition and customer retention allows you to onboard a new customer – including the initial payment – any time day or night, and our customer retention tool allows you to easily keep all of<span style={{ whiteSpace: 'nowrap' }}>your customers informed.</span>
						</p>
						<Link to="/Contact">
							<img style={{ maxWidth: '100px' }} src={thumbsup} alt="" />
						</Link>
					</div>
					<div className="col s12 m10 offset-m1 l4 center" style={{ marginBottom: '40px' }}>
						<h4 style={{ textTransform: 'uppercase' }} className="red-text">
							Are you focused on profitability?
						</h4>
						<p>
							We know how to help you achieve financial stability now and into the future. The trash business is about recurring revenue.The real question is, how profitable is your recurring revenue?If you don’t know the answer to this, we will help you focus your time and<span style={{ whiteSpace: 'nowrap' }}> resources on profits.</span>
						</p>
						<Link to="/Contact">
							<img style={{ maxWidth: '100px' }} src={thumbsup} alt="" />
						</Link>
					</div>
					<div className="col s12 m10 offset-m1 l4 center" style={{ marginBottom: '40px' }}>
						<h4 style={{ textTransform: 'uppercase' }} className="red-text">
							Do you know everything you need to know?
						</h4>
						<p>
							Trash Joe’s has relationships with many people that will lend their expertise. If you have questions, we will answer them or get you to the right people to answer them.In short, we want independent haulers to be successful.It's good for the customers, your employees and you.<span style={{ whiteSpace: 'nowrap' }}>Let's do this.</span>
						</p>
						<Link to="/Contact">
							<img style={{ maxWidth: '100px' }} src={thumbsup} alt="" />
						</Link>
					</div>
				</div>
			</div>
			{/* <div className="row container">
				<div className="col s12 m12 l10 offset-l1 padAdj center-align">
					<h4>What can you expect from Trash Joes?</h4>

					<p>You can expect us to get into growing and promoting your business. from you. We know the trash business inside and out, and we will help you acquire new customers that embrace online communication and automated payments. Our strategies will reduce phone calls during the day, allowing you to focus on revenue generating activity.</p>
					<p>
						You will onboard customers faster than you ever have and give them great service. Our system uses geo-targeted maps based on your service areas and then walks a customer through the sign up process online. No phone calls! Every dashboard is customized for the hauler, and you can manage communication with your customers 24-7-365. You need the resources we have to grow your
						business!
					</p>
					<p>Trash Joe’s makes it easy for customers to sign up for service, make an initial payment and get on your routes!</p>
				</div>
			</div> */}
			<br />
			<br />
			<br />
		</div>
	);
};

export default WhyJoe;
