import React, { createContext, useCallback, useReducer } from 'react';
import { SignUpReducer } from './signUpReducer.js';
import AddressCard from '../cardAddress';
import ServiceCardW from '../cardService';
import CommercialServiceCard from '../cardServiceCommercial';
import PurchaseReviewCard from '../cardPurchaseReview';
import CustromerDetailsCard from '../cardCustomerDetails';
import BillingDetailsCard from '../cardBillingDetails';
import DeliveryDateCard from '../cardDeliveryDate';
import ServiceTypeCard from '../cardServiceType';
import ThankYou from '../cardThankYou';

export const SignUpContext = createContext();

const loadStorage = () => (localStorage.getItem('signup') ? JSON.parse(localStorage.getItem('signup')) : []);
const storage = loadStorage();

const initialState = {
	steps: [
		{
			id: 1,
			index: 0,
			title: <>Choose Service Type</>,
			name: 'intro',
			content: (props) => <ServiceTypeCard stepIndex={0} {...props} />,
			answers: {},
		},
		{
			id: 2,
			index: 1,
			title: 'Please enter your service address',
			name: 'address',
			content: (props) => <AddressCard stepIndex={1} {...props} />,
			answers: {},
			requiredFn: (a) => (a.available ? '' : 'Approved service address is required to continue'),
		},
		{
			id: 3,
			index: 2,
			title: 'Available services for your location',
			name: 'services',
			content: (props) => <ServiceCardW stepIndex={2} {...props} />,
			answers: {},
			requiredFn: (a) => {
				return loadStorage().availableServices.reduce((ret, x) => {
					return 'requested' in x === false || x.requested === undefined ? false : true;
				})
					? ''
					: 'Please complete all available services';
			},
		},
		{
			id: 4,
			index: 3,
			title: 'Please complete all fields',
			name: 'customerdetails',
			content: (props) => <CustromerDetailsCard stepIndex={3} {...props} />,
			answers: {},
			requiredFn: (a) => {
				if (!a.firstname || a.firstname.length === 0) return 'First Name is required!';
				if (!a.lastname || a.lastname.length === 0) return 'Last Name is required!';
				if (!a.email || a.email.length === 0) return 'Email is required!';
				return '';
			},
		},
		{
			id: 5,
			index: 4,
			title: 'Please fill out your billing information',
			name: 'billingdetails',
			content: (props) => <BillingDetailsCard stepIndex={4} {...props} />,
			answers: {},
			requiredFn: (a) => {
				if (a.tokenResponse.tokenError !== undefined) {
					return a.tokenResponse.tokenError;
				} else if (a.tokenResponse.message === '') {
					return 'Card Number is Required';
				}

				return a.fullname && a.tokenResponse ? '' : 'Complete this slide';
			},
		},
		{
			id: 6,
			index: 5,
			title: 'Please review and confirm your service purchase',
			name: 'review',
			content: (props) => <PurchaseReviewCard stepIndex={5} {...props} />,
			answers: {},
		},
		{
			id: 7,
			index: 6,
			title: 'When would you like the drop-off?',
			name: 'deliverydate',
			content: (props) => <DeliveryDateCard stepIndex={6} {...props} />,
			answers: {},
		},
		{
			id: 8,
			index: 7,
			title: 'Available services for your location',
			name: 'commercialservices',
			content: (props) => <CommercialServiceCard stepIndex={7} {...props} />,
			answers: {},
		},
		{
			id: 9,
			index: 8,
			title: 'Available services for your location',
			name: 'rolloffservices',
			content: (props) => <ServiceCardW stepIndex={8} {...props} />,
			// content: (props) => <RollOffServiceCard stepIndex={8} {...props} />,
			answers: {},
		},
		{
			id: 10,
			index: 9,
			title: 'Thank you!',
			name: 'confirmation',
			content: (props) => <ThankYou stepIndex={8} {...props} />,
			answers: {},
		},
	],
	stepAnswers: storage.stepAnswers ? storage.stepAnswers : [],
	availableServices: storage.availableServices ? storage.availableServices : [],
	allServices: storage.allServices ? storage.allServices : [],
	selectedServices: storage.selectedServices ? storage.selectedServices : [],
	hauler: storage.hauler ? storage.hauler : {},
	// ...storage,
};

const SignUpContextProvider = ({ hauler, children }) => {
	const [state, dispatch] = useReducer(SignUpReducer, hauler ? { ...initialState, hauler } : hauler);

	const setHauler = useCallback((payload) => {
		console.log('context/setting hauler', payload);
		dispatch({ type: 'SET_HAULER', payload });
	}, []);

	const setAvailableServices = useCallback((payload) => {
		console.log('context/setting availableServices', payload);
		dispatch({ type: 'SET_AVAILABLE_SERVICES', payload });
	}, []);

	const setAllServices = useCallback((payload) => {
		console.log('context/setting allServices', payload);
		dispatch({ type: 'SET_ALL_SERVICES', payload });
	}, []);

	const setSelectedServices = useCallback((payload) => {
		console.log('context/setting allServices', payload);
		dispatch({ type: 'SET_SELECTED_SERVICES', payload });
	}, []);

	const setAnswer = useCallback((payload) => {
		console.log('context/setting answer', payload);
		dispatch({ type: 'SET_ANSWER', payload });
	}, []);

	const handleCheckout = useCallback(() => {
		console.log('CHECKOUT', state);
		dispatch({ type: 'CHECKOUT' });
	}, []);

	const getStepAnswers = (get) => {
		if (get === parseInt(get, 10)) return state.stepAnswers[get];
		return state.stepAnswers[state.steps.findIndex((el) => el.name === get)];
	};

	const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);

	const getItemTotal = () => {
		const type = state.stepAnswers[0];
		let payCycle = 1;
		let amount = 0;

		try {
			if (getStepAnswers('billingdetails').payfrequency > 1) {
				payCycle = getStepAnswers('billingdetails').payfrequency;
			}
		} catch (e) {}

		if (type === 'Residential') amount = getStepAnswers('services') && getStepAnswers('services').reduce((total, i) => (total += i.cans.frequency.price), 0) * payCycle;
		if (type === 'Commercial') amount = getStepAnswers('commercialservices') && getStepAnswers('commercialservices').reduce((total, i) => (total += i.cans.frequency.price), 0) * payCycle;

		if (getStepAnswers('rolloffservices')) amount = getStepAnswers('rolloffservices').cost;

		// const purchaseItemsStep = state.stepAnswers[0] === 'Commercial' ? 7 : state.stepAnswers[0] === 'Roll-Offs' ? 8 : 2;

		// const taxRate = getStepAnswers('rolloffservices') ? state.stepAnswers[purchaseItemsStep].service.tax : state.stepAnswers[purchaseItemsStep][0].tax;
		// const tax = round(amount * (taxRate / 100), 2);

		return amount;
	};

	const getOrderTotalNoFrequency = () => {
		const type = state.stepAnswers[0];
		let amount = 0;
		let tax = 0;

		if (type === 'Residential') amount = getStepAnswers('services') && getStepAnswers('services').reduce((total, i) => (total += i.cans.frequency.price), 0);
		if (type === 'Commercial') amount = getStepAnswers('commercialservices') && getStepAnswers('commercialservices').reduce((total, i) => (total += i.cans.frequency.price), 0);
		if (type === 'Roll-Offs') amount = getStepAnswers('rolloffservices').cost;

		const purchaseItemsStep = state.stepAnswers[0] === 'Commercial' ? 7 : state.stepAnswers[0] === 'Roll-Offs' ? 8 : 2;

		if (type === 'Roll-Offs') {
			tax = round(amount * (getStepAnswers('rolloffservices').service.tax / 100), 2);
		} else {
			tax = round(amount * (state.stepAnswers[purchaseItemsStep][0].tax / 100), 2);
		}

		return amount + tax;
	};

	const getOrderTotal = () => {
		const type = state.stepAnswers[0];
		let payCycle = 1;
		let amount = 0;
		let tax = 0;

		try {
			if (getStepAnswers('billingdetails').payfrequency > 1) {
				payCycle = getStepAnswers('billingdetails').payfrequency;
			}
		} catch (e) {}

		if (type === 'Residential') amount = getStepAnswers('services') && getStepAnswers('services').reduce((total, i) => (total += i.cans.frequency.price), 0) * payCycle;
		if (type === 'Commercial') amount = getStepAnswers('commercialservices') && getStepAnswers('commercialservices').reduce((total, i) => (total += i.cans.frequency.price), 0) * payCycle;
		if (type === 'Roll-Offs') amount = getStepAnswers('rolloffservices').cost;

		const purchaseItemsStep = state.stepAnswers[0] === 'Commercial' ? 7 : state.stepAnswers[0] === 'Roll-Offs' ? 8 : 2;

		if (type === 'Roll-Offs') {
			tax = round(amount * (getStepAnswers('rolloffservices').service.tax / 100), 2);
		} else {
			tax = round(amount * (state.stepAnswers[purchaseItemsStep][0].tax / 100), 2);
		}

		return amount + tax;
	};

	const getOrderTax = () => {
		const type = state.stepAnswers[0];
		let payCycle = 1;
		let amount = 0;
		let tax = 0;

		try {
			if (getStepAnswers('billingdetails').payfrequency > 1) {
				payCycle = getStepAnswers('billingdetails').payfrequency;
			}
		} catch (e) {}

		if (type === 'Residential') amount = getStepAnswers('services') && getStepAnswers('services').reduce((total, i) => (total += i.cans.frequency.price), 0) * payCycle;
		if (type === 'Commercial') amount = getStepAnswers('commercialservices') && getStepAnswers('commercialservices').reduce((total, i) => (total += i.cans.frequency.price), 0) * payCycle;
		if (type === 'Roll-Offs') amount = getStepAnswers('rolloffservices').cost;

		const purchaseItemsStep = state.stepAnswers[0] === 'Commercial' ? 7 : state.stepAnswers[0] === 'Roll-Offs' ? 8 : 2;

		if (type === 'Roll-Offs') {
			tax = round(amount * (getStepAnswers('rolloffservices').service.tax / 100), 2);
		} else {
			tax = round(amount * (state.stepAnswers[purchaseItemsStep][0].tax / 100), 2);
		}

		return tax;
	};

	const contextValues = {
		getOrderTax,
		getItemTotal,
		getOrderTotal,
		getOrderTotalNoFrequency,
		getStepAnswers,
		setHauler,
		setAvailableServices,
		setSelectedServices,
		setAllServices,
		setAnswer,
		handleCheckout,
		...state,
	};

	return <SignUpContext.Provider value={contextValues}>{children}</SignUpContext.Provider>;
};

export default SignUpContextProvider;
