import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import useFetch, { useFormSubmit } from '../../hooks/useFetch';
import { AnimatePresence, motion } from 'framer-motion';

import TrashMan from '../HomePage/TrashJoes-man.png';
import { api } from '../../config';

const RegistrationForm = ({ groups, role }) => {
	const history = useHistory();
	const group = groups && groups.length > 0 ? groups[0] : false;
	const [{ error, classes }, setSubmitError] = useState('');
	const { postData } = useFormSubmit(`${api}canmanager.cfc?method=register`);

	const { register, handleSubmit, errors, reset, watch } = useForm({
		defaultValues: {
			// firstname: 'test',
			// lastname: 'test',
			// username: 'test',
			// password: 'test12345',
			// confirm_password: 'test12345',
		},
	});
	const password = useRef({});
	password.current = watch('password', '');

	const onSubmit = (values) => {
		values.owner = group ? false : true;
		values.groupId = group ? group.id : '';
		values.roleId = '';
		values.roleId = group && role ? group[`${role.toLowerCase()}id`] : values.roleId;
		values.username = values.username.trim();

		postData(values).then((r) => {
			setSubmitError({ error: r.message, classes: r.success ? 'green white-text' : 'red white-text' });
			setTimeout(() => {
				setSubmitError({});
				if (r.success) {
					history.push('/login');
				}
			}, 2000);
			// if (!r.success) M.toast({ html: r.message, classes: 'red white-text' });
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="card">
				<div className="card-content">
					<span className="card-title black-text" style={{ textDecoration: 'none' }}>
						Please Fill out the information below to register your company with Trash Joes "Can Manger"
						<br />
						{group && group.name ? (
							<span>
								You are signing up as a <u>{role.toUpperCase()}</u> for group <u>{group.name.toUpperCase()}</u>
							</span>
						) : (
							<span>You are signing up as the owner of your group.</span>
						)}
					</span>
					<div className="row" style={{ margin: 0 }}>
						{!group && (
							<>
								<div className="col s12 input-field" style={{ marginBottom: 0 }}>
									<input id="groupname" name="groupname" type="text" ref={register({ required: 'Username is required!' })} />
									<label className="active" htmlFor="groupname">
										Company Name
									</label>
									<span className="red-text helper-text">{errors.groupname && errors.groupname.message}</span>
								</div>
								<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
									<input id="companyphone" name="companyphone" type="text" ref={register({ required: 'Company Phone is required!' })} />
									<label className="active" htmlFor="companyphone">
										Company Phone
									</label>
									<span className="red-text helper-text">{errors.companyphone && errors.companyphone.message}</span>
								</div>
								<div className="col s12 input-field" style={{ marginBottom: 0 }}>
									<input id="companyaddress" name="companyaddress" type="text" ref={register({ required: 'Company Phone is required!' })} />
									<label className="active" htmlFor="companyaddress">
										Company Address
									</label>
									<span className="red-text helper-text">{errors.companyaddress && errors.companyaddress.message}</span>
								</div>
							</>
						)}
						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<input id="firstname" name="firstname" type="text" ref={register({ required: 'First Name is required!' })} />
							<label className="active" htmlFor="firstname">
								First Name
							</label>
							<span className="red-text helper-text">{errors.firstname && errors.firstname.message}</span>
						</div>
						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<input id="lastname" name="lastname" type="text" ref={register({ required: 'Last Name is required!' })} />
							<label className="active" htmlFor="lastname">
								Last Name
							</label>
							<span className="red-text helper-text">{errors.lastname && errors.lastname.message}</span>
						</div>

						<div className="col s12 input-field" style={{ marginBottom: 0 }}>
							<input id="username" name="username" type="text" ref={register({ required: 'Username is required!' })} placeholder="email@domain.com" />
							<label className="active" htmlFor="username">
								Username ( email@domain.com )
							</label>
							<span className="red-text helper-text">{errors.username && errors.username.message}</span>
						</div>
					</div>
					<div className="row" style={{ margin: 0 }}>
						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<input
								id="password"
								name="password"
								type="password"
								ref={register({
									required: 'You must specify a password',
									minLength: {
										value: 8,
										message: 'Password must have at least 8 characters',
									},
								})}
							/>
							<label className="active" htmlFor="password">
								Password
							</label>
							<span className="red-text helper-text">{errors.password && errors.password.message}</span>
						</div>
						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<input
								id="confirm_password"
								name="confirm_password"
								type="password"
								ref={register({
									validate: (value) => value === password.current || 'The passwords do not match',
								})}
							/>
							<label className="active" htmlFor="confirm_password">
								Confirm Password
							</label>
							<span className="red-text helper-text">{errors.confirm_password && errors.confirm_password.message}</span>
						</div>

						<AnimatePresence>
							{error && (
								<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={`toast ${classes}`} style={{ top: 0 }}>
									{error}
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</div>
				<div className="card-action">
					<button type="submit" className="green white-text btn btn-small" style={{ marginRight: 10 }}>
						Register
					</button>
					<button onClick={reset} className="grey white-text btn btn-small" style={{ marginRight: 10 }}>
						Clear
					</button>
				</div>
			</div>
		</form>
	);
};

const Wrapper = () => {
	// const [{ data, loading, error }, setFetch] = useState({});
	const [role, setRole] = useState('Manager');
	const { codes } = useParams();
	const fetchUrl = codes ? `${api}canmanager.cfc?method=getGroup&id=${codes.split('::')[0]}` : '';

	const { data: groups, loading } = useFetch(fetchUrl);

	useEffect(() => {
		if (groups && groups.length > 0) {
			if (groups[0].managerid === codes.split('::')[1]) setRole('Manager');
			if (groups[0].driverid === codes.split('::')[1]) setRole('Driver');
		}
	}, [groups, codes, setRole]);

	const hero = {
		backgroundImage: `url(https://source.unsplash.com/UBU_pM78yxQ)`,
		// backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${hero_bg})`,
		height: '50%',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
	};

	return (
		<div className="home">
			<div className="titleStyle" style={hero}>
				<div className="container row">
					<div className="col s12 m6 l5 whiteBluryBg" style={{ width: '60%' }}>
						<h1>Welcome to Trash Joes Can Manager</h1>
						<p>Register for our free Can Manger that will help you organize can deliveries and go backs!</p>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m4">
						<div style={{ textAlign: 'center', marginTop: 20 }}>
							<img src={TrashMan} />
						</div>
					</div>
					<div className="col s12 m8">{!loading && <RegistrationForm groups={groups} role={role} />}</div>
				</div>
			</div>
		</div>
	);
};

export default Wrapper;
