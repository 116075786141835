import config from './config';
import Cookies from 'js-cookie';

export default class Data {
	api(path, method = 'GET', body = null, requiresAuth = false, credentials = null) {
		const url = config.apiBaseUrl + path;

		const options = {
			method,
			headers: {
				'Content-Type': 'application/json',
				// 'Access-Control-Allow-Origin': '*',
			},
		};

		if (body !== null) {
			options.body = JSON.stringify(body);
		}
		if (requiresAuth) {
			if ('username' in credentials && 'password' in credentials) {
				const encodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
				options.headers['Authorization'] = ` Basic ${encodedCredentials}`;
			} else if ('token' in credentials) {
				const encodedCredentials = btoa(`${credentials.token}`);
				options.headers['Authorization'] = ` Bearer ${encodedCredentials}`;
			}
		}

		return fetch(url, options);
	}

	async login(username, password) {
		const path = `/users.cfc?method=login&x=${Math.random()}`;
		const credentials = { username, password };
		return await this.api(path, 'POST', null, true, credentials)
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch(() => null);
	}

	async authorize(token) {
		const response = await this.api(`/users.cfc?method=authorize`, 'GET', null, true, {
			token,
		});

		if (response.status === 200) {
			return response.json().then((data) => data);
		} else if (response.status === 401) {
			return null;
		} else {
			throw new Error();
		}
	}

	async createUser(user) {
		const response = await this.api('/users', 'POST', user);
		if (response.status === 201) {
			return [];
		} else if (response.status === 400) {
			return response.json().then((data) => {
				return data.errors;
			});
		} else {
			throw new Error();
		}
	}

	async getUser(user, token) {
		// API call to retrieve User Data
		if (!user) {
			return {};
		}

		return await this.api(`/users.cfc?method=get&i=${user}`, 'GET', null, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async reloadUser(user, token) {
		// API call to retrieve User Data
		return await this.api(`/users.cfc?method=reload&i=${user}`, 'GET', null, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	getUserFromToken() {
		const token = Cookies.getJSON('authToken') || null;
		// API call to retrieve User Data
		if (token) {
			return token;
		} else {
			return null;
		}
	}

	async getUsers(token) {
		return await this.api(`/users.cfc?method=getUsers`, 'GET', null, true, {
			token,
		}).then((response) => response.json());
	}

	async update(user, token) {
		return await this.api(`/users.cfc?method=update`, 'POST', user, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async insert(user, token) {
		return await this.api(`/users.cfc?method=insert`, 'POST', user, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async resetProfileImage(uid, token) {
		return await this.api(`/users.cfc?method=resetProfileImage`, 'POST', { uid }, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async updateProfileImage(data, token) {
		console.log({ data });
		return await this.api('/users.cfc?method=updateProfileImage', 'POST', data, true, { token })
			.then((response) => {
				if (response.status === 200) return response.json();
				return null;
			})
			.catch((err) => console.log({ err }));
	}

	async initPasswordReset(data) {
		return await this.api('/users.cfc?method=initPasswordReset', 'POST', data)
			.then((response) => {
				if (response.status === 200) return response.json();
				return null;
			})
			.catch((err) => console.log({ err }));
	}

	async doPasswordReset(data) {
		return await this.api('/users.cfc?method=doPasswordReset', 'POST', data)
			.then((response) => {
				if (response.status === 200) return response.json();
				return null;
			})
			.catch((err) => console.log({ err }));
	}
}
