import React, { useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import trashMan from '../HomePage/TrashJoes-man.png';

import Demo from '../hauler';

const Sevices = () => {
	const history = useHistory();
	const { hauler } = useParams();

	useEffect(() => {
		const onMessage = (e) => {
			// if(e.origin !== "https://app.trashjoes.com") return;
			try {
				const { message } = e.data;
				message === 'push-contact' && history.push('/Contact');
			} catch (e) {}
		};

		if (hauler) {
			if (window.addEventListener) {
				window.addEventListener('message', onMessage, false);
			}
			window.scrollTo({
				top: document.getElementById('demo').offsetTop,
				behavior: 'smooth',
			});
		}

		return () => {
			try {
				window.removeEventListener('message', onMessage);
			} catch (e) {}
			return;
		};
	}, [hauler]);

	return (
		<div className="home">
			<div className="container">
				{!hauler && (
					<div className="row" style={{ marginTop: '90px', marginBottom: hauler ? 0 : '80px' }}>
						<div className="col s12 m4 center-align hide-on-small-only">
							<img src={trashMan} alt="" />
						</div>
						<div className="col s12 m8 left-align titleStyle">
							<h1 className="red-text">What are you about to see?</h1>

							<p>When you click our demo, you’re going to see a tool that will allow you to onboard new customers without answering a phone call or processing any paperwork. New customers can sign up for service online at any time from any device ... and it only takes a few clicks.</p>
							<ol>
								<li>
									<b>Choose what type of service you want to add</b> - haulers have the ability to customize service options using a password protected manager so customer see current service options and pricing.
								</li>
								<li>
									<b>Enter your address</b> - haulers upload maps of the areas they service and customer can choose to see a street view or map view to make sure they are seeing the right address before moving to the next step
								</li>
								<li>
									<b>Confirm service</b> - customer will be asked to confirm the service they are ordering before finalizing the order
								</li>
								<li>
									<b>Customer information</b> - haulers will get all the information they need to create a new customer record
								</li>
								<li>
									<b>Checkout</b> - customer will pay the initial payment to start service through a secure connection
								</li>
							</ol>
							<p>
								{/* <a className="btn-large" href="https://app.trashjoes.com/h/trash-joes-demo" target="_blank">
									VIEW DEMO
								</a> */}
								<Link className="btn-large" to="/demo/trash-joes-demo">
									START DEMONSTRATION
								</Link>
							</p>
						</div>
					</div>
				)}
				{/* {demo && <iframe id="demo" src="https://app.trashjoes.com/h/trash-joes-demo" style={{ width: '100%', minHeight: '700px', border: 0 }} />} */}
			</div>
			{hauler && (
				<>
					<h4 style={{ marginTop: '40px' }} className="center-align">
						APPLICATION DEMONSTRATION
					</h4>
					<div id="demo" style={{ minHeight: '110vh' }}>
						<Demo demo={true} />
					</div>
				</>
			)}

			{/* <div className="row container wrap-contact displayAdj">
				<div className="col s12 m6 l6 padAdj left-align animatedParent animateOnce">
					<img src={maps} alt="" className="responsive-img card animated fadeInUpShort" />
				</div>
				<div className="col s12 m6 l6 padAdj left-align">
					<h4 className="red-text">Google Maps</h4>
					<p>Integrated Google Maps to improve customer address validation and improve customer experience</p>
				</div>
			</div>
			<br />
			<br />

			<div className="row container wrap-contact displayAdj">
				<div className="col s12 m6 l6 push-m6 push-l6 padAdj right-align animatedParent animateOnce">
					<img src={pic2} alt="" className="responsive-img card animated fadeInUpShort" />
				</div>
				<div className="col s12 m6 l6 pull-m6 pull-l6 padAdj right-align">
					<h4 className="red-text">Retention</h4>
					<p>
						Once you’ve got them, you need to keep them. You will receive access to our direct-to-consumer texting platform that enables you to text your customers the night before trash day to remind them to put their cans out. And you can text reminders about holiday pick ups, schedule changes and unpaid invoices. Even better – your customers can reply with questions that you can
						answer from anywhere without being tied to an office phone.
					</p>
				</div>
			</div>
			<br />
			<br />

			<div className="row container wrap-contact displayAdj">
				<div className="col s12 m6 l6 padAdj left-align animatedParent animateOnce">
					<img src={pic3} alt="" className="responsive-img card animated fadeInUpShort" />
				</div>
				<div className="col s12 m6 l6 padAdj left-align">
					<h4 className="red-text">Payment</h4>
					<p>Trash Joe’s gives you the ability to text customers with account and payment info so they can easily submit payments to directly to you. Customer payment information can easily be imported into your existing operational system – TrashFlow, WAM, CRO and many others! Trash Joe’s is just a conduit – the money comes directly to you.</p>
				</div>
			</div>
			<br />
			<br />
			<br /> */}
		</div>
	);
};

export default Sevices;
