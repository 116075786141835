import React, { useContext } from 'react';
import List from './list';
import Edit from './edit';
import { AuthContext } from '../../../components/auth-provider';
import './style.css';
import { useHistory } from 'react-router-dom';

export default function Company({ match, ...rest }) {
	const { user } = useContext(AuthContext);
	const history = useHistory();
	if (['can-manager', 'can-driver'].includes(user.user.role)) history.push('/admin/can-manager');

	if (user.user.role === 'admin') {
		if (match.params.userId) {
			return <Edit u={match.params.userId} {...rest} />;
		}
		return <List {...rest} />;
	} else {
		return <Edit u={user.user.company.id} {...rest} />;
	}
}
