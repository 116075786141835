import M from 'materialize-css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { isMobile } from 'react-device-detect';
import { SignUpContext } from './context/signup';
import { useStyles } from './elementStyles';
import CCImage from './iframe-cc.jpg';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

const encodeQueryData = (data) => {
	const ret = [];
	for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
	return ret.join('&');
};

const badCreditFreePurchase = (id) => {
	var apache = 30;
	var mustang = 33;
	var check = [mustang, apache];

	var isFound = check.includes(id);

	return isFound;
};

const has50ServiceCharge = (id) => {
	var dependable = 35;
	var check = [dependable];

	return check.includes(id);
};

const canDisplayFrequency = (id, freq, type) => {
	if (id !== 38) return true;
	if (type !== 'Residential') return true;
	if (freq == 3) return true;
	return false;
};

const hasCommercial50ServiceCharge = (id, type) => {
	if (type !== 'Commercial') return false;

	var blueRibbonDisposal = 38;
	var check = [blueRibbonDisposal];

	return check.includes(id);
};

const Card1 = ({ step, stepIndex, goToStep }) => {
	const { getStepAnswers, getOrderTotalNoFrequency, stepAnswers, hauler, setAnswer } = useContext(SignUpContext);

	const type = getStepAnswers('intro');

	const initialPayFrequency = (() => {
		const frequencies = ['1', '3', '12'];
		const result =
			frequencies.find((frequency) => {
				return canDisplayFrequency(hauler.id, parseInt(frequency), type) && hauler.payfrequency.includes(frequency);
			}) || null;

		return result ?? 1;
	})();

	const answer = stepAnswers[stepIndex] ? stepAnswers[stepIndex] : { payfrequency: initialPayFrequency, fullname: '', tokenResponse: {} };

	const purchaseItemsStep = getStepAnswers('intro') === 'Commercial' ? 'commercialservices' : getStepAnswers('intro') === 'Roll-Offs' ? 'rolloffservices' : 'services';

	const order = {
		user: getStepAnswers('customerdetails'),
		billing: { ...getStepAnswers('billingdetails'), total: getOrderTotalNoFrequency() },
		services: getStepAnswers(purchaseItemsStep),
		address: { ...getStepAnswers('address'), type: getStepAnswers('intro') },
		company: hauler,
		purchaseItemsStep,
	};

	const [form, setForm] = useState(answer);

	useEffect(() => {
		// setAnswer({ stepIndex, answer: form });
		M.AutoInit();
		M.updateTextFields();
	}, [form]);

	useEffect(() => {
		M.AutoInit();
		M.updateTextFields();
	}, []);

	const customer = getStepAnswers('customerdetails');
	const current = getStepAnswers('billingdetails');

	useEffect(() => {
		const handleToken = (e) => {
			try {
				const tryToken = JSON.parse(e.data);
				if (tryToken.message.length > 0) {
					setForm({ ...form, tokenResponse: tryToken });
					setAnswer({ stepIndex, answer: { ...form, tokenResponse: tryToken } });
				}
			} catch (e) {}
		};

		window.addEventListener('message', handleToken, false);

		return () => {
			window.removeEventListener('message', handleToken);
		};
	});

	const s = {
		demoNotes: {
			position: 'fixed',
			top: '50px',
			left: 0,
			width: '100%',
			background: 'white',
			padding: '10px',
			zIndex: '100',
		},
	};

	// const [notice, setNotice] = useNotice('notice', false);
	// const [notice, setNotice] = useState(true);
	const { styles } = useStyles();

	const handleInitialPaymentChange = (e) => setForm({ ...form, payfrequency: e.target.value });

	const demoCompanyId = 10;
	const blueRibbonDisposal = 38;

	return (
		<React.Fragment>
			<div className="card container">
				{/* {hauler.id === 10 && notice && (
					<div style={s.demoNotes} className="card demo-notes-container">
						<div className="container">
							<div className="row">
								<div className="col s12">
									<p>Please use 4111 1111 1111 1111 as your payment card below with any valid expiration and cvv to simulate a valid transaction</p>
									<button className="btn red white-text" onClick={() => setNotice(false)}>
										CLOSE
									</button>
								</div>
							</div>
						</div>
					</div>
				)} */}
				<div className="card-content" style={{ maxHeight: '600px', maxHeight: '100%' }}>
					<span style={{ ...styles.title, marginBottom: 0, paddingBottom: 0 }} className="card-title z-depth-1">
						{hauler.id === 10 ? <>Please click NEXT STEP to continue</> : step.title}
					</span>
					<div>
						<div className="row" style={{ marginBottom: 0 }}>
							<div className="col s12 m10 offset-m1 l8 offset-l2">
								{badCreditFreePurchase(hauler.id) ? (
									<h5 style={{ marginTop: '51px' }}>We will text and email you a link for you to make your first payment</h5>
								) : (
									<>
										{hauler.id === 10 && (
											<p>
												<small>Billing information has been excluded from this demo</small>
												<br />
											</p>
										)}
										<div className="row" style={{ marginBottom: '0' }}>
											<div className={`col ${type === 'Roll-Offs' ? `s12 m6 offset-m3` : 's6'} input-field`}>
												<input style={styles.input} id="fullname" type="text" defaultValue={answer.fullname || `${customer.firstname} ${customer.lastname}`} onChange={(e) => setForm({ ...form, fullname: e.target.value })} />
												<label className="active" style={{ left: '1.5rem' }} htmlFor="fullname">
													Full Name
												</label>
											</div>
											{type !== 'Roll-Offs' && hauler.payfrequency.length > 1 && (
												<div className="col s6 input-field">
													<label style={{ left: '1.5rem' }} className="active" htmlFor="payfrequency">
														Initial Payment For
													</label>
													<select className="browser-default" name="payfrequency" defaultValue={form.payfrequency} onChange={handleInitialPaymentChange}>
														<option disabled value={0}>
															SELECT
														</option>
														{canDisplayFrequency(hauler.id, 1, type) && (hauler.payfrequency.indexOf('1') !== -1 || hauler.payfrequency.indexOf(1) !== -1) && <option value={1}>Monthly</option>}
														{canDisplayFrequency(hauler.id, 3, type) && (hauler.payfrequency.indexOf('3') !== -1 || hauler.payfrequency.indexOf(3) !== -1) && <option value={3}>Quarterly</option>}
														{canDisplayFrequency(hauler.id, 12, type) && (hauler.payfrequency.indexOf('12') !== -1 || hauler.payfrequency.indexOf(12) !== -1) && <option value={12}>Yearly</option>}
													</select>
												</div>
											)}
											{type === 'Roll-Offs' && (
												<div className="col s12">
													<p style={{ margin: 0 }}>
														Initial Deposit/Payment
														<br />
														See{' '}
														<a href={hauler.tosrolloff} target="_blank">
															Terms
														</a>{' '}
														for Full Fee Disclosures
													</p>
												</div>
											)}
										</div>
										<div className="row" style={{ margin: 0 }}>
											<div className="col s12">
												<ul className="left-align collection" style={{ border: '0', margin: '0 auto' }}>
													<li className="collection-item">
														{type !== 'Roll-Offs' && (
															<>
																Total for {form.payfrequency} Month{form.payfrequency > 1 ? 's' : ''}
															</>
														)}
														{type === 'Roll-Offs' && <>Total</>}
														<span className="right">
															<CurrencyFormat {...currencyAttr} value={round(order.billing.total * form.payfrequency, 2)} />
														</span>
													</li>
													{has50ServiceCharge(hauler.id) && (
														<li class="collection-item">
															One time setup fee
															<span class="right">
																<span>$50.00</span>
															</span>
														</li>
													)}
													{hasCommercial50ServiceCharge(hauler.id, type) && (
														<li class="collection-item">
															Delivery fee
															<span class="right">
																<span>$50.00</span>
															</span>
														</li>
													)}
												</ul>
											</div>
										</div>
										<div className="row" style={{ margin: '0' }}>
											<div className="col s12" style={{ padding: 0 }}>
												{/* <iframe id="tokenFrame" style={{ height: '210px' }} name="tokenFrame" src={`https://fts${hauler.id === 10 ? '-uat' : ''}.cardconnect.com/itoke/ajax-tokenizer.html?${encodeQueryData(tokenUrlData)}`} frameBorder="0" scrolling="no" /> */}
												{hauler.id !== demoCompanyId && <CardConnectIFrame />}
												{hauler.id === demoCompanyId && (
													<div className="center-align">
														<img src={CCImage} alt="" style={{ maxWidth: '300px', width: '100%' }} />
													</div>
												)}
											</div>
										</div>
										{hauler.id !== demoCompanyId && (
											<div className="row" style={{ margin: '0' }}>
												<div className="input-field col s12 m6 offset-m3" style={{ marginBottom: 0 }}>
													<input style={styles.input} id="zip" type="text" defaultValue={answer.zip || ''} onKeyUp={(e) => setForm({ ...form, zip: e.target.value })} />
													<label style={{ left: '1.5rem' }} className="active" htmlFor="zip">
														Postal Code/Zip
													</label>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="card-action">
					<button style={{ ...styles.button.previous, margin: '0 10px' }} className="btn btn-small themedButton" onClick={() => goToStep('customerdetails', -1)}>
						Previous Step
					</button>

					<button
						style={{ ...styles.button.enabled, margin: '0 10px' }}
						className="btn btn-small themedButton"
						onClick={() => {
							try {
								if (hauler.id === 10 || badCreditFreePurchase(hauler.id)) {
									setAnswer({ stepIndex, answer: form });
									goToStep('review');
								}
								if (form !== current && form && form.tokenResponse && form.tokenResponse.message && form.tokenResponse.message.length > 0) {
									setAnswer({ stepIndex, answer: form });
								}
								if (current.tokenResponse.message.length > 0) {
									goToStep('review');
								}
							} catch (e) {}
						}}
					>
						Next Step
					</button>
				</div>
			</div>
		</React.Fragment>
	);
};
const RollOff = (props) => <Card1 {...props} />;

const RollOff1 = ({ step, stepIndex, goToStep, goToNextStep, goToPreviousStep }) => {
	const tokenEl = useRef(null);
	const { getStepAnswers, getOrderTotal, stepAnswers, hauler, setAnswer } = useContext(SignUpContext);

	const initialPayFrequency = ((hauler.payfrequency.indexOf('1') !== -1 || hauler.payfrequency.indexOf(1) !== -1) && 1) || ((hauler.payfrequency.indexOf('3') !== -1 || hauler.payfrequency.indexOf(3) !== -1) && 3) || ((hauler.payfrequency.indexOf('12') !== -1 || hauler.payfrequency.indexOf(12) !== -1) && 12);

	const answer = stepAnswers[stepIndex] ? stepAnswers[stepIndex] : { payfrequency: initialPayFrequency, fullname: '', tokenResponse: {} };

	const purchaseItemsStep = getStepAnswers('intro') === 'Commercial' ? 'commercialservices' : getStepAnswers('intro') === 'Roll-Offs' ? 'rolloffservices' : 'services';

	const order = {
		user: getStepAnswers('customerdetails'),
		billing: { ...getStepAnswers('billingdetails'), total: getOrderTotal() },
		services: getStepAnswers(purchaseItemsStep),
		address: { ...getStepAnswers('address'), type: getStepAnswers('intro') },
		company: hauler,
		purchaseItemsStep,
	};

	const [form, setForm] = useState(answer);

	const { primaryColor, primaryTextColor } = hauler.theme;

	const secondaryColor = 'blue';

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: primaryTextColor,
	};

	useEffect(() => {
		// setAnswer({ stepIndex, answer: form });
		M.AutoInit();
	}, [form]);

	useEffect(() => {
		M.AutoInit();

		setForm({ ...form, fullname: `${customer.firstname} ${customer.lastname}` });
		setAnswer({ stepIndex, answer: { ...form, fullname: `${customer.firstname} ${customer.lastname}` } });
	}, []);

	const customer = getStepAnswers('customerdetails');
	const current = getStepAnswers('billingdetails');

	const encodeQueryData = (data) => {
		const ret = [];
		for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
		return ret.join('&');
	};

	useEffect(() => {
		const handleToken = (e) => {
			try {
				const tryToken = JSON.parse(e.data);
				if (tryToken.message.length > 0 && !tryToken.validationError) {
					setForm({ ...form, tokenResponse: tryToken });
					setAnswer({ stepIndex, answer: { ...form, tokenResponse: tryToken } });
				}
			} catch (e) {}
		};

		window.addEventListener('message', handleToken, false);

		return () => {
			window.removeEventListener('message', handleToken);
		};
	});

	const tokenUrlData = {
		unique: true,
		useexpiry: true,
		usemonthnames: true,
		usecvv: true,
		orientation: 'horizontal',
		formatinput: true,
		autofocus: true,
		invalidinputevent: true,
		tokenizewheninactive: isMobile ? true : false,
		css: 'br{display:none;}label{display:block;}input{background-color: transparent; border: none; border-bottom: 1px solid #9e9e9e; border-radius: 0; outline: none; height: 35px; width: 100%; font-size: 16px; margin: 0 0 8px 0; padding: 0; -webkit-box-shadow: none; box-shadow: none; -webkit-box-sizing: content-box; box-sizing: content-box; -webkit-transition: border .3s, -webkit-box-shadow .3s; transition: border .3s, -webkit-box-shadow .3s; transition: box-shadow .3s, border .3s; transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;}select{osition: relative; cursor: pointer; background-color: transparent; border: none; border-bottom: 1px solid #9e9e9e; outline: none; height: 3rem; line-height: 2.5rem; font-size: 16px; margin: 0 0 8px 0; padding: 0; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; z-index: 1;}#cccvvfield{width:auto}',
	};

	const { styles } = useStyles();

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content" style={{ maxHeight: '600px', maxHeight: '100%' }}>
					<span style={primaryBgColor} className="card-title z-depth-1">
						{step.title}
					</span>
					<div style={{ padding: '20px 0' }}>
						<div className="row">
							<div className="col s6 m6 offset-m3">
								{badCreditFreePurchase(hauler.id) ? (
									<h5 style={{ marginTop: '51px' }}>We will text and email you a link for you to make your first payment</h5>
								) : (
									<>
										<div className="row" style={{ marginBottom: '0' }}>
											<div className={`col s12 input-field`}>
												<input id="fullname" type="text" defaultValue={answer.fullname || `${customer.firstname} ${customer.lastname}`} onKeyUp={(e) => setForm({ ...form, fullname: e.target.value })} />
												<label className="active" htmlFor="fullname">
													Full Name
												</label>
											</div>
											<div className="col s12">
												<ul className="left-align collection" style={{ border: '0', margin: '0 auto' }}>
													<li className="collection-item">
														Total charge
														<span className="right">
															<CurrencyFormat {...currencyAttr} value={round(order.billing.total * (1 + order.services[0].tax / 100), 2)} />
														</span>
													</li>
													{has50ServiceCharge(hauler.id) && (
														<li class="collection-item">
															One time setup fee
															<span class="right">
																<span>$50.00</span>
															</span>
														</li>
													)}
												</ul>
											</div>
										</div>
										<div className="row" style={{ margin: '0' }}>
											<div className="col s12" style={{ padding: 0 }}>
												{/* <iframe id="tokenFrame" style={{ height: '210px' }} name="tokenFrame" src={`https://fts${hauler.id === 10 ? '-uat' : ''}.cardconnect.com/itoke/ajax-tokenizer.html?${encodeQueryData(tokenUrlData)}`} frameBorder="0" scrolling="no" /> */}
												<CardConnectIFrame />
												{/* <iframe style={{ height: '210px' }} id="tokenFrame" name="tokenFrame" src={`https://fts.cardconnect.com/itoke/ajax-tokenizer.html?${encodeQueryData(tokenUrlData)}`} frameBorder="0" scrolling="no" /> */}
											</div>
										</div>
										<div className="row" style={{ margin: '0' }}>
											<div className="input-field col s12" style={{ marginBottom: 0 }}>
												<input id="zip" type="text" defaultValue={answer.zip || ''} onKeyUp={(e) => setForm({ ...form, zip: e.target.value })} />
												<label className="active" htmlFor="zip">
													Postal Code/Zip
												</label>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="card-action">
					<button style={{ ...styles.button.previous, margin: '0 10px' }} className="btn btn-small themedButton" onClick={() => goToPreviousStep(stepIndex)}>
						Previous Step
					</button>

					<button
						style={{ ...styles.button.enabled, margin: '0 10px' }}
						className="btn btn-small themedButton"
						onClick={() => {
							try {
								if (form !== current && form && form.tokenResponse && form.tokenResponse.message && form.tokenResponse.message.length > 0) {
									setAnswer({ stepIndex, answer: form });
								}
								goToStep('review');
							} catch (e) {}
						}}
					>
						Next Step
					</button>
				</div>
			</div>
		</React.Fragment>
	);
};

const CardConnectIFrame = () => {
	const { hauler } = useContext(SignUpContext);

	const tokenUrlData = {
		useexpiry: true,
		unique: true,
		usemonthnames: true,
		usecvv: true,
		invalidcvvevent: true,
		orientation: 'custom',
		formatinput: true,
		autofocus: true,
		placeholder: hauler.id === 10 ? 4111111111111111 : '',
		invalidinputevent: true,
		tokenizewheninactive: isMobile ? true : false,
		css: 'br{display:none;}label{display:block;}input{max-width:90%;background-color: transparent; border: 1px solid #9e9e9e; border-radius: 0; outline: none; height: 35px; width: 100%; font-size: 16px; margin: 0 0 8px 0; padding: 0; -webkit-box-shadow: none; box-shadow: none; -webkit-box-sizing: content-box; box-sizing: content-box; -webkit-transition: border .3s, -webkit-box-shadow .3s; transition: border .3s, -webkit-box-shadow .3s; transition: box-shadow .3s, border .3s; transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;padding-left:10px;}select{max-height:37px;position: relative; cursor: pointer; background-color: transparent; border: 1px solid #9e9e9e; outline: none; height: 3rem; line-height: 2.5rem; font-size: 16px; margin: 0 0 8px 0; padding: 0; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; z-index: 1;}#cccvvfield{width:60px;}',
	};

	return <iframe id="tokenFrame" style={{ height: '210px' }} name="tokenFrame" src={`https://fts${hauler.id === 10 ? '-uat' : ''}.cardconnect.com/itoke/ajax-tokenizer.html?${encodeQueryData(tokenUrlData)}`} frameBorder="0" scrolling="no" />;
};

const Default = (props) => {
	const { stepAnswers } = useContext(SignUpContext);

	return stepAnswers[0] === 'Roll-Offs' ? <RollOff {...props} /> : <Card1 {...props} />;
};

export default Default;
