import React, { useEffect, useState, useRef, useCallback } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import { useRaisedShadow } from '../../hooks/useRaisedShadow';
import './style.css';
import { googleApiKey } from '../../config';

// const initialAddresses = ['123 east 31st', '312 willaby', '111 crazy lane', '1213 east 31st', '3121 willaby', '1111 crazy lane'];

export function arrayMove(items, startIndex, endIndex) {
	const clone = [...items];
	clone[endIndex] = items[startIndex];
	clone[startIndex] = items[endIndex];
	return clone;
}

const findIndex = (i, yOffset, sizes, swapDistance) => {
	let target = i;

	// If moving down
	if (yOffset > 0) {
		const nextHeight = sizes[i + 1];
		if (nextHeight === undefined) return i;

		const swapOffset = swapDistance(nextHeight);
		if (yOffset > swapOffset) target = i + 1;

		// If moving up
	} else if (yOffset < 0) {
		const prevHeight = sizes[i - 1];
		if (prevHeight === undefined) return i;

		const swapOffset = swapDistance(prevHeight);
		if (yOffset < -swapOffset) target = i - 1;
	}

	return Math.min(Math.max(target, 0), sizes.length);
};

const Item = ({ address, itemProps, i }) => {
	const [dragState, ref, eventHandlers] = useDynamicListItem(i, 'y', itemProps);
	const y = useMotionValue(0);
	const boxShadow = useRaisedShadow(y);

	// We'll use a `ref` to access the DOM element that the `motion.li` produces.
	// This will allow us to measure its height and position, which will be useful to
	// decide when a dragging element should switch places with its siblings.
	// By manually creating a reference to `dragOriginY` we can manipulate this value
	// if the user is dragging this DOM element while the drag gesture is active to
	// compensate for any movement as the items are re-positioned.
	const dragOriginY = useMotionValue(0);

	// Update the measured position of the item so we can calculate when we should rearrange.
	return (
		<motion.li
			className="collection-item"
			layout
			initial={false}
			drag="y"
			ref={ref}
			style={{
				boxShadow: boxShadow.current,
			}}
			whileTap={{
				scale: 1.06,
			}}
			{...eventHandlers}
		>
			{address.address}
		</motion.li>
	);
};

const useDynamicListItem = (index, drag, { handleChange, handleDragStart, handleDragEnd, handleMeasure }) => {
	const [state, setState] = useState('idle');
	const ref = useRef(null);

	useEffect(() => {
		if (ref && ref.current) handleMeasure(index, drag === 'y' ? ref.current.offsetHeight : ref.current.offsetWidth);
	}, [ref, handleMeasure, index, drag]);

	return [
		state,
		ref,
		{
			onDragStart: () => {
				setState('dragging');
				handleDragStart(index);
			},
			onDragEnd: () => {
				setState('animating');
				handleDragEnd(index);
			},
			onAnimationComplete: () => {
				if (state === 'animating') setState('idle');
			},
			onViewportBoxUpdate: (_viewportBox, delta) => {
				if (state === 'dragging') handleChange(index, delta.y.translate);
			},
		},
	];
};

const useDynamicList = ({ addresses, swapDistance, onPositionUpdate, onPositionChange }) => {
	const sizes = useRef(new Array(addresses.length).fill(0)).current;
	const [startIndex, handleDragStart] = useState(-1);

	const handleChange = useCallback(
		(i, dragOffset) => {
			const targetIndex = findIndex(i, dragOffset, sizes, swapDistance);

			if (targetIndex !== i) {
				const swapSize = sizes[targetIndex];
				sizes[targetIndex] = sizes[i];
				sizes[i] = swapSize;

				onPositionUpdate(i, targetIndex);
			}
		},
		[sizes, swapDistance, onPositionUpdate]
	);

	const handleDragEnd = useCallback(
		(endIndex) => {
			if (onPositionChange && startIndex !== endIndex) onPositionChange(startIndex, endIndex);
			handleDragStart(-1);
		},
		[startIndex, onPositionChange]
	);

	const handleMeasure = useCallback(
		(index, size) => {
			sizes[index] = size;
		},
		[sizes]
	);

	return {
		handleChange,
		handleDragStart,
		handleDragEnd,
		handleMeasure,
	};
};

const calculateSwapDistance = (sibling) => sibling;

const MapRouter = ({ items, updateOrder, onCancelRouter }) => {
	const [addresses, setAddresses] = useState(items);
	const [openMap, setOpenMap] = useState(false);
	const [origin, setOrigin] = useState('');
	const [error, setError] = useState('');
	const [destination, setDestination] = useState('');

	const onPositionUpdate = useCallback(
		(startIndex, endIndex) => {
			const newOrder = arrayMove(addresses, startIndex, endIndex);
			setAddresses(newOrder);
			// updateOrder(newOrder);
		},
		[addresses, setAddresses]
	);

	const handleCloseRouter = () => {
		updateOrder(addresses);
		onCancelRouter();
	};

	const handleLoadMap = () => {
		if (origin.length > 0 && destination.length > 0) {
			setOpenMap(true);
		} else {
			setError('Starting and Ending Location must both be filled out.');
		}
	};

	const handleBlur = (e) => {
		if (e.target.name === 'origin') setOrigin(e.target.value);
		if (e.target.name === 'destination') setDestination(e.target.value);
	};

	const handleChange = () => setError('');

	const props = useDynamicList({
		addresses,
		swapDistance: calculateSwapDistance,
		onPositionUpdate,
	});

	const containerStyle = {
		// width: '100vw',
		// height: '100vh',
		background: 'white',
		// overflow: 'hidden',
		padding: '0',
		margin: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};

	return (
		<>
			<div className="wrapper ">
				<div className="container">
					<div className="col s12 input-field">
						<input id="origin" name="origin" type="text" onBlur={handleBlur} onChange={handleChange} />
						<label className="active" htmlFor="origin">
							Starting Location
						</label>
					</div>
					<div style={containerStyle} className="grey">
						<ul className="collection dynamic-reorder" style={{ overflow: 'unset', position: 'relative', width: '90%' }}>
							{addresses.map((address, i) => (
								// <Item key={address} i={i} address={address} setPosition={setPosition} moveItem={moveItem} />
								<Item key={address.id} i={i} address={address} itemProps={props} />
							))}
						</ul>
					</div>
					<div style={{ marginTop: 20 }} className="col s12 input-field">
						<input id="destination" name="destination" type="text" onBlur={handleBlur} onChange={handleChange} />
						<label className="active" htmlFor="destination">
							Ending Location
						</label>
					</div>
					<div className="s12">
						<div style={{ display: 'flex', marginBottom: 0 }}>
							<button className=" btn btn-small grey white-text" onClick={handleCloseRouter}>
								Close and Save Route Order
							</button>
							<button className="btn btn-small green white-text" onClick={handleLoadMap} style={{ marginLeft: 'auto' }}>
								Load Route
							</button>
						</div>
						{error.length > 0 && (
							<div className="toast-container">
								<div className="toast red white-text" style={{ top: 0, borderRadius: 0, display: 'block' }}>
									<p>
										<center>{error}</center>
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
				<hr />
				{openMap && <GMapRouter origin={origin} destination={destination} waypoints={addresses} />}
			</div>
		</>
	);
};

const convertWaypoints = (waypointsArray) => {
	const points = waypointsArray.map((point) => encodeURIComponent(point));
	return points.join('|');
};

const GMapRouter = ({ waypoints, origin, destination }) => {
	const base = `https://www.google.com/maps/embed/v1/directions?key=${googleApiKey}&origin=${encodeURIComponent(origin)}`;
	const [uri, setURI] = useState(`${base}&destination=${encodeURIComponent(destination)}&waypoints=${convertWaypoints(waypoints)}&avoid=tolls|highways`);

	useEffect(() => {
		setURI(`${base}&destination=${encodeURIComponent(destination)}&waypoints=${convertWaypoints(waypoints)}&avoid=tolls|highways`);
	}, [waypoints, origin, destination]);

	return <iframe height="600" style={{ border: 0, width: '100%' }} loading="lazy" allowFullScreen src={uri} />;
	// return <iframe height="450" style={{ border: 0, width: '100%' }} loading="lazy" allowFullScreen src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${encodeURIComponent(place)}`} />;
};

export default MapRouter;
