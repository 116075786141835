import M from 'materialize-css';
import React, { useEffect, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import Loading from '../../../components/Loader';
import { Select, useCompanies } from '../../../hooks/useCompanies';
import customerService from '../../../services/customers';
import Table from './table';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

export default function List({ auth }) {
	const [customers, setCustomers] = useState({ data: [], fetching: false });
	const { token } = auth.user;

	const [companies] = useCompanies({ token });

	const [companyId, setCompanyId] = useState(auth.user.user.company.id);

	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	const [filter, setFilter] = useState({ companyId });

	const columns = useMemo(
		() => [
			{
				Header: 'Customers',
				columns: [
					{
						Header: 'Customer/Lead',
						accessor: 'status',
					},
					{
						Header: 'Sign Up/Start',
						accessor: 'datestart',
					},
					{
						Header: 'Type',
						accessor: 'type',
					},
					{
						Header: 'ID',
						accessor: 'id',
						hide: true,
					},
					{
						Header: 'Full Name',
						accessor: 'fullname',
					},
					{
						Header: 'Price',
						accessor: 'price',
					},
					{
						Header: 'In Snapdesk',
						accessor: 'insnapdesk',
					},
					{
						Header: 'In Ops',
						accessor: 'inops',
					},
				],
			},
		],
		[]
	);

	const [init, setInit] = useState(false);

	useEffect(() => {
		if (init) return;

		if (companies.length > 0) {
			// const setComapnyIdTo = auth.user.user.company.id === 10 ? 0 : auth.user.user.company.id;

			// setCompanyId(companies[setComapnyIdTo].id);
			const found = companies.find((c) => c.id === auth.user.user.company.id);

			setCompanyId(found ? auth.user.user.company.id : companies[0].id);
			setInit(true);
		}
	}, [init, companies]);

	useEffect(() => {
		let rendered = true;

		const fetchCustomers = () => {
			setCustomers({ ...customers, fetching: true });
			new customerService()
				.getCustomers({ ...filter, companyId }, token)
				.then((json) => {
					if (rendered && json) {
						setCustomers({ ...customers, data: json, fetching: false });
					}
				})
				.catch((e) => {
					console.log(e, token);
					setCustomers({ ...customers, fetching: false });
				});
		};

		fetchCustomers();

		return () => {
			M.AutoInit();
			rendered = false;
		};
	}, [filter, companyId, token]);

	useEffect(() => {
		M.AutoInit();
	});

	return (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Customers List</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<a href="index.html">Home</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#">Customer</a>
								</li>
								<li className="breadcrumb-item active">Customers List</li>
							</ol>
						</div>
						{/* <div className="col s2 m6 l6">
							<Link className="btn waves-effect waves-light breadcrumbs-btn right" to="/admin/customers/e/0">
								<i className="material-icons hide-on-med-and-up">add</i>
								<span className="hide-on-small-onl">Add</span>
							</Link>
						</div> */}
					</div>
				</div>
			</div>
			<div className="col s12">
				<section className="users-list-wrapper section">
					<div className="users-list-filter">
						<div className="card-panel">
							<div className="row">
								<div className="col s12 m6 l3">
									<div className="input-field">
										<label htmlFor="users-list-verified" className="active">
											Search
										</label>
										<input placeholder="Search here" onChange={(e) => setFilter({ ...filter, query: e.target.value })} name="query" id="query" defaultValue={filter.query} />
									</div>
								</div>
								{auth.user.user.role === 'admin' && (
									<div className="col s12 m6 l9">
										<div className="input-field">
											<Select onChange={(e) => setCompanyId(e.target.value)} options={adminCompanySelect} value={companyId} />
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="users-list-table">
						<div className="card">
							<div className="card-content">
								<div className="responsive-table">
									{!customers.fetching ? <Table columns={columns} data={customers.data.map((el) => ({ ...el, price: <CurrencyFormat {...currencyAttr} value={round(el.price, 2)} />, datestart: el.datestart.split(' ( ')[1].replace(' )', ''), insnapdesk: el.insnapdesk === 1 ? 'YES' : 'NO', inops: el.inops === 1 ? 'YES' : 'NO' }))} /> : <Loading />}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
}
