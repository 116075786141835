import React from 'react';

export default function Dashboard({ match }) {
	return (
		<div className="row">
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Dashboard</span>
							</h5>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12 l4 ">RECENT</div>

			<div className="col s12 m6 l8">
				<div className="card subscriber-list-card fadeRight">CONTENT</div>
			</div>
		</div>
	);
}
