import React from 'react';

const AuthenticatedPage = (props) => {
	return (
		<div className="container">
			<div className="row">You are Authenticated Page</div>
		</div>
	);
};

export default AuthenticatedPage;
