import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Polygon } from 'google-maps-react';

const mapStyles = {
	width: '100%',
	height: '300px',
};

// const areEqual = (prevProps, nextProps) => prevProps.layers === nextProps.layers && prevProps.color === nextProps.color;

const MapContainer = (props) => {
	const [bounds, setBounds] = useState([]);
	// const [kmlLayer, setKmlLayer] = useState([]);
	const [layers, setLayers] = useState([]);

	useEffect(() => {
		let rendered = true;

		const doBounds = () => {
			if (rendered) {
				let b = new props.google.maps.LatLngBounds();

				for (var i = 0; i < props.markers.length; i++) {
					b.extend((({ lat, lng }) => ({ lat, lng }))(props.markers[i]));
				}

				layers.map((layer) => {
					layer.map((point) => {
						b.extend(point);
					});
				});

				setBounds(b);
			}
		};

		doBounds();

		return () => {
			rendered = false;
		};
	}, [layers, props.markers, props.google.maps.LatLngBounds]);

	useEffect(() => {
		let rendered = true;

		const doLayers = () => {
			if (rendered) {
				try {
					setLayers(
						props.layers.reduce((res, layer) => {
							res.push(
								layer.reduce((ret, pnts) => {
									ret.push({
										lat: pnts[1],
										lng: pnts[0],
									});

									return ret;
								}, [])
							);

							return res;
						}, [])
					);
				} catch (e) {}
			}
		};

		doLayers();

		return () => {
			rendered = false;
		};
	}, [props.layers]);

	if (bounds.length === 0) {
		return <div>LOADING . . . </div>;
	}

	// const onMapClicked = (mapProps, map, clickEvent) => {
	const onMapClicked = (clickEvent) => {
		console.log(clickEvent.latLng.lat());
		console.log(clickEvent.latLng.lng());
	};

	return (
		<React.Fragment>
			<Map
				google={props.google}
				zoom={0}
				style={mapStyles}
				initialCenter={{
					lat: 32.06129972551981,
					lng: -81.09380612429113,
				}}
				onClick={onMapClicked}
				// onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
				bounds={bounds}
			>
				{layers.map((layer, i) => (
					<Polygon
						key={i}
						paths={layer}
						strokeColor={props.color}
						icon={{
							url: '/path/to/custom_icon.png',
							anchor: new props.google.maps.Point(32, 32),
							scaledSize: new props.google.maps.Size(0, 0),
						}}
						strokeOpacity={0.8}
						strokeWeight={2}
						fillColor={props.color}
						fillOpacity={0.35}
					/>
				))}
				{/* {props.markers.map((el, i) => {
					if (el.lat && el.lng) return <Marker key={i} onClick={props.onMarkerClick} {...el} position={(({ lat, lng }) => ({ lat, lng }))(el)} />;
				})} */}
			</Map>
		</React.Fragment>
	);
};

export default GoogleApiWrapper((props) => ({
	apiKey: props.apiKey,
}))(MapContainer);
