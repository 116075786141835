import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import BlogContextProvider, { BlogContext } from './context';
import Loading from '../../components/Loader';
import Post from './post';

const PostPage = (props) => {
	const { post, getPostByUrl } = useContext(BlogContext);
	const { url } = props.match.params;

	useEffect(() => {
		if (url) getPostByUrl(url);
	}, [url]);

	if (post && !post.id) props.history.push('/blog');

	return (
		<div className="home">
			<div className="container">{post ? <Post {...post} /> : <Loading />}</div>
		</div>
	);
};

const BlogIndex = (props) => {
	const { posts, getPosts } = useContext(BlogContext);

	useEffect(() => {
		getPosts({});
	}, []);

	const goToPost = (slug) => {
		props.history.push(slug);
	};

	return (
		<div className="home">
			<div className="container">
				<div className="section mt-2" id="blog-list">
					{posts ? (
						<div className="posts-list">
							{posts.map(({ title, excerpt, featureimage, url }, i) => (
								<div key={i} onClick={() => goToPost(`/blog/${url}`)} style={{ cursor: 'pointer' }} className="row">
									<div className="col s12 m4">
										<img className="responsive-img" src={featureimage} alt="" />
									</div>
									<div className="col s12 m8">
										<h2 className="red-text">{title}</h2>
										<p>{excerpt}</p>
									</div>
								</div>
							))}
						</div>
					) : (
						<Loading />
					)}
				</div>
			</div>
		</div>
	);
};

const BlogContainer = (props) => {
	// const { posts, post, getPosts, getPostByUrl } = useContext(BlogContext);
	const { url } = props.match.params;

	return <div>{url ? <PostPage {...props} /> : <BlogIndex {...props} />}</div>;

	// return (
	// 	<div className="container">
	// 		<div className="section mt-2" id="blog-list">
	// 			<div className="row">
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 mt-10 card-animation-1">
	// 						<a href="#">
	// 							<img className="responsive-img border-radius-8 z-depth-4 image-n-margin" src="/assets/images/cards/news-fashion.jpg" alt="" />
	// 						</a>
	// 						<h6 className="deep-purple-text text-darken-3 mt-5">
	// 							<a href="#">Fashion</a>
	// 						</h6>
	// 						<span>Fashion is a popular style, especially in clothing, footwear, lifestyle, accessories, makeup, hairstyle and body.</span>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 mt-10 card-animation-1">
	// 						<a href="#">
	// 							<img className="responsive-img border-radius-8 z-depth-4 image-n-margin" src="/assets/images/cards/news-apple.jpg" alt="" />
	// 						</a>
	// 						<h6 className="deep-purple-text text-darken-3 mt-5">
	// 							<a href="#">Apple News</a>
	// 						</h6>
	// 						<span>Some users have reported their new phones won't charge when plugged into lightning cables when their screens are off.</span>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 mt-10 card-animation-1">
	// 						<a href="#">
	// 							<img className="responsive-img border-radius-8 z-depth-4 image-n-margin" src="/assets/images/cards/news-twitter.jpg" alt="" />
	// 						</a>
	// 						<h6 className="deep-purple-text text-darken-3 mt-5">
	// 							<a href="#">Twitter Brings Its 'Data Saver</a>
	// 						</h6>
	// 						<span>Microblogging site Twitter has rolled out its latest update for Android and iOS users with the "data saver" twitter Application.</span>
	// 					</div>
	// 				</div>
	// 			</div>
	// 			<div className="row">
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 white-text gradient-45deg-deep-purple-blue card-animation-2">
	// 						<h6 className="mt-5">
	// 							<b>
	// 								<a href="#" className="white-text">
	// 									Sensex Nosedives 850 Points
	// 								</a>
	// 							</b>
	// 						</h6>
	// 						<span>Share markets on Thursday: Prominent losers in the Sensex pack were Maruti, ICICI Bank, Reliance and Yes Bank.</span>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 white-text gradient-45deg-purple-deep-orange card-animation-2">
	// 						<h6 className="mt-5">
	// 							<b>
	// 								<a href="#" className="white-text">
	// 									Famous for stylish jewelries
	// 								</a>
	// 							</b>
	// 						</h6>
	// 						<span>Sophia Fiori is one of the most famous jewelry designer in the USA. Sophia Fiori has got legacy of four generations.</span>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 white-text gradient-45deg-indigo-light-blue card-animation-2">
	// 						<h6 className="mt-5">
	// 							<b>
	// 								<a href="#" className="white-text">
	// 									Lagom Way to Try Fall rends
	// 								</a>
	// 							</b>
	// 						</h6>
	// 						<span>You're not going to try every fall trend we write about here at Who What Wear, but where's the fun in not trying any at all?</span>
	// 					</div>
	// 				</div>
	// 			</div>

	// 			<div className="row">
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 pt-4 pb-4">
	// 						<span className="red-text">
	// 							<i className="material-icons vertical-align-bottom"> trending_up </i> Trending
	// 						</span>
	// 						<p className="mt-4 mb-0">Bringing smooth animation transition in android</p>
	// 						<div className="display-flex justify-content-between flex-wrap mt-2">
	// 							<div className="display-flex align-items-center mt-1">
	// 								<img src="/assets/images/user/2.jpg" width="36" alt="news" className="circle mr-10 vertical-text-middle" />
	// 								<span className="pt-2">Alex</span>
	// 							</div>
	// 							<div className="display-flex mt-3 right-align social-icon">
	// 								<span className="material-icons">favorite_border</span> <span className="ml-3 vertical-align-top">120</span>
	// 								<span className="material-icons ml-10">chat_bubble_outline</span> <span className="ml-3 vertical-align-top">67</span>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 pt-4 pb-4">
	// 						<span className="red-text">
	// 							<i className="material-icons vertical-align-bottom"> trending_up </i> Trending
	// 						</span>
	// 						<p className="mt-4 mb-0">Bringing smooth animation transition in android</p>
	// 						<div className="display-flex justify-content-between flex-wrap mt-2">
	// 							<div className="display-flex align-items-center mt-1">
	// 								<img src="/assets/images/user/4.jpg" width="36" alt="news" className="circle mr-10 vertical-text-middle" />
	// 								<span className="pt-2">John</span>
	// 							</div>
	// 							<div className="display-flex mt-3 right-align social-icon">
	// 								<span className="material-icons">favorite_border</span> <span className="ml-3 vertical-align-top">120</span>
	// 								<span className="material-icons ml-10">chat_bubble_outline</span> <span className="ml-3 vertical-align-top">67</span>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 pt-4 pb-4">
	// 						<span className="red-text">
	// 							<i className="material-icons vertical-align-bottom"> trending_up </i> Trending
	// 						</span>
	// 						<p className="mt-4 mb-0">Bringing smooth animation transition in android</p>
	// 						<div className="display-flex justify-content-between flex-wrap mt-2">
	// 							<div className="display-flex align-items-center mt-1">
	// 								<img src="/assets/images/user/3.jpg" width="36" alt="news" className="circle mr-10 vertical-text-middle" />
	// 								<span className="pt-2">Mike</span>
	// 							</div>
	// 							<div className="display-flex mt-3 right-align social-icon">
	// 								<span className="material-icons">favorite_border</span> <span className="ml-3 vertical-align-top">120</span>
	// 								<span className="material-icons ml-10">chat_bubble_outline</span> <span className="ml-3 vertical-align-top">67</span>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>

	// 			<div className="row">
	// 				<div className="col s12 m6 l6">
	// 					<div className="card horizontal border-radius-6">
	// 						<div className="card-image">
	// 							<a href="#">
	// 								<img className="responsive-img image-n-margin" src="/assets/images/cards/horizontal-news-1.jpg" alt="" />
	// 							</a>
	// 						</div>
	// 						<div className="card-stacked">
	// 							<div className="card-content pl-7 pt-7 pr-7 pb-7">
	// 								<p className="mb-4">It’s 2018, and the race is on between tech giants to decide who will control...</p>
	// 								<a href="#">Read More</a>
	// 							</div>
	// 							<div className="card-action pt-4 pb-3">
	// 								<div className="display-flex">
	// 									<div className="display-flex mr-10">
	// 										<span className="material-icons mr-10">favorite_border</span>
	// 										<span className="vertical-align-top">809</span>
	// 									</div>
	// 									<div className="display-flex">
	// 										<span className="material-icons mr-10">chat_bubble_outline</span>
	// 										<span className="vertical-align-top">409</span>
	// 									</div>
	// 								</div>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l6">
	// 					<div className="card horizontal border-radius-6">
	// 						<div className="card-image">
	// 							<a href="#">
	// 								<img className="responsive-img image-n-margin" src="/assets/images/cards/horizontal-news-2.jpg" alt="" />
	// 							</a>
	// 						</div>
	// 						<div className="card-stacked">
	// 							<div className="card-content pl-7 pt-7 pr-7 pb-7">
	// 								<p className="mb-4">It’s 2018, and the race is on between tech giants to decide who will control...</p>
	// 								<a href="#">Read More</a>
	// 							</div>
	// 							<div className="card-action pt-4 pb-3">
	// 								<div className="display-flex">
	// 									<div className="display-flex mr-10">
	// 										<span className="material-icons mr-10">favorite_border</span>
	// 										<span className="vertical-align-top">300</span>
	// 									</div>
	// 									<div className="display-flex">
	// 										<span className="material-icons mr-10">chat_bubble_outline</span>
	// 										<span className="vertical-align-top">239</span>
	// 									</div>
	// 								</div>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>

	// 			<div className="row">
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 white-text bg-image-1 pb-4">
	// 						<p className="mt-0 mb-8">
	// 							<b>Macro Infrared</b> Photographs Unlock The Depth Of Green In A Stunning Array Of Canary Island Plants
	// 						</p>
	// 						<div className="display-flex justify-content-between flex-wrap mt-4">
	// 							<div className="pr-0">
	// 								<a href="#" className="white-text">
	// 									{' '}
	// 									Read More
	// 								</a>
	// 							</div>
	// 							<div className=" display-flex mt-1 right-align social-icon">
	// 								<span className="material-icons">favorite_border</span>
	// 								<span className="ml-3 vertical-align-top">120</span>
	// 								<span className="material-icons ml-10">chat_bubble_outline</span>
	// 								<span className="ml-3 vertical-align-top">09</span>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 white-text bg-image-2 pb-4">
	// 						<p className="mt-0 mb-8">
	// 							<b>Macro Infrared</b> Photographs Unlock The Depth Of Green In A Stunning Array Of Canary Island Plants
	// 						</p>
	// 						<div className="display-flex justify-content-between flex-wrap mt-4">
	// 							<div className="pr-0">
	// 								<a href="#" className="white-text">
	// 									{' '}
	// 									Read More
	// 								</a>
	// 							</div>
	// 							<div className="display-flex mt-1 right-align social-icon">
	// 								<span className="material-icons">favorite_border</span> <span className="ml-3 vertical-align-top">51</span>
	// 								<span className="material-icons ml-10">chat_bubble_outline</span> <span className="ml-3 vertical-align-top">40</span>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 				<div className="col s12 m6 l4">
	// 					<div className="card-panel border-radius-6 white-text bg-image-3 pb-4">
	// 						<p className="mt-0 mb-8">
	// 							<b>Macro Infrared</b> Photographs Unlock The Depth Of Green In A Stunning Array Of Canary Island Plants
	// 						</p>
	// 						<div className="display-flex justify-content-between flex-wrap mt-4">
	// 							<div className="pr-0">
	// 								<a href="#" className="white-text">
	// 									{' '}
	// 									Read More
	// 								</a>
	// 							</div>
	// 							<div className="display-flex mt-1 right-align social-icon">
	// 								<span className="material-icons">favorite_border</span> <span className="ml-3 vertical-align-top">444</span>
	// 								<span className="material-icons ml-10">chat_bubble_outline</span> <span className="ml-3 vertical-align-top">23</span>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// );
};

const BlogContextHolder = (props) => {
	return (
		<BlogContextProvider>
			<BlogContainer {...props} />
		</BlogContextProvider>
	);
};

export default BlogContextHolder;
