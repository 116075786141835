import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import Avata from './TrashJoes-square.jpg';
import LeaveComment from './leaveComment';
import moment from 'moment';
import config from '../../services/config';
import './animate.scss';
import Axios from 'axios';
import { Helmet } from 'react-helmet';

const Comment = ({ post, comment, i, refresh }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<div className="commentPost card" key={i}>
				<div className="card-content">
					<p>
						<b>
							{comment.name} • {moment(comment.created).format('MM/DD/YYYY')}
						</b>
					</p>
					<br />
					<div>{comment.comment}</div>
					<br />
					<p style={{ padding: '0 0 20px 0' }}>
						<button type="submit" name="saveComment" className="btn grey" onClick={() => setOpen(!open)}>
							{open ? 'Cancel Reply' : 'Reply'}
						</button>
					</p>
					<div className={`animate ${open && 'grow'}`} style={{ overflow: 'hidden', position: 'relative' }}>
						<LeaveComment
							post={post}
							comment={comment}
							refresh={() => {
								refresh();
								setOpen(false);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="replys" style={comment.comments.length === 0 ? { marginTop: '0' } : {}}>
				{comment.comments.map((reply, i) => (
					<div className="replyCommentPost card" key={i}>
						<div className="card-content">
							<p>
								<b>
									{reply.name} • {moment(reply.created).format('MM/DD/YYYY')}
								</b>
							</p>
							<br />
							<div>{reply.comment}</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

const Post = ({ post }) => {
	const [open, setOpen] = useState(false);
	const [comments, setComments] = useState([]);

	console.log({ post });

	const getComments = () => {
		Axios.get(`${config.apiBaseUrl}/comments.cfc?method=getComments&i=${post.id}`)
			.then((res) => res.status === 200 && res.data)
			.then((data) => setComments(data));
	};

	useEffect(() => {
		getComments();
	}, []);

	const variants = {
		open: {
			opacity: 1,
			// y: 0,
			height: '100%',
			transition: {
				type: 'spring',
				// velocity: 1,
				damping: 20,
			},
		},
		closed: {
			opacity: 0,
			// y: '-100%',
			height: '0',
			transition: {
				type: 'spring',
				// velocity: 1,
				damping: 20,
			},
		},
	};

	return (
		<div className="home">
			<Helmet>
				<title>{post.title} : Trash Joes</title>
				<meta name="description" content={post.metadescription} />
			</Helmet>
			<div className="titleStyle">
				<div className="container">
					<div className="row fvalign-wrapper" style={{ marginBottom: '0' }}>
						<div className="col s12 m8 offset-m2">
							<h1>{post.title}</h1>
							<div className="valign-wrapper" style={{ width: 'auto' }}>
								<img src={Avata} alt="" className="circle responsive-img" style={{ width: '60px', marginRight: '10px' }} />

								<p className="">
									<span style={{ fontSize: '20px', fontWeight: '700' }}>{post.author}</span>
									<br />
									{moment(post.publishdate).format('MMMM D, YYYY')}
								</p>
							</div>
						</div>
						{post.featureimage && post.featureimage.length > 0 && (
							<div className="center-align col s12 m8 offset-m2 center-align">
								<img className="responsive-img" src={post.featureimage} alt="" />
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m8 offset-m2">
						<div dangerouslySetInnerHTML={{ __html: post.body }} />

						{comments.length > 0 && (
							<div id="" className="lineThoBox">
								<h4 className="lineTho">Comments</h4>
							</div>
						)}
						{/* {comments.length === 0 && <p>There are no comments yet... Kick things off by filling out the form below.</p>} */}

						{comments.map((comment, i) => (
							<Comment key={i} post={post} comment={comment} i={i} refresh={() => getComments()} />
						))}

						<div id="" className="lineThoBox">
							<h4 className="lineTho">Leave a Comment</h4>
						</div>
						<LeaveComment post={post} refresh={() => getComments()} />
						<br />
						<br />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Post;
