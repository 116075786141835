import React, { useRef, useState, useEffect } from 'react';
import priceService from '../../../services/prices';
import { useFormContext } from 'react-hook-form';
import M from 'materialize-css';

const PricingRow = ({ type, service, removePricing, updatePricing, i }) => {
	const [form, setForm] = useState(service);

	// const doUpatePricing = () => {};
	const updateForm = (key, value) => {
		switch (key) {
			case 'cans':
				setForm({ ...form, cans: value });
				break;
			case 'frequency':
				setForm({ ...form, frequency: value });
				break;
			case 'price':
				setForm({ ...form, price: value });
				break;
			case 'duration':
				setForm({ ...form, duration: value });
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		updatePricing(form);
	}, [form]);

	return (
		<tr key={i}>
			<td style={{ textAlign: 'left' }}>
				<button className="btn btn-small red white-text" type="button" onClick={() => removePricing(service.id)}>
					<i className="material-icons">delete</i>
				</button>
			</td>
			<td>
				<div style={{ margin: '0' }} className="input-field col s6">
					{/* <i className="material-icons prefix">account_circle</i> */}
					<input id={`cans${i}`} type="number" step="1" min="1" onChange={(el) => updateForm('cans', el.target.value)} defaultValue={service.cans} placeholder={`${type !== 'Roll-Offs' ? 'Number of Cans (2)' : 'Number of Rolls-Offs (2)'}`} />
				</div>
			</td>
			<td style={{ width: '200px' }}>
				<div style={{ margin: '0' }} className="input-field col s12">
					{/* <i className="material-icons prefix">account_circle</i> */}
					{/* <input id="icon_prefix" type="text"/> */}
					<div className={`${type === 'Roll-Offs' && 'hide'}`}>
						<select id={`frequency${i}`} defaultValue={service.frequency} onChange={(el) => updateForm('frequency', el.target.value)}>
							<option>Weekly</option>
							<option>Bi-Weekly</option>
							<option>Monthly</option>
						</select>
					</div>
					<input id={`duration${i}`} defaultValue={service.duration} type="number" step="1" min="0" className={`${type !== 'Roll-Offs' && 'hide'} validate`} onChange={(el) => updateForm('duration', el.target.value)} placeholder="Duration in days" />
				</div>
			</td>
			<td>
				<div style={{ margin: '0' }} className="input-field col s12">
					<i className="material-icons prefix">attach_money</i>
					<input onChange={(el) => updateForm('price', el.target.value)} id={`price${i}`} type="number" step="0.01" min="0" defaultValue={service.price} placeholder="Price (20.00)" />
				</div>
			</td>
		</tr>
	);
};

const PricingTable = ({ type, prices, addPricing, removePricing, updatePricing }) => {
	const [changed, setChanged] = useState([]);
	const [notice, setNotice] = useState('');
	const cansInput = useRef(null);
	const durationInput = useRef(null);
	const frequencyInput = useRef(null);
	const priceInput = useRef(null);

	const doAddPricing = () => {
		const newPricing = {
			cans: cansInput.current.value,
			frequency: frequencyInput ? frequencyInput.current.value : '',
			price: priceInput.current.value,
			duration: durationInput ? durationInput.current.value : 0,
		};
		addPricing(newPricing);
		setNotice('');
		cansInput.current.value = '';
		frequencyInput.current.value = 'Weekly';
		priceInput.current.value = '';
		durationInput.current.value = '';
	};

	useEffect(() => {
		if (changed.includes('cansInput') && changed.includes('priceInput')) {
			setNotice('Please be sure to click the "ADD" button and save to persistance new entry.');
		}
	}, [changed]);

	const handleChange = (e) => {
		setChanged((curChanged) => [...curChanged, e.target.name]);
	};

	return (
		<div className="col s12">
			<table>
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th>{`${type !== 'Roll-Offs' ? 'Cans' : 'Roll-Offs'}`}</th>
						<th>{`${type !== 'Roll-Offs' ? 'Pickup Frequency' : 'Per Day Cost'}`}</th>
						<th>Price/Mo</th>
					</tr>
				</thead>

				<tbody>
					{prices && prices.map((el, i) => <PricingRow type={type} removePricing={removePricing} updatePricing={updatePricing} service={el} key={el.id} />)}
					{notice.length > 0 && (
						<tr>
							<td colSpan="4">{notice}</td>
						</tr>
					)}
					<tr>
						<td style={{ textAlign: 'left' }}>
							<button className="btn btn-small green white-text" type="button" onClick={() => doAddPricing()}>
								<i className="material-icons">add</i>
							</button>
						</td>
						<td>
							<div style={{ margin: '0' }} className="input-field col s6">
								{/* <i className="material-icons prefix">account_circle</i> */}
								<input name="cansInput" onChange={handleChange} ref={cansInput} id="cans" type="number" step="1" min="1" className="validate" placeholder={`${type !== 'Roll-Offs' ? 'Number of Cans (2)' : 'Number of Rolls-Offs (2)'}`} />
								{/* <label htmlFor="cans">Number of Cans (2)</label> */}
							</div>
						</td>
						<td>
							<div style={{ margin: '0' }} className="input-field col s6">
								{/* <i className="material-icons prefix">account_circle</i> */}
								{/* <input id="icon_prefix" type="text" className="validate" /> */}
								<div className={`${type === 'Roll-Offs' && 'hide'}`}>
									<select ref={frequencyInput} id="frequency">
										<option>Weekly</option>
										<option>Bi-Weekly</option>
										<option>Monthly</option>
									</select>
								</div>
								<input ref={durationInput} id="duration" type="number" step="1" min="0" className={`${type !== 'Roll-Offs' && 'hide'} validate`} placeholder="Duration in days" />
								{/* <label htmlFor="frequency">Frequency (Weekly)</label> */}
							</div>
						</td>
						<td>
							<div style={{ margin: '0' }} className="input-field col s12">
								<i className="material-icons prefix">attach_money</i>
								<input name="priceInput" onChange={handleChange} ref={priceInput} id="price_cans" type="number" step="0.01" min="0" className="validate" placeholder="Price (20.00)" />
								{/* <label htmlFor="price_cans">Price (20.00)</label> */}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

const CommercialPricingSelector = ({ serviceid, companyPrices }) => {
	// const doUpatePricing = () => {};
	const { setValue, register } = useFormContext();

	useEffect(() => {
		const getCurrent = async () => {
			const currentGet = await new priceService().getPricingForService(serviceid);

			if (currentGet.length > 0) {
				setValue('pricing_relation', currentGet[0].uid);
			}
		};

		getCurrent();
	}, []);

	// useEffect(() => {
	// 	updatePricing(form);
	// }, [form]);

	useEffect(() => {
		M.AutoInit();
	}, []);

	// auth.user.user.company.id
	const getUniqueUID = (x) => {
		if (Array.isArray(x)) {
			return x.reduce((fin, cur) => {
				const opt = {
					uid: cur.uid,
					name: cur.name,
				};

				return fin.filter((e) => e.uid === cur.uid).length > 0 ? fin : [...fin, opt];
			}, []);
		}
		return [];
	};

	return (
		<div className="col s12" style={{ marginBottom: 10 }}>
			<label htmlFor="type">Pricing Table Reference</label>
			<select name="pricing_relation" ref={register} className="browser-defauslt">
				<option>SELECT REFERENCE</option>
				{getUniqueUID(companyPrices).map((el, i) => (
					<option key={i} value={el.uid}>
						{el.name}
					</option>
				))}
			</select>
		</div>
	);
};

const PricingTableWrapper = ({ ...props }) => {
	const { type } = props;

	if (`${type}`.toLowerCase() === 'commercial') return <CommercialPricingSelector {...props} />;

	return <PricingTable {...props} />;
};

export default PricingTableWrapper;
