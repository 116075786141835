import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import pixelConfig from '../pixelConfig';

const FacebookPixel = () => {
	const location = useLocation();
	const path = location.pathname.split('/')[2]; // Assuming the path is "/h/company-name"

	useEffect(() => {
		console.log({ path, px: pixelConfig[path] });
		if (pixelConfig[path]) {
			// Remove any existing Facebook Pixel script
			const existingScript = document.getElementById('fb-pixel-script');
			if (existingScript) {
				existingScript.parentNode.removeChild(existingScript);
			}

			// Add the new Facebook Pixel script
			const script = document.createElement('script');
			script.id = 'fb-pixel-script';
			script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelConfig[path]}');
        fbq('track', 'PageView');
      `;
			document.head.appendChild(script);

			// Optionally add the noscript fallback
			const noscript = document.createElement('noscript');
			noscript.id = 'fb-pixel-noscript';
			noscript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${pixelConfig[path]}&ev=PageView&noscript=1" />
      `;
			document.body.appendChild(noscript);
		}
	}, [path]);

	return null;
};

export default FacebookPixel;
