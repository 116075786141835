import { useContext } from 'react';
import { SignUpContext } from './context/signup';

export const useStyles = () => {
	const { hauler } = useContext(SignUpContext);
	const { primaryColor, primaryTextColor } = hauler.theme;

	const default_button = {
		borderRadius: '25px',
		background: 'transparent',
		color: 'black',
		boxShadow: 'none',
		paddingLeft: '10px',
		paddingRight: '10px',
		margin: '0 5px',
	};

	const default_title = {
		backgroundColor: 'transparent',
		color: primaryColor,
		border: '0px',
		boxShadow: 'none',
		fontSize: '18px',
		fontWeight: '700',
		margin: '0',
		whiteSpace: 'unset',
	};

	const styles = {
		button: {
			default: default_button,
			disabled: {
				...default_button,
				color: 'darkgrey',
				borderColor: 'darkgrey',
			},
			enabled: {
				...default_button,
				background: primaryColor,
				color: primaryTextColor,
			},
			previous: {
				...default_button,
				border: '1px solid black',
			},
		},
		title: {
			...default_title,
		},
		input: {
			borderBottom: '1px solid #9e9e9e',
			borderTop: '1px solid #9e9e9e',
			borderLeft: '1px solid #9e9e9e',
			borderRight: '1px solid #9e9e9e',
			paddingLeft: '10px',
		},
	};

	return {
		styles,
	};
};
