export const api = `${window.location.protocol}//${window.location.hostname}/cfc/api/`;

export const ga4Config = {
	id: process.env.REACT_APP_GA4_ID,
};

export const gtmConfig = {
	containerId: process.env.REACT_APP_GTM_ID,
};

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
