import React from 'react';

export const SvgCommercial = ({ style }) => {
	return (
		<div className="svg">
			<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 200 200" style={{ enableBackground: 'new 0 0 200 200', ...style }}>
				<g>
					<g>
						<path
							className="st0"
							d="M-278,79.97c-0.05,0-0.1-0.01-0.14-0.04l-4.52-3c-0.06-0.04-0.09-0.09-0.11-0.16s0-0.13,0.04-0.19l0.26-0.4
			c0.91-1.38,1.82-2.76,2.73-4.12c0.55-0.84,1.41-1.36,2.25-1.36c0.14,0,0.25,0.11,0.25,0.25s-0.11,0.25-0.25,0.25
			c-0.52,0-1.28,0.3-1.83,1.14c-0.9,1.37-1.82,2.75-2.73,4.12l-0.13,0.19l4.1,2.72c0.95-1.44,1.91-2.88,2.86-4.31
			c0.08-0.12,0.23-0.15,0.35-0.07c0.11,0.08,0.15,0.23,0.07,0.35c-1,1.5-2,3.01-3,4.52C-277.84,79.93-277.91,79.97-278,79.97z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-268.9,78.89h-6.02c-0.11,0-0.21-0.08-0.24-0.19c-0.03-0.11,0.02-0.22,0.12-0.28l1.57-0.91
			c-1.5-3.12-3.36-6.25-4.08-6.32c-0.13-0.01-0.23-0.13-0.22-0.26c0.01-0.13,0.12-0.24,0.25-0.24h6.75c0.53,0,1.01,0.29,1.26,0.75
			l1.58,2.87l1.66-0.96c0.1-0.06,0.22-0.04,0.3,0.04c0.08,0.08,0.1,0.2,0.05,0.3l-2.75,5.07C-268.73,78.84-268.81,78.89-268.9,78.89
			z M-273.99,78.39h4.94l2.26-4.16l-1.11,0.64c-0.06,0.03-0.13,0.04-0.19,0.02c-0.06-0.02-0.12-0.06-0.15-0.12l-1.7-3.09
			c-0.17-0.3-0.48-0.49-0.83-0.49h-5.93c1.29,1.28,3,4.68,3.78,6.32c0.06,0.12,0.01,0.26-0.1,0.32L-273.99,78.39z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-262.02,87.06c-0.03,0-0.06-0.01-0.1-0.02c-0.13-0.05-0.19-0.2-0.14-0.33c0.24-0.57,0.22-1.22-0.04-1.87
			l-1.92-4.8c-1.52,0.61-3.05,1.22-4.58,1.83l0.41,1.02c0.44,1.1,0.89,2.21,1.33,3.31c0.05,0.13-0.01,0.27-0.14,0.33
			c-0.13,0.05-0.27-0.01-0.33-0.14c-0.44-1.11-0.89-2.21-1.33-3.31l-0.51-1.26c-0.05-0.13,0.01-0.27,0.14-0.33
			c1.68-0.68,3.36-1.35,5.04-2.02c0.13-0.05,0.27,0.01,0.32,0.14l2.01,5.03c0.31,0.78,0.33,1.55,0.04,2.25
			C-261.83,87.01-261.92,87.06-262.02,87.06z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-269.71,95.25c-0.09,0-0.17-0.05-0.22-0.12l-3.03-5.2c-0.05-0.08-0.05-0.18,0-0.26l3-4.93
			c0.06-0.1,0.17-0.14,0.28-0.11c0.11,0.03,0.18,0.13,0.18,0.24l0.01,1.97h6.78c0.21,0,0.41-0.09,0.54-0.24
			c0.05-0.05,0.12-0.08,0.19-0.08h0.01c0.09,0,0.17,0.05,0.21,0.12c0.04,0.08,0.05,0.17,0,0.25l-3.23,5.93
			c-0.19,0.34-0.54,0.55-0.93,0.55h-3.56l0.01,1.64c0,0.11-0.08,0.21-0.18,0.24C-269.66,95.25-269.68,95.25-269.71,95.25z
			 M-272.45,89.81l2.49,4.27l0-0.95c0-0.07,0.03-0.13,0.07-0.18c0.05-0.05,0.11-0.07,0.18-0.07h3.81c0.21,0,0.39-0.11,0.49-0.29
			l2.85-5.25c-0.05,0.01-0.1,0.01-0.15,0.01h-7.03c-0.14,0-0.25-0.11-0.25-0.25l0-1.33L-272.45,89.81z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-280.6,92.57c-1.27,0-2.29-0.67-2.73-1.8c-0.05-0.13,0.01-0.27,0.14-0.32c0.13-0.05,0.27,0.01,0.32,0.14
			c0.37,0.94,1.19,1.48,2.26,1.48c0.01,0,0.02,0,0.02,0c1.73-0.01,3.45-0.02,5.17-0.02c0-1.64-0.01-3.29-0.02-4.93
			c-1.75,0.01-3.46,0.02-5.17,0.02c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25c1.8,0,3.59-0.01,5.38-0.02c0,0,0,0,0,0
			c0.14,0,0.28,0.11,0.28,0.25c0.01,1.81,0.02,3.62,0.02,5.43c0,0.14-0.11,0.25-0.25,0.25c-1.8,0-3.61,0.01-5.42,0.02
			C-280.58,92.57-280.59,92.57-280.6,92.57z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-283.1,90.93c-0.1,0.02-0.17-0.04-0.21-0.11l-3.61-5.71c-0.21-0.33-0.22-0.74-0.03-1.08l1.77-3.17
			l-1.51-0.86c-0.1-0.06-0.15-0.17-0.12-0.28c0.03-0.11,0.13-0.19,0.24-0.19l6.02-0.06c0,0,0,0,0,0c0.09,0,0.17,0.05,0.22,0.13
			l2.81,5.04c0.05,0.1,0.04,0.22-0.04,0.3c-0.08,0.08-0.2,0.09-0.3,0.04l-1.62-0.92l-3.32,5.94c-0.1,0.18-0.12,0.39-0.05,0.59
			c0.02,0.07,0.01,0.15-0.03,0.22C-282.94,90.88-283.02,90.93-283.1,90.93z M-285.65,80.02l0.92,0.53c0.12,0.07,0.16,0.22,0.09,0.34
			l-1.89,3.38c-0.1,0.18-0.09,0.4,0.02,0.57l3.19,5.05c0.02-0.04,0.04-0.09,0.06-0.13l3.44-6.16c0.03-0.06,0.09-0.1,0.15-0.12
			c0.06-0.02,0.13-0.01,0.19,0.02l1.07,0.6l-2.3-4.14L-285.65,80.02z"
						/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-127.63,91.23H-149c-1.14,0-2.06-0.92-2.06-2.06V78.02c0-1.14,0.92-2.06,2.06-2.06h5.73
				c0.14,0,0.25,0.11,0.25,0.25s-0.11,0.25-0.25,0.25H-149c-0.86,0-1.56,0.7-1.56,1.56v11.15c0,0.86,0.7,1.56,1.56,1.56h21.37
				c0.85,0,1.55-0.7,1.55-1.56V78.02c0-0.86-0.7-1.56-1.55-1.56h-5.74c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h5.74
				c1.13,0,2.05,0.92,2.05,2.06v11.15C-125.58,90.31-126.5,91.23-127.63,91.23z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-125.89,83.01h-24.93c-0.14,0-0.25-0.11-0.25-0.25v-3.08c0-0.14,0.11-0.25,0.25-0.25h24.93
				c0.14,0,0.25,0.11,0.25,0.25v3.08C-125.64,82.9-125.75,83.01-125.89,83.01z M-150.57,82.51h24.43v-2.58h-24.43V82.51z"
							/>
						</g>
						<g>
							<rect x="-148.47" y="84.15" className="st0" width="4.23" height="0.5" />
						</g>
						<g>
							<rect x="-148.47" y="86.19" className="st0" width="6.65" height="0.5" />
						</g>
						<g>
							<rect x="-148.47" y="88.08" className="st0" width="10.06" height="0.5" />
						</g>
					</g>
					<g>
						<g>
							<g>
								<path
									className="st0"
									d="M-138.32,78.9c-2.72,0-4.93-2.47-4.93-5.5s2.21-5.5,4.93-5.5c2.72,0,4.93,2.47,4.93,5.5
					S-135.6,78.9-138.32,78.9z M-138.32,68.4c-2.44,0-4.43,2.24-4.43,5s1.99,5,4.43,5c2.44,0,4.43-2.24,4.43-5
					S-135.88,68.4-138.32,68.4z"
								/>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path
											className="st0"
											d="M-138.91,71.32c-0.14,0-0.25-0.11-0.25-0.25c0-0.14,0.11-0.25,0.25-0.25l1.93-0.04
							c0.14,0.02,0.25,0.11,0.25,0.25c0,0.14-0.11,0.25-0.25,0.25L-138.91,71.32C-138.91,71.32-138.91,71.32-138.91,71.32z"
										/>
									</g>
									<g>
										<path
											className="st0"
											d="M-139.66,76.18c-0.14,0-0.25-0.11-0.25-0.25c0-0.14,0.11-0.25,0.25-0.25l1.93-0.04
							c0.16,0.02,0.25,0.11,0.25,0.25c0,0.14-0.11,0.25-0.25,0.25L-139.66,76.18C-139.66,76.18-139.66,76.18-139.66,76.18z"
										/>
									</g>
									<g>
										<path
											className="st0"
											d="M-137.78,76.15c-0.14,0-0.25-0.11-0.25-0.25c0-0.14,0.11-0.25,0.25-0.25c0.27,0,0.52-0.11,0.71-0.31
							s0.29-0.45,0.28-0.73c0-0.27-0.11-0.53-0.31-0.71c-0.2-0.19-0.44-0.28-0.73-0.28l-1.11,0.02c-0.38-0.01-0.73-0.13-1-0.39
							c-0.27-0.26-0.42-0.61-0.43-0.99c-0.01-0.37,0.13-0.73,0.39-1c0.26-0.27,0.61-0.42,0.99-0.43c0.12-0.02,0.25,0.11,0.25,0.25
							c0,0.14-0.11,0.25-0.25,0.25c-0.24,0-0.47,0.1-0.64,0.28c-0.17,0.17-0.26,0.4-0.25,0.65c0,0.24,0.1,0.47,0.28,0.64
							c0.17,0.17,0.38,0.26,0.65,0.25l1.11-0.02c0.42,0,0.79,0.14,1.08,0.42c0.29,0.28,0.46,0.66,0.46,1.07
							c0.01,0.41-0.14,0.79-0.42,1.08C-136.99,75.98-137.37,76.14-137.78,76.15C-137.77,76.15-137.78,76.15-137.78,76.15z"
										/>
									</g>
								</g>
								<g>
									<path
										className="st0"
										d="M-138.32,76.9c-0.14,0-0.25-0.11-0.25-0.25v-6.51c0-0.14,0.11-0.25,0.25-0.25s0.25,0.11,0.25,0.25v6.51
						C-138.07,76.79-138.19,76.9-138.32,76.9z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-337.61,77.86c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73v-1.62c0-1.27,1.94-1.27,1.94,0
				v1.62C-336.64,77.52-337.12,77.86-337.61,77.86z M-337.61,74.8c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47v-1.62C-337.14,74.92-337.39,74.8-337.61,74.8z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-326.12,77.88c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73v-1.62c0-1.28,1.95-1.28,1.95,0
				v1.62C-325.14,77.54-325.63,77.88-326.12,77.88z M-326.12,74.81c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47v-1.62C-325.64,74.94-325.9,74.81-326.12,74.81z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-320.9,81.91h-21.86c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h21.86c0.14,0,0.25,0.11,0.25,0.25
				S-320.76,81.91-320.9,81.91z"
							/>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-320.48,96.66h-17.2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h16.95v-4.64
					c0-0.14,0.11-0.25,0.25-0.25s0.25,0.11,0.25,0.25v4.89C-320.23,96.54-320.35,96.66-320.48,96.66z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-331.55,85.9h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-331.3,85.79-331.41,85.9-331.55,85.9z M-333.35,85.4h1.56v-1.55h-1.56V85.4z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-327.79,85.9h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-327.54,85.79-327.65,85.9-327.79,85.9z M-329.6,85.4h1.56v-1.55h-1.56V85.4z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-324.04,85.9h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-323.79,85.79-323.9,85.9-324.04,85.9z M-325.84,85.4h1.56v-1.55h-1.56V85.4z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-331.27,92.7h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-331.02,92.59-331.14,92.7-331.27,92.7z M-333.08,92.2h1.55v-1.55h-1.55V92.2z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-327.52,92.7h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-327.27,92.59-327.38,92.7-327.52,92.7z M-329.32,92.2h1.56v-1.55h-1.56V92.2z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-335.19,89.46h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-334.94,89.35-335.05,89.46-335.19,89.46z M-336.99,88.96h1.55V87.4h-1.55V88.96z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-331.47,89.46h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-331.22,89.35-331.33,89.46-331.47,89.46z M-333.28,88.96h1.56V87.4h-1.56V88.96z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-327.75,89.46h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-327.5,89.35-327.62,89.46-327.75,89.46z M-329.56,88.96h1.56V87.4h-1.56V88.96z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-324.04,89.46h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-323.79,89.35-323.9,89.46-324.04,89.46z M-325.84,88.96h1.56V87.4h-1.56V88.96z"
								/>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-325.38,95.44h-11.11c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h10.86v-3.59c0-0.49,0.4-0.9,0.9-0.9
				h3.7V78.08c0-0.75-0.61-1.36-1.36-1.36h-1.99l0.01,1c0,2.26-3.44,2.26-3.44,0v-1.02l-8.1,0.02l0.02,1c0,1.13-0.86,1.72-1.72,1.72
				c-0.45,0-0.86-0.15-1.18-0.43c-0.35-0.32-0.54-0.76-0.54-1.29v-1.02l-1.98,0.02c-0.75,0-1.36,0.61-1.36,1.36v9.59
				c0,0.14-0.11,0.25-0.25,0.25s-0.25-0.11-0.25-0.25v-9.59c0-1.02,0.83-1.86,1.86-1.86h1.99c0.28,0,0.49,0.21,0.49,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.48-0.48h8.11c0.27,0,0.48,0.21,0.48,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.49-0.48h1.99c1.02,0,1.86,0.83,1.86,1.86V90.7
				c0,0.14-0.11,0.25-0.25,0.25h-3.95c-0.21,0-0.4,0.18-0.4,0.4v3.84C-325.13,95.33-325.24,95.44-325.38,95.44z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-325.38,95.44c-0.06,0-0.13-0.03-0.18-0.08c-0.1-0.1-0.09-0.26,0-0.35l4.6-4.48c0.1-0.1,0.26-0.09,0.35,0
				c0.1,0.1,0.09,0.26,0,0.35l-4.6,4.48C-325.26,95.42-325.32,95.44-325.38,95.44z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="st0"
										d="M-341.02,97.31c-2.6,0-4.71-2.11-4.71-4.71c0-2.6,2.12-4.71,4.71-4.71c2.6,0,4.71,2.12,4.71,4.71
						C-336.3,95.19-338.42,97.31-341.02,97.31z M-341.02,88.38c-2.32,0-4.21,1.89-4.21,4.21c0,2.32,1.89,4.21,4.21,4.21
						c2.32,0,4.21-1.89,4.21-4.21C-336.8,90.27-338.69,88.38-341.02,88.38z"
									/>
								</g>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-341.81,94.59c-0.07,0-0.13-0.03-0.18-0.07l-1.78-1.75c-0.1-0.1-0.1-0.25,0-0.35c0.1-0.1,0.26-0.1,0.35,0
				l1.6,1.57l3.19-3.34c0.1-0.1,0.25-0.1,0.35-0.01c0.1,0.1,0.1,0.25,0.01,0.35l-3.36,3.53C-341.67,94.56-341.73,94.56-341.81,94.59
				z"
							/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path
							className="st1"
							d="M194.23,177.9H40.95c-0.87,0-1.57-0.7-1.57-1.57V85.01c0-0.87,0.7-1.57,1.57-1.57h91.63
			c0.87,0,1.57,0.7,1.57,1.57c0,0.87-0.7,1.57-1.57,1.57H42.52v88.17h150.13v-19.57c0-0.87,0.7-1.57,1.57-1.57s1.57,0.7,1.57,1.57
			v21.15C195.8,177.19,195.1,177.9,194.23,177.9z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M194.23,150.25c-0.87,0-1.57-0.7-1.57-1.57v-62.1h-9.35c-0.87,0-1.57-0.7-1.57-1.57
			c0-0.87,0.7-1.57,1.57-1.57h10.92c0.87,0,1.57,0.7,1.57,1.57v63.68C195.8,149.55,195.1,150.25,194.23,150.25z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M74.19,177.9H6.57c-0.87,0-1.57-0.7-1.57-1.57V12.3c0-0.87,0.7-1.57,1.57-1.57h67.63
			c0.87,0,1.57,0.7,1.57,1.57v72.71c0,0.87-0.7,1.57-1.57,1.57c-0.87,0-1.57-0.7-1.57-1.57V13.87H8.14v160.88h66.05
			c0.87,0,1.57,0.7,1.57,1.57C75.77,177.19,75.06,177.9,74.19,177.9z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M182.97,150.02c-0.87,0-1.57-0.7-1.57-1.57V53.53h-47.57v94.92c0,0.87-0.7,1.57-1.57,1.57
			s-1.57-0.7-1.57-1.57V51.96c0-0.87,0.7-1.57,1.57-1.57h50.72c0.87,0,1.57,0.7,1.57,1.57v96.49
			C184.54,149.32,183.83,150.02,182.97,150.02z"
						/>
					</g>
					<g>
						<g>
							<path
								className="st1"
								d="M159.07,142.7h-5.54c-0.87,0-1.57-0.7-1.57-1.57V59.27c0-0.87,0.7-1.57,1.57-1.57h5.54
				c0.87,0,1.57,0.7,1.57,1.57v81.86C160.64,142,159.94,142.7,159.07,142.7z M155.1,139.56h2.4V60.84h-2.4V139.56z"
							/>
						</g>
						<g>
							<path
								className="st1"
								d="M144.87,142.7h-5.54c-0.87,0-1.57-0.7-1.57-1.57V59.27c0-0.87,0.7-1.57,1.57-1.57h5.54
				c0.87,0,1.57,0.7,1.57,1.57v81.86C146.45,142,145.74,142.7,144.87,142.7z M140.9,139.56h2.4V60.84h-2.4V139.56z"
							/>
						</g>
						<g>
							<path
								className="st1"
								d="M173.27,142.7h-5.54c-0.87,0-1.57-0.7-1.57-1.57V59.27c0-0.87,0.7-1.57,1.57-1.57h5.54
				c0.87,0,1.57,0.7,1.57,1.57v81.86C174.84,142,174.14,142.7,173.27,142.7z M169.3,139.56h2.4V60.84h-2.4V139.56z"
							/>
						</g>
					</g>
					<g>
						<path
							className="st1"
							d="M201.84,156.75H95.32c-0.87,0-1.57-0.7-1.57-1.57v-6.5c0-0.87,0.7-1.57,1.57-1.57h106.51
			c0.87,0,1.57,0.7,1.57,1.57v6.5C203.41,156.05,202.71,156.75,201.84,156.75z M96.9,153.61h103.37v-3.35H96.9V153.61z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M22.77,149.12H12.49c-0.87,0-1.57-0.7-1.57-1.57V25.25c0-0.87,0.7-1.57,1.57-1.57h10.28
			c0.87,0,1.57,0.7,1.57,1.57v122.3C24.34,148.42,23.64,149.12,22.77,149.12z M14.06,145.98h7.14V26.82h-7.14V145.98z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M64.05,77.56H33.9c-0.87,0-1.57-0.7-1.57-1.57V26.1c0-0.87,0.7-1.57,1.57-1.57h30.15
			c0.87,0,1.57,0.7,1.57,1.57v49.88C65.62,76.86,64.92,77.56,64.05,77.56z M35.47,74.41h27.01V27.68H35.47V74.41z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M115.9,110.24H49.68c-0.87,0-1.57-0.7-1.57-1.57V93.73c0-0.87,0.7-1.57,1.57-1.57h66.23
			c0.87,0,1.57,0.7,1.57,1.57v14.94C117.48,109.54,116.77,110.24,115.9,110.24z M51.25,107.09h63.08V95.3H51.25V107.09z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M125.52,136.45H59.3c-0.87,0-1.57-0.7-1.57-1.57v-14.93c0-0.87,0.7-1.57,1.57-1.57h66.22
			c0.87,0,1.57,0.7,1.57,1.57v14.93C127.09,135.74,126.39,136.45,125.52,136.45z M60.88,133.3h63.07v-11.79H60.88V133.3z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M88.57,169.14H52.22c-0.87,0-1.57-0.7-1.57-1.57v-21.42c0-0.87,0.7-1.57,1.57-1.57h36.35
			c0.87,0,1.57,0.7,1.57,1.57v21.42C90.14,168.44,89.44,169.14,88.57,169.14z M53.8,165.99H87v-18.27H53.8V165.99z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M110.27,177.88c-0.87,0-1.57-0.7-1.57-1.57v-21.13c0-0.87,0.7-1.57,1.57-1.57c0.87,0,1.57,0.7,1.57,1.57
			v21.13C111.85,177.18,111.14,177.88,110.27,177.88z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M119.01,177.88c-0.87,0-1.57-0.7-1.57-1.57v-21.13c0-0.87,0.7-1.57,1.57-1.57s1.57,0.7,1.57,1.57v21.13
			C120.58,177.18,119.88,177.88,119.01,177.88z"
						/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<rect x="406.26" y="131.18" className="st0" width="3.71" height="86.82" />
							</g>
							<g>
								<rect x="478.72" y="131.18" className="st0" width="3.71" height="86.82" />
							</g>
							<g>
								<path
									className="st0"
									d="M516.83,234.36c-0.23,0-0.47-0.04-0.69-0.13l-35.58-14.26l-35.5,14.26c-0.44,0.18-0.94,0.18-1.38,0
					l-35.58-14.26l-35.5,14.26c-0.57,0.23-1.22,0.16-1.73-0.18c-0.51-0.34-0.81-0.92-0.81-1.54V131.05c0-0.76,0.46-1.44,1.17-1.72
					l31.52-12.61c0.95-0.38,2.02,0.08,2.41,1.03c0.38,0.95-0.08,2.03-1.03,2.41l-30.36,12.15v97.46l33.65-13.52
					c0.44-0.18,0.94-0.18,1.38,0l35.58,14.26l35.5-14.26c0.44-0.18,0.94-0.18,1.38,0l33.73,13.52V132.3l-30.43-12.14
					c-0.95-0.38-1.41-1.46-1.04-2.41c0.38-0.95,1.47-1.41,2.41-1.04l31.6,12.61c0.7,0.28,1.17,0.96,1.17,1.72V232.5
					c0,0.62-0.3,1.19-0.81,1.54C517.56,234.25,517.19,234.36,516.83,234.36z"
								/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path
								className="st0"
								d="M444.37,186.5c-3.34-0.12-6.57-1.67-8.49-4.48c-14.14-20.76-37.86-58.91-37.86-81.21
				c0-30.66,20.78-55.6,46.33-55.6c25.54,0,46.32,24.94,46.32,55.6c0,22.29-23.72,60.46-37.86,81.22
				C450.9,184.84,447.75,186.5,444.37,186.5z M444.35,48.93c-23.5,0-42.62,23.28-42.62,51.89c0,22.1,26.03,62.7,37.22,79.13
				c1.22,1.79,3.24,2.85,5.4,2.85c1.98,0.03,4.18-1.06,5.39-2.85c11.19-16.44,37.22-57.05,37.22-79.14
				C486.96,72.2,467.84,48.93,444.35,48.93z M444.35,117.74c-14.29,0-25.91-11.62-25.91-25.91s11.62-25.91,25.91-25.91
				c14.28,0,25.9,11.62,25.9,25.91S458.63,117.74,444.35,117.74z M444.35,69.63c-12.24,0-22.2,9.96-22.2,22.2s9.96,22.2,22.2,22.2
				c12.24,0,22.19-9.96,22.19-22.2S456.58,69.63,444.35,69.63z"
							/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path
							className="st0"
							d="M-410,69.08h-25c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h25c0.14,0,0.25,0.11,0.25,0.25
			S-409.86,69.08-410,69.08z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-415.23,91.83h-14.52c-0.61,0-1.11-0.46-1.16-1.06l-1.93-21.93l0.5-0.04l1.93,21.93
			c0.03,0.34,0.31,0.6,0.66,0.6h14.52c0.35,0,0.63-0.26,0.66-0.6l1.93-21.93l0.5,0.04l-1.93,21.93
			C-414.13,91.38-414.62,91.83-415.23,91.83z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-431.83,77.84h-0.81c-1.25,0-2.27-1.02-2.27-2.27v-2.22c0-0.6,0.49-1.09,1.09-1.09h1.55v0.5h-1.55
			c-0.33,0-0.59,0.26-0.59,0.59v2.22c0,0.98,0.79,1.77,1.77,1.77h0.81V77.84z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-412.34,77.84h-0.82v-0.5h0.82c0.97,0,1.76-0.79,1.76-1.77v-2.22c0-0.32-0.26-0.59-0.58-0.59h-1.55v-0.5
			h1.55c0.6,0,1.08,0.49,1.08,1.09v2.22C-410.08,76.83-411.1,77.84-412.34,77.84z"
						/>
					</g>
					<g>
						<rect x="-432.53" y="70.26" className="st0" width="20.08" height="0.5" />
					</g>
					<g>
						<rect x="-431.2" y="85.56" className="st0" width="17.41" height="0.5" />
					</g>
					<g>
						<path
							className="st0"
							d="M-419.26,68.72h-0.5c0-1-0.81-1.81-1.82-1.81h-1.84c-1,0-1.82,0.81-1.82,1.81h-0.5
			c0-1.28,1.04-2.31,2.32-2.31h1.84C-420.3,66.41-419.26,67.45-419.26,68.72z"
						/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-378.1,89.05h-2.5c-0.14,0-0.25-0.11-0.25-0.25v-1.58c-0.19-0.06-0.38-0.14-0.56-0.23l-1.11,1.11
				c-0.09,0.09-0.26,0.09-0.35,0l-1.77-1.77c-0.1-0.1-0.1-0.26,0-0.35l1.11-1.11c-0.09-0.18-0.16-0.36-0.23-0.55h-1.58
				c-0.14,0-0.25-0.11-0.25-0.25v-2.5c0-0.14,0.11-0.25,0.25-0.25h1.58c0.07-0.19,0.14-0.38,0.23-0.55l-1.11-1.12
				c-0.1-0.1-0.1-0.26,0-0.35l1.77-1.77c0.1-0.1,0.26-0.1,0.35,0l1.11,1.11c0.18-0.09,0.36-0.16,0.56-0.23v-1.58
				c0-0.14,0.11-0.25,0.25-0.25h2.5c0.14,0,0.25,0.11,0.25,0.25v1.58c0.19,0.06,0.38,0.14,0.56,0.23l1.11-1.11
				c0.09-0.09,0.26-0.09,0.35,0l1.77,1.77c0.1,0.1,0.1,0.26,0,0.35l-1.11,1.11c0.09,0.18,0.16,0.36,0.23,0.55h1.58
				c0.14,0,0.25,0.11,0.25,0.25v2.5c0,0.14-0.11,0.25-0.25,0.25h-1.58c-0.07,0.19-0.14,0.38-0.23,0.55l1.11,1.11
				c0.1,0.1,0.1,0.26,0,0.35l-1.77,1.77c-0.1,0.1-0.26,0.1-0.35,0l-1.11-1.11c-0.18,0.09-0.36,0.16-0.56,0.23v1.58
				C-377.85,88.94-377.96,89.05-378.1,89.05z M-380.35,88.55h2v-1.51c0-0.11,0.07-0.21,0.18-0.24c0.28-0.08,0.55-0.19,0.81-0.33
				c0.1-0.05,0.22-0.04,0.3,0.04l1.07,1.07l1.42-1.41l-1.07-1.07c-0.08-0.08-0.1-0.2-0.04-0.3c0.13-0.24,0.24-0.51,0.33-0.8
				c0.03-0.11,0.13-0.18,0.24-0.18h1.51v-2h-1.51c-0.11,0-0.21-0.07-0.24-0.18c-0.08-0.29-0.2-0.56-0.33-0.8
				c-0.05-0.1-0.04-0.22,0.04-0.3l1.07-1.07l-1.41-1.41l-1.07,1.07c-0.08,0.08-0.2,0.1-0.3,0.04c-0.25-0.14-0.52-0.25-0.8-0.33
				c-0.11-0.03-0.18-0.13-0.18-0.24v-1.51h-2v1.51c0,0.11-0.07,0.21-0.18,0.24c-0.28,0.08-0.55,0.2-0.8,0.33
				c-0.1,0.05-0.22,0.04-0.3-0.04l-1.07-1.07l-1.41,1.41l1.07,1.07c0.08,0.08,0.1,0.2,0.04,0.3c-0.13,0.24-0.24,0.51-0.33,0.8
				c-0.03,0.11-0.13,0.18-0.24,0.18h-1.51v2h1.51c0.11,0,0.21,0.07,0.24,0.18c0.09,0.29,0.2,0.56,0.33,0.8
				c0.05,0.1,0.04,0.22-0.04,0.3l-1.07,1.07l1.41,1.41l1.07-1.07c0.08-0.08,0.2-0.1,0.3-0.04c0.25,0.14,0.52,0.25,0.8,0.33
				c0.11,0.03,0.18,0.13,0.18,0.24V88.55z M-379.35,84.9c-1.15,0-2.08-0.93-2.08-2.08s0.93-2.08,2.08-2.08
				c1.15,0,2.08,0.93,2.08,2.08S-378.2,84.9-379.35,84.9z M-379.35,81.24c-0.87,0-1.58,0.71-1.58,1.58s0.71,1.58,1.58,1.58
				c0.87,0,1.58-0.71,1.58-1.58S-378.48,81.24-379.35,81.24z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path
									className="st0"
									d="M-366.59,97.69h-2.2c-0.38,0-0.69-0.31-0.69-0.69v-1.29c-0.03-0.1-0.38-0.4-0.56-0.48
					c-0.08-0.04-0.27,0-0.37,0.1l-0.91,0.91c-0.26,0.26-0.72,0.26-0.98,0l-1.55-1.55c-0.13-0.13-0.2-0.3-0.2-0.49
					c0-0.18,0.07-0.36,0.2-0.49l0.91-0.91c0.05-0.09,0.02-0.54-0.05-0.74c-0.03-0.08-0.18-0.19-0.33-0.19h-1.29
					c-0.38,0-0.69-0.31-0.69-0.69v-2.2c0-0.38,0.31-0.69,0.69-0.69h1.29c0.1-0.03,0.4-0.38,0.48-0.56c0.04-0.08,0-0.26-0.1-0.37
					l-0.91-0.91c-0.13-0.13-0.2-0.3-0.2-0.49s0.07-0.36,0.2-0.49l1.55-1.55c0.27-0.27,0.71-0.27,0.97,0l0.91,0.91
					c0.09,0.05,0.55,0.01,0.74-0.05c0.09-0.03,0.19-0.18,0.19-0.33v-1.29c0-0.38,0.31-0.69,0.69-0.69h2.2
					c0.38,0,0.69,0.31,0.69,0.69v1.29c0.03,0.1,0.38,0.39,0.57,0.48c0.08,0.04,0.26,0,0.37-0.1l0.91-0.91
					c0.27-0.27,0.71-0.27,0.97,0l1.55,1.55c0.13,0.13,0.2,0.3,0.2,0.49s-0.07,0.36-0.2,0.49l-0.91,0.91
					c-0.05,0.09-0.02,0.54,0.05,0.74c0.03,0.09,0.18,0.19,0.33,0.19h1.29c0.38,0,0.69,0.31,0.69,0.69v2.2
					c0,0.38-0.31,0.69-0.69,0.69h-1.29c-0.1,0.03-0.4,0.38-0.48,0.56c-0.04,0.08,0,0.27,0.1,0.37l0.91,0.91
					c0.13,0.13,0.2,0.3,0.2,0.49c0,0.19-0.07,0.36-0.2,0.49l-1.55,1.55c-0.27,0.27-0.7,0.27-0.97,0l-0.91-0.91
					c-0.09-0.05-0.55-0.01-0.74,0.05c-0.09,0.03-0.19,0.18-0.19,0.33V97C-365.9,97.38-366.21,97.69-366.59,97.69z M-370.12,94.72
					c0.11,0,0.21,0.02,0.3,0.06c0.01,0,0.85,0.46,0.85,0.94V97c0,0.1,0.09,0.19,0.19,0.19h2.2c0.1,0,0.19-0.09,0.19-0.19v-1.29
					c0-0.35,0.23-0.7,0.53-0.8c0.13-0.05,0.95-0.24,1.26,0.06l0.91,0.91c0.07,0.07,0.19,0.07,0.27,0l1.55-1.55
					c0.04-0.04,0.05-0.08,0.05-0.13s-0.02-0.1-0.06-0.13l-0.91-0.91c-0.25-0.25-0.33-0.65-0.2-0.94c0.06-0.13,0.5-0.85,0.94-0.85
					h1.29c0.1,0,0.19-0.09,0.19-0.19v-2.2c0-0.1-0.08-0.19-0.19-0.19h-1.29c-0.35,0-0.7-0.23-0.8-0.53
					c-0.05-0.14-0.24-0.96,0.06-1.26l0.91-0.91c0.04-0.04,0.05-0.08,0.05-0.13c0-0.05-0.02-0.1-0.05-0.13l-1.55-1.55
					c-0.07-0.07-0.19-0.07-0.27,0l-0.91,0.91c-0.25,0.25-0.65,0.33-0.93,0.2c-0.13-0.06-0.85-0.51-0.85-0.94v-1.29
					c0-0.1-0.09-0.19-0.19-0.19h-2.2c-0.1,0-0.19,0.08-0.19,0.19v1.29c0,0.35-0.23,0.7-0.53,0.8c-0.13,0.05-0.95,0.24-1.26-0.06
					l-0.91-0.91c-0.07-0.07-0.19-0.07-0.27,0l-1.55,1.55c-0.04,0.04-0.05,0.08-0.05,0.13c0,0.05,0.02,0.1,0.06,0.14l0.91,0.91
					c0.25,0.25,0.33,0.65,0.2,0.94c0,0.01-0.46,0.85-0.93,0.85h-1.29c-0.1,0-0.19,0.09-0.19,0.19v2.2c0,0.1,0.09,0.19,0.19,0.19
					h1.29c0.35,0,0.69,0.23,0.8,0.52c0,0.01,0.27,0.93-0.06,1.26l-0.91,0.91c-0.04,0.04-0.05,0.08-0.05,0.13s0.02,0.1,0.05,0.13
					l1.55,1.55c0.07,0.07,0.2,0.07,0.27,0l0.91-0.91C-370.59,94.81-370.35,94.72-370.12,94.72z M-367.69,93.47
					c-1.86,0-3.38-1.52-3.38-3.38c0-1.86,1.52-3.38,3.38-3.38s3.38,1.52,3.38,3.38C-364.31,91.95-365.82,93.47-367.69,93.47z
					 M-367.69,87.21c-1.59,0-2.88,1.29-2.88,2.88c0,1.59,1.29,2.88,2.88,2.88s2.88-1.29,2.88-2.88
					C-364.81,88.5-366.1,87.21-367.69,87.21z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-367.69,92.59c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5
					C-365.19,91.47-366.31,92.59-367.69,92.59z M-367.69,88.09c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2
					C-365.69,88.98-366.58,88.09-367.69,88.09z"
								/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-237.08,71.77c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73v-1.62c0-1.27,1.94-1.27,1.94,0
				v1.62C-236.11,71.44-236.6,71.77-237.08,71.77z M-237.08,68.71c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47v-1.62C-236.61,68.84-236.87,68.71-237.08,68.71z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-225.59,71.79c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73V69.2c0-1.28,1.95-1.28,1.95,0
				v1.62C-224.62,71.46-225.11,71.79-225.59,71.79z M-225.59,68.73c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47V69.2C-225.12,68.85-225.38,68.73-225.59,68.73z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-220.38,75.82h-21.86c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h21.86c0.14,0,0.25,0.11,0.25,0.25
				S-220.24,75.82-220.38,75.82z"
							/>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-219.96,90.57h-17.2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h16.95v-4.64
					c0-0.14,0.11-0.25,0.25-0.25s0.25,0.11,0.25,0.25v4.89C-219.71,90.46-219.82,90.57-219.96,90.57z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-231.03,79.81h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-230.78,79.7-230.89,79.81-231.03,79.81z M-232.83,79.31h1.56v-1.55h-1.56V79.31z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-227.27,79.81h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-227.02,79.7-227.13,79.81-227.27,79.81z M-229.08,79.31h1.56v-1.55h-1.56V79.31z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-223.51,79.81h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-223.26,79.7-223.38,79.81-223.51,79.81z M-225.32,79.31h1.56v-1.55h-1.56V79.31z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-230.75,86.61h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-230.5,86.5-230.61,86.61-230.75,86.61z M-232.56,86.11h1.55v-1.55h-1.55V86.11z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-226.99,86.61h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-226.74,86.5-226.85,86.61-226.99,86.61z M-228.8,86.11h1.56v-1.55h-1.56V86.11z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-234.66,83.37h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-234.41,83.26-234.53,83.37-234.66,83.37z M-236.47,82.87h1.55v-1.55h-1.55V82.87z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-230.95,83.37H-233c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-230.7,83.26-230.81,83.37-230.95,83.37z M-232.75,82.87h1.56v-1.55h-1.56V82.87z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-227.23,83.37h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-226.98,83.26-227.09,83.37-227.23,83.37z M-229.04,82.87h1.56v-1.55h-1.56V82.87z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-223.51,83.37h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-223.26,83.26-223.38,83.37-223.51,83.37z M-225.32,82.87h1.56v-1.55h-1.56V82.87z"
								/>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-224.86,89.35h-11.11c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h10.86v-3.59c0-0.49,0.4-0.9,0.9-0.9
				h3.7V72c0-0.75-0.61-1.36-1.36-1.36h-1.99l0.01,1c0,2.26-3.44,2.26-3.44,0v-1.02l-8.1,0.02l0.02,1c0,1.13-0.86,1.72-1.72,1.72
				c-0.45,0-0.86-0.15-1.18-0.43c-0.35-0.32-0.54-0.76-0.54-1.29v-1.02l-1.98,0.02c-0.75,0-1.36,0.61-1.36,1.36v9.59
				c0,0.14-0.11,0.25-0.25,0.25s-0.25-0.11-0.25-0.25V72c0-1.02,0.83-1.86,1.86-1.86h1.99c0.28,0,0.49,0.21,0.49,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.48-0.48h8.11c0.27,0,0.48,0.21,0.48,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.49-0.48h1.99c1.02,0,1.86,0.83,1.86,1.86v12.62
				c0,0.14-0.11,0.25-0.25,0.25h-3.95c-0.21,0-0.4,0.18-0.4,0.4v3.84C-224.61,89.24-224.72,89.35-224.86,89.35z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-224.86,89.35c-0.06,0-0.13-0.03-0.18-0.08c-0.1-0.1-0.09-0.26,0-0.35l4.6-4.48c0.1-0.1,0.26-0.09,0.35,0
				c0.1,0.1,0.09,0.26,0,0.35l-4.6,4.48C-224.73,89.33-224.8,89.35-224.86,89.35z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="st0"
										d="M-240.49,91.22c-2.6,0-4.71-2.11-4.71-4.71c0-2.6,2.12-4.71,4.71-4.71c2.6,0,4.71,2.12,4.71,4.71
						C-235.78,89.11-237.9,91.22-240.49,91.22z M-240.49,82.29c-2.32,0-4.21,1.89-4.21,4.21c0,2.32,1.89,4.21,4.21,4.21
						c2.32,0,4.21-1.89,4.21-4.21C-236.28,84.19-238.17,82.29-240.49,82.29z"
									/>
								</g>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-241.28,88.5c-0.07,0-0.13-0.03-0.18-0.07l-1.78-1.75c-0.1-0.1-0.1-0.25,0-0.35s0.25-0.1,0.35,0l1.6,1.57
				l3.19-3.34c0.1-0.1,0.25-0.1,0.35-0.01c0.1,0.1,0.1,0.25,0.01,0.35l-3.36,3.53C-241.15,88.47-241.21,88.5-241.28,88.5
				C-241.28,88.5-241.28,88.5-241.28,88.5z"
							/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path
									className="st0"
									d="M-177.32,93.57h-16.14c-0.93,0-1.68-0.75-1.68-1.68V69.75c0-0.93,0.75-1.68,1.68-1.68h9.66
					c0.07,0,0.13,0.03,0.18,0.07l7.9,7.9c0.05,0.05,0.07,0.11,0.07,0.18v15.67C-175.64,92.81-176.4,93.57-177.32,93.57z
					 M-193.46,68.57c-0.65,0-1.18,0.53-1.18,1.18v22.14c0,0.65,0.53,1.18,1.18,1.18h16.14c0.65,0,1.18-0.53,1.18-1.18V76.33
					l-7.76-7.76H-193.46z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-175.89,76.47h-6.47c-0.93,0-1.68-0.75-1.68-1.68v-6.47c0-0.1,0.06-0.19,0.15-0.23
					c0.09-0.04,0.2-0.02,0.27,0.05l7.9,7.9c0.07,0.07,0.09,0.18,0.05,0.27S-175.79,76.47-175.89,76.47z M-183.55,68.92v5.87
					c0,0.65,0.53,1.18,1.18,1.18h5.87L-183.55,68.92z"
								/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path
								className="st0"
								d="M-178.8,87.33h-13.18c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h13.18c0.14,0,0.25,0.11,0.25,0.25
				S-178.66,87.33-178.8,87.33z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-178.8,83.28h-12.93c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h12.93c0.14,0,0.25,0.11,0.25,0.25
				S-178.66,83.28-178.8,83.28z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-178.8,79.14h-9.22c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h9.22c0.14,0,0.25,0.11,0.25,0.25
				S-178.67,79.14-178.8,79.14z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="st0"
										d="M-190.52,81.64c-0.09,0-0.17-0.05-0.21-0.12l-3.31-5.53c-0.05-0.08-0.05-0.17,0-0.25
						c0.04-0.08,0.13-0.13,0.22-0.13h1.53l-0.51-7.28c-0.01-0.14,0.09-0.26,0.23-0.27c0.13-0.01,0.26,0.09,0.27,0.23l0.53,7.55
						c0,0.07-0.02,0.14-0.07,0.19c-0.05,0.05-0.11,0.08-0.18,0.08h-1.35l2.87,4.79l2.87-4.79h-1.37c-0.07,0-0.14-0.03-0.18-0.08
						s-0.07-0.12-0.06-0.19l0.54-6.05c0.01-0.14,0.14-0.24,0.27-0.23c0.14,0.01,0.24,0.13,0.23,0.27l-0.51,5.78h1.53
						c0.09,0,0.17,0.05,0.22,0.13c0.04,0.08,0.04,0.17,0,0.25l-3.31,5.53C-190.35,81.6-190.44,81.64-190.52,81.64z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path
							className="st1"
							d="M-153.51,345.41h-118.95c-1.08,0-1.96-0.88-1.96-1.96v-75.11c0-1.08,0.88-1.96,1.96-1.96
			c1.08,0,1.96,0.88,1.96,1.96v73.15h115.03v-73.15c0-1.08,0.88-1.96,1.96-1.96c1.08,0,1.96,0.88,1.96,1.96v75.11
			C-151.55,344.53-152.43,345.41-153.51,345.41z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-142.53,272.37c-0.28,0-0.55-0.05-0.81-0.18l-69.64-31.75l-69.64,31.75c-0.97,0.46-2.14,0.02-2.6-0.97
			c-0.45-0.98-0.02-2.14,0.97-2.6l70.45-32.12c0.52-0.23,1.1-0.23,1.62,0l70.45,32.12c0.99,0.45,1.42,1.62,0.97,2.6
			C-141.08,271.95-141.79,272.37-142.53,272.37z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-94.49,345.41h-55.39c-1.08,0-1.96-0.88-1.96-1.96s0.88-1.96,1.96-1.96h53.43v-53.82
			c0-1.08,0.88-1.96,1.96-1.96s1.96,0.88,1.96,1.96v55.78C-92.53,344.53-93.41,345.41-94.49,345.41z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-87.35,293.91c-0.37,0-0.74-0.1-1.06-0.31l-46.69-29.99l-5.38,3.47c-0.9,0.6-2.11,0.34-2.71-0.58
			c-0.59-0.91-0.32-2.13,0.58-2.71l6.45-4.15c0.66-0.41,1.48-0.41,2.12,0l47.75,30.67c0.91,0.58,1.18,1.79,0.59,2.7
			C-86.08,293.59-86.71,293.91-87.35,293.91z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-248.87,256.73c-1.08,0-1.96-0.88-1.96-1.96v-33.38c0-1.08,0.88-1.96,1.96-1.96c1.08,0,1.96,0.88,1.96,1.96
			v33.38C-246.91,255.85-247.79,256.73-248.87,256.73z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-118.68,271.2c-1.08,0-1.96-0.88-1.96-1.96v-47.84c0-1.08,0.88-1.96,1.96-1.96s1.96,0.88,1.96,1.96v47.84
			C-116.72,270.31-117.6,271.2-118.68,271.2z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-257.85,226.33c-0.71,0-1.4-0.39-1.75-1.07c-0.49-0.97-0.11-2.14,0.86-2.63l74.1-37.72
			c0.55-0.29,1.23-0.29,1.78,0l74.07,37.72c0.97,0.49,1.35,1.67,0.86,2.63c-0.49,0.97-1.67,1.36-2.63,0.86l-73.18-37.27
			l-73.21,37.27C-257.25,226.26-257.55,226.33-257.85,226.33z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-174.15,343.49h-3.92v-50.02h-69.82v50.02h-3.92v-51.98c0-1.08,0.88-1.96,1.96-1.96h73.74
			c1.08,0,1.96,0.88,1.96,1.96V343.49z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-198.7,280.02h-28.55c-1.08,0-1.96-0.88-1.96-1.96V267.9c0-1.08,0.88-1.96,1.96-1.96h28.55
			c1.08,0,1.96,0.88,1.96,1.96v10.16C-196.74,279.14-197.62,280.02-198.7,280.02z M-225.3,276.1h24.63v-6.23h-24.63V276.1z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-134.58,252.43h-29.04c-1.08,0-1.96-0.88-1.96-1.96v-21.09c0-1.08,0.88-1.96,1.96-1.96h29.04
			c1.08,0,1.96,0.88,1.96,1.96v21.09C-132.62,251.55-133.5,252.43-134.58,252.43z M-161.66,248.51h25.12v-17.17h-25.12V248.51z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-186.57,234.39h-29.04c-1.08,0-1.96-0.88-1.96-1.96v-19.45c0-1.08,0.88-1.96,1.96-1.96h29.04
			c1.08,0,1.96,0.88,1.96,1.96v19.45C-184.61,233.51-185.49,234.39-186.57,234.39z M-213.66,230.47h25.12v-15.53h-25.12V230.47z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-139.18,320.61h-14.78v-3.92h12.82v-24.79h-12.82v-3.92h14.78c1.08,0,1.96,0.88,1.96,1.96v28.71
			C-137.22,319.73-138.1,320.61-139.18,320.61z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M-106.99,343.45h-3.92v-33.56h-15.79v33.56h-3.92v-35.52c0-1.08,0.88-1.96,1.96-1.96h19.72
			c1.08,0,1.96,0.88,1.96,1.96V343.45z"
						/>
					</g>
					<g>
						<rect x="-248.66" y="301.38" className="st1" width="71.25" height="3.92" />
					</g>
					<g>
						<rect x="-248.66" y="315.21" className="st1" width="71.25" height="3.92" />
					</g>
					<g>
						<rect x="-248.66" y="329.04" className="st1" width="71.25" height="3.92" />
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-130.14,124.34c-0.04,0-0.09-0.01-0.12-0.03c-0.12-0.07-0.16-0.22-0.09-0.34l4.5-7.8
				c0.16-0.28,0.21-0.61,0.12-0.92c-0.08-0.31-0.28-0.57-0.57-0.74l-14.95-8.63c-0.28-0.16-0.6-0.2-0.91-0.12
				c-0.31,0.08-0.58,0.29-0.74,0.56l-4.5,7.8c-0.07,0.12-0.22,0.16-0.34,0.09c-0.12-0.07-0.16-0.22-0.09-0.34l4.5-7.8
				c0.23-0.4,0.59-0.68,1.04-0.8c0.44-0.12,0.9-0.06,1.29,0.17l14.95,8.63c0.4,0.24,0.68,0.61,0.8,1.04
				c0.12,0.43,0.05,0.91-0.18,1.3l-4.5,7.8C-129.97,124.3-130.06,124.34-130.14,124.34z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-127.6,119.82c-0.04,0-0.09-0.01-0.12-0.03l-2.67-1.54l0.25-0.43l2.46,1.42l1-1.72l-17.01-9.82l-0.99,1.73
				l8.81,5.08l-0.25,0.43l-9.03-5.21c-0.12-0.07-0.16-0.22-0.09-0.34l1.24-2.16c0.03-0.06,0.09-0.1,0.15-0.12
				c0.06-0.02,0.13-0.01,0.19,0.02l17.44,10.07c0.06,0.03,0.1,0.09,0.12,0.15s0.01,0.13-0.03,0.19l-1.25,2.15
				C-127.43,119.78-127.52,119.82-127.6,119.82z"
							/>
						</g>
						<g>
							<rect x="-142.83" y="110.75" transform="matrix(0.5001 -0.866 0.866 0.5001 -168.6663 -67.2469)" className="st0" width="0.5" height="3.41" />
						</g>
						<g>
							<rect x="-144.03" y="112.4" transform="matrix(0.4995 -0.8663 0.8663 0.4995 -170.4418 -67.6676)" className="st0" width="0.5" height="2.53" />
						</g>
					</g>
					<g>
						<g>
							<path
								className="st0"
								d="M-131.73,126.89h-17.25c-0.94,0-1.71-0.77-1.71-1.71v-9.01c0-0.94,0.77-1.71,1.71-1.71h17.25
				c0.94,0,1.71,0.77,1.71,1.71v9.01C-130.02,126.13-130.79,126.89-131.73,126.89z M-148.99,114.97c-0.67,0-1.21,0.54-1.21,1.21
				v9.01c0,0.67,0.54,1.21,1.21,1.21h17.25c0.67,0,1.21-0.54,1.21-1.21v-9.01c0-0.67-0.54-1.21-1.21-1.21H-148.99z"
							/>
						</g>
						<g>
							<rect x="-142.83" y="117.56" className="st0" width="7.65" height="0.5" />
						</g>
						<g>
							<rect x="-147.25" y="121.67" className="st0" width="14.32" height="0.5" />
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-147.06,119.13h-0.97c-0.52,0-0.95-0.43-0.95-0.95v-0.97c0-0.53,0.43-0.95,0.95-0.95h0.97
					c0.52,0,0.95,0.43,0.95,0.95v0.97C-146.11,118.71-146.53,119.13-147.06,119.13z M-148.03,116.76c-0.25,0-0.45,0.2-0.45,0.45
					v0.97c0,0.25,0.2,0.45,0.45,0.45h0.97c0.25,0,0.45-0.2,0.45-0.45v-0.97c0-0.25-0.2-0.45-0.45-0.45H-148.03z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-136.51,126.09h-2.24c-0.79,0-1.21-0.61-1.21-1.22c0-0.31,0.11-0.61,0.3-0.83
					c0.16-0.18,0.44-0.39,0.91-0.39h2.24v0.5h-2.24c-0.49,0-0.71,0.36-0.71,0.72s0.22,0.72,0.71,0.72h2.24V126.09z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-135.79,126.09h-1.95v-0.5c0.23,0,0.41-0.07,0.54-0.22c0.11-0.13,0.18-0.31,0.18-0.5
					c0-0.36-0.22-0.71-0.72-0.71v-0.5h1.95c0.8,0,1.21,0.61,1.21,1.21c0,0.31-0.11,0.61-0.3,0.83
					C-135.03,125.88-135.32,126.09-135.79,126.09z M-136.74,125.59h0.95c0.23,0,0.41-0.07,0.54-0.22c0.11-0.13,0.18-0.31,0.18-0.5
					c0-0.36-0.22-0.71-0.71-0.71h-0.95c0.14,0.21,0.22,0.46,0.22,0.71C-136.52,125.13-136.6,125.38-136.74,125.59z"
								/>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};
export const SvgResidential = ({ style }) => {
	return (
		<div className="svg">
			<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 200 200" style={{ enableBackground: 'new 0 0 200 200', ...style }}>
				<g>
					<g>
						<path
							className="st0"
							d="M-278,79.97c-0.05,0-0.1-0.01-0.14-0.04l-4.52-3c-0.06-0.04-0.09-0.09-0.11-0.16s0-0.13,0.04-0.19l0.26-0.4
			c0.91-1.38,1.82-2.76,2.73-4.12c0.55-0.84,1.41-1.36,2.25-1.36c0.14,0,0.25,0.11,0.25,0.25s-0.11,0.25-0.25,0.25
			c-0.52,0-1.28,0.3-1.83,1.14c-0.9,1.37-1.82,2.75-2.73,4.12l-0.13,0.19l4.1,2.72c0.95-1.44,1.91-2.88,2.86-4.31
			c0.08-0.12,0.23-0.15,0.35-0.07c0.11,0.08,0.15,0.23,0.07,0.35c-1,1.5-2,3.01-3,4.52C-277.84,79.93-277.91,79.97-278,79.97z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-268.9,78.89h-6.02c-0.11,0-0.21-0.08-0.24-0.19c-0.03-0.11,0.02-0.22,0.12-0.28l1.57-0.91
			c-1.5-3.12-3.36-6.25-4.08-6.32c-0.13-0.01-0.23-0.13-0.22-0.26c0.01-0.13,0.12-0.24,0.25-0.24h6.75c0.53,0,1.01,0.29,1.26,0.75
			l1.58,2.87l1.66-0.96c0.1-0.06,0.22-0.04,0.3,0.04c0.08,0.08,0.1,0.2,0.05,0.3l-2.75,5.07C-268.73,78.84-268.81,78.89-268.9,78.89
			z M-273.99,78.39h4.94l2.26-4.16l-1.11,0.64c-0.06,0.03-0.13,0.04-0.19,0.02c-0.06-0.02-0.12-0.06-0.15-0.12l-1.7-3.09
			c-0.17-0.3-0.48-0.49-0.83-0.49h-5.93c1.29,1.28,3,4.68,3.78,6.32c0.06,0.12,0.01,0.26-0.1,0.32L-273.99,78.39z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-262.02,87.06c-0.03,0-0.06-0.01-0.1-0.02c-0.13-0.05-0.19-0.2-0.14-0.33c0.24-0.57,0.22-1.22-0.04-1.87
			l-1.92-4.8c-1.52,0.61-3.05,1.22-4.58,1.83l0.41,1.02c0.44,1.1,0.89,2.21,1.33,3.31c0.05,0.13-0.01,0.27-0.14,0.33
			c-0.13,0.05-0.27-0.01-0.33-0.14c-0.44-1.11-0.89-2.21-1.33-3.31l-0.51-1.26c-0.05-0.13,0.01-0.27,0.14-0.33
			c1.68-0.68,3.36-1.35,5.04-2.02c0.13-0.05,0.27,0.01,0.32,0.14l2.01,5.03c0.31,0.78,0.33,1.55,0.04,2.25
			C-261.83,87.01-261.92,87.06-262.02,87.06z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-269.71,95.25c-0.09,0-0.17-0.05-0.22-0.12l-3.03-5.2c-0.05-0.08-0.05-0.18,0-0.26l3-4.93
			c0.06-0.1,0.17-0.14,0.28-0.11c0.11,0.03,0.18,0.13,0.18,0.24l0.01,1.97h6.78c0.21,0,0.41-0.09,0.54-0.24
			c0.05-0.05,0.12-0.08,0.19-0.08h0.01c0.09,0,0.17,0.05,0.21,0.12c0.04,0.08,0.05,0.17,0,0.25l-3.23,5.93
			c-0.19,0.34-0.54,0.55-0.93,0.55h-3.56l0.01,1.64c0,0.11-0.08,0.21-0.18,0.24C-269.66,95.25-269.68,95.25-269.71,95.25z
			 M-272.45,89.81l2.49,4.27l0-0.95c0-0.07,0.03-0.13,0.07-0.18c0.05-0.05,0.11-0.07,0.18-0.07h3.81c0.21,0,0.39-0.11,0.49-0.29
			l2.85-5.25c-0.05,0.01-0.1,0.01-0.15,0.01h-7.03c-0.14,0-0.25-0.11-0.25-0.25l0-1.33L-272.45,89.81z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-280.6,92.57c-1.27,0-2.29-0.67-2.73-1.8c-0.05-0.13,0.01-0.27,0.14-0.32c0.13-0.05,0.27,0.01,0.32,0.14
			c0.37,0.94,1.19,1.48,2.26,1.48c0.01,0,0.02,0,0.02,0c1.73-0.01,3.45-0.02,5.17-0.02c0-1.64-0.01-3.29-0.02-4.93
			c-1.75,0.01-3.46,0.02-5.17,0.02c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25c1.8,0,3.59-0.01,5.38-0.02c0,0,0,0,0,0
			c0.14,0,0.28,0.11,0.28,0.25c0.01,1.81,0.02,3.62,0.02,5.43c0,0.14-0.11,0.25-0.25,0.25c-1.8,0-3.61,0.01-5.42,0.02
			C-280.58,92.57-280.59,92.57-280.6,92.57z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-283.1,90.93c-0.1,0.02-0.17-0.04-0.21-0.11l-3.61-5.71c-0.21-0.33-0.22-0.74-0.03-1.08l1.77-3.17
			l-1.51-0.86c-0.1-0.06-0.15-0.17-0.12-0.28c0.03-0.11,0.13-0.19,0.24-0.19l6.02-0.06c0,0,0,0,0,0c0.09,0,0.17,0.05,0.22,0.13
			l2.81,5.04c0.05,0.1,0.04,0.22-0.04,0.3c-0.08,0.08-0.2,0.09-0.3,0.04l-1.62-0.92l-3.32,5.94c-0.1,0.18-0.12,0.39-0.05,0.59
			c0.02,0.07,0.01,0.15-0.03,0.22C-282.94,90.88-283.02,90.93-283.1,90.93z M-285.65,80.02l0.92,0.53c0.12,0.07,0.16,0.22,0.09,0.34
			l-1.89,3.38c-0.1,0.18-0.09,0.4,0.02,0.57l3.19,5.05c0.02-0.04,0.04-0.09,0.06-0.13l3.44-6.16c0.03-0.06,0.09-0.1,0.15-0.12
			c0.06-0.02,0.13-0.01,0.19,0.02l1.07,0.6l-2.3-4.14L-285.65,80.02z"
						/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-127.63,91.23H-149c-1.14,0-2.06-0.92-2.06-2.06V78.02c0-1.14,0.92-2.06,2.06-2.06h5.73
				c0.14,0,0.25,0.11,0.25,0.25s-0.11,0.25-0.25,0.25H-149c-0.86,0-1.56,0.7-1.56,1.56v11.15c0,0.86,0.7,1.56,1.56,1.56h21.37
				c0.85,0,1.55-0.7,1.55-1.56V78.02c0-0.86-0.7-1.56-1.55-1.56h-5.74c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h5.74
				c1.13,0,2.05,0.92,2.05,2.06v11.15C-125.58,90.31-126.5,91.23-127.63,91.23z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-125.89,83.01h-24.93c-0.14,0-0.25-0.11-0.25-0.25v-3.08c0-0.14,0.11-0.25,0.25-0.25h24.93
				c0.14,0,0.25,0.11,0.25,0.25v3.08C-125.64,82.9-125.75,83.01-125.89,83.01z M-150.57,82.51h24.43v-2.58h-24.43V82.51z"
							/>
						</g>
						<g>
							<rect x="-148.47" y="84.15" className="st0" width="4.23" height="0.5" />
						</g>
						<g>
							<rect x="-148.47" y="86.19" className="st0" width="6.65" height="0.5" />
						</g>
						<g>
							<rect x="-148.47" y="88.08" className="st0" width="10.06" height="0.5" />
						</g>
					</g>
					<g>
						<g>
							<g>
								<path
									className="st0"
									d="M-138.32,78.9c-2.72,0-4.93-2.47-4.93-5.5s2.21-5.5,4.93-5.5c2.72,0,4.93,2.47,4.93,5.5
					S-135.6,78.9-138.32,78.9z M-138.32,68.4c-2.44,0-4.43,2.24-4.43,5s1.99,5,4.43,5c2.44,0,4.43-2.24,4.43-5
					S-135.88,68.4-138.32,68.4z"
								/>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path
											className="st0"
											d="M-138.91,71.32c-0.14,0-0.25-0.11-0.25-0.25c0-0.14,0.11-0.25,0.25-0.25l1.93-0.04
							c0.14,0.02,0.25,0.11,0.25,0.25c0,0.14-0.11,0.25-0.25,0.25L-138.91,71.32C-138.91,71.32-138.91,71.32-138.91,71.32z"
										/>
									</g>
									<g>
										<path
											className="st0"
											d="M-139.66,76.18c-0.14,0-0.25-0.11-0.25-0.25c0-0.14,0.11-0.25,0.25-0.25l1.93-0.04
							c0.16,0.02,0.25,0.11,0.25,0.25c0,0.14-0.11,0.25-0.25,0.25L-139.66,76.18C-139.66,76.18-139.66,76.18-139.66,76.18z"
										/>
									</g>
									<g>
										<path
											className="st0"
											d="M-137.78,76.15c-0.14,0-0.25-0.11-0.25-0.25c0-0.14,0.11-0.25,0.25-0.25c0.27,0,0.52-0.11,0.71-0.31
							s0.29-0.45,0.28-0.73c0-0.27-0.11-0.53-0.31-0.71c-0.2-0.19-0.44-0.28-0.73-0.28l-1.11,0.02c-0.38-0.01-0.73-0.13-1-0.39
							c-0.27-0.26-0.42-0.61-0.43-0.99c-0.01-0.37,0.13-0.73,0.39-1c0.26-0.27,0.61-0.42,0.99-0.43c0.12-0.02,0.25,0.11,0.25,0.25
							c0,0.14-0.11,0.25-0.25,0.25c-0.24,0-0.47,0.1-0.64,0.28c-0.17,0.17-0.26,0.4-0.25,0.65c0,0.24,0.1,0.47,0.28,0.64
							c0.17,0.17,0.38,0.26,0.65,0.25l1.11-0.02c0.42,0,0.79,0.14,1.08,0.42c0.29,0.28,0.46,0.66,0.46,1.07
							c0.01,0.41-0.14,0.79-0.42,1.08C-136.99,75.98-137.37,76.14-137.78,76.15C-137.77,76.15-137.78,76.15-137.78,76.15z"
										/>
									</g>
								</g>
								<g>
									<path
										className="st0"
										d="M-138.32,76.9c-0.14,0-0.25-0.11-0.25-0.25v-6.51c0-0.14,0.11-0.25,0.25-0.25s0.25,0.11,0.25,0.25v6.51
						C-138.07,76.79-138.19,76.9-138.32,76.9z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-337.61,77.86c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73v-1.62c0-1.27,1.94-1.27,1.94,0
				v1.62C-336.64,77.52-337.12,77.86-337.61,77.86z M-337.61,74.8c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47v-1.62C-337.14,74.92-337.39,74.8-337.61,74.8z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-326.12,77.88c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73v-1.62c0-1.28,1.95-1.28,1.95,0
				v1.62C-325.14,77.54-325.63,77.88-326.12,77.88z M-326.12,74.81c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47v-1.62C-325.64,74.94-325.9,74.81-326.12,74.81z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-320.9,81.91h-21.86c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h21.86c0.14,0,0.25,0.11,0.25,0.25
				S-320.76,81.91-320.9,81.91z"
							/>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-320.48,96.66h-17.2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h16.95v-4.64
					c0-0.14,0.11-0.25,0.25-0.25s0.25,0.11,0.25,0.25v4.89C-320.23,96.54-320.35,96.66-320.48,96.66z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-331.55,85.9h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-331.3,85.79-331.41,85.9-331.55,85.9z M-333.35,85.4h1.56v-1.55h-1.56V85.4z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-327.79,85.9h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-327.54,85.79-327.65,85.9-327.79,85.9z M-329.6,85.4h1.56v-1.55h-1.56V85.4z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-324.04,85.9h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-323.79,85.79-323.9,85.9-324.04,85.9z M-325.84,85.4h1.56v-1.55h-1.56V85.4z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-331.27,92.7h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-331.02,92.59-331.14,92.7-331.27,92.7z M-333.08,92.2h1.55v-1.55h-1.55V92.2z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-327.52,92.7h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-327.27,92.59-327.38,92.7-327.52,92.7z M-329.32,92.2h1.56v-1.55h-1.56V92.2z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-335.19,89.46h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-334.94,89.35-335.05,89.46-335.19,89.46z M-336.99,88.96h1.55V87.4h-1.55V88.96z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-331.47,89.46h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-331.22,89.35-331.33,89.46-331.47,89.46z M-333.28,88.96h1.56V87.4h-1.56V88.96z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-327.75,89.46h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-327.5,89.35-327.62,89.46-327.75,89.46z M-329.56,88.96h1.56V87.4h-1.56V88.96z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-324.04,89.46h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-323.79,89.35-323.9,89.46-324.04,89.46z M-325.84,88.96h1.56V87.4h-1.56V88.96z"
								/>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-325.38,95.44h-11.11c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h10.86v-3.59c0-0.49,0.4-0.9,0.9-0.9
				h3.7V78.08c0-0.75-0.61-1.36-1.36-1.36h-1.99l0.01,1c0,2.26-3.44,2.26-3.44,0v-1.02l-8.1,0.02l0.02,1c0,1.13-0.86,1.72-1.72,1.72
				c-0.45,0-0.86-0.15-1.18-0.43c-0.35-0.32-0.54-0.76-0.54-1.29v-1.02l-1.98,0.02c-0.75,0-1.36,0.61-1.36,1.36v9.59
				c0,0.14-0.11,0.25-0.25,0.25s-0.25-0.11-0.25-0.25v-9.59c0-1.02,0.83-1.86,1.86-1.86h1.99c0.28,0,0.49,0.21,0.49,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.48-0.48h8.11c0.27,0,0.48,0.21,0.48,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.49-0.48h1.99c1.02,0,1.86,0.83,1.86,1.86V90.7
				c0,0.14-0.11,0.25-0.25,0.25h-3.95c-0.21,0-0.4,0.18-0.4,0.4v3.84C-325.13,95.33-325.24,95.44-325.38,95.44z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-325.38,95.44c-0.06,0-0.13-0.03-0.18-0.08c-0.1-0.1-0.09-0.26,0-0.35l4.6-4.48c0.1-0.1,0.26-0.09,0.35,0
				c0.1,0.1,0.09,0.26,0,0.35l-4.6,4.48C-325.26,95.42-325.32,95.44-325.38,95.44z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="st0"
										d="M-341.02,97.31c-2.6,0-4.71-2.11-4.71-4.71c0-2.6,2.12-4.71,4.71-4.71c2.6,0,4.71,2.12,4.71,4.71
						C-336.3,95.19-338.42,97.31-341.02,97.31z M-341.02,88.38c-2.32,0-4.21,1.89-4.21,4.21c0,2.32,1.89,4.21,4.21,4.21
						c2.32,0,4.21-1.89,4.21-4.21C-336.8,90.27-338.69,88.38-341.02,88.38z"
									/>
								</g>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-341.81,94.59c-0.07,0-0.13-0.03-0.18-0.07l-1.78-1.75c-0.1-0.1-0.1-0.25,0-0.35c0.1-0.1,0.26-0.1,0.35,0
				l1.6,1.57l3.19-3.34c0.1-0.1,0.25-0.1,0.35-0.01c0.1,0.1,0.1,0.25,0.01,0.35l-3.36,3.53C-341.67,94.56-341.73,94.56-341.81,94.59
				z"
							/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path
							className="st0"
							d="M458.31,27.88H305.04c-0.87,0-1.57-0.7-1.57-1.57v-91.32c0-0.87,0.7-1.57,1.57-1.57h91.63
			c0.87,0,1.57,0.7,1.57,1.57c0,0.87-0.7,1.57-1.57,1.57h-90.06v88.17h150.13V5.16c0-0.87,0.7-1.57,1.57-1.57s1.57,0.7,1.57,1.57
			V26.3C459.88,27.17,459.18,27.88,458.31,27.88z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M458.31,0.23c-0.87,0-1.57-0.7-1.57-1.57v-62.1h-9.35c-0.87,0-1.57-0.7-1.57-1.57c0-0.87,0.7-1.57,1.57-1.57
			h10.92c0.87,0,1.57,0.7,1.57,1.57v63.68C459.88-0.47,459.18,0.23,458.31,0.23z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M338.28,27.88h-67.63c-0.87,0-1.57-0.7-1.57-1.57v-164.03c0-0.87,0.7-1.57,1.57-1.57h67.63
			c0.87,0,1.57,0.7,1.57,1.57v72.71c0,0.87-0.7,1.57-1.57,1.57s-1.57-0.7-1.57-1.57v-71.14h-64.48V24.73h66.05
			c0.87,0,1.57,0.7,1.57,1.57S339.15,27.88,338.28,27.88z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M447.05,0c-0.87,0-1.57-0.7-1.57-1.57v-94.92h-47.57v94.92c0,0.87-0.7,1.57-1.57,1.57s-1.57-0.7-1.57-1.57
			v-96.49c0-0.87,0.7-1.57,1.57-1.57h50.72c0.87,0,1.57,0.7,1.57,1.57v96.49C448.62-0.7,447.92,0,447.05,0z"
						/>
					</g>
					<g>
						<g>
							<path
								className="st0"
								d="M423.15-7.32h-5.54c-0.87,0-1.57-0.7-1.57-1.57v-81.86c0-0.87,0.7-1.57,1.57-1.57h5.54
				c0.87,0,1.57,0.7,1.57,1.57v81.86C424.72-8.02,424.02-7.32,423.15-7.32z M419.18-10.47h2.4v-78.72h-2.4V-10.47z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M408.96-7.32h-5.54c-0.87,0-1.57-0.7-1.57-1.57v-81.86c0-0.87,0.7-1.57,1.57-1.57h5.54
				c0.87,0,1.57,0.7,1.57,1.57v81.86C410.53-8.02,409.83-7.32,408.96-7.32z M404.99-10.47h2.4v-78.72h-2.4V-10.47z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M437.36-7.32h-5.54c-0.87,0-1.57-0.7-1.57-1.57v-81.86c0-0.87,0.7-1.57,1.57-1.57h5.54
				c0.87,0,1.57,0.7,1.57,1.57v81.86C438.93-8.02,438.22-7.32,437.36-7.32z M433.38-10.47h2.4v-78.72h-2.4V-10.47z"
							/>
						</g>
					</g>
					<g>
						<path
							className="st0"
							d="M465.92,6.73H359.41c-0.87,0-1.57-0.7-1.57-1.57v-6.5c0-0.87,0.7-1.57,1.57-1.57h106.51
			c0.87,0,1.57,0.7,1.57,1.57v6.5C467.49,6.03,466.79,6.73,465.92,6.73z M360.98,3.58h103.37V0.23H360.98V3.58z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M286.85-0.9h-10.28c-0.87,0-1.57-0.7-1.57-1.57v-122.3c0-0.87,0.7-1.57,1.57-1.57h10.28
			c0.87,0,1.57,0.7,1.57,1.57v122.3C288.42-1.6,287.72-0.9,286.85-0.9z M278.14-4.04h7.14V-123.2h-7.14V-4.04z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M328.14-72.46h-30.15c-0.87,0-1.57-0.7-1.57-1.57v-49.88c0-0.87,0.7-1.57,1.57-1.57h30.15
			c0.87,0,1.57,0.7,1.57,1.57v49.88C329.71-73.16,329-72.46,328.14-72.46z M299.55-75.61h27.01v-46.74h-27.01V-75.61z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M379.99-39.78h-66.23c-0.87,0-1.57-0.7-1.57-1.57v-14.94c0-0.87,0.7-1.57,1.57-1.57h66.23
			c0.87,0,1.57,0.7,1.57,1.57v14.94C381.56-40.48,380.86-39.78,379.99-39.78z M315.34-42.93h63.08v-11.79h-63.08V-42.93z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M389.61-13.57h-66.22c-0.87,0-1.57-0.7-1.57-1.57v-14.93c0-0.87,0.7-1.57,1.57-1.57h66.22
			c0.87,0,1.57,0.7,1.57,1.57v14.93C391.18-14.28,390.48-13.57,389.61-13.57z M324.96-16.72h63.07v-11.79h-63.07V-16.72z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M352.66,19.12h-36.35c-0.87,0-1.57-0.7-1.57-1.57V-3.87c0-0.87,0.7-1.57,1.57-1.57h36.35
			c0.87,0,1.57,0.7,1.57,1.57v21.42C354.23,18.41,353.53,19.12,352.66,19.12z M317.88,15.97h33.2V-2.3h-33.2V15.97z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M374.36,27.86c-0.87,0-1.57-0.7-1.57-1.57V5.16c0-0.87,0.7-1.57,1.57-1.57s1.57,0.7,1.57,1.57v21.13
			C375.93,27.16,375.23,27.86,374.36,27.86z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M383.09,27.86c-0.87,0-1.57-0.7-1.57-1.57V5.16c0-0.87,0.7-1.57,1.57-1.57s1.57,0.7,1.57,1.57v21.13
			C384.66,27.16,383.96,27.86,383.09,27.86z"
						/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<rect x="406.26" y="131.18" className="st0" width="3.71" height="86.82" />
							</g>
							<g>
								<rect x="478.72" y="131.18" className="st0" width="3.71" height="86.82" />
							</g>
							<g>
								<path
									className="st0"
									d="M516.83,234.36c-0.23,0-0.47-0.04-0.69-0.13l-35.58-14.26l-35.5,14.26c-0.44,0.18-0.94,0.18-1.38,0
					l-35.58-14.26l-35.5,14.26c-0.57,0.23-1.22,0.16-1.73-0.18c-0.51-0.34-0.81-0.92-0.81-1.54V131.05c0-0.76,0.46-1.44,1.17-1.72
					l31.52-12.61c0.95-0.38,2.02,0.08,2.41,1.03c0.38,0.95-0.08,2.03-1.03,2.41l-30.36,12.15v97.46l33.65-13.52
					c0.44-0.18,0.94-0.18,1.38,0l35.58,14.26l35.5-14.26c0.44-0.18,0.94-0.18,1.38,0l33.73,13.52V132.3l-30.43-12.14
					c-0.95-0.38-1.41-1.46-1.04-2.41c0.38-0.95,1.47-1.41,2.41-1.04l31.6,12.61c0.7,0.28,1.17,0.96,1.17,1.72V232.5
					c0,0.62-0.3,1.19-0.81,1.54C517.56,234.25,517.19,234.36,516.83,234.36z"
								/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path
								className="st0"
								d="M444.37,186.5c-3.34-0.12-6.57-1.67-8.49-4.48c-14.14-20.76-37.86-58.91-37.86-81.21
				c0-30.66,20.78-55.6,46.33-55.6c25.54,0,46.32,24.94,46.32,55.6c0,22.29-23.72,60.46-37.86,81.22
				C450.9,184.84,447.75,186.5,444.37,186.5z M444.35,48.93c-23.5,0-42.62,23.28-42.62,51.89c0,22.1,26.03,62.7,37.22,79.13
				c1.22,1.79,3.24,2.85,5.4,2.85c1.98,0.03,4.18-1.06,5.39-2.85c11.19-16.44,37.22-57.05,37.22-79.14
				C486.96,72.2,467.84,48.93,444.35,48.93z M444.35,117.74c-14.29,0-25.91-11.62-25.91-25.91s11.62-25.91,25.91-25.91
				c14.28,0,25.9,11.62,25.9,25.91S458.63,117.74,444.35,117.74z M444.35,69.63c-12.24,0-22.2,9.96-22.2,22.2s9.96,22.2,22.2,22.2
				c12.24,0,22.19-9.96,22.19-22.2S456.58,69.63,444.35,69.63z"
							/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path
							className="st0"
							d="M-410,69.08h-25c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h25c0.14,0,0.25,0.11,0.25,0.25
			S-409.86,69.08-410,69.08z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-415.23,91.83h-14.52c-0.61,0-1.11-0.46-1.16-1.06l-1.93-21.93l0.5-0.04l1.93,21.93
			c0.03,0.34,0.31,0.6,0.66,0.6h14.52c0.35,0,0.63-0.26,0.66-0.6l1.93-21.93l0.5,0.04l-1.93,21.93
			C-414.13,91.38-414.62,91.83-415.23,91.83z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-431.83,77.84h-0.81c-1.25,0-2.27-1.02-2.27-2.27v-2.22c0-0.6,0.49-1.09,1.09-1.09h1.55v0.5h-1.55
			c-0.33,0-0.59,0.26-0.59,0.59v2.22c0,0.98,0.79,1.77,1.77,1.77h0.81V77.84z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M-412.34,77.84h-0.82v-0.5h0.82c0.97,0,1.76-0.79,1.76-1.77v-2.22c0-0.32-0.26-0.59-0.58-0.59h-1.55v-0.5
			h1.55c0.6,0,1.08,0.49,1.08,1.09v2.22C-410.08,76.83-411.1,77.84-412.34,77.84z"
						/>
					</g>
					<g>
						<rect x="-432.53" y="70.26" className="st0" width="20.08" height="0.5" />
					</g>
					<g>
						<rect x="-431.2" y="85.56" className="st0" width="17.41" height="0.5" />
					</g>
					<g>
						<path
							className="st0"
							d="M-419.26,68.72h-0.5c0-1-0.81-1.81-1.82-1.81h-1.84c-1,0-1.82,0.81-1.82,1.81h-0.5
			c0-1.28,1.04-2.31,2.32-2.31h1.84C-420.3,66.41-419.26,67.45-419.26,68.72z"
						/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-378.1,89.05h-2.5c-0.14,0-0.25-0.11-0.25-0.25v-1.58c-0.19-0.06-0.38-0.14-0.56-0.23l-1.11,1.11
				c-0.09,0.09-0.26,0.09-0.35,0l-1.77-1.77c-0.1-0.1-0.1-0.26,0-0.35l1.11-1.11c-0.09-0.18-0.16-0.36-0.23-0.55h-1.58
				c-0.14,0-0.25-0.11-0.25-0.25v-2.5c0-0.14,0.11-0.25,0.25-0.25h1.58c0.07-0.19,0.14-0.38,0.23-0.55l-1.11-1.12
				c-0.1-0.1-0.1-0.26,0-0.35l1.77-1.77c0.1-0.1,0.26-0.1,0.35,0l1.11,1.11c0.18-0.09,0.36-0.16,0.56-0.23v-1.58
				c0-0.14,0.11-0.25,0.25-0.25h2.5c0.14,0,0.25,0.11,0.25,0.25v1.58c0.19,0.06,0.38,0.14,0.56,0.23l1.11-1.11
				c0.09-0.09,0.26-0.09,0.35,0l1.77,1.77c0.1,0.1,0.1,0.26,0,0.35l-1.11,1.11c0.09,0.18,0.16,0.36,0.23,0.55h1.58
				c0.14,0,0.25,0.11,0.25,0.25v2.5c0,0.14-0.11,0.25-0.25,0.25h-1.58c-0.07,0.19-0.14,0.38-0.23,0.55l1.11,1.11
				c0.1,0.1,0.1,0.26,0,0.35l-1.77,1.77c-0.1,0.1-0.26,0.1-0.35,0l-1.11-1.11c-0.18,0.09-0.36,0.16-0.56,0.23v1.58
				C-377.85,88.94-377.96,89.05-378.1,89.05z M-380.35,88.55h2v-1.51c0-0.11,0.07-0.21,0.18-0.24c0.28-0.08,0.55-0.19,0.81-0.33
				c0.1-0.05,0.22-0.04,0.3,0.04l1.07,1.07l1.42-1.41l-1.07-1.07c-0.08-0.08-0.1-0.2-0.04-0.3c0.13-0.24,0.24-0.51,0.33-0.8
				c0.03-0.11,0.13-0.18,0.24-0.18h1.51v-2h-1.51c-0.11,0-0.21-0.07-0.24-0.18c-0.08-0.29-0.2-0.56-0.33-0.8
				c-0.05-0.1-0.04-0.22,0.04-0.3l1.07-1.07l-1.41-1.41l-1.07,1.07c-0.08,0.08-0.2,0.1-0.3,0.04c-0.25-0.14-0.52-0.25-0.8-0.33
				c-0.11-0.03-0.18-0.13-0.18-0.24v-1.51h-2v1.51c0,0.11-0.07,0.21-0.18,0.24c-0.28,0.08-0.55,0.2-0.8,0.33
				c-0.1,0.05-0.22,0.04-0.3-0.04l-1.07-1.07l-1.41,1.41l1.07,1.07c0.08,0.08,0.1,0.2,0.04,0.3c-0.13,0.24-0.24,0.51-0.33,0.8
				c-0.03,0.11-0.13,0.18-0.24,0.18h-1.51v2h1.51c0.11,0,0.21,0.07,0.24,0.18c0.09,0.29,0.2,0.56,0.33,0.8
				c0.05,0.1,0.04,0.22-0.04,0.3l-1.07,1.07l1.41,1.41l1.07-1.07c0.08-0.08,0.2-0.1,0.3-0.04c0.25,0.14,0.52,0.25,0.8,0.33
				c0.11,0.03,0.18,0.13,0.18,0.24V88.55z M-379.35,84.9c-1.15,0-2.08-0.93-2.08-2.08s0.93-2.08,2.08-2.08
				c1.15,0,2.08,0.93,2.08,2.08S-378.2,84.9-379.35,84.9z M-379.35,81.24c-0.87,0-1.58,0.71-1.58,1.58s0.71,1.58,1.58,1.58
				c0.87,0,1.58-0.71,1.58-1.58S-378.48,81.24-379.35,81.24z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path
									className="st0"
									d="M-366.59,97.69h-2.2c-0.38,0-0.69-0.31-0.69-0.69v-1.29c-0.03-0.1-0.38-0.4-0.56-0.48
					c-0.08-0.04-0.27,0-0.37,0.1l-0.91,0.91c-0.26,0.26-0.72,0.26-0.98,0l-1.55-1.55c-0.13-0.13-0.2-0.3-0.2-0.49
					c0-0.18,0.07-0.36,0.2-0.49l0.91-0.91c0.05-0.09,0.02-0.54-0.05-0.74c-0.03-0.08-0.18-0.19-0.33-0.19h-1.29
					c-0.38,0-0.69-0.31-0.69-0.69v-2.2c0-0.38,0.31-0.69,0.69-0.69h1.29c0.1-0.03,0.4-0.38,0.48-0.56c0.04-0.08,0-0.26-0.1-0.37
					l-0.91-0.91c-0.13-0.13-0.2-0.3-0.2-0.49s0.07-0.36,0.2-0.49l1.55-1.55c0.27-0.27,0.71-0.27,0.97,0l0.91,0.91
					c0.09,0.05,0.55,0.01,0.74-0.05c0.09-0.03,0.19-0.18,0.19-0.33v-1.29c0-0.38,0.31-0.69,0.69-0.69h2.2
					c0.38,0,0.69,0.31,0.69,0.69v1.29c0.03,0.1,0.38,0.39,0.57,0.48c0.08,0.04,0.26,0,0.37-0.1l0.91-0.91
					c0.27-0.27,0.71-0.27,0.97,0l1.55,1.55c0.13,0.13,0.2,0.3,0.2,0.49s-0.07,0.36-0.2,0.49l-0.91,0.91
					c-0.05,0.09-0.02,0.54,0.05,0.74c0.03,0.09,0.18,0.19,0.33,0.19h1.29c0.38,0,0.69,0.31,0.69,0.69v2.2
					c0,0.38-0.31,0.69-0.69,0.69h-1.29c-0.1,0.03-0.4,0.38-0.48,0.56c-0.04,0.08,0,0.27,0.1,0.37l0.91,0.91
					c0.13,0.13,0.2,0.3,0.2,0.49c0,0.19-0.07,0.36-0.2,0.49l-1.55,1.55c-0.27,0.27-0.7,0.27-0.97,0l-0.91-0.91
					c-0.09-0.05-0.55-0.01-0.74,0.05c-0.09,0.03-0.19,0.18-0.19,0.33V97C-365.9,97.38-366.21,97.69-366.59,97.69z M-370.12,94.72
					c0.11,0,0.21,0.02,0.3,0.06c0.01,0,0.85,0.46,0.85,0.94V97c0,0.1,0.09,0.19,0.19,0.19h2.2c0.1,0,0.19-0.09,0.19-0.19v-1.29
					c0-0.35,0.23-0.7,0.53-0.8c0.13-0.05,0.95-0.24,1.26,0.06l0.91,0.91c0.07,0.07,0.19,0.07,0.27,0l1.55-1.55
					c0.04-0.04,0.05-0.08,0.05-0.13s-0.02-0.1-0.06-0.13l-0.91-0.91c-0.25-0.25-0.33-0.65-0.2-0.94c0.06-0.13,0.5-0.85,0.94-0.85
					h1.29c0.1,0,0.19-0.09,0.19-0.19v-2.2c0-0.1-0.08-0.19-0.19-0.19h-1.29c-0.35,0-0.7-0.23-0.8-0.53
					c-0.05-0.14-0.24-0.96,0.06-1.26l0.91-0.91c0.04-0.04,0.05-0.08,0.05-0.13c0-0.05-0.02-0.1-0.05-0.13l-1.55-1.55
					c-0.07-0.07-0.19-0.07-0.27,0l-0.91,0.91c-0.25,0.25-0.65,0.33-0.93,0.2c-0.13-0.06-0.85-0.51-0.85-0.94v-1.29
					c0-0.1-0.09-0.19-0.19-0.19h-2.2c-0.1,0-0.19,0.08-0.19,0.19v1.29c0,0.35-0.23,0.7-0.53,0.8c-0.13,0.05-0.95,0.24-1.26-0.06
					l-0.91-0.91c-0.07-0.07-0.19-0.07-0.27,0l-1.55,1.55c-0.04,0.04-0.05,0.08-0.05,0.13c0,0.05,0.02,0.1,0.06,0.14l0.91,0.91
					c0.25,0.25,0.33,0.65,0.2,0.94c0,0.01-0.46,0.85-0.93,0.85h-1.29c-0.1,0-0.19,0.09-0.19,0.19v2.2c0,0.1,0.09,0.19,0.19,0.19
					h1.29c0.35,0,0.69,0.23,0.8,0.52c0,0.01,0.27,0.93-0.06,1.26l-0.91,0.91c-0.04,0.04-0.05,0.08-0.05,0.13s0.02,0.1,0.05,0.13
					l1.55,1.55c0.07,0.07,0.2,0.07,0.27,0l0.91-0.91C-370.59,94.81-370.35,94.72-370.12,94.72z M-367.69,93.47
					c-1.86,0-3.38-1.52-3.38-3.38c0-1.86,1.52-3.38,3.38-3.38s3.38,1.52,3.38,3.38C-364.31,91.95-365.82,93.47-367.69,93.47z
					 M-367.69,87.21c-1.59,0-2.88,1.29-2.88,2.88c0,1.59,1.29,2.88,2.88,2.88s2.88-1.29,2.88-2.88
					C-364.81,88.5-366.1,87.21-367.69,87.21z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-367.69,92.59c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5s2.5,1.12,2.5,2.5
					C-365.19,91.47-366.31,92.59-367.69,92.59z M-367.69,88.09c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2
					C-365.69,88.98-366.58,88.09-367.69,88.09z"
								/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-237.08,71.77c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73v-1.62c0-1.27,1.94-1.27,1.94,0
				v1.62C-236.11,71.44-236.6,71.77-237.08,71.77z M-237.08,68.71c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47v-1.62C-236.61,68.84-236.87,68.71-237.08,68.71z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-225.59,71.79c-0.25,0-0.49-0.09-0.66-0.24c-0.14-0.13-0.31-0.35-0.31-0.73V69.2c0-1.28,1.95-1.28,1.95,0
				v1.62C-224.62,71.46-225.11,71.79-225.59,71.79z M-225.59,68.73c-0.22,0-0.47,0.12-0.47,0.47v1.62c0,0.35,0.25,0.47,0.47,0.47
				s0.47-0.12,0.47-0.47V69.2C-225.12,68.85-225.38,68.73-225.59,68.73z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-220.38,75.82h-21.86c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h21.86c0.14,0,0.25,0.11,0.25,0.25
				S-220.24,75.82-220.38,75.82z"
							/>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-219.96,90.57h-17.2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h16.95v-4.64
					c0-0.14,0.11-0.25,0.25-0.25s0.25,0.11,0.25,0.25v4.89C-219.71,90.46-219.82,90.57-219.96,90.57z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-231.03,79.81h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-230.78,79.7-230.89,79.81-231.03,79.81z M-232.83,79.31h1.56v-1.55h-1.56V79.31z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-227.27,79.81h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-227.02,79.7-227.13,79.81-227.27,79.81z M-229.08,79.31h1.56v-1.55h-1.56V79.31z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-223.51,79.81h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-223.26,79.7-223.38,79.81-223.51,79.81z M-225.32,79.31h1.56v-1.55h-1.56V79.31z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-230.75,86.61h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-230.5,86.5-230.61,86.61-230.75,86.61z M-232.56,86.11h1.55v-1.55h-1.55V86.11z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-226.99,86.61h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-226.74,86.5-226.85,86.61-226.99,86.61z M-228.8,86.11h1.56v-1.55h-1.56V86.11z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-234.66,83.37h-2.05c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.05
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-234.41,83.26-234.53,83.37-234.66,83.37z M-236.47,82.87h1.55v-1.55h-1.55V82.87z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-230.95,83.37H-233c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-230.7,83.26-230.81,83.37-230.95,83.37z M-232.75,82.87h1.56v-1.55h-1.56V82.87z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-227.23,83.37h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-226.98,83.26-227.09,83.37-227.23,83.37z M-229.04,82.87h1.56v-1.55h-1.56V82.87z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-223.51,83.37h-2.06c-0.14,0-0.25-0.11-0.25-0.25v-2.05c0-0.14,0.11-0.25,0.25-0.25h2.06
					c0.14,0,0.25,0.11,0.25,0.25v2.05C-223.26,83.26-223.38,83.37-223.51,83.37z M-225.32,82.87h1.56v-1.55h-1.56V82.87z"
								/>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-224.86,89.35h-11.11c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h10.86v-3.59c0-0.49,0.4-0.9,0.9-0.9
				h3.7V72c0-0.75-0.61-1.36-1.36-1.36h-1.99l0.01,1c0,2.26-3.44,2.26-3.44,0v-1.02l-8.1,0.02l0.02,1c0,1.13-0.86,1.72-1.72,1.72
				c-0.45,0-0.86-0.15-1.18-0.43c-0.35-0.32-0.54-0.76-0.54-1.29v-1.02l-1.98,0.02c-0.75,0-1.36,0.61-1.36,1.36v9.59
				c0,0.14-0.11,0.25-0.25,0.25s-0.25-0.11-0.25-0.25V72c0-1.02,0.83-1.86,1.86-1.86h1.99c0.28,0,0.49,0.21,0.49,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.48-0.48h8.11c0.27,0,0.48,0.21,0.48,0.48v1.02
				c0,0.84,0.63,1.22,1.22,1.22s1.22-0.38,1.22-1.22v-1.02c0-0.27,0.22-0.48,0.49-0.48h1.99c1.02,0,1.86,0.83,1.86,1.86v12.62
				c0,0.14-0.11,0.25-0.25,0.25h-3.95c-0.21,0-0.4,0.18-0.4,0.4v3.84C-224.61,89.24-224.72,89.35-224.86,89.35z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-224.86,89.35c-0.06,0-0.13-0.03-0.18-0.08c-0.1-0.1-0.09-0.26,0-0.35l4.6-4.48c0.1-0.1,0.26-0.09,0.35,0
				c0.1,0.1,0.09,0.26,0,0.35l-4.6,4.48C-224.73,89.33-224.8,89.35-224.86,89.35z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="st0"
										d="M-240.49,91.22c-2.6,0-4.71-2.11-4.71-4.71c0-2.6,2.12-4.71,4.71-4.71c2.6,0,4.71,2.12,4.71,4.71
						C-235.78,89.11-237.9,91.22-240.49,91.22z M-240.49,82.29c-2.32,0-4.21,1.89-4.21,4.21c0,2.32,1.89,4.21,4.21,4.21
						c2.32,0,4.21-1.89,4.21-4.21C-236.28,84.19-238.17,82.29-240.49,82.29z"
									/>
								</g>
							</g>
						</g>
						<g>
							<path
								className="st0"
								d="M-241.28,88.5c-0.07,0-0.13-0.03-0.18-0.07l-1.78-1.75c-0.1-0.1-0.1-0.25,0-0.35s0.25-0.1,0.35,0l1.6,1.57
				l3.19-3.34c0.1-0.1,0.25-0.1,0.35-0.01c0.1,0.1,0.1,0.25,0.01,0.35l-3.36,3.53C-241.15,88.47-241.21,88.5-241.28,88.5
				C-241.28,88.5-241.28,88.5-241.28,88.5z"
							/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path
									className="st0"
									d="M-177.32,93.57h-16.14c-0.93,0-1.68-0.75-1.68-1.68V69.75c0-0.93,0.75-1.68,1.68-1.68h9.66
					c0.07,0,0.13,0.03,0.18,0.07l7.9,7.9c0.05,0.05,0.07,0.11,0.07,0.18v15.67C-175.64,92.81-176.4,93.57-177.32,93.57z
					 M-193.46,68.57c-0.65,0-1.18,0.53-1.18,1.18v22.14c0,0.65,0.53,1.18,1.18,1.18h16.14c0.65,0,1.18-0.53,1.18-1.18V76.33
					l-7.76-7.76H-193.46z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-175.89,76.47h-6.47c-0.93,0-1.68-0.75-1.68-1.68v-6.47c0-0.1,0.06-0.19,0.15-0.23
					c0.09-0.04,0.2-0.02,0.27,0.05l7.9,7.9c0.07,0.07,0.09,0.18,0.05,0.27S-175.79,76.47-175.89,76.47z M-183.55,68.92v5.87
					c0,0.65,0.53,1.18,1.18,1.18h5.87L-183.55,68.92z"
								/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<path
								className="st0"
								d="M-178.8,87.33h-13.18c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h13.18c0.14,0,0.25,0.11,0.25,0.25
				S-178.66,87.33-178.8,87.33z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-178.8,83.28h-12.93c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h12.93c0.14,0,0.25,0.11,0.25,0.25
				S-178.66,83.28-178.8,83.28z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-178.8,79.14h-9.22c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h9.22c0.14,0,0.25,0.11,0.25,0.25
				S-178.67,79.14-178.8,79.14z"
							/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="st0"
										d="M-190.52,81.64c-0.09,0-0.17-0.05-0.21-0.12l-3.31-5.53c-0.05-0.08-0.05-0.17,0-0.25
						c0.04-0.08,0.13-0.13,0.22-0.13h1.53l-0.51-7.28c-0.01-0.14,0.09-0.26,0.23-0.27c0.13-0.01,0.26,0.09,0.27,0.23l0.53,7.55
						c0,0.07-0.02,0.14-0.07,0.19c-0.05,0.05-0.11,0.08-0.18,0.08h-1.35l2.87,4.79l2.87-4.79h-1.37c-0.07,0-0.14-0.03-0.18-0.08
						s-0.07-0.12-0.06-0.19l0.54-6.05c0.01-0.14,0.14-0.24,0.27-0.23c0.14,0.01,0.24,0.13,0.23,0.27l-0.51,5.78h1.53
						c0.09,0,0.17,0.05,0.22,0.13c0.04,0.08,0.04,0.17,0,0.25l-3.31,5.53C-190.35,81.6-190.44,81.64-190.52,81.64z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path
							className="st1"
							d="M131.88,178.6H12.93c-1.08,0-1.96-0.88-1.96-1.96v-75.11c0-1.08,0.88-1.96,1.96-1.96s1.96,0.88,1.96,1.96
			v73.15h115.03v-73.15c0-1.08,0.88-1.96,1.96-1.96c1.08,0,1.96,0.88,1.96,1.96v75.11C133.84,177.72,132.96,178.6,131.88,178.6z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M142.86,105.57c-0.28,0-0.55-0.05-0.81-0.18L72.41,73.64L2.77,105.39c-0.97,0.46-2.14,0.02-2.6-0.97
			c-0.45-0.98-0.02-2.14,0.97-2.6L71.6,69.71c0.52-0.23,1.1-0.23,1.62,0l70.45,32.12c0.99,0.45,1.42,1.62,0.97,2.6
			C144.32,105.14,143.61,105.57,142.86,105.57z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M190.9,178.6h-55.39c-1.08,0-1.96-0.88-1.96-1.96c0-1.08,0.88-1.96,1.96-1.96h53.43v-53.82
			c0-1.08,0.88-1.96,1.96-1.96c1.08,0,1.96,0.88,1.96,1.96v55.78C192.86,177.72,191.98,178.6,190.9,178.6z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M198.04,127.11c-0.37,0-0.74-0.1-1.06-0.31L150.29,96.8l-5.38,3.47c-0.9,0.6-2.11,0.34-2.71-0.58
			c-0.59-0.91-0.32-2.13,0.58-2.71l6.45-4.15c0.66-0.41,1.48-0.41,2.12,0l47.75,30.67c0.91,0.58,1.18,1.79,0.59,2.7
			C199.31,126.78,198.68,127.11,198.04,127.11z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M36.52,89.93c-1.08,0-1.96-0.88-1.96-1.96V54.59c0-1.08,0.88-1.96,1.96-1.96s1.96,0.88,1.96,1.96v33.38
			C38.48,89.05,37.6,89.93,36.52,89.93z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M166.71,104.39c-1.08,0-1.96-0.88-1.96-1.96V54.59c0-1.08,0.88-1.96,1.96-1.96c1.08,0,1.96,0.88,1.96,1.96
			v47.84C168.67,103.51,167.79,104.39,166.71,104.39z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M27.54,59.52c-0.71,0-1.4-0.39-1.75-1.07c-0.49-0.97-0.11-2.14,0.86-2.63l74.1-37.72
			c0.55-0.29,1.23-0.29,1.78,0l74.07,37.72c0.97,0.49,1.35,1.67,0.86,2.63c-0.49,0.97-1.67,1.36-2.63,0.86l-73.18-37.27L28.43,59.3
			C28.15,59.45,27.84,59.52,27.54,59.52z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M111.24,176.68h-3.92v-50.02H37.5v50.02h-3.92V124.7c0-1.08,0.88-1.96,1.96-1.96h73.74
			c1.08,0,1.96,0.88,1.96,1.96V176.68z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M86.69,113.21H58.13c-1.08,0-1.96-0.88-1.96-1.96v-10.16c0-1.08,0.88-1.96,1.96-1.96h28.55
			c1.08,0,1.96,0.88,1.96,1.96v10.16C88.65,112.33,87.77,113.21,86.69,113.21z M60.1,109.29h24.63v-6.23H60.1V109.29z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M150.81,85.62h-29.04c-1.08,0-1.96-0.88-1.96-1.96V62.57c0-1.08,0.88-1.96,1.96-1.96h29.04
			c1.08,0,1.96,0.88,1.96,1.96v21.09C152.77,84.74,151.89,85.62,150.81,85.62z M123.73,81.7h25.12V64.53h-25.12V81.7z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M98.82,67.58H69.78c-1.08,0-1.96-0.88-1.96-1.96V46.18c0-1.08,0.88-1.96,1.96-1.96h29.04
			c1.08,0,1.96,0.88,1.96,1.96v19.45C100.78,66.7,99.9,67.58,98.82,67.58z M71.74,63.66h25.12V48.14H71.74V63.66z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M146.22,153.81h-14.78v-3.92h12.82V125.1h-12.82v-3.92h14.78c1.08,0,1.96,0.88,1.96,1.96v28.71
			C148.18,152.92,147.3,153.81,146.22,153.81z"
						/>
					</g>
					<g>
						<path
							className="st1"
							d="M178.4,176.64h-3.92v-33.56h-15.79v33.56h-3.92v-35.52c0-1.08,0.88-1.96,1.96-1.96h19.72
			c1.08,0,1.96,0.88,1.96,1.96V176.64z"
						/>
					</g>
					<g>
						<rect x="36.73" y="134.57" className="st1" width="71.25" height="3.92" />
					</g>
					<g>
						<rect x="36.73" y="148.41" className="st1" width="71.25" height="3.92" />
					</g>
					<g>
						<rect x="36.73" y="162.23" className="st1" width="71.25" height="3.92" />
					</g>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M-130.14,124.34c-0.04,0-0.09-0.01-0.12-0.03c-0.12-0.07-0.16-0.22-0.09-0.34l4.5-7.8
				c0.16-0.28,0.21-0.61,0.12-0.92c-0.08-0.31-0.28-0.57-0.57-0.74l-14.95-8.63c-0.28-0.16-0.6-0.2-0.91-0.12
				c-0.31,0.08-0.58,0.29-0.74,0.56l-4.5,7.8c-0.07,0.12-0.22,0.16-0.34,0.09c-0.12-0.07-0.16-0.22-0.09-0.34l4.5-7.8
				c0.23-0.4,0.59-0.68,1.04-0.8c0.44-0.12,0.9-0.06,1.29,0.17l14.95,8.63c0.4,0.24,0.68,0.61,0.8,1.04
				c0.12,0.43,0.05,0.91-0.18,1.3l-4.5,7.8C-129.97,124.3-130.06,124.34-130.14,124.34z"
							/>
						</g>
						<g>
							<path
								className="st0"
								d="M-127.6,119.82c-0.04,0-0.09-0.01-0.12-0.03l-2.67-1.54l0.25-0.43l2.46,1.42l1-1.72l-17.01-9.82l-0.99,1.73
				l8.81,5.08l-0.25,0.43l-9.03-5.21c-0.12-0.07-0.16-0.22-0.09-0.34l1.24-2.16c0.03-0.06,0.09-0.1,0.15-0.12
				c0.06-0.02,0.13-0.01,0.19,0.02l17.44,10.07c0.06,0.03,0.1,0.09,0.12,0.15s0.01,0.13-0.03,0.19l-1.25,2.15
				C-127.43,119.78-127.52,119.82-127.6,119.82z"
							/>
						</g>
						<g>
							<rect x="-142.83" y="110.75" transform="matrix(0.5001 -0.866 0.866 0.5001 -168.6663 -67.2469)" className="st0" width="0.5" height="3.41" />
						</g>
						<g>
							<rect x="-144.03" y="112.4" transform="matrix(0.4995 -0.8663 0.8663 0.4995 -170.4418 -67.6676)" className="st0" width="0.5" height="2.53" />
						</g>
					</g>
					<g>
						<g>
							<path
								className="st0"
								d="M-131.73,126.89h-17.25c-0.94,0-1.71-0.77-1.71-1.71v-9.01c0-0.94,0.77-1.71,1.71-1.71h17.25
				c0.94,0,1.71,0.77,1.71,1.71v9.01C-130.02,126.13-130.79,126.89-131.73,126.89z M-148.99,114.97c-0.67,0-1.21,0.54-1.21,1.21
				v9.01c0,0.67,0.54,1.21,1.21,1.21h17.25c0.67,0,1.21-0.54,1.21-1.21v-9.01c0-0.67-0.54-1.21-1.21-1.21H-148.99z"
							/>
						</g>
						<g>
							<rect x="-142.83" y="117.56" className="st0" width="7.65" height="0.5" />
						</g>
						<g>
							<rect x="-147.25" y="121.67" className="st0" width="14.32" height="0.5" />
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-147.06,119.13h-0.97c-0.52,0-0.95-0.43-0.95-0.95v-0.97c0-0.53,0.43-0.95,0.95-0.95h0.97
					c0.52,0,0.95,0.43,0.95,0.95v0.97C-146.11,118.71-146.53,119.13-147.06,119.13z M-148.03,116.76c-0.25,0-0.45,0.2-0.45,0.45
					v0.97c0,0.25,0.2,0.45,0.45,0.45h0.97c0.25,0,0.45-0.2,0.45-0.45v-0.97c0-0.25-0.2-0.45-0.45-0.45H-148.03z"
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									className="st0"
									d="M-136.51,126.09h-2.24c-0.79,0-1.21-0.61-1.21-1.22c0-0.31,0.11-0.61,0.3-0.83
					c0.16-0.18,0.44-0.39,0.91-0.39h2.24v0.5h-2.24c-0.49,0-0.71,0.36-0.71,0.72s0.22,0.72,0.71,0.72h2.24V126.09z"
								/>
							</g>
							<g>
								<path
									className="st0"
									d="M-135.79,126.09h-1.95v-0.5c0.23,0,0.41-0.07,0.54-0.22c0.11-0.13,0.18-0.31,0.18-0.5
					c0-0.36-0.22-0.71-0.72-0.71v-0.5h1.95c0.8,0,1.21,0.61,1.21,1.21c0,0.31-0.11,0.61-0.3,0.83
					C-135.03,125.88-135.32,126.09-135.79,126.09z M-136.74,125.59h0.95c0.23,0,0.41-0.07,0.54-0.22c0.11-0.13,0.18-0.31,0.18-0.5
					c0-0.36-0.22-0.71-0.71-0.71h-0.95c0.14,0.21,0.22,0.46,0.22,0.71C-136.52,125.13-136.6,125.38-136.74,125.59z"
								/>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};
export const SvgRollOffs = ({ style }) => {
	return (
		<div className="svg">
			{/* <style type="text/css">
				.st0{fill:#4E4E4E;}
				.st1{fill:#FFFFFF;}
			</style> */}
			<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 200 200" style={{ enableBackground: 'new 0 0 200 200', ...style }}>
				<g>
					<g>
						<g>
							<path
								className="st0"
								d="M20.3,63.9c53.3,0,106.7,0,160,0c1.6,3.4,3.2,6.9,4.8,10.3c1.5,3.4,3,6.8,4.5,10.2c0,0.3,0,0.7,0,1
				c-2.7,1.6-2.7,4.1-2.7,6.8c0.1,14.4,0.1,28.8,0,43.1c0,1.2-0.1,3-0.8,3.4c-2.6,1.6-2.5,4.2-2.8,6.6c-0.3,2-1.3,2.8-3.3,2.7
				c-2.8-0.1-5.5,0-8.3,0c-4.2,0-4.4-0.2-4.9-4.4c-0.4-3.4-0.4-3.4-3.8-3.4c-41.1,0-82.2,0-123.3,0c-5.2,0-5.3,0-6.2,5.1
				c-0.4,2-1.3,2.8-3.2,2.8c-2.8,0-5.7,0-8.5,0c-3.9,0-4.2-0.3-4.7-4.1c-0.2-1.3-0.4-3.1-1.2-3.8c-1.8-1.4-2.1-3-2.1-5.1
				c0-15.1,0-30.2,0-45.3c0-1-0.4-2.4-1.1-2.8c-1.5-1.1-1.4-2.1-0.9-3.5c2.3-5.6,4.5-11.1,6.8-16.7C18.9,65.7,19.7,64.8,20.3,63.9z
				 M100.3,87.1c-26.7,0-53.3,0-80,0c-2.2,0-2.9,0.6-2.9,2.8c0.1,14.5,0.1,29.1,0,43.6c0,2.1,0.6,3,2.9,3c7.2-0.1,14.4,0,21.6,0
				c10.2-0.1,20.4-0.2,30.6-0.2c10.8,0,21.7,0.2,32.5,0.2c9.2,0,18.4,0,27.6,0c15.9,0,31.8,0,47.6,0c2.1,0,2.7-0.7,2.7-2.8
				c-0.1-14.5,0-28.9,0-43.4c0-3.2,0-3.2-3.1-3.2C153.3,87.1,126.8,87.1,100.3,87.1z M20.7,140.5c-0.1,3.5-0.1,3.6,3.1,3.6
				c1,0,2.1,0,3.1,0c3,0,3.2-0.3,2.9-3.6C26.9,140.5,23.9,140.5,20.7,140.5z M170.6,140.5c-0.3,3.4-0.2,3.6,2.9,3.6
				c1.1,0,2.3,0,3.4,0c3,0,3.3-0.4,2.7-3.6C176.6,140.5,173.6,140.5,170.6,140.5z"
							/>
							<path
								className="st1"
								style={{ fill: '#fff' }}
								d="M100.3,87.1c26.5,0,53,0,79.5,0c3.1,0,3.1,0,3.1,3.2c0,14.5,0,28.9,0,43.4c0,2.1-0.6,2.9-2.7,2.8
				c-15.9-0.1-31.8,0-47.6,0c-9.2,0-18.4,0-27.6,0c-10.8,0-21.7-0.2-32.5-0.2c-10.2,0-20.4,0.2-30.6,0.2c-7.2,0-14.4-0.1-21.6,0
				c-2.3,0-2.9-0.9-2.9-3c0.1-14.5,0.1-29.1,0-43.6c0-2.2,0.7-2.9,2.9-2.8C47,87.1,73.6,87.1,100.3,87.1z"
							/>
							<path className="st1" style={{ fill: '#fff' }} d="M20.7,140.5c3.1,0,6.1,0,9.1,0c0.3,3.3,0.1,3.6-2.9,3.6c-1,0-2.1,0-3.1,0C20.6,144.1,20.6,144,20.7,140.5z" />
							<path
								className="st1"
								style={{ fill: '#fff' }}
								d="M170.6,140.5c3,0,6,0,9,0c0.5,3.2,0.2,3.6-2.7,3.6c-1.1,0-2.3,0-3.4,0C170.5,144.1,170.3,143.8,170.6,140.5z
				"
							/>
						</g>
					</g>
				</g>
				<path
					className="st1"
					style={{ fill: '#fff' }}
					d="M170.7,83.2H30.2c-4.5,0-8.1-3.7-8.1-8.1v0c0-4.5,3.7-8.1,8.1-8.1h140.5c4.5,0,8.1,3.7,8.1,8.1v0
	C178.8,79.5,175.1,83.2,170.7,83.2z"
				/>
				<path
					className="st0"
					d="M173.6,103.1H27.2c-0.9,0-1.6-0.7-1.6-1.6v0c0-0.9,0.7-1.6,1.6-1.6h146.3c0.9,0,1.6,0.7,1.6,1.6v0
	C175.1,102.4,174.4,103.1,173.6,103.1z"
				/>
				<path
					className="st0"
					d="M173.6,112.9H27.2c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h146.3c0.9,0,1.6,0.7,1.6,1.6l0,0
	C175.1,112.2,174.4,112.9,173.6,112.9z"
				/>
				<path
					className="st0"
					d="M173.6,122.8H27.2c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h146.3c0.9,0,1.6,0.7,1.6,1.6l0,0
	C175.1,122.1,174.4,122.8,173.6,122.8z"
				/>
			</svg>
		</div>
	);
};
