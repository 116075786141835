const storeSignUp = (storage) => {
	try {
		localStorage.setItem('signup', JSON.stringify(storage));
	} catch (e) {}
};

export const SignUpReducer = (state, action) => {
	switch (action.type) {
		case 'SET_LEAD':
			const s = {
				...state,
				...action.payload,
			};
			storeSignUp(s);
			return s;
		case 'SET_HAULER':
			storeSignUp({
				...state,
				...action.payload,
			});
			return {
				...state,
				...action.payload,
			};
		case 'SET_ANSWER':
			state.steps[action.payload.stepIndex].answers = action.payload.answer;
			state.stepAnswers[action.payload.stepIndex] = action.payload.answer;
			storeSignUp(state);
			return {
				...state,
			};

		case 'SET_AVAILABLE_SERVICES':
			// storeSignUp(state);
			storeSignUp({
				...state,
				...action.payload,
			});
			return {
				...state,
				...action.payload,
			};
		case 'SET_SELECTED_SERVICES':
			// storeSignUp(state);
			storeSignUp(state);
			return {
				...state,
				...action.payload,
			};
		case 'SET_ALL_SERVICES':
			// storeSignUp(state);
			storeSignUp(state);
			return {
				...state,
				...action.payload,
			};
		case 'STORE_SIGNUP':
			storeSignUp(state);
			return {
				...state,
			};

		default:
			return { ...state };
	}
};
