import React from 'react';

const LoginLayout = (props) => {
	return (
		<div className="vertical-layout vertical-menu-collapsible page-header-dark vertical-modern-menu preload-transitions 1-column login-bg blank-page">
			<div id="content">
				<h3 className="center white-text" style={{ marginTop: '0', fontSize: '1.5rem', paddingTop: '50px' }}>
					Please click on Log In / Sign Up to continue.
				</h3>
				{props.children}
			</div>
		</div>
	);
};

export const LoginLayoutModal = (props) => {
	return (
		<div style={{ height: 'auto' }} className="vertical-layout vertical-menu-collapsible page-header-dark vertical-modern-menu preload-transitions 1-column login-bg blank-page">
			<div id="content">
				<h3 className="center white-text" style={{ fontSize: '1.5rem', paddingTop: '50px' }}>
					These posts are private, you need to log in to read.
				</h3>
				{props.children}
			</div>
		</div>
	);
};

export default LoginLayout;
