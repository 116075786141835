import React from 'react';
import List from './list';
import Edit from './edit';
import './style.css';

export default function Company({ match, ...rest }) {
	if (match.path.indexOf('/add') > -1) {
		return <Edit u={match.params.mapId} {...rest} />;
	}
	if (match.params.mapId) {
		return <Edit u={match.params.mapId} {...rest} />;
	}
	return <List {...rest} />;
}
