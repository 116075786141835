import React, { useEffect, useState } from 'react';
import customerService from '../../../services/customers';
import M from 'materialize-css';
import { useForm } from 'react-hook-form';

const Edit = ({ initialForm, u, auth, uuid, status }) => {
	const { register, handleSubmit, errors, setValue } = useForm({
		mode: 'onChange',
		defaultValues: {
			...initialForm,
		},
	});
	const user = initialForm;

	useEffect(() => {
		window.setTimeout(() => {
			M.AutoInit();
			M.updateTextFields();
		}, 500);
	}, [user]);

	useEffect(() => {
		setValue('status', status === 'Purchased');
	}, [status, setValue]);

	const onSubmit = (values) => {
		values.id = Number(u);
		values.status = values.status ? 'Purchased' : 'Lead';
		values.uuid = uuid;

		new customerService().update(values, auth.user.token).then((json) => {
			if (json) {
				M.toast({ html: 'Customer Saved!', classes: 'green white-text' });
			}
		});
	};

	// const phoneReq = { maxLength: { value: 12, message: 'Too long, 12 characters max' }, pattern: { value: /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, message: 'Invalid phone format, IE 111-222-3333' } };

	return (
		<form id="customerForm" onSubmit={handleSubmit(onSubmit)}>
			<input id="companyid" name="companyid" type="hidden" ref={register} />
			<div className="row">
				<div className="col s12">
					<div className="row">
						<div className="col s12 input-field">
							<input id="fullname" name="fullname" type="text" ref={register({ required: 'Full Name is required!' })} />
							<label htmlFor="fullname">Full Name</label>
							<span className="red-text helper-text">{errors.fullname && errors.fullname.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12">
					<div className="row">
						<div className="col s12 input-field">
							<input id="address" name="address" type="text" ref={register} />
							<label htmlFor="address">Address</label>
							<span className="red-text helper-text">{errors.address && errors.address.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="row">
						<div className="col s12 input-field">
							<input id="city" name="city" type="text" className={`${errors.city ? 'invalid' : 'valid'}`} ref={register} />
							<label htmlFor="city">City</label>
							<span className="red-text helper-text">{errors.city && errors.city.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="row">
						<div className="col s12 input-field">
							<input id="state" name="state" type="text" className={`${errors.state ? 'invalid' : 'valid'}`} ref={register} />
							<label htmlFor="state">State</label>
							<span className="red-text helper-text">{errors.state && errors.state.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="row">
						<div className="col s12 input-field">
							<input id="zip" name="zip" type="text" className={`${errors.zip ? 'invalid' : 'valid'}`} ref={register} />
							<label htmlFor="zip">Zip</label>
							<span className="red-text helper-text">{errors.zip && errors.zip.message}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m4">
					<div className="row">
						<div className="col s12 input-field">
							<input id="homephone" name="homephone" type="text" className={`${errors.homephone ? 'invalid' : 'valid'}`} ref={register} />
							<label htmlFor="homephone">
								Home Phone (<small>123-123-1231</small>)
							</label>
							<span className="red-text helper-text">{errors.homephone && errors.homephone.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="row">
						<div className="col s12 input-field">
							<input id="cellphone" name="cellphone" type="text" className="validate" ref={register} />
							<label htmlFor="cellphone">
								Cell Phone (<small>123-123-1231</small>)
							</label>
							<span className="red-text helper-text">{errors.cellphone && errors.cellphone.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="row">
						<div className="col s12 input-field">
							<input id="workphone" name="workphone" type="text" className="validate" ref={register} />
							<label htmlFor="workphone">
								Work Phone (<small>123-123-1231</small>)
							</label>
							<span className="red-text helper-text">{errors.workphone && errors.workphone.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12">
					<div className="row">
						<div className="col s12 input-field">
							<input id="email" name="email" type="email" className="validate" ref={register} />
							<label htmlFor="email">E-mail</label>
							<span className="red-text helper-text">{errors.email && errors.email.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12">
					<div className="row">
						<div className="col s12 input-field">
							<input id="notes" name="notes" type="text" className="validate" ref={register} />
							<label htmlFor="notes">Notes</label>
							<span className="red-text helper-text">{errors.notes && errors.notes.message}</span>
						</div>
					</div>
				</div>
				<div className="col s12">
					<div className="row col s12 m4 l3 center-align">
						<p>Customer in SnapDesk</p>
						<div className="switch">
							<label>
								NO
								<input type="checkbox" name="insnapdesk" id="insnapdesk" ref={register} />
								<span className="lever"></span>
								YES
							</label>
						</div>
					</div>
					<div className="row col s12 m4 l3 center-align">
						<p>Customer in Ops</p>
						<div className="switch">
							<label>
								NO
								<input type="checkbox" name="inops" id="inops" ref={register} />
								<span className="lever"></span>
								YES
							</label>
						</div>
					</div>
					<div className="row col s12 m4 l3 center-align">
						<p>Is this a Customer</p>
						<div className="switch">
							<label>
								Lead
								<input type="checkbox" name="status" id="status" ref={register} />
								<span className="lever"></span>
								Customer
							</label>
						</div>
					</div>
				</div>
				<div className="col s12 display-flex justify-content-end mt-3">
					{/* <button type="button" onClick={() => alert('send to snap')} className="btn indigo" style={{ marginRight: 'auto' }}>
						Send To SnapDesk
					</button> */}
					<button type="submit" className="btn indigo">
						Save changes
					</button>
					<button type="button" className="btn btn-light">
						Cancel
					</button>
				</div>
			</div>
		</form>
	);
};

export default Edit;
