import config from './config';
import Cookies from 'js-cookie';

export default class Data {
	api(path, method = 'GET', body = null, requiresAuth = false, credentials = null) {
		const url = config.apiBaseUrl + path;

		const options = {
			method,
			headers: {
				'Content-Type': 'application/json',
				// 'Access-Control-Allow-Origin': '*',
			},
		};

		if (body !== null) {
			options.body = JSON.stringify(body);
		}
		if (requiresAuth) {
			if ('username' in credentials && 'password' in credentials) {
				const encodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
				options.headers['Authorization'] = ` Basic ${encodedCredentials}`;
			} else if ('token' in credentials) {
				const encodedCredentials = btoa(`${credentials.token}`);
				options.headers['Authorization'] = ` Bearer ${encodedCredentials}`;
			}
		}

		const response = fetch(url, options);

		try {
			response.then((json) => {
				if (json.statusText === 'Unauthorized') {
					Cookies.remove('authToken');
				}
			});
		} catch (e) {
			console.log(e);
		}
		return response;
	}

	async getMap(map) {
		// API call to retrieve User Data
		return await this.api(`/maps.cfc?method=get&i=${map}`, 'GET')
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async getMaps(token, companyid = 0) {
		return await this.api(`/maps.cfc?method=getMaps&i=${companyid}`, 'GET', null, true, {
			token,
		})
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					return response.json();
				} else {
					return [];
				}
			})
			.catch((err) => console.log({ err }));
	}

	async update(map, token) {
		// API call to retrieve User Data
		return await this.api(`/maps.cfc?method=update`, 'POST', map, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}
}
