import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import './login.scss';

const FormPlain = ({ auth }) => {
	return (
		<div className="home">
			<div className="container">
				<div className="row" style={{ marginTop: '90px' }}>
					<div className="col s10 offset-s1 center titleStyle">
						<h1 className="red-text">Password Reset</h1>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m8 offset-m2 l6 offset-l3 center-align">
						<p>Please enter the information below to reset your account</p>
					</div>
				</div>
			</div>
			<div className="container row">
				<div className="col s12 m8 offset-m2 l6 offset-l3">
					<div className="leaveComment card">
						<div className="card-content">
							<PasswordResetForm auth={auth} />
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<br />
		</div>
	);
};

const PasswordResetForm = ({ auth }) => {
	// const { handleSubmit, register, setError, errors } = useForm();
	const { handleSubmit, register, watch, errors } = useForm();
	const [postErrors, setPostErrors] = useState('');
	const [postSuccess, setPostSuccess] = useState('');
	const [processing, setProcessing] = useState(false);
	// const [password, setPassword] = useState("");
	const { code } = useParams();
	const history = useHistory();
	const password = useRef({});
	password.current = watch('password', '');

	const onSubmit = (values) => {
		if (values.password !== values.confirmPassword) {
			return;
		}

		if (processing) return;
		setProcessing(true);

		values.code = code;

		auth.doPasswordReset(values).then((response) => {
			setProcessing(false);
			console.log({ response });
			if (!response.success) {
				setPostErrors(response.message);
				return false;
			}
			setPostSuccess(response.message);

			setTimeout(() => {
				history.push('/login');
			}, 4000);
			return;
		});
	};

	const validateSchema = {
		password: { required: 'Password is required!', minLength: { value: 8, message: 'Password must have at least 8 characters' } },
		confirmPassword: {
			validate: (value) => value === password.current || 'The passwords do not match',
		},
	};

	useEffect(() => {
		if (postErrors.length > 0) {
			setTimeout(() => setPostErrors(''), 4000);
		}
	}, [postErrors]);

	return (
		<form name="login-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="form-holder">
				<div className="center-align helper-text red-text">{postErrors && postErrors}</div>
				<div className="center-align helper-text green-text">{postSuccess && postSuccess}</div>
				<div className="input-field col s12">
					<input
						type="email"
						ref={register({
							required: 'Username is required!',
							pattern: {
								value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
								message: 'Invalid Email Address',
							},
						})}
						className="input"
						name="username"
						id="username"
						placeholder="email@domain.com"
					/>
					<span style={{ pointerEvents: 'none' }} className="helper-text red-text">
						{errors.username && errors.username.message}
					</span>
				</div>
			</div>
			<div className="input-field col s12">
				<input type="password" ref={register(validateSchema.password)} className="input" name="password" id="password" placeholder="Password" />
				<span style={{ pointerEvents: 'none' }} className="helper-text red-text">
					{errors.password && errors.password.message}
				</span>
			</div>
			<div className="input-field col s12">
				<input type="password" ref={register(validateSchema.confirmPassword)} className="input" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" />
				<span style={{ pointerEvents: 'none' }} className="helper-text red-text">
					{errors.confirmPassword && errors.confirmPassword.message}
				</span>
			</div>
			<p className="center-align">
				<button className="btn submit-btn">Reset</button>
			</p>
		</form>
	);
};

export default FormPlain;
