import React, { createContext, useState, useEffect, useContext } from 'react';
import authService from '../../services/users';

const noop = () => {};

export const AuthContext = createContext({
	user: null,
	login: noop,
	logout: noop,
});

function setWithExpiry(key, value, ttl) {
	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire

	const item = {
		value: value,
		expiry: now.getTime() + ttl * 3600000,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key);
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}

export const Provider = (props) => {
	const [user, setUser] = useState(getWithExpiry('authToken') || null);

	useEffect(() => {
		const currentUser = new authService().getUserFromToken();
		if (currentUser) {
			setUser(currentUser);
		}
	}, []);

	const reload = async () => {
		return await new authService()
			.reloadUser(user.user.id, user.token)
			.then((json) => {
				if (json) {
					setWithExpiry('authToken', json, 6);
					setUser(json);
					return json;
				}
			})
			.catch((e) => console.log({ e }));
	};

	const logout = () => {
		localStorage.removeItem('authToken');
		setUser(null);
	};

	const login = async (username, password) => {
		return await new authService()
			.login(username, password)
			.then((res) => {
				if (res) {
					res.user.username = res.user.username.trim();
					setWithExpiry('authToken', res, 6);
					setUser(res);
					return res;
				}
			})
			.catch((e) => console.log({ e }));
	};

	const initPasswordReset = async (username) => {
		return await new authService().initPasswordReset(username).then((response) => {
			if (response) return response;
			return null;
		});
	};

	const doPasswordReset = async (data) => {
		return await new authService().doPasswordReset(data).then((response) => {
			if (response) return response;
			return null;
		});
	};

	const auth = { user, login, logout, reload, initPasswordReset, doPasswordReset };

	return <AuthContext.Provider value={auth} {...props} />;
};

export const useAuthentication = () => useContext(AuthContext);

// export const WithAuthentication = ({ children }) => children(useAuthentication());

const withAuthentication = (Comp) => (props) => {
	const auth = useAuthentication();
	return <Comp auth={auth} {...props} />;
};

export default withAuthentication;
