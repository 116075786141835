import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { useHistory } from 'react-router-dom';

export default function Table({ columns, data }) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	return (
		<table {...getTableProps()}>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<Tr index={i} row={row} {...row.getRowProps()}>
							{row.cells.map((cell) => {
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
							})}
						</Tr>
					);
				})}
			</tbody>
		</table>
	);
}

function Tr(props) {
	const { row } = props;
	let history = useHistory();

	const onClick = (e) => {
		history.push(`/admin/company/e/${e.row.values.id}`);
	};

	return (
		<tr {...props} onClick={(e) => onClick(props)}>
			{row.cells.map((cell) => {
				return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
			})}
		</tr>
	);
}
