import React, { useContext, useEffect, useRef, useState } from 'react';
import { SignUpContext } from './context/signup';
import ServiceItem from '../../components/ServiceListItem/commercial';
import M from 'materialize-css';
// import TrashCanIcon from '../../containers/hauler/TJ-Can.svg';
import TrashCanIcon from '../../containers/hauler/TJ-CommContainer.svg';
import DateIcon from '../../containers/hauler/TJ-Date.svg';
import { useStyles } from './elementStyles';

import CurrencyFormat from 'react-currency-format';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

const CardAddress = ({ step, stepIndex, goToStep }) => {
	const { availableServices, getStepAnswers, setAvailableServices, setAnswer, stepAnswers, hauler } = useContext(SignUpContext);
	const { primaryColor, primaryTextColor } = hauler.theme;
	const { styles } = useStyles();

	const ApplyService = (service, add, cans) => {
		if (add) {
			setShowService(null);
			setAnswer({ stepIndex, answer: [...choosenServices, { ...service, cans }] });

			M.toast({ html: `${service.name} added` });
		}

		const updatedServices = {
			availableServices: availableServices.reduce((arr, el) => arr.concat([el.id === service.id ? { ...el, requested: add } : { ...el }]), []),
		};

		setAvailableServices(updatedServices);
	};

	// const allServicesRequested = availableServices.reduce((tot, x) => (x.requested === undefined ? tot : tot + 1), 0) === availableServices.length;

	const resetRequestedServices = () => {
		setAvailableServices(
			availableServices.reduce((arr, el) => {
				delete el['requested'];
				return arr.concat([el]);
			}, [])
		);
		setAnswer({ stepIndex, answer: null });
	};

	useEffect(() => {
		// if (requestedServices && requestedServices.length === 0 && availableServices) setShowService(availableServices[0]);
	}, []);

	useEffect(() => {
		if (stepAnswers[stepIndex]) {
		}
	}, [stepAnswers[stepIndex]]);

	const choosenServices = stepAnswers[stepIndex] || [];
	// const serviceOptions = availableServices.filter((el) => {
	// 	return el.requested === undefined;
	// });

	// const allServicesRequested = availableServices.reduce((tot, x) => (x.requested === undefined ? tot : tot + 1), 0) === availableServices.length;

	// const showService = serviceOptions[0];
	const [showService, setShowService] = useState(null);
	const requestedServices = getStepAnswers(stepIndex) || [];

	const removeServiceFromRequested = (i) => {
		setAnswer({ stepIndex, answer: choosenServices.filter((el) => el.id !== i) });
		const updatedService = availableServices.reduce((arr, el) => {
			if (el.id === i) delete el['requested'];
			return arr.concat([el]);
		}, []);
		setAvailableServices(updatedService);
	};
	const editServiceFromRequested = (i) => {
		setAnswer({ stepIndex, answer: choosenServices.filter((el) => el.id !== i) });
		const updatedService = availableServices.reduce((arr, el) => {
			if (el.id === i) delete el['requested'];
			return arr.concat([el]);
		}, []);
		setAvailableServices(updatedService);
	};

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content">
					<span style={styles.title} className="card-title z-depth-1">
						{step.title}
					</span>
					{requestedServices.length === 0 && (
						<div className="valign-wrapper row" style={{ height: '85%', padding: '20px 0' }}>
							{showService ? <ServiceItem theme={hauler.theme} stepIndex={stepIndex} key={0} {...showService} onSelect={(add, cans) => ApplyService(showService, add, cans)} /> : <SelectServiceSlide theme={hauler.theme} {...showService} onChange={setShowService} />}
						</div>
					)}
					{requestedServices.length > 0 && (
						<div>
							<br />
							{!requestedServices && <>You need to select a service to continue.</>}
							{requestedServices && (
								<>
									Please review the service(s) you added <span style={{ whiteSpace: 'nowrap' }}>to your location.</span>
								</>
							)}
							<br />
							{requestedServices &&
								requestedServices.map((service, i) => (
									<div className="row" key={i}>
										<div className="col s12" style={{ border: '1px solid lightgrey', borderRadius: '25px', paddingBottom: '10px' }}>
											<div className="row" style={{ margin: 0 }}>
												<div className="col s12 hide-on-small-only">
													<div className="card fadeLeft" style={{ marginBottom: 0, boxShadow: 'none' }}>
														<div className="card-content left-align" style={{ paddingBottom: 0 }}>
															<span className="right right-align">
																<span className="row" style={{ margin: '0' }}>
																	<button style={{ ...styles.button.enabled, margin: '0 5px', textAlign: 'left' }} onClick={() => editServiceFromRequested(service.id)} className="right btn ">
																		<i className="material-icons left">edit</i>
																		Edit
																	</button>

																	<button onClick={() => removeServiceFromRequested(service.id)} className="push-right left red white-text btn grey darken-2" style={styles.button.enabled}>
																		<i className="material-icons left">delete</i>
																		Remove
																	</button>
																</span>
															</span>
															<div>
																<p className="red-text">{service.name}</p>
																<p>
																	{service.cans.cans} Cans - <CurrencyFormat {...currencyAttr} value={round(service.cans.frequency.price, 2)} /> per month
																	<br />
																	{service.cans.frequency.frequency} Pickup : {service.cans.preferred.join(', ')}
																</p>
															</div>
														</div>
													</div>
												</div>
												<div className="col s12 hide-on-med-and-up">
													<div className="card fadeLeft" style={{ marginBottom: 0, boxShadow: 'none' }}>
														<div className="card-content left-align" style={{ paddingBottom: 0 }}>
															<span className="right-align right">
																<span className="row" style={{ margin: '0' }}>
																	<button style={{ ...styles.button.enabled, margin: '0 5px 10px', textAlign: 'left' }} onClick={() => editServiceFromRequested(service.id)} className="btn ">
																		<i className="material-icons left">edit</i>
																		Edit
																	</button>
																	<br />
																	<button onClick={() => removeServiceFromRequested(service.id)} className="red white-text btn grey darken-2" style={styles.button.enabled}>
																		<i className="material-icons">delete</i>
																	</button>
																</span>
															</span>
															<p className="red-text">{service.name}</p>
															<p>
																{service.cans.cans} Cans
																<br />
																{service.cans.frequency.frequency} Pickup
																<br />
																<CurrencyFormat {...currencyAttr} value={round(service.cans.frequency.price, 2)} /> per month
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					)}
				</div>
				<div className="card-action" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
					<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => goToStep('address', -1)}>
						Previous Step
					</button>
					{requestedServices.length > 0 && (
						<>
							<button style={styles.button.enabled} className="btn btn-small themedButton grey darken-2" onClick={resetRequestedServices}>
								Change
							</button>
						</>
					)}
					{requestedServices.length > 0 ? (
						<>
							<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => goToStep('customerdetails')}>
								Next Step
							</button>
						</>
					) : (
						<button style={styles.button.enabled} className="btn btn-small grey themedButton" disabled>
							Next Step
						</button>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

const SelectServiceSlide = ({ onChange = () => console.log('test'), ...serv }) => {
	const { availableServices, hauler } = useContext(SignUpContext);

	const getImg = (wanted, stage) => {
		if (!wanted) return <img src={DateIcon} style={{ width: 'auto', height: '100%' }} />;

		switch (stage) {
			default:
				return <img src={TrashCanIcon} style={{ width: 'auto', height: '100%' }} />;
		}
	};

	const {
		theme: { primaryColor, primaryTextColor },
	} = hauler;

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: primaryTextColor,
	};

	const s = {
		selection_content: {
			borderRadius: '25px',
			border: `1px solid ${primaryColor}`,
			padding: '10px 20px 20px',
		},
	};

	const { styles } = useStyles();

	const handleServiceSelect = (service) => {
		onChange(service);
	};

	const getMinPrice = (arr) => {
		const allPrices = arr.map((el) => {
			return el.price;
		});
		return arr.length === 0 ? 0 : Math.min(...allPrices);
	};

	// W 8th St Wyoming, Pennsylvania
	return (
		<div className="col s12 m10 offset-m1 l8 offset-l2" style={{ float: 'none' }}>
			<div className="card animate fadeLeft" style={{ maxHeight: 'unset', boxShadow: 'none' }}>
				<div className="card-content" style={{ padding: '0', borderRadius: '2px' }}>
					<div className="valign-wrapper center-align" style={{ maxHeight: '100px', alignContent: 'space-around' }}>
						<span style={{ height: '100px', width: '100%' }}>
							<img src={TrashCanIcon} style={{ width: 'auto', height: '100%' }} />
						</span>
					</div>
				</div>
				<div className="center-align" style={s.selection_content}>
					<div className="center-align">
						{/* <h5 style={{ marginTop: '0', color: primaryColor }}>{serv.name.toUpperCase()}</h5> */}
						<h5 style={{ marginTop: '0', color: primaryColor }}>CHOOSE YOUR SIZE</h5>
						<DropDown style={{ ...styles.button.enabled, width: '100%' }}>
							{availableServices
								.filter((el) => el.prices.length > 0)
								.map((el, i) => (
									<li key={i} onClick={() => handleServiceSelect(el)}>
										<div style={{ padding: '0 19px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', lineHeight: '50px', fontSize: '16px' }}>
											<span>{el.name.toUpperCase()} </span>
											<small style={{ marginLeft: '10px' }}>
												Starting at{' '}
												<span className="green-text">
													<CurrencyFormat {...currencyAttr} value={round(getMinPrice(el.prices), 2)} />
												</span>
											</small>
										</div>
									</li>
								))}
						</DropDown>
						{/* <Select onChange={handleServiceSelect}>
							<option>SELECT YOUR CONTAINER SIZE</option>
							{availableServices.map((el, i) => (
								<option key={i}>{el.name.toUpperCase()}</option>
							))}
						</Select> */}
					</div>
				</div>
			</div>
		</div>
	);
};

const DropDown = ({ children, ...props }) => {
	const DDRef = useRef();

	useEffect(() => {
		if (DDRef.current) {
			M.Dropdown.init(DDRef.current, {
				onOpenStart: () => console.log('open'),
			});
		}
	}, [children, DDRef]);

	return (
		<>
			<button {...props} ref={DDRef} className="dropdown-trigger btn" data-target="dropdown1">
				SELECT YOUR CONTAINER SIZE
			</button>
			<ul id="dropdown1" className="dropdown-content">
				{children}
			</ul>
		</>
	);
};

const Select = ({ children, ...props }) => {
	const selectRef = useRef();

	useEffect(() => {
		if (selectRef.current) M.FormSelect.init(selectRef.current, {});
	}, [children, selectRef]);

	return (
		<select {...props} ref={selectRef}>
			{children}
		</select>
	);
};

export default CardAddress;
