import React, { useEffect } from 'react';
import M from 'materialize-css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import config from '../../services/config';
import { Helmet } from 'react-helmet';

const apiBaseUrl = config.apiBaseUrl;

const ContactForm = () => {
	const { register, handleSubmit, reset } = useForm();

	const onSubmit = (data) => {
		const options = {
			url: `${apiBaseUrl}/vmail.cfc?method=contact`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			data,
		};

		axios(options)
			.then((r) => r.status === 200 && r.data)
			.then((d) => reset())
			.then((d) => M.toast({ html: 'Thanks for contacting us, we will get back with you shortly!' }));
	};

	return (
		<form id="customerForm" onSubmit={handleSubmit(onSubmit)}>
			<Helmet>
				<title>Contact : Trash Joes</title>
			</Helmet>
			<div className="row">
				<div className="input-field col s12 m6 l6 ">
					<input id="firstname" name="firstname" type="text" ref={register({ required: 'First Name is required' })} />
					<label htmlFor="firstname">First Name *</label>
				</div>
				<div className="input-field col s12 m6 l6 ">
					<input id="lastname" name="lastname" type="text" ref={register({ required: 'Last Name is required' })} />
					<label htmlFor="lastname">Last Name *</label>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12 m6 l6 ">
					<input id="phone" name="phone" type="text" ref={register} />
					<label htmlFor="phone">Phone Number</label>
				</div>
				<div className="col s12 m6 l6 input-field">
					<input id="email" name="email" type="text" ref={register({ required: 'Email is required' })} />
					<label htmlFor="email">Your Email *</label>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12 l12 input-field">
					<textarea id="comment" name="comment" className="materialize-textarea" ref={register({ required: 'Message is required' })}></textarea>
					<label htmlFor="comment">Your Comment *</label>
				</div>
			</div>
			<p style={{ textAlign: 'center', padding: '0 0 25px 0' }}>
				<button type="submit" name="saveComment" className="btn">
					SEND MESSAGE
				</button>
			</p>
		</form>
	);
};

const Contact = () => {
	const gradient = {
		background: 'white',
		// background: 'rgba(78, 78, 78, 1.0)',
		// background: '-webkit-linear-gradient(bottom right, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
		// background: '-moz-linear-gradient(bottom right, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
		// background: 'linear-gradient(to top left, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
	};
	return (
		<div className="home">
			<div className="container">
				<div className="row" style={{ marginTop: '90px' }}>
					<div className="col s10 offset-s1 center titleStyle">
						<h1 className="red-text">Contact</h1>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m8 offset-m2 l6 offset-l3">
						<p>Contact us with any comments/questions, one of our associates will get back to you shortly.</p>
					</div>
				</div>
			</div>
			<div className="container row">
				<div className="col s12 m8 offset-m2 l6 offset-l3">
					<div className="leaveComment card">
						<div className="card-content" style={{ paddingBottom: '0' }}>
							<ContactForm />
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<br />
		</div>
	);
};

export default Contact;
