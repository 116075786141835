import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navlinks({ closeMobileNav, ...rest }) {
	const { pathname } = useLocation();

	return (
		<React.Fragment>
			<li>
				<Link className={`hvr-underline-reveal111 ${pathname.toLowerCase() === '/why-joe' && 'active'}`} to="/Why-Joe" onClick={() => closeMobileNav()}>
					WHY JOE
				</Link>
			</li>
			<li>
				<Link className={`hvr-underline-reveal111 ${pathname.toLowerCase() === '/services' && 'active'}`} to="/Services" onClick={() => closeMobileNav()}>
					SERVICES
				</Link>
			</li>
			<li>
				<Link className={`hvr-underline-reveal111 ${pathname.toLowerCase() === '/resources' && 'active'}`} to="/Resources" onClick={() => closeMobileNav()}>
					RESOURCES
				</Link>
			</li>
			<li>
				<Link className={`hvr-underline-reveal111 ${pathname.toLowerCase() === '/contact' && 'active'}`} to="/Contact" onClick={() => closeMobileNav()}>
					CONTACT
				</Link>
			</li>
		</React.Fragment>
	);
}

export default React.memo(Navlinks, (prev, next) => prev === next);
