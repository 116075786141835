import React, { useEffect, useState, useRef } from 'react';
import priceService from '../../../services/prices';
import useMounted from '../../../hooks/useMounted';
import M from 'materialize-css';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/Loader';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Select, useCompanies } from '../../../hooks/useCompanies';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

const CustomSelect = ({ children, ...props }) => {
	const selectRef = useRef();

	useEffect(() => {
		if (selectRef.current) M.FormSelect.init(selectRef.current, {});
	}, [children, selectRef]);

	return (
		<select {...props} ref={selectRef}>
			{children}
		</select>
	);
};

// button for week selection
const WeekdayButton = ({ children, className, ...props }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', margin: '0 10px' }}>
			<button type="button" className={`btn ${className}`} {...props}>
				{children}
			</button>
		</div>
	);
};

const WeekDisplay = ({ hide = true, enabled, handleEnabledChange }) => {
	const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	const addOrRemove = (arr, item) => (arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]);

	if (hide) return null;

	const doChange = (x) => {
		handleEnabledChange(addOrRemove(enabled, x));
	};

	return (
		<tr>
			<td style={{ marginTop: 0 }} colSpan="4">
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
					{weekdays.map((x, i) => (
						<WeekdayButton key={uuidv4()} onClick={() => doChange(x)} className={enabled.includes(x) ? 'green' : 'grey'}>
							{x}
						</WeekdayButton>
					))}
				</div>
			</td>
		</tr>
	);
};

const WeekDisplayNoTable = ({ hide = true, enabled, handleEnabledChange }) => {
	const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	const addOrRemove = (arr, item) => (arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]);

	if (hide) return null;

	const doChange = (x) => {
		handleEnabledChange(addOrRemove(enabled, x));
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
			{weekdays.map((x, i) => (
				<WeekdayButton key={uuidv4()} onClick={() => doChange(x)} className={enabled.includes(x) ? 'green' : 'grey'}>
					{x}
				</WeekdayButton>
			))}
		</div>
	);
};

const PriceRow = ({ data, onSetChange, onSetRemove }) => {
	// const [cans, setCans] = useState(data.cans);
	// const [frequency, setFrequency] = useState(data.frequency);
	// const [price, setPrice] = useState(data.price);
	const [enabled, setEnabled] = useState(Array.isArray(data.enabled) ? data.enabled : []);

	useEffect(() => {
		if (Array.isArray(data.enabled) && data.enabled !== enabled) setEnabled(data.enabled);
	}, [data.enabled]);

	const handleEnabledChange = (arr) => {
		const d = {
			...data,
			enabled: arr,
		};
		setEnabled(d.enabled);
		onSetChange(d);
	};

	const weekProps = {
		enabled,
		handleEnabledChange,
		// hide: !(data.frequency.split('').indexOf('x') > -1),
		hide: false,
	};

	// useEffect(() => {
	// 	const d = {
	// 		...data,
	// 		enabled,
	// 	};

	// 	onSetChange(d);
	// }, [enabled]);

	const handleChange = (e) => {
		const d = {
			...data,
			enabled,
			[e.target.name]: e.target.value,
		};

		onSetChange(d);
	};

	return (
		<>
			<tr style={{ border: 0 }}>
				<td>
					<button className="btn red white-text" type="button" onClick={() => onSetRemove(data.id)}>
						<i className="material-icons">delete</i>
					</button>
				</td>
				<td style={{ minWidth: '80px' }}>
					<input name="cans" type="number" step="1" min="1" onChange={handleChange} value={data.cans} placeholder={`Number of Cans (2)`} />
				</td>
				<td style={{ width: '70%' }}>
					<div>
						<CustomSelect name="frequency" value={data.frequency} onChange={handleChange}>
							<option>Weekly</option>
							<option>2x Weekly</option>
							<option>3x Weekly</option>
							<option>4x Weekly</option>
							<option>5x Weekly</option>
							<option>6x Weekly</option>
							<option>7x Weekly</option>
							<option>Bi-Weekly</option>
							<option>Monthly</option>
						</CustomSelect>
					</div>
				</td>
				<td style={{ width: '30%' }}>
					<div style={{ margin: '0' }} className="input-field col s12">
						<i className="material-icons prefix">attach_money</i>
						<input name="price" onChange={handleChange} type="number" step="0.01" min="0" value={data.price} placeholder="Price (20.00)" />
					</div>
				</td>
			</tr>
			{/* <WeekDisplay {...weekProps} /> */}
		</>
	);
};

/* 
	to save a pricing set you need 
	name
	uid
	array =>
		cans
		frequency
		price
		pickups allowed
*/
const newInitialSetData = () => {
	const newSet = { cans: 0, frequency: 'Weekly', price: 0, uid: uuidv4(), id: uuidv4() };
	return newSet;
};

const initialForm = {
	name: '',
	sets: [newInitialSetData()],
};

const Edit = (props) => {
	const { u, auth } = props;
	const history = useHistory();

	const [companyId, setCompanyId] = useState(auth.user.user.company.id);
	const { token } = auth.user;

	const [companies] = useCompanies({ token });
	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	const { handleSubmit, errors } = useForm({
		mode: 'onChange',
	});
	const [form, setForm] = useState({ ...initialForm, uid: u });

	useEffect(() => {
		const fetch = async () => {
			const prices = await new priceService().getPrice(u, auth.user.token).then((json) => json);

			try {
				const data = {
					name: prices[0].name,
					uid: prices[0].uid,
					enabled: prices[0].enabled,
					sets: prices.reduce(
						(fin, price) => [
							...fin,
							{
								duration: 0,
								...price,
							},
						],
						[]
					),
				};

				setForm({
					...form,
					...data,
				});
				auth.user.user.role === 'admin' && setCompanyId(prices[0].companyid);
			} catch (e) {}
		};

		fetch();
		M.updateTextFields();
	}, [u]);

	useEffect(() => M.updateTextFields(), [form.name]);

	const onSubmit = (values) => {
		const uploadData = {
			companyid: Number(companyId),
			...form,
		};

		new priceService().update(uploadData, auth.user.token).then((json) => {
			if (json) {
				M.toast({ html: 'Price Set Saved!', classes: 'green white-text' });
				history.push('/admin/prices');
			}
		});
	};

	const cover = {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	};

	const popover = {
		position: 'absolute',
		zIndex: '2',
	};

	const handleDelete = () => {
		const doDelete = () => {
			const uploadData = {
				companyid: Number(auth.user.user.company.id),
				...form,
			};

			new priceService().deletePriceSet(uploadData, auth.user.token).then((json) => {
				if (json) {
					M.toast({ html: 'Price Set Deleted!', classes: 'green white-text' });
					history.push('/admin/prices');
				}
			});
		};

		if (window.confirm('Do you really with to delete this item?')) {
			doDelete();
		}
	};

	const onSetChange = (d) => {
		setForm({
			...form,
			sets: form.sets.reduce((res, curr) => {
				return curr.id !== d.id ? [...res, curr] : [...res, d];
			}, []),
		});
	};

	const onSetRemove = (id) => {
		const doDelete = () => {
			try {
				new priceService().deletePriceItem(id, auth.user.token);
			} catch (e) {
				console.log({ e });
			}

			setForm({
				...form,
				sets: form.sets.filter((x) => x.id != id),
			});
		};

		if (window.confirm('Do you really with to delete this item?')) {
			doDelete();
		}
	};

	const onAddSet = () => {
		setForm({
			...form,
			sets: [...form.sets, newInitialSetData()],
		});
	};

	const handleEnabledChange = (arr) => {
		setForm({ ...form, enabled: arr });
	};

	const weekProps = {
		enabled: form.enabled || [],
		handleEnabledChange,
		// hide: !(data.frequency.split('').indexOf('x') > -1),
		hide: false,
	};

	return (
		<React.Fragment>
			{/* <input style={{ display: 'none' }} type="file" onChange={(e) => handleImageUpload(e)} ref={(fi) => setFileInput(fi)} /> */}
			<div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Price Set edit</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<Link to="/admin">Home</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/admin/prices">Prices</Link>
								</li>
								<li className="breadcrumb-item active">Price Set Edit</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<div className="container">
					<div className="section users-edit">
						<div className="card">
							<div className="card-content">
								<ul className="tabs mb-2 row">
									<li className="tab">
										<a className="display-flex align-items-center active" id="account-tab" href="#account">
											{' '}
											<i className="material-icons mr-1">person_outline</i>
											<span>Details</span>{' '}
										</a>
									</li>
								</ul>
								<div className="divider mb-3"></div>
								<div className="row">
									<div className="col s12" id="account">
										<form id="customerForm" onSubmit={handleSubmit(onSubmit)}>
											<div className="row">
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">
															<input value={form.name} id="name" name="name" type="text" onChange={(e) => setForm({ ...form, name: e.target.value })} />
															<label className="active" htmlFor="name">
																Price Set Name
															</label>
															<span className="red-text helper-text">{errors.name && errors.name.message}</span>
														</div>
													</div>
												</div>
												<div className="col s12 m6">
													<div className="row">
														<div className="col s12 input-field">{auth.user.user.role === 'admin' && <Select label="Select Company Owner" firstOption="Select Company" onChange={(e) => setCompanyId(e.target.value)} options={adminCompanySelect} value={companyId} />}</div>
													</div>
												</div>
												<div className="col s12" style={{ marginBottom: '20px' }}>
													<WeekDisplayNoTable {...weekProps} />
												</div>

												<div className="col s12">
													<table className="striped">
														<thead>
															<tr>
																<th>
																	<button type="button" className="btn green" onClick={onAddSet}>
																		<i className="material-icons">add</i>
																	</button>
																</th>
																<th>Cans</th>
																<th>Pickup Frequency</th>
																<th>Price/mo</th>
															</tr>
														</thead>
														<tbody>
															{form.sets.map((set, i) => (
																<PriceRow key={i} data={set} onSetChange={onSetChange} onSetRemove={onSetRemove} />
															))}
														</tbody>
													</table>
												</div>

												<div className="col s12 display-flex justify-content-end mt-3">
													<Link to="/admin/prices" style={{ marginRight: 'auto' }} className="btn btn-light">
														Cancel
													</Link>
													<button type="submit" className="btn indigo">
														Save changes
													</button>
													<button type="button" onClick={handleDelete} className="btn red white-text confirmDelete">
														Delete
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-overlay"></div>
			</div>
		</React.Fragment>
	);
};

export default Edit;
