import React, { useEffect, useState } from 'react';
// import './style.scss';
import Loading from '../../components/Loader';
import config from '../../services/config';
import Post from './post';
import Axios from 'axios';

const useGetPost = (url) => {
	const [post, setPost] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const run = () => {
			setLoading(true);
			Axios.get(`${config.apiBaseUrl}/posts.cfc?method=getPostByUrl&i=${url}`).then((res) => {
				setPost(res.data);
				setLoading(false);
			});
		};
		run();
	}, [url]);

	return { post, loading };
};

const gradient = {
	background: 'white',
	// background: 'rgba(78, 78, 78, 1.0)',
	// background: '-webkit-linear-gradient(bottom right, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
	// background: '-moz-linear-gradient(bottom right, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
	// background: 'linear-gradient(to top left, rgba(78, 78, 78, 1.0), rgba(127, 124, 119, 1.0))',
};

const PostPage = ({ history, match }) => {
	const { post, loading } = useGetPost(match.params.url);

	if (!post && !loading) history.push('/resources');

	return (
		<div className="home">
			<div className="titleStyle white white-text" style={gradient}>
				<div className="container">
					<div className="row" style={{ margin: 0 }}>
						<div className="col s12 right-align">
							<h1 style={{ padding: '30px 40px 10px 10px' }}>&nbsp;</h1>
						</div>
					</div>
				</div>
			</div>
			<div className="container">{post && !loading ? <Post post={post} /> : <Loading />}</div>
		</div>
	);
};

const BlogContainer = (props) => <PostPage {...props} />;

const BlogContextHolder = (props) => {
	return <BlogContainer {...props} />;
};

export default BlogContextHolder;
