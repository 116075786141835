import React, { useState, useEffect, useContext, useRef } from 'react';
import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';
import { SignUpContext } from './context/signup';
import { useStyles } from './elementStyles';
import MapIcon from '../hauler/TJ-Address.svg';

const mapStyles = {
	// width: '100%',
	height: '300px',
};

const isInside = (point, polygon) => {
	const bbox = (poly) => poly.reduce((b, [x, y]) => ({ miX: Math.min(x, b.miX), maX: Math.max(x, b.maX), miY: Math.min(y, b.miY), maY: Math.max(y, b.maY) }), { miX: poly[0][0], maX: poly[0][0], miY: poly[0][1], maY: poly[0][1] });
	const inBBox = ([x, y], box) => !(x < box.miX || x > box.maX || y < box.miY || y > box.maY);
	const intersect = (xi, yi, xj, yj, u, v) => yi > v !== yj > v && u < ((xj - xi) * (v - yi)) / (yj - yi) + xi;
	const nex = (i, t) => (i === 0 ? t.length - 1 : i - 1);
	const insideWN = ([x, y], vs) => !!vs.reduce((s, p, i, t) => s + intersect(p[0], p[1], t[nex(i, t)][0], t[nex(i, t)][1], x, y), 0);
	return inBBox([point.lng, point.lat], bbox(polygon)) && insideWN([point.lng, point.lat], polygon);
};

const areEqual = (prevProps, nextProps) => prevProps.address === nextProps.address;

const MapContainer = (props) => {
	const [bounds, setBounds] = useState([]);
	// const [kmlLayer, setKmlLayer] = useState([]);
	const [layers, setLayers] = useState([]);

	useEffect(() => {
		let rendered = true;

		const processSVData = (data, status) => {
			if (status === 'OK') {
				const location = data.location;
				const marker = new props.google.maps.Marker({
					position: location.latLng,
					Map,
					title: location.description,
				});
				panorama.setPano(location.pano);
				panorama.setPov({
					heading: 270,
					pitch: 0,
				});
				panorama.setVisible(true);
				marker.addListener('click', () => {
					const markerPanoID = location.pano;
					// Set the Pano to use the passed panoID.
					panorama.setPano(markerPanoID);
					panorama.setPov({
						heading: 270,
						pitch: 0,
					});
					panorama.setVisible(true);
				});
			} else {
				console.error('Street View data not found for this location.');
			}
		};

		const sv = new props.google.maps.StreetViewService();
		let panorama = new props.google.maps.StreetViewPanorama(document.getElementById('pan'));

		sv.getPanorama({ location: (({ lat, lng }) => ({ lat, lng }))(props.markers[0]), radius: 50 }, processSVData);

		const doBounds = () => {
			if (rendered && props.markers.length > 1) {
				let b = new props.google.maps.LatLngBounds();

				for (var i = 0; i < props.markers.length; i++) {
					b.extend((({ lat, lng }) => ({ lat, lng }))(props.markers[i]));
				}

				layers.map((layer) => {
					return layer.map((point) => {
						return b.extend(point);
					});
				});

				setBounds(b);
			}
		};

		doBounds();

		return () => {
			rendered = false;
		};
	}, [layers, props.markers, props.google.maps.LatLngBounds]);

	useEffect(() => {
		let rendered = true;

		const doLayers = () => {
			if (rendered) {
				setLayers(
					props.layers.reduce((res, layer) => {
						res.push(
							layer.reduce((ret, pnts) => {
								ret.push({
									lat: pnts[1],
									lng: pnts[0],
								});

								return ret;
							}, [])
						);

						return res;
					}, [])
				);
			}
		};

		doLayers();

		return () => {
			rendered = false;
		};
	}, [props.layers]);

	// if (bounds.length === 0) {
	// 	return <div>LOADING . . . </div>;
	// }

	return (
		<React.Fragment>
			<Map
				google={props.google}
				zoom={14}
				streetViewControl={true}
				style={mapStyles}
				initialCenter={(({ lat, lng }) => ({ lat, lng }))(props.markers[0])}
				center={(({ lat, lng }) => ({ lat, lng }))(props.markers[0])}
				// onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
				// bounds={bounds.length > 0 ? bounds : {}}
			>
				{props.markers.map((el, i) => {
					if (el.lat && el.lng) return <Marker key={i} onClick={props.onMarkerClick} {...el} position={(({ lat, lng }) => ({ lat, lng }))(el)} />;
					return <span></span>;
				})}
			</Map>
			<div id="pan"></div>
		</React.Fragment>
	);
};

const StreetView = ({ google, address, place, onMapLoad }) => {
	const { hauler, stepAnswers, allServices, setAnswer, setAvailableServices, setAllServices, getStepAnswers } = useContext(SignUpContext);

	const [streetViewFound, setStreetViewFound] = useState(false);

	const mapDiv = useRef();
	const SVRef = useRef();

	let map;
	let map2;
	let panorama;
	let geocoder;

	const initMapWithPlace = () => {
		if (!place || !place.geometry || !place.geometry.location) return;

		onMapLoad(place.geometry.location);

		map = new google.maps.Map(mapDiv.current);

		var marker = new google.maps.Marker({
			map: map,
			title: place.name,
			position: place.geometry.location,
		});

		const bounds = new google.maps.LatLngBounds();

		if (place.geometry.viewport) {
			// Only geocodes have viewport.
			bounds.union(place.geometry.viewport);
		} else {
			bounds.extend(place.geometry.location);
		}

		map.fitBounds(bounds);

		// const locationLatLong = place.geometry.location;

		const sv = new google.maps.StreetViewService();
		panorama = new google.maps.StreetViewPanorama(SVRef.current);

		// Set up the map.
		map2 = new google.maps.Map(SVRef.current, {
			center: place.geometry.location,
			zoom: 16,
			streetViewControl: false,
			mapTypeId: 'satellite',
		});
		// Set the initial Street View camera to the center of the map
		sv.getPanorama({ location: place.geometry.location, radius: 50 }, processSVData);
		// Look for a nearby Street View panorama when the map is clicked.
		// getPanorama will return the nearest pano when the given
		// radius is 50 meters or less.
		// map2.addListener('click', (event) => {
		// 	sv.getPanorama({ location: event.latLng, radius: 50 }, processSVData);
		// });
	};

	function processSVData(data, status) {
		if (status === 'OK') {
			const location = data.location;
			const marker = new google.maps.Marker({
				position: location.latLng,
				map: map2,
				title: location.description,
			});
			panorama.setPano(location.pano);
			panorama.setPov({
				heading: 270,
				pitch: 0,
			});
			panorama.setVisible(true);
			marker.addListener('click', () => {
				const markerPanoID = location.pano;
				// Set the Pano to use the passed panoID.
				panorama.setPano(markerPanoID);
				panorama.setPov({
					heading: 270,
					pitch: 0,
				});
				panorama.setVisible(true);
			});
			setStreetViewFound(true);
		} else {
			setStreetViewFound(false);
			console.error('Street View data not found for this location.');
		}
	}

	useEffect(() => {
		// initMap();
	}, [address]);

	useEffect(() => {
		initMapWithPlace();
	}, [place]);

	return (
		<div className={`row ${streetViewFound ? 'found' : ''}`}>
			<div id="map" ref={mapDiv} style={{ ...mapStyles }} className={`col ${streetViewFound ? 's6' : 's12'}`}></div>
			<div id="pano" ref={SVRef} style={mapStyles} className={`col ${!streetViewFound ? 'hide' : 's6'}`}></div>
		</div>
	);
};

const Container = (props) => {
	return <StreetView {...props} />;
	// return <MapContainer {...props} />;
};

const SearchBar = ({ google, onAddressSearch }) => {
	const [q, setQ] = useState('');
	const searchBarRef = useRef(null);
	const detailsRef = useRef(null);

	const { styles } = useStyles();

	useEffect(() => {
		let autocomplete;
		let map;
		let panorama;

		const onPlaceChanged = () => {
			var place = autocomplete.getPlace();

			if (!place.geometry) {
				searchBarRef.current.placeholder = 'Enter your address here';
			} else {
				onAddressSearch(place);
			}
		};

		const initAutocomplete = () => {
			autocomplete = new google.maps.places.Autocomplete(searchBarRef.current, {
				types: ['address'],
				componentRestriction: { country: ['US'] },
				// fields: ['geometry', 'formatted_address'],
			});

			autocomplete.addListener('place_changed', onPlaceChanged);
		};

		if (searchBarRef.current && google) {
			initAutocomplete();
		}

		return () => {
			try {
				autocomplete.removeEventListener('place_changed', onPlaceChanged);
			} catch (e) {}
		};
	}, [searchBarRef, google]);

	return (
		<>
			<div className="input-field col s9 m10 l11" style={{ margin: 0 }}>
				<img src={MapIcon} alt="" className="prefix" style={{ marginTop: '-6px' }} />
				<input ref={searchBarRef} defaultValue={q} name="address" placeholder="enter your address here" style={styles.input} />
				<div ref={detailsRef}></div>
			</div>
		</>
	);
};

export const SearchBarWrapper = GoogleApiWrapper((props) => ({
	apiKey: props.apiKey,
	libraries: ['places', 'geometry'],
}))(SearchBar);

export default GoogleApiWrapper((props) => ({
	apiKey: props.apiKey,
	libraries: ['geometry', 'places'],
}))(Container);
// }))(React.memo(Container, areEqual));
