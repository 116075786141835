import React, { useMemo, useEffect, useState } from 'react';
import M from 'materialize-css';
import customerService from '../../../services/companies';
import Table from './table';
import Loading from '../../../components/Loader';
import { useCompanies, Select } from '../../../hooks/useCompanies';
import { useHistory } from 'react-router-dom';

export default function List({ auth }) {
	// const [companies, setCompanies] = useState({ data: [], fetching: false });
	const { token } = auth.user;
	const [companyId, setCompanyId] = useState(auth.user.user.company.id);
	const history = useHistory();

	const [companies, fetching] = useCompanies({ token });
	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	if (auth.user.user.role !== 'admin') history.push('/admin');

	const [filter, setFilter] = useState({});

	const columns = useMemo(
		() => [
			{
				Header: 'Users',
				columns: [
					{
						Header: 'ID',
						accessor: 'id',
					},
					{
						Header: 'Company Name',
						accessor: 'name',
					},
				],
			},
		],
		[]
	);

	useEffect(() => {}, [filter]);

	useEffect(() => {
		M.AutoInit();
	});

	const filteredCompanies = companies.filter((el) => {
		let filtered = false;
		if (filter.query && filter.query.length > 0) {
			filtered = true;
			if (`${el.name} ${el.slug}`.toLowerCase().includes(filter.query.toLowerCase())) return true;
		}

		return !filtered;
	});

	return (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Companies List</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<a href="index.html">Home</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#">Customer</a>
								</li>
								<li className="breadcrumb-item active">Companies List</li>
							</ol>
						</div>
						<div className="col s2 m6 l6">
							<a className="btn waves-effect waves-light breadcrumbs-btn right" href="/admin/company/e/0">
								<i className="material-icons hide-on-med-and-up">add</i>
								<span className="hide-on-small-onl">Add</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<section className="users-list-wrapper section">
					<div className="users-list-filter">
						<div className="card-panel">
							<div className="row">
								<div className="col s12 m6 l3">
									<div className="input-field">
										<label htmlFor="users-list-verified" className="active">
											Search
										</label>
										<input placeholder="Search here" onChange={(e) => setFilter({ ...filter, query: e.target.value })} name="query" id="query" defaultValue={filter.query} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="users-list-table">
						<div className="card">
							<div className="card-content">
								<div className="responsive-table">{!fetching ? <Table columns={columns} data={filteredCompanies} /> : <Loading />}</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
}
