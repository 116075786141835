import M from 'materialize-css';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import useLocalStorage from '../../hooks/useLocalStorage';
import serviceService from '../../services/services';
import { SignUpContext } from './context/signup';
import { useStyles } from './elementStyles';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

const has50ServiceCharge = (id) => {
	var dependable = 35;
	var check = [dependable];

	return check.includes(id);
};

const hasCommercial50ServiceCharge = (id, type) => {
	if (type !== 'Commercial') return false;

	var blueRibbonDisposal = 38;
	var check = [blueRibbonDisposal];

	return check.includes(id);
};

export const OrderTable = () => {
	const { hauler, getStepAnswers, getOrderTotal, getOrderTax } = useContext(SignUpContext);

	const purchaseItemsStep = getStepAnswers('intro') === 'Commercial' ? 'commercialservices' : getStepAnswers('intro') === 'Roll-Offs' ? 'rolloffservices' : 'services';

	const order = {
		user: getStepAnswers('customerdetails'),
		billing: { ...getStepAnswers('billingdetails'), total: getOrderTotal(), tax: getOrderTax() },
		services: getStepAnswers('intro') === 'Roll-Offs' ? [getStepAnswers(purchaseItemsStep)] : getStepAnswers(purchaseItemsStep),
		address: { ...getStepAnswers('address'), type: getStepAnswers('intro') },
		company: hauler,
		purchaseItemsStep,
	};

	return (
		<>
			<p>
				<b>{`${order.address.address.name}`}</b>
			</p>
			<br />
			<ul className="left-align collection" style={{ margin: '0 auto' }}>
				{order.services &&
					order.services.map((el, i) => (
						<li key={i} className="collection-item">
							{getStepAnswers('intro') === 'Roll-Offs' && (
								<>
									<div className="right hide-on-med-and-up">
										<CurrencyFormat {...currencyAttr} value={round(el.cost, 2)} />
									</div>
									<span className="right hide-on-small-only">
										<CurrencyFormat {...currencyAttr} value={round(el.cost, 2)} />
									</span>
									{el.service.name}
									<br />
									Drop Off : {moment(el.range[0].startDate).format('l')} - Pick Up: {moment(el.range[0].endDate).format('l')}
								</>
							)}
							{getStepAnswers('intro') !== 'Roll-Offs' && (
								<>
									<div className="hide-on-med-and-up">{order.purchaseItemsStep === 'rolloffservices' ? <CurrencyFormat {...currencyAttr} value={round(el.prices[0].duration * el.cans.duration, 2)} /> : <CurrencyFormat {...currencyAttr} value={round(el.cans.frequency.price, 2)} />}</div>
									<span className="right hide-on-small-only">{order.purchaseItemsStep === 'rolloffservices' ? <CurrencyFormat {...currencyAttr} value={round(el.prices[0].duration * el.cans.duration, 2)} /> : <CurrencyFormat {...currencyAttr} value={round(el.cans.frequency.price, 2)} />}</span>
									{el.name}
									{order.purchaseItemsStep === 'services' && (
										<>
											{' - '}
											{el.cans.cans} Can{`${el.cans.cans > 1 ? 's' : ''}`} - {el.cans.frequency.frequency} Pickup
										</>
									)}
									{order.purchaseItemsStep === 'commercialservices' && (
										<>
											{' - '}
											{el.cans.cans} Can{`${el.cans.cans > 1 ? 's' : ''}`}
											<br />
											{el.cans.frequency.frequency} Pickup : {el.cans.preferred.join(', ')}
										</>
									)}
								</>
							)}
						</li>
					))}
			</ul>
		</>
	);
};

const Card1 = ({ step, goToStep }) => {
	const { hauler, getStepAnswers, getOrderTotal, getItemTotal, getOrderTax } = useContext(SignUpContext);
	const { primaryColor, primaryTextColor } = hauler.theme;
	const [placingOrder, setPlacingOrder] = useState(false);
	const [orderPlaced, setOrderPlaced] = useState(false);
	// const [closeAttempted, setCloseAttempted] = useState(false);
	const [lead, setLead] = useLocalStorage('lead', false);

	const purchaseItemsStep = getStepAnswers('intro') === 'Commercial' ? 'commercialservices' : getStepAnswers('intro') === 'Roll-Offs' ? 'rolloffservices' : 'services';

	const order = {
		user: getStepAnswers('customerdetails'),
		billing: { ...getStepAnswers('billingdetails'), total: getOrderTotal(), tax: getOrderTax() },
		services: (getStepAnswers('intro') === 'Commercial') === 'Roll-Offs' ? [getStepAnswers(purchaseItemsStep)] : getStepAnswers(purchaseItemsStep),
		address: { ...getStepAnswers('address'), type: getStepAnswers('intro') },
		company: hauler,
		purchaseItemsStep,
		lead,
	};

	const [tosChecked, setTOSChecked] = useState(order.purchaseItemsStep === 'commercialservices' ? false : true);

	useEffect(() => {
		// setTOSChecked(true);
	}, []);

	const doPurchase = () => {
		if (!tosChecked) return;

		setPlacingOrder(true);
		new serviceService()
			.servicePurchase(order)
			.then((resp) => {
				try {
					if (resp.success) {
						// success
						M.toast({ html: 'Order placed!' });
						setOrderPlaced(true);
						setLead(false);
						goToStep('confirmation');
					} else if ('resptext' in resp) {
						M.toast({ html: `There was an issue placing the order : ${resp.resptext}` });
					}
				} catch (e) {
					M.toast({ html: 'There was an issue placing the order' });
				}
			})
			.catch((e) => {
				console.log(e);
				M.toast({ html: 'There was an issue placing the order' });
			})
			.finally((e) => setPlacingOrder(false));
	};

	const secondaryColor = 'blue';

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: primaryTextColor,
	};

	const onClose = () => {
		// setCloseAttempted(true);
		window.opener = null;
		window.open('', '_self');
		window.top.close();
	};

	const notySuccessStyle = {
		backgroundColor: '#BCF5BC',
		border: '1px solid #7cdd77',
		color: 'darkgreen',
	};

	const payCycle = getStepAnswers('billingdetails').payfrequency;

	const handleTOSChange = (e) => {
		order.purchaseItemsStep === 'commercialservices' ? setTOSChecked(e.target.checked) : setTOSChecked(true);
	};

	const tos = () => {
		switch (order.purchaseItemsStep) {
			case 'commercialservices':
				return (
					<span>
						<br />
						<label>
							<input type="checkbox" checked={tosChecked} onChange={handleTOSChange} />
							<span style={{ color: 'black' }}>
								Please check this box to confirm to our{' '}
								<a style={{ padding: '0 5px' }} href={order.company.toscommercial} target="_blank">
									Terms of Service
								</a>
							</span>
						</label>
						.
					</span>
				);
				break;
			case 'services':
				return (
					<span>
						<br />
						By Confirming this order below, you agree to our
						<a style={{ padding: '0 5px' }} href={order.company.tosresidential} target="_blank">
							TOS
						</a>
						.
					</span>
				);
				break;
			case 'rolloffservices':
				return (
					<span>
						<br />
						By Confirming this order below, you agree to our
						<a style={{ padding: '0 5px' }} href={order.company.tosrolloff} target="_blank">
							TOS
						</a>
						.
					</span>
				);
				break;

			default:
				break;
		}
	};

	const { styles } = useStyles();

	const totalCharge = round(order.billing.total + (has50ServiceCharge(hauler.id) ? 50 : 0) + (hasCommercial50ServiceCharge(hauler.id, getStepAnswers('intro')) ? 50 : 0), 2);

	return (
		<React.Fragment>
			<div className="card container">
				<div className="card-content" style={{ maxHeight: '600px', maxHeight: '100%' }}>
					<span style={styles.title} className="card-title z-depth-1">
						{step.title}
					</span>
					<div style={{ height: '85%', padding: '0 0' }}>
						<OrderTable />
						{getStepAnswers('intro') === 'Roll-Offs' && (
							<>
								<ul className="left-align collection" style={{ border: '0', margin: '0 auto' }}>
									{has50ServiceCharge(hauler.id) && (
										<li className="collection-item">
											<>One time setup fee</>
											<span className="right">
												<CurrencyFormat {...currencyAttr} value={50} />
											</span>
										</li>
									)}
									{order.services.service.tax > 0 && (
										<li className="collection-item">
											<>Tax</>
											<span className="right">
												<CurrencyFormat {...currencyAttr} value={round(getItemTotal() * (order.services.service.tax / 100), 2)} />
											</span>
										</li>
									)}
									<li className="collection-item">
										<>Total charge</>
										<span className="right">
											<CurrencyFormat {...currencyAttr} value={totalCharge} />
										</span>
									</li>
								</ul>
							</>
						)}
						{getStepAnswers('intro') !== 'Roll-Offs' && (
							<>
								<ul className="left-align collection" style={{ border: '0', margin: '0 auto' }}>
									{has50ServiceCharge(hauler.id) && (
										<li className="collection-item">
											<>One time setup fee</>
											<span className="right">
												<CurrencyFormat {...currencyAttr} value={50} />
											</span>
										</li>
									)}
									{hasCommercial50ServiceCharge(hauler.id, getStepAnswers('intro')) && (
										<li className="collection-item">
											<>Delivery Fee</>
											<span className="right">
												<CurrencyFormat {...currencyAttr} value={50} />
											</span>
										</li>
									)}
									{order.services[0].tax > 0 && (
										<li className="collection-item">
											<>Tax</>
											<span className="right">
												<CurrencyFormat {...currencyAttr} value={round(getItemTotal() * (order.services[0].tax / 100), 2)} />
											</span>
										</li>
									)}
									<li className="collection-item">
										<>Total charge</>
										{/* {stepAnswers[0] === 'Roll-Offs' ? <>Total charge</> : `Total for ${payCycle} Month ${payCycle > 1 ? 's' : ''}`} */}
										<span className="right">
											<CurrencyFormat {...currencyAttr} value={totalCharge} />
										</span>
									</li>
								</ul>
							</>
						)}
						<hr />
						<div className="row left-align">
							<div className="col s12 m6" style={{ fontSize: '14px' }}>
								<p>
									<b>Customer Details</b>
								</p>
								<p>{`${order.user.firstname} ${order.user.lastname}`}</p>
								<p>{`${order.user.email}`}</p>
								<p>{`${order.user.cellphone}`}</p>
							</div>
							<div className="col s12 m6" style={{ fontSize: '14px' }}>
								<p>
									<b>Billing Details</b>
								</p>
								<p>{`${order.billing.fullname.length === 0 ? `${order.user.firstname} ${order.user.lastname}` : order.billing.fullname}`}</p>
							</div>
						</div>
						<div className="row">
							<div className="s12">
								<p style={{ fontSize: '14px' }}>
									{hauler.id === 10 && (
										<>
											After confirming this order below, you will be directed to our thank you screen
											<br />
											and soon receive a confirmation email to {order.user.email}
										</>
									)}
									{hauler.id !== 10 && <>This is a one time sign-up fee.</>}
									{hauler.id !== 0 && <>{tos()}</>}
									{hauler.id !== 10 && (
										<>
											<br />
											For any further questions, please contact {hauler.name}.
										</>
									)}
								</p>
							</div>
							{orderPlaced && (
								<div style={{ marginTop: '15px', ...notySuccessStyle }} className="s12">
									You order has been placed! Welcome to the family.
									<br />
									You can now close this window at any time.
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="card-action">
					{placingOrder ? (
						<div className="progress">
							<div className="indeterminate"></div>
						</div>
					) : orderPlaced ? (
						<button style={styles.button.enabled} className="btn btn-small themedButton" onClick={() => onClose()}>
							Order Placed! You can now close this window at any time.
						</button>
					) : (
						<>
							<button style={styles.button.previous} className="btn btn-small themedButton" onClick={() => goToStep('billingdetails', -1)}>
								Previous Step
							</button>
							<button style={styles.button.enabled} className="btn btn-small themedButton" disabled={!tosChecked} onClick={doPurchase}>
								Confirm Service Order
							</button>
						</>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Card1;
