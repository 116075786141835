import React, { useState } from 'react';
// import axios, { post } from 'axios';
// import config from '../../../services/config';
import userService from '../../../services/users';
import { api } from '../../../config';

const SimpleReactFileUpload = (props) => {
	const [fi, setFI] = useState();
	const [uid, setUId] = useState(0);

	const { auth } = props;
	const { user } = auth.user;
	const { token } = user;
	// const token = user.token;

	const onFormSubmit = (e) => {
		e.preventDefault();

		return false;
	};

	const onChange = (e) => {
		setUId(user.id);

		document.getElementById('profileImageForm').target = 'fileUploadIframe';

		setTimeout(() => {
			document.getElementById('profileImageForm').submit();
			setUId(0);
		}, 500);
	};

	const onReset = (e) => {
		new userService().resetProfileImage(user.id, token).then((json) => {
			auth.reload();
			window.location.reload(false);
		});
	};

	const getRandomInt = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	return (
		<div className="media display-flex align-items-center mb-2">
			<a className="mr-2" href="#!">
				<img src={user.profileimage ? user.profileimage : `/assets/images/avatar/avatar-${getRandomInt(1, 17)}.png`} alt="users avatar" className="z-depth-4 circle" height="64" width="64" />
			</a>
			<div className="media-body">
				<h5 className="media-heading mt-0">Avatar</h5>
				<div className="user-edit-btns display-flex">
					<button type="button" onClick={() => fi.click()} href="#!" className="btn-small indigo">
						Change
					</button>
					<button onClick={onReset} style={{ marginLeft: '10px' }} className="btn-small pink lighten-2 white-text">
						Reset
					</button>
				</div>
			</div>
			<form target="fileUploadIframe" name="profileImageForm" id="profileImageForm" action={`${api}users.cfc?method=updateProfileImage`} method="POST" onSubmit={onFormSubmit} style={{ display: 'none' }} encType="multipart/form-data">
				<h1>File Upload</h1>
				<input type="file" name="image" onChange={onChange} ref={(fi) => setFI(fi)} />
				<input type="hidden" value={uid} name="uid" />
				<button type="submit">Upload</button>
			</form>
			<iframe title="UploadFrame1" src="" frameBorder="0" id="fsileUploadIframe" name="fileUploadIframe"></iframe>
		</div>
	);
};
export default SimpleReactFileUpload;
