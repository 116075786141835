import React, { useContext, useEffect, useState } from 'react';

// import FrequencyCard from '../../containers/hauler/cardFrequency';
import { isSameDay, isWeekend } from 'date-fns';
import M from 'materialize-css';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import trashCan from '../../containers/hauler/TJ-Can.svg';
import date from '../../containers/hauler/TJ-Date.svg';
import { SignUpContext } from '../../containers/hauler/context/signup';
import { useStyles } from '../../containers/hauler/elementStyles';

import CurrencyFormat from 'react-currency-format';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
	decimalScale: 2,
	fixedDecimalScale: true,
	displayType: 'text',
	thousandSeparator: true,
	prefix: '$',
};

const Item = ({ setSlideTitle = () => null, stepIndex, selected, theme, onSelect = () => console.log('default action'), services, reset, ...serv }) => {
	const { getStepAnswers, hauler } = useContext(SignUpContext);

	const wanted = null;
	const stage = serv.type === 'Roll-Offs' ? 1 : 0;
	const { styles } = useStyles();

	const doReset = () => {
		reset();
	};

	const getImg = (wanted, stage) => {
		if (!wanted) return <img src={date} style={{ width: 'auto', height: '100%' }} />;

		switch (stage) {
			// case 'Residential':
			// 	return <img src={img01} style={{ width: 'auto', height: '100%' }} />;
			// case 'Recycling':
			// 	return <img src={img02} style={{ width: 'auto', height: '100%' }} />;
			// case 'Commercial':
			// 	return <img src={img02} style={{ width: 'auto', height: '100%' }} />;
			default:
				return <img src={trashCan} style={{ width: 'auto', height: '100%' }} />;
		}
	};

	const { primaryColor, primaryTextColor } = theme;

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: primaryTextColor,
	};

	const s = {
		selection_content: {
			borderRadius: '25px',
			border: `1px solid ${primaryColor}`,
			padding: '10px 20px 20px',
		},
	};

	const getInitState = (key, defaultValue) => {
		const answers = getStepAnswers(8);

		if (!answers) return defaultValue;

		if (key === 'notes') return answers.notes;
		if (key === 'step') return 2;
		if (key === 'cost') return answers.cost;
		if (key === 'service') return answers.service;
	};

	const daysNoticeCount = hauler.daysnotice || 0;

	const getMinDate = (days = 0) => {
		const date = new Date();
		days && Number(days) !== 0 && date.setDate(date.getDate() + Number(days));
		return date;
	};

	const minStartDate = (() => {
		let minDate = getMinDate(daysNoticeCount);

		if (hauler.offweekends !== 0 || !isWeekend(minDate)) {
			return minDate;
		}

		while (isWeekend(minDate)) {
			minDate.setDate(minDate.getDate() + 2);
		}

		return minDate;
	})();

	const [state, setState] = useState({
		notes: getInitState('notes', ''),
		step: getInitState('step', 0),
		cost: getInitState('cost', 0),
		service: getInitState('service', {}),
		month: new Date(),
		excluded: hauler.disabledDates,
		range:
			[
				{
					startDate: minStartDate,
					endDate: minStartDate,
					key: 'selection',
				},
			] || getStepAnswers(8).range,
	});

	useEffect(() => {
		const service = getServiceByMostValue();
		setState((s) => ({ ...s, service, cost: service.cost }));
	}, [state.range[0]]);

	const getCost = (service) => {
		if (!state.range[0].startDate || !state.range[0].endDate) return 0;

		const proRate = service.rolloff_prorate;
		const perDay = service.rolloff_perdayrate;
		const calcDays = dateDiff(state.range[0].startDate, state.range[0].endDate) - service.rolloff_proratedays;
		const days = calcDays > -1 ? calcDays : 0;
		return proRate + days * perDay;
	};

	const getServiceByMostValue = () =>
		services.reduce(
			(finalService, serv) => {
				const cost = getCost(serv);

				if (cost > finalService.cost) return { ...serv, cost };
				return finalService;
			},
			{ cost: 0 }
		);

	function getDisabledWeekends(startDate, endDate) {
		if (startDate > endDate) return false;
		var date = startDate;
		var dates = [];

		while (date < endDate) {
			if (isWeekend(date)) dates.push(new Date(date));
			date.setDate(date.getDate() + 1);
		}

		return dates;
	}

	// const disabledDates = getDisabledWeekends(new Date(), addDays(new Date(), 100));

	const handleRangeDateChange = (item) => {
		const { startDate, endDate } = item.selection;

		const [startConflict, endConflict] = hauler.disabledDates.reduce((final, current) => [final[0] ? true : isSameDay(moment(current).toDate(), startDate), final[1] ? true : isSameDay(moment(current).toDate(), endDate)], [false, false]);

		// hauler has disabled weekends
		if ((hauler.offweekends === 0 && (isWeekend(startDate) || isWeekend(endDate))) || startConflict || endConflict) {
			M.toast({ html: `This date is disabled` });
		} else {
			setState((s) => ({ ...s, range: [item.selection] }));
		}
	};

	const handleRangeDatePreviewChange = (item) => {};

	const dayContentRenderer = (a, b) => {
		const [startConflict, endConflict] = hauler.disabledDates.reduce((final, current) => [final[0] ? true : isSameDay(moment(current).toDate(), a), final[1] ? true : isSameDay(moment(current).toDate(), b)], [false, false]);

		const daysNotice = () => {
			// return false to bypace day text change for notice
			return false;
			return moment(a).isBetween(moment().subtract(1, 'd'), moment().add(daysNoticeCount, 'd'));
		};

		if ((hauler.offweekends === 0 && (isWeekend(a) || isWeekend(b))) || startConflict || endConflict || daysNotice()) {
			return <span>N/A</span>;
		}
		return <span>{moment(a).format('D')}</span>;
	};

	const onShownDateChange = (month) => setState((s) => ({ ...s, month }));

	useEffect(() => {
		if (state.step === 0) setSlideTitle('Choose your service dates');
		if (state.step === 1) setSlideTitle('');
		if (state.step === 2) setSlideTitle('Please confirm your order');
		if (state.step === 3) setSlideTitle('');
	}, [state.step]);

	useEffect(() => {
		if (selected) setSlideTitle('');
	}, [selected]);

	return (
		<div className="col s12 l10 offset-l1" style={{ float: 'none' }}>
			<input type="hidden" id="service-slide-step" value={state.step} />
			<div className="card animate fadeLeft" style={{ maxHeight: 'unset', boxShadow: 'none' }}>
				<div className="card-content" style={{ padding: '0', borderRadius: '2px' }}>
					{state.step !== 2 && (
						<div className="valign-wrapper center-align" style={{ maxHeight: '100px', alignContent: 'space-around' }}>
							<span style={{ height: '100px', width: '100%', textAlign: 'right' }}>{getImg(wanted, stage)}</span>
							<span className="black-text" style={{ paddingLeft: '10px', width: '100%', textAlign: 'left' }}>
								{`${services[0].rolloff_size} Yard Roll Off`}
							</span>
						</div>
					)}
				</div>
				<div className="center-align" style={s.selection_content}>
					<div className="center-align">
						{/* user select date range */}
						<div className="row" style={{ margin: 0 }}>
							<div className="col s12 m8 offset-m2" style={{ padding: 0 }}>
								{state.step === 0 && (
									<>
										<div className={hauler.offweekends === 0 ? `disableWeekends` : ''}>
											<DateRange scroll={{ enabled: false }} onShownDateChange={onShownDateChange} dayContentRenderer={dayContentRenderer} onRangeFocusChange={handleRangeDatePreviewChange} minDate={getMinDate(daysNoticeCount)} editableDateInputs={true} onChange={handleRangeDateChange} moveRangeOnFirstSelection={false} ranges={state.range} />
										</div>

										<div className="center-align">
											<button style={styles.button.previous} onClick={doReset} className="btn btn-small themedButton">
												Previous
											</button>
											<button style={styles.button.enabled} onClick={() => setState((s) => ({ ...s, step: 1 }))} className="btn">
												Confirm
											</button>
										</div>
									</>
								)}

								{/* user select notes */}
								{state.step === 1 && (
									<>
										<p>Please tell us from the street view where you want the rolloff box placed and any important notes.</p>
										<Textarea
											onChange={(e) => {
												e.persist();
												setState((s) => ({ ...s, notes: e.target.value }));
											}}
											name="notes"
										/>
										<p>
											<small>For example - when looking at my house from the street, place the rolloff next to the basketball hoop, and keep 10 feet away from the garage door.</small>
										</p>
										<div className="col s12 center-align" style={{ marginTop: '10px' }}>
											<button style={styles.button.previous} onClick={() => setState((s) => ({ ...s, step: 0 }))} className="btn btn-small themedButton">
												Previous
											</button>
											<button style={styles.button.enabled} onClick={() => setState((s) => ({ ...s, step: 2 }))} className="btn">
												Confirm
											</button>
										</div>
									</>
								)}

								{/* user confirm price */}
								{state.step === 2 && (
									<>
										<table className="compact clean">
											<tbody>
												<tr>
													<td style={{ width: '20%' }} />
													<td style={{ width: '80%' }} />
												</tr>
												<tr>
													<td>
														<b>Roll-Off</b>
													</td>
													<td style={{ width: '100%' }}>
														{state.service.name}
														<span style={{ float: 'right' }}>
															<a target="_blank" href={hauler.rolloffdetails}>
																More Details
															</a>
														</span>
													</td>
												</tr>
												<tr>
													<td>
														<b>Duration</b>
													</td>
													<td>
														{moment(state.range[0].startDate).format('M/DD/yyyy')} <br /> {moment(state.range[0].endDate).format('M/DD/yyyy')}
													</td>
												</tr>
												<tr>
													<td>
														<b>Notes</b>
													</td>
													<td>
														<div className="notes-wrapper">
															{state.notes.slice(0, 30)}
															{state.notes.length > 0 && <>...</>}
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<b>Cost</b>
													</td>
													<td>
														<CurrencyFormat {...currencyAttr} value={round(state.service.cost, 2)} />
													</td>
												</tr>
											</tbody>
										</table>
										<div className="row" style={{ margin: 0 }}>
											<div className="col s12" style={{ lineHeight: '20px' }}>
												<small>
													This includes {state.service.allowedtons} Ton and a charge of <CurrencyFormat {...currencyAttr} value={round(state.service.rolloff_tonoveragecharge, 2)} /> per ton over after pickup
													<br />
													By confirming, you agree to our terms and conditions.
													<br />
													Please take a few minutes to read and understand them.
												</small>
											</div>
											<div className="col s12 center-align btn-row" style={{ margin: '10px 0 0', padding: 0 }}>
												{/* {!selected && (
													<button style={styles.button.previous} onClick={() => setState((s) => ({ ...s, step: 1 }))} className="btn btn-small themedButton">
														Previous
													</button>
												)} */}
												<a href={hauler.tosrolloff} target="_blank" style={styles.button.enabled} className="btn btn-small themedButton">
													Terms
												</a>
												{!selected && (
													<>
														<button style={styles.button.enabled} onClick={doReset} className="btn btn-small themedButton">
															Change Order
														</button>
														<button style={styles.button.enabled} onClick={() => onSelect(state)} className="btn btn-small themedButton">
															Confirm
														</button>
													</>
												)}
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const dateDiff = (start, end) => {
	const dateStart = moment(start, 'YYYY-MM-DD');
	const dateEnd = moment(end, 'YYYY-MM-DD');
	return dateEnd.diff(dateStart, 'days');
};

const Textarea = ({ name, onChange }) => {
	return (
		<div className="input-field">
			<textarea style={{ borderColor: 'lightgrey' }} name={name} id={name} onChange={onChange}></textarea>
		</div>
	);
};

export default Item;
