import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import LeadIcon from './gift-icon.png';
import PurchasedIcon from './money-bag-emoji.png';

export default function Table({ columns, data }) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	return (
		<table {...getTableProps()}>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => {
							return column.Header !== 'ID' && <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>;
						})}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<Tr index={i} row={row} {...row.getRowProps()}>
							{row.cells.map((cell) => {
								return cell.column.Header !== 'ID' && <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
							})}
						</Tr>
					);
				})}
			</tbody>
		</table>
	);
}

function Tr(props) {
	const { row } = props;
	let history = useHistory();

	const onClick = (e) => {
		history.push(`/admin/customers/e/${e.row.values.id}`);
	};

	return (
		<tr {...props} onClick={(e) => onClick(props)}>
			{row.cells.map((cell) => {
				if (cell.column.Header === 'Customer/Lead' && cell.value === 'Lead') {
					return (
						<td {...cell.getCellProps()}>
							<img style={{ maxHeight: 25 }} src={LeadIcon} alt="Lead" aria-label="Lead" /> Lead
						</td>
					);
				}
				if (cell.column.Header === 'Customer/Lead' && cell.value === 'Purchased') {
					return (
						<td {...cell.getCellProps()}>
							<img style={{ maxHeight: 25 }} src={PurchasedIcon} alt="Purchased" aria-label="Purchased" /> Purchase
						</td>
					);
				}
				if (cell.column.Header === 'Sign Up/Start') {
					return (
						<td {...cell.getCellProps()}>
							<div dangerouslySetInnerHTML={{ __html: cell.value }} />
						</td>
					);
				}
				return cell.column.Header !== 'ID' && <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
			})}
		</tr>
	);
}
