import React from 'react';

const Post = (post) => {
	return (
		<div className="col s12 m9">
			<div>
				<div>
					<h1 style={{ margin: '0' }} className="pgHead">
						{post.title}
					</h1>
					<p style={{ margin: '0' }}>{post.publish_date}</p>
					<div>{post.body}</div>
				</div>
			</div>
		</div>
	);
};

export default Post;
