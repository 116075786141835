import config from './config';

export default class Data {
	api(path, method = 'GET', body = null, requiresAuth = false, credentials = null) {
		const url = config.apiBaseUrl + path;

		const options = {
			method,
			headers: {
				'Content-Type': 'application/json',
				// 'Access-Control-Allow-Origin': '*',
			},
		};

		if (body !== null) {
			options.body = JSON.stringify(body);
		}
		if (requiresAuth) {
			if ('username' in credentials && 'password' in credentials) {
				const encodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
				options.headers['Authorization'] = ` Basic ${encodedCredentials}`;
			} else if ('token' in credentials) {
				const encodedCredentials = btoa(`${credentials.token}`);
				options.headers['Authorization'] = ` Bearer ${encodedCredentials}`;
			}
		}

		return fetch(url, options);
	}

	async getServices(user, token) {
		// API call to retrieve User Data
		if (!user) {
			return [];
		}

		return await this.api(`/customers.cfc?method=getServices&i=${user}`, 'GET', null, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async updateCustomerService(customer, token) {
		// API call to retrieve User Data
		return await this.api(`/customers.cfc?method=updateCustomerService`, 'POST', customer, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async createCustomer(user) {
		const response = await this.api('/users', 'POST', user);
		if (response.status === 201) {
			return [];
		} else if (response.status === 400) {
			return response.json().then((data) => {
				return data.errors;
			});
		} else {
			throw new Error();
		}
	}

	async getCustomer(user, token) {
		// API call to retrieve User Data
		return await this.api(`/customers.cfc?method=get&i=${user}`, 'GET', null, true, {
			token,
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					return null;
				}
			})
			.catch((err) => console.log({ err }));
	}

	async getCustomers(details, token) {
		return await this.api(`/customers.cfc?method=getCustomers&${this.encodeQueryData(details)}`, 'GET', null, true, {
			token,
		}).then((response) => response.json());
	}

	async update(customer, token) {
		// API call to retrieve User Data
		return await this.api(`/customers.cfc?method=update`, 'POST', customer, true, {
			token,
		})
			.then((response) => (response.status === 200 ? response.json() : null))
			.catch((err) => console.log({ err }));
	}

	encodeQueryData(data) {
		const ret = [];
		for (let d in data) if (data[d] !== undefined) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
		return ret.join('&');
	}
}
