import { v4 as uuidv4 } from 'uuid';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import moment from 'moment';
import Sheet from 'react-modal-sheet';
import { useForm, useFieldArray } from 'react-hook-form';
import useWindowSize from '../../hooks/useWindowSize';
import M from 'materialize-css';
import MapRouter, { arrayMove } from '../../components/MapRouter';
import { addDays, isWeekend } from 'date-fns';

import Modal from 'react-modal';

import './style.scss';
import { DateRange } from 'react-date-range';
import useFetch, { useFormSubmit } from '../../hooks/useFetch';
import { api, googleApiKey } from '../../config';

Modal.setAppElement('#root');

const adminRoles = ['admin', 'hauler', 'can-manager'];

const emptyItem = () => ({
	id: uuidv4(),
	subject: '',
	name: '',
	address: '',
	phone: '',
	cans: [],
	notes: '',
	new: true,
	deliveryDate: new moment(),
	fixCan: false,
	done: false,
	assignedTo: '',
	type: 'deliveries',
	displayOrder: 9999,
});

const initialShowing = {
	label: 'Today',
	range: [
		{
			startDate: moment().endOf('day').toDate(),
			endDate: moment().endOf('day').toDate(),
			key: 'selection',
		},
	],
	currentRange: [
		{
			startDate: moment().endOf('day').toDate(),
			endDate: moment().endOf('day').toDate(),
			key: 'selection',
		},
	],
};

const MainScreen = ({ ...props }) => {
	const {
		auth: {
			user: { user },
		},
	} = props;

	const [data, setData] = useState([]);
	const [search, setSearch] = useState('');
	const [mode, setMode] = useState('list');
	const [showArchived, setShowArchived] = useState(false);
	const [showInviteCode, setShowInviteCode] = useState(false);
	const [editingItemId, setEditingItemId] = useState(null);
	const [showing, setShowing] = useState(initialShowing);
	const [showDateRange, setShowDateRange] = useState(false);
	const [counter, setCounter] = useState(0);
	const [fetching, setFetching] = useState(false);
	const [filter, setFilter] = useState({});
	const [showStats, setShowStats] = useState(null);
	const { data: users } = useFetch(`${api}canmanager.cfc?method=getGroupUsers&groupid=${user.canmanagergroupid}`);

	const { data: fetchedData, loading: isFetchingData, error } = useFetch(`${api}canmanager.cfc?method=getTasks&groupid=${user.canmanagergroupid}&counter=${counter}`);
	const { postData: postNewData, status: createStatus } = useFormSubmit(`${api}canmanager.cfc?method=createTask`);
	const { postData: postUpdateData, status: updateStatus } = useFormSubmit(`${api}canmanager.cfc?method=updateTask`);

	useEffect(() => {
		setTimeout(() => {
			setFetching(false);
		});
	}, [counter]);

	useEffect(() => {
		if (fetchedData) {
			const parsedData = fetchedData.map((item, x) => {
				return {
					...item,
					assignedTo: item.assignedTo.trim(),
					fixCan: item.fixCan ? true : false,
					deliveryDate: moment(item.deliveryDate),
				};
			});

			setData(parsedData);
		}
	}, [fetchedData]);

	const handleToggleTaskItem = useCallback((item) => {
		postUpdateData({
			...item,
			done: !item.done,
		});

		setData((prevData) => {
			const newData = [...prevData];
			const index = prevData.indexOf(item);

			newData[index] = {
				...item,
				done: !item.done,
			};

			return newData;
		});
	});

	const handleFinishEditingTaskItem = useCallback((item, newItem, isNew) => {
		let parsedItem = {};

		if (isNew) {
			parsedItem = {
				...newItem,
				id: uuidv4(),
				done: false,
				archived: false,
				deliveryDate: moment(newItem.deliveryDate, 'MM/DD/YYYY'),
				groupId: user.canmanagergroupid,
			};

			postNewData(parsedItem);
		} else {
			postUpdateData({
				...item,
				...newItem,
			});
		}

		setData((prevTasks) => {
			if (isNew) {
				return [parsedItem, ...prevTasks];
			}
			const newData = [...prevTasks];
			const index = prevTasks.indexOf(item);

			newData[index] = {
				...item,
				...newItem,
			};

			return newData;
		});
		setEditingItemId(null);
	}, []);

	const handleFinishShowInviteCode = () => {
		setShowInviteCode(false);
	};
	const handlePressTaskItemLabel = useCallback((item) => {
		setEditingItemId(item.id);
	}, []);

	const handleRemoveItem = useCallback((item) => {
		postUpdateData({
			...item,
			archived: true,
		});

		setData((prevData) => {
			const newData = [...prevData];
			const index = prevData.indexOf(item);

			newData[index] = {
				...item,
				archived: true,
			};

			return newData;
		});
	}, []);

	const handleBeginCreateNew = useCallback((newData) => {
		setEditingItemId(-1);
	}, []);

	const handleSearch = (e) => setSearch(e.target.value);

	const handleStartRouter = () => setMode('map-router');
	const handleEndMapRouter = () => setMode('list');

	const handleShowArchived = (e) => {
		setShowArchived(e.target.checked);
	};

	const handleHideShowStats = () => setShowStats(null);

	const handleShowInviteCode = () => setShowInviteCode(true);
	const onChangeShowing = () => setShowDateRange(true);
	const onCloseDateRange = () => {
		setShowDateRange(false);
		setShowing((current) => ({ ...current, range: current.currentRange }));
	};
	const onSetToday = () => {
		setShowing(initialShowing);
		onCloseDateRange();
	};
	const onSaveDateRange = () => {
		const label = `${new moment(showing.range[0].startDate).format('M/D/YYYY')} to ${new moment(showing.range[0].endDate).format('M/D/YYYY')}`;
		setShowing((current) => ({ ...current, currentRange: current.range, label }));
		onCloseDateRange();
	};

	const addCounter = () => {
		setFetching(true);
		setCounter((cc) => cc + 1);
	};

	const handleChangeShowUsers = (e) => {
		setFilter({ user: e.target.value });
	};

	const handleMoveTask = (item, swapItem) => {
		if (!item || !swapItem) return;

		if (item.displayOrder === swapItem.displayOrder) {
			const currentDataArray = data.map((currentItem, x) => {
				return {
					...currentItem,
					displayOrder: (x + 1) * 10,
				};
			});

			// swap orders
			const itemIndex = currentDataArray.findIndex((currentItem) => currentItem.id === item.id);
			const swapIndex = currentDataArray.findIndex((currentItem) => currentItem.id === swapItem.id);

			const dataPromises = currentDataArray.map((currentData, x) => {
				if (x === itemIndex) {
					return postUpdateData({ ...currentData, displayOrder: currentDataArray[itemIndex].displayOrder });
				}
				if (x === swapIndex) {
					return postUpdateData({ ...currentData, displayOrder: currentDataArray[swapIndex].displayOrder });
				}
				return postUpdateData(currentData);
			});

			Promise.all(dataPromises).then(() => {
				setCounter((prevCounter) => (prevCounter += 1));
			});
			return;
		}

		const a = postUpdateData({
			...item,
			displayOrder: swapItem.displayOrder,
		});

		const b = postUpdateData({
			...swapItem,
			displayOrder: item.displayOrder,
		});

		Promise.all([a, b]).then(() => {
			setCounter((prevCounter) => (prevCounter += 1));
		});

		return;
	};

	const updateOrder = (data) => {
		const currentDataArray = data.map((currentItem, x) => {
			return {
				...currentItem,
				displayOrder: (x + 1) * 10,
			};
		});

		const dataPromises = currentDataArray.map((currentData, x) => {
			return postUpdateData(currentData);
		});

		Promise.all(dataPromises).then(() => {
			setCounter((prevCounter) => (prevCounter += 1));
		});
	};

	const editingItem = data.find((item) => item.id === editingItemId);

	const filteredByDateData = data.filter((item) => {
		if (adminRoles.includes(user.role)) {
			// do filter by date range
			if (moment(item.deliveryDate).isBefore(showing.currentRange[0].startDate, 'day')) return false;
			if (moment(item.deliveryDate).isAfter(showing.currentRange[0].endDate, 'day')) return false;

			// do filter by user
		} else {
			if (moment(item.deliveryDate).isBefore(moment(), 'day')) return false;
			if (moment(item.deliveryDate).isAfter(moment(), 'day')) return false;
		}
		return true;
	});

	const searchedData = filteredByDateData.filter((item) => {
		if (!showArchived && item.archived) return false;
		if (filter.user && filter.user.length > 0 && item.assignedTo !== filter.user) return false;

		// return `${item.name} ${item.address} ${item.subject}`.toLowerCase().indexOf(search.toLowerCase()) > -1;
		return true;
	});
	const userData = searchedData.filter((item) => {
		return item.assignedTo.localeCompare(user.username) === 0;
	});

	const othersData = searchedData.filter((item) => item.assignedTo !== user.username);

	const filteredUsers = filteredByDateData.reduce((arr, d) => {
		if (d.assignedTo.trim().length > 0 && arr.indexOf(d.assignedTo.trim()) < 0 && d.type === 'deliveries') return arr.concat([d.assignedTo.trim()]);
		return arr;
	}, []);

	const dataToRouter = userData.filter((el) => !el.done && !el.archived);

	return (
		<Wrapper
			userData={userData}
			onChangeUsers={handleChangeShowUsers}
			addCounter={addCounter}
			handleShowInviteCode={handleShowInviteCode}
			fetching={fetching}
			onChangeShowing={onChangeShowing}
			showing={showing.label}
			showArchived={showArchived}
			onSearch={handleSearch}
			onNew={handleBeginCreateNew}
			user={user}
			users={filteredUsers}
			onStartRouter={handleStartRouter}
			onShowArchived={handleShowArchived}
		>
			{adminRoles.includes(user.role) > -1 && (
				<button type="button" className="btn waves-effect waves-light breadcrumbs-btn right" onClick={handleBeginCreateNew} style={{ marginLeft: 20 }}>
					{/* <i className="material-icons ">add</i> */}
					<span className="">Create New Can service</span>
				</button>
			)}
			<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
				<h5>
					({userData.length}) : Your Deliveries for {showing.label}
				</h5>
				{userData.length > 0 && (
					<button className="btn btn-flat" type="button" onClick={() => setShowStats(userData)} style={{ marginLeft: 10 }}>
						<i className="material-icons">help_outline</i>
					</button>
				)}
			</div>
			<AnimatedTaskList onMoveTask={handleMoveTask} data={userData} user={user} editingItemId={editingItemId} onToggleItem={handleToggleTaskItem} onFinishEditing={handleFinishEditingTaskItem} onPressLabel={handlePressTaskItemLabel} onRemoveItem={handleRemoveItem} />
			<hr />
			<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
				<h5>
					{['can-driver'].includes(user.role) ? "Other's" : `(${othersData.length}) : Other `} Deliveries For {showing.label}
				</h5>
				{othersData.length > 0 && (
					<button className="btn btn-flat" type="button" onClick={() => setShowStats(othersData)} style={{ marginLeft: 10 }}>
						<i className="material-icons">help_outline</i>
					</button>
				)}
			</div>
			<AnimatedTaskList onMoveTask={handleMoveTask} data={othersData} user={user} editingItemId={editingItemId} onToggleItem={handleToggleTaskItem} onFinishEditing={handleFinishEditingTaskItem} onPressLabel={handlePressTaskItemLabel} onRemoveItem={handleRemoveItem} />
			<Sheet isOpen={mode === 'map-router'} onClose={handleEndMapRouter} className="editingContainer">
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<MapRouterContainer onCancelRouter={handleEndMapRouter} items={dataToRouter} updateOrder={updateOrder} />
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet>
			<Sheet isOpen={editingItem || editingItemId === -1} onClose={handleFinishEditingTaskItem} className="editingContainer">
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						{editingItemId && editingItemId.length > 3 && <TaskEditContainer users={users} user={user} task={editingItem} onFinishEditing={handleFinishEditingTaskItem} />}
						{editingItemId && editingItemId === -1 && <TaskEditContainer users={users} user={user} task={emptyItem()} onFinishEditing={handleFinishEditingTaskItem} />}
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet>
			<Sheet isOpen={showInviteCode} onClose={handleFinishShowInviteCode} className="editingContainer">
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<InviteOthersContainer user={user} onClose={handleFinishShowInviteCode} />
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop />
			</Sheet>
			<Modal isOpen={showDateRange} onRequestClose={onCloseDateRange} contentLabel="My dialog" className="mymodal" overlayClassName="myoverlay">
				<div className="modal open" style={{ maxWidth: 710, zIndex: '1003', display: 'block', opacity: 1, top: '10%', transform: 'scaleX(1) scaleY(1)' }}>
					<div className="modal-content">
						<div>
							<CalendarRangePicker setState={setShowing} state={showing} />
						</div>
					</div>
					<div
						className="modal-footer"
						style={{
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
						}}
					>
						<button type="button" className="btn btn-small green white-text" onClick={onCloseDateRange}>
							Cancel
						</button>
						<button type="button" className="btn btn-small green white-text" onClick={onSetToday}>
							Show Today
						</button>
						<button type="button" className="btn btn-small green white-text" onClick={onSaveDateRange}>
							Save
						</button>
					</div>
				</div>
			</Modal>
			<Modal isOpen={!!showStats} onRequestClose={handleHideShowStats} contentLabel="Stats" className="mymodal" overlayClassName="myoverlay">
				{showStats && (
					<motion.div animate={{ opacity: 1 }} className="modal open" style={{ maxWidth: 400, zIndex: '99999999', display: 'block', opacity: 1, top: '10%', transform: 'scaleX(1) scaleY(1)' }}>
						<div className="modal-content">
							<RoutesStatWindow routes={showStats} />
						</div>
						<div
							className="modal-footer"
							style={{
								display: 'flex',
								justifyContent: 'space-around',
								alignItems: 'center',
							}}
						>
							<button type="button" className="btn btn-small green white-text" onClick={handleHideShowStats}>
								Cancel
							</button>
						</div>
					</motion.div>
				)}
			</Modal>
		</Wrapper>
	);
};

const AnimatedTaskList = ({ data, ...props }) => {
	// const [controls, ref] = useAutoHeightAnimation([data]);

	return (
		<motion.div layout className="collection" style={{ transformOrigin: 'top', overflow: 'hidden' }}>
			<TaskList data={data} {...props} />
		</motion.div>
	);
};

const TaskList = ({ data, editingItemId, onMoveTask, onRemoveItem, ...props }) => {
	const handleMoveTask = useCallback(
		(item, direction) => {
			const swapIndex = data.indexOf(item) + direction;
			onMoveTask(item, data[swapIndex]);
		},
		[data, onMoveTask]
	);

	return (
		<AnimatePresence initial={false}>
			{data.map((item, z) => {
				return <AnimatedTaskItem total={data.length} index={z} key={item.id} data={item} isEditing={item.id === editingItemId} onRemove={onRemoveItem} {...props} onMoveTask={handleMoveTask} />;
			})}
		</AnimatePresence>
	);
};

const AnimatedTaskItem = ({ data, onRemove, onMoveTask, onToggleItem, onFinishEditing, onPressLabel, ...props }) => {
	const handleRemove = useCallback(() => onRemove(data), [data, onRemove]);
	const handleToggleCheckbox = useCallback(() => onToggleItem(data), [data, onToggleItem]);
	const handleFinishEditing = useCallback((newData) => onFinishEditing(data, { ...newData, type: 'deliveries' }), [data, onFinishEditing]);
	const handlePressLabel = useCallback(() => onPressLabel(data), [data, onPressLabel]);
	const handleMoveTaskUp = useCallback(() => onMoveTask(data, -1));
	const handleMoveTaskDown = useCallback(() => onMoveTask(data, 1));

	return <TaskItem {...props} onMoveTaskDown={handleMoveTaskDown} onMoveTaskUp={handleMoveTaskUp} onRemove={handleRemove} {...data} isDone={data.done} onToggleCheckbox={handleToggleCheckbox} onPressLabel={handlePressLabel} onFinishEditing={handleFinishEditing} />;
};

const TaskItem = (props) => {
	const { total, index, assignedTo, isEditing, onMoveTaskUp, onMoveTaskDown, cans, archived, isDone, onToggleCheckbox, subject, onPressLabel, onRemove, onChangeSubject, onFinishEditing, simultaneousHandlers, user } = props;

	const s = {
		item: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'flex-start',
			borderLeft: archived ? '4px solid red' : '4px solid transparent',
		},
	};

	return (
		<motion.div
			layout
			className="collection-item"
			style={s.item}
			initial={{ opacity: 0, x: -window.innerWidth }}
			transition={{
				x: { type: 'easeOut', duration: 0.7 },
				opacity: { duration: 0.7 },
			}}
			animate={{
				opacity: 1,
				x: 0,
			}}
			exit={{
				opacity: 0.6,
				x: window.innerWidth,
			}}
			// drag="x"
			// dragConstraints={{ left: 0, right: 0 }}
			// dragElastic={1}
			// onDragStart={handleDragStart}
			// onDragEnd={(e, { offset, velocity }) => {
			// 	const swipe = swipePower(offset.x, velocity.x);

			// 	if (swipe > swipeConfidenceThreshold && offset.x / window.innerWidth > 0.6) {
			// 		onRemove();
			// 	}

			// 	setDragging(false);
			// }}
		>
			<label style={{ flexGrow: 1, cursor: 'pointer' }}>
				<input type="checkbox" checked={isDone} onChange={onToggleCheckbox} />
				<AnimatedTaskLabel isDone={isDone}>
					<span>
						<b>{assignedTo}</b> {subject}
					</span>
					<div style={{ paddingLeft: 20, lineHeight: '16px' }}>
						<small>
							{cans.map(
								(can, x) =>
									can.count !== '' && (
										<span key={x}>
											{can.action} : {can.count} {can.canType} {can.canSize}
											<br />
										</span>
									)
							)}
						</small>
					</div>
				</AnimatedTaskLabel>
			</label>
			{adminRoles.includes(user.role) && (
				<button type="button" onClick={onPressLabel} className="btn btn-small green" style={{ marginLeft: '1rem' }}>
					<i className="material-icons white-text tiny">edit</i>
				</button>
			)}
			{['can-driver'].includes(user.role) && (
				<button type="button" onClick={onPressLabel} className="btn btn-small green" style={{ marginLeft: '1rem' }}>
					<i className="material-icons white-text tiny">visibility</i>
				</button>
			)}
			{!archived && adminRoles.includes(user.role) && (
				<button type="button" onClick={onRemove} className="btn btn-small red" style={{ marginLeft: '1rem' }}>
					<i className="material-icons white-text tiny">delete</i>
				</button>
			)}
			{index / total !== 0 && (
				<button type="button" onClick={onMoveTaskUp} className="btn btn-small grey white-text" style={{ marginLeft: '1rem' }}>
					<i className="material-icons">arrow_drop_up</i>
				</button>
			)}
			{index + 1 !== total && (
				<button type="button" onClick={onMoveTaskDown} className="btn btn-small grey white-text" style={{ marginLeft: '1rem' }}>
					<i className="material-icons">arrow_drop_down</i>
				</button>
			)}
		</motion.div>
	);
};

const AnimatedTaskLabel = ({ isDone, children }) => {
	const style = {
		textDecoration: isDone ? 'line-through' : 'none',
		color: isDone ? 'grey' : 'black',
	};

	return <motion.span animate={style}>{children}</motion.span>;
};

const CalendarRangePicker = ({ setState, state }) => {
	const onShownDateChange = (month) => setState((s) => ({ ...s, month }));
	const dayContentRenderer = (a, b) => {
		return <span>{moment(a).format('D')}</span>;
	};
	const handleRangeDateChange = (item) => {
		const { startDate, endDate } = item.selection;

		// hauler has disabled weekends
		setState((s) => ({ ...s, range: [item.selection] }));
	};

	const handleRangeDatePreviewChange = (item) => {};

	return <DateRange scroll={{ enabled: false }} direction="horizontal" months={2} onShownDateChange={onShownDateChange} dayContentRenderer={dayContentRenderer} onRangeFocusChange={handleRangeDatePreviewChange} editableDateInputs onChange={handleRangeDateChange} moveRangeOnFirstSelection={false} ranges={state.range} />;
};

const InviteOthersContainer = ({ onClose, code, user }) => {
	const { data: fetchData, loading } = useFetch(`${api}canmanager.cfc?method=getGroup&id=${user.canmanagergroupid}`);
	const { postData } = useFormSubmit(`${api}canmanager.cfc?method=invite`);
	const [{ error, classes }, setSubmitError] = useState('');

	const [data] = fetchData && !loading ? fetchData : [];

	const [to, setTo] = useState('');
	const handleToChange = (e) => setTo(e.target.value);

	const subject = encodeURIComponent('Invite to Trash Joes Can Manager');

	const handleSendManagerInvite = () => {
		const values = {
			to,
			code: `${encodeURIComponent(data.id)}::${encodeURIComponent(data.managerid)}`,
		};

		postData(values).then((r) => {
			setSubmitError({ error: r.message, classes: r.success ? 'green white-text' : 'red white-text' });
			if (r.success) {
				setTo('');
			}
			setTimeout(() => {
				setSubmitError({});
			}, 2000);
		});
	};

	const handleSendDriverInvite = () => {
		const values = {
			to,
			code: `${encodeURIComponent(data.id)}::${encodeURIComponent(data.driverid)}`,
		};

		postData(values).then((r) => {
			setSubmitError({ error: r.message, classes: r.success ? 'green white-text' : 'red white-text' });
			if (r.success) {
				setTo('');
			}
			setTimeout(() => {
				setSubmitError({});
			}, 2000);
			// if (!r.success) M.toast({ html: r.message, classes: 'red white-text' });
		});
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col s12 m8 offset-m2">
					{loading ? (
						<>
							<div className="progress">
								<div className="indeterminate"></div>
							</div>
							<button type="button" style={{ marginTop: 20 }} onClick={onClose} className="btn btn-small grey white-text">
								Close
							</button>
						</>
					) : (
						<>
							<h5>Feel free to invite whoever you want into your group, signup is free.</h5>
							{/* <p>All you need is to refer your manager or drivers to our registration page and provide them with the code below</p> */}

							<div className="code-display">
								{/* <div className="code">{code}</div> */}
								{/* {code.split('').map((char, i) => (
									<div key={char + i}>
									<div>
									<div>{char}</div>
									</div>
									</div>
								))} */}
								{/* <CopyToClipboard text={code} onCopy={onCopyText}>
									<div className="copy-area center-align">
										<button className="btn btn-small green white-text">Copy to Clipboard</button>
										{isCopied && (
											<div style={{ marginTop: 5 }} className={`copy-feedback`}>
												COPIED!
											</div>
										)}
									</div>
								</CopyToClipboard> */}
								<hr />
								<input name="to" value={to} onChange={handleToChange} placeholder="email1@domain.com,email2@domain.com" />
								<div style={{ display: 'flex', width: '100%' }}>
									<button type="button" style={{ marginRight: 10 }} className="btn btn-small green white-text" onClick={handleSendManagerInvite}>
										Invite as Managers
									</button>
									<button type="button" style={{ marginRight: 10 }} className="btn btn-small green white-text" onClick={handleSendDriverInvite}>
										Invite as Drivers
									</button>
									<button type="button" style={{ marginLeft: 'auto' }} onClick={onClose} className="btn btn-small grey white-text">
										Close
									</button>
								</div>
								<AnimatePresence>
									{error && (
										<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={`toast ${classes}`} style={{ top: 0 }}>
											{error}
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const TaskEditContainer = ({ task, onFinishEditing, user, users }) => {
	const { register, handleSubmit, errors, control } = useForm({
		defaultValues: {
			...task,
			deliveryDate: moment(task.deliveryDate).format('MM/DD/YYYY'),
		},
	});
	const { fields } = useFieldArray({ name: 'cans', control });
	const [place, setPlace] = useState(task.address);
	const { width: deviceWidth } = useWindowSize();

	useEffect(() => {
		const elems = document.querySelectorAll('.datepicker');
		M.Datepicker.init(elems, {
			autoClose: true,
			format: 'mm/dd/yyyy',
		});

		const openOnFocus = ({ target }) => {
			const instance = M.Datepicker.getInstance(target);
			if (instance) instance.open();
		};

		const destroy = ({ target }) => {
			const instance = M.Datepicker.getInstance(target);
			if (instance) instance.destroy();
		};

		document.getElementById('deliveryDate').addEventListener('focus', openOnFocus);

		return () => {
			for (var elem in elems) {
				if (elem) {
					destroy({ target: elem });
					// elem.removeEventListener('focus', openOnFocus);
				}
			}
		};
	}, []);

	const onSubmit = (v) => {
		onFinishEditing(task, { ...v, subject: v.address }, task?.new);
	};

	const onUpdatePlace = (e) => setPlace(e.target.value);

	const disabled = !adminRoles.includes(user.role);

	return (
		<>
			<div className="container">
				<h5 className="center-align" style={{ marginTop: 0 }}>
					{task?.new ? (
						<>Create New Can Delivery</>
					) : (
						<>
							Editing {task?.name}
							<br />
							<small>{task?.address}</small>
						</>
					)}
				</h5>
				<hr />
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col s12 input-field" style={{ marginBottom: 0 }}>
							<div style={{ display: 'flex', flexDirection: deviceWidth < 585 ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
								<input onBlur={onUpdatePlace} id="address" name="address" type="text" ref={register({ required: 'Address is required!' })} disabled={disabled} />
								<button type="button" className="btn btn-small green" style={{ margin: '0 0 -4px', borderRadius: 0, whiteSpace: 'nowrap' }}>
									Update Map
								</button>
							</div>
							<label className="active" htmlFor="address">
								Service Address
							</label>
							<span className="red-text helper-text">{errors.address && errors.address.message}</span>
						</div>

						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<input id="name" name="name" type="text" ref={register} disabled={disabled} />
							<label className="active" htmlFor="name">
								Customer Name
							</label>
							<span className="red-text helper-text">{errors.name && errors.name.message}</span>
						</div>

						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<input id="phone" name="phone" type="text" ref={register} disabled={disabled} />
							<label className="active" htmlFor="phone">
								Customer Phone
							</label>
							<span className="red-text helper-text">{errors.phone && errors.phone.message}</span>
						</div>

						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<input id="deliveryDate" name="deliveryDate" type="text" ref={register} className="datepicker" disabled={disabled} />
							<label className="active" htmlFor="deliveryDate">
								Delivery Date
							</label>
							<span className="red-text helper-text">{errors.deliveryDate && errors.deliveryDate.message}</span>
						</div>

						<div className="col s12 m6 input-field" style={{ marginBottom: 0 }}>
							<select className="browser-default" id="fixCan" name="fixCan" ref={register} disabled={disabled}>
								<option value="false">No</option>
								<option value="true">Yes</option>
							</select>
							<label className="active" htmlFor="fixCan">
								Fix Can?
							</label>
							<span className="red-text helper-text">{errors.fixCan && errors.fixCan.message}</span>
						</div>

						<div className="col s12 input-field" style={{ marginBottom: 0 }}>
							{/* <input id="notes" name="notes" type="text" ref={register} /> */}
							<textarea id="notes" name="notes" ref={register} className="materialize-textarea" disabled={disabled}></textarea>
							<label className="active" htmlFor="notes">
								Notes
							</label>
							<span className="red-text helper-text">{errors.notes && errors.notes.message}</span>
						</div>
						<div className="col s12">
							<label>Cans Requested</label>
							<ul className="collection current-cans-list" style={{ marginTop: 0 }}>
								{[1, 2, 3].map((item, i) => (
									<li key={i} className="collection-item " style={{ display: 'flex' }}>
										<select className="browser-default" name={`cans[${i}]action`} ref={register} defaultValue={fields?.[item]?.action}>
											<option value="">Select Action</option>
											<option>Delivery</option>
											<option>Pick-Up</option>
											<option>Swap</option>
											<option>Fix</option>
											<option>Other. See Notes</option>
										</select>
										<input name={`cans[${i}]count`} ref={register} defaultValue={fields?.[item]?.count} placeholder="1" min="1" step="1" max="9999" type="number" className={`form-control ${errors.cans?.[i]?.count ? 'is-invalid' : ''}`} style={{ maxWidth: 50 }} disabled={disabled} />
										<select className="browser-default" name={`cans[${i}]canType`} ref={register} defaultValue={fields?.[item]?.canType}>
											<option value="">Select Type</option>
											<option>Trash</option>
											<option>Recycle</option>
											<option>Yard</option>
											<option>Organic</option>
											<option>Other. See Notes</option>
										</select>
										<select className="browser-default" name={`cans[${i}]canSize`} ref={register} defaultValue={fields?.[item]?.canSize}>
											<option value="">Select Size</option>
											<option>95/96</option>
											<option>64/65</option>
											<option>45/48</option>
											<option>32/35</option>
											<option>200</option>
											<option>300</option>
											<option>1 CY</option>
											<option>1.5 CY</option>
											<option>2 CY</option>
											<option>3 CY</option>
											<option>4 CY</option>
											<option>5 CY</option>
											<option>6 CY</option>
											<option>8 CY</option>
											<option>10 CY</option>
											<option>Other. See Notes</option>
										</select>
										{/* <input name={`cans[${i}]action`} ref={register} defaultValue={fields?.[item]?.action} placeholder="Action" type="text" className={`form-control ${errors.cans?.[i]?.action ? 'is-invalid' : ''}`} disabled={disabled} /> */}
										{/* <input name={`cans[${i}]canType`} ref={register} defaultValue={fields?.[item]?.canType} placeholder="Can Type" type="text" className={`form-control ${errors.cans?.[i]?.canType ? 'is-invalid' : ''}`} disabled={disabled} /> */}
										{/* <input name={`cans[${i}]canSize`} ref={register} defaultValue={fields?.[item]?.canSize} placeholder="Can Size" type="text" className={`form-control ${errors.cans?.[i]?.canSize ? 'is-invalid' : ''}`} disabled={disabled} /> */}
									</li>
								))}
							</ul>
						</div>
						<div className="col s12 m6 input-field">
							{users && (
								<select className="browser-default" id="assignedTo" name="assignedTo" ref={register({ required: 'User assigned to is required!' })}>
									{users.map((u) => (
										<option key={u}>{u}</option>
									))}
								</select>
							)}
							<label className="active" htmlFor="assignedTo">
								Assigned To
							</label>
							<span className="red-text helper-text">{errors.assignedTo && errors.assignedTo.message}</span>
						</div>
						<div className="col s12 m6 input-field">
							<input id="displayOrder" name="displayOrder" type="number" ref={register} min="1" max="9999" step="1" disabled={disabled} />
							<label className="active" htmlFor="displayOrder">
								Delivery Order
							</label>
							<span className="red-text helper-text">{errors.displayOrder && errors.displayOrder.message}</span>
						</div>

						<div className="col s12" style={{ marginBottom: 0 }}>
							<div style={{ display: 'flex', marginBottom: 0 }}>
								<button onClick={onFinishEditing} type="button" className="btn btn-small grey white-text">
									Close
								</button>
								<button type="submit" className="btn btn-small green white-text" style={{ marginLeft: 'auto' }}>
									Save
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			{place.length > 0 && <MapPlace place={place} />}
		</>
	);
};

const RoutesStatWindow = ({ routes }) => {
	const parseRoutesToStats = (routes) => {
		const allCans = routes.reduce((arr, route) => {
			return arr.concat(route.cans);
		}, []);

		const stats = allCans.reduce((arr, can) => {
			if (`${can.action}${can.canType}${can.canSize}`.length === 0) return arr;

			const key = `${can.action}-${can.canType}-${can.canSize}`;
			const index = arr.findIndex((item) => `${item.action}-${item.canType}-${item.canSize}` === key);

			if (index < 0) {
				return arr.concat([can]);
			}

			arr[index] = {
				...arr[index],
				count: Number(arr[index].count) + Number(can.count),
			};

			return arr;
		}, []);

		return stats;
	};

	const stats = parseRoutesToStats(routes);

	return (
		<>
			<ul className="collection">
				{stats.map((route, i) => (
					<div className="center-align collection-item" key={i} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<span style={{ textAlign: 'center', padding: '0 20px' }}>
							<b style={{ fontSize: 14 }}>{route.count}</b>
						</span>
						<span style={{ marginRight: 'auto', textAlign: 'left' }}>
							{route.action} : {route.canType} {route.canSize}
						</span>
					</div>
				))}
			</ul>
		</>
	);
};

const MapRouterContainer = (props) => {
	return <MapRouter {...props} />;
};

const MapPlace = ({ place }) => <iframe height="450" style={{ border: 0, width: '100%' }} loading="lazy" allowFullScreen src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${encodeURIComponent(place)}`} />;

const Wrapper = ({ users = [], handleShowInviteCode, onChangeUsers, addCounter, fetching = false, showing, onChangeShowing, onSearch, onShowArchived, onStartRouter, userData = [], user, query, showArchived, children }) => (
	<>
		<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
			<div className="container">
				<div className="row">
					<div className="col s12">
						<h3 className="breadcrumbs-title mt-0 mb-0 center-align" style={{ fontSize: '24px', lineHeight: '25px' }}>
							<span>
								<b>
									{user.firstname} {user.lastname} - Can Manager
								</b>
							</span>
							<br />
							<span>
								<b>
									{['can-manager'].includes(user.role) && 'Manager'}
									{['can-driver'].includes(user.role) && 'Driver'}
									{['hauler'].includes(user.role) && 'Owner'}
									{['admin'].includes(user.role) && 'Admin'}
								</b>
							</span>
						</h3>
						{/* <ol className="breadcrumbs mb-0">
							<li className="breadcrumb-item">
								<a href="/admin">Home</a>
							</li>
							<li className="breadcrumb-item active">Can Manager Services</li>
						</ol> */}
					</div>
					<div className="col s12 right">
						<motion.button
							variants={
								{
									// rotate: { rotate: [0, -30, 0], transition: { duration: 0.5 } },
									// stop: { y: [0, -10, 0], transition: { repeat: Infinity, repeatDelay: 3 } },
								}
							}
							animate={fetching ? 'rotate' : 'stop'}
							className="btn btn-floating green white-text waves-effect waves-light breadcrumbs-btn right"
							onClick={addCounter}
							style={{ marginLeft: 20 }}
						>
							<i className="material-icons right" style={{ margin: 0 }}>
								refresh
							</i>
						</motion.button>
						{adminRoles.includes(user.role) && (
							<button type="button" className="btn waves-effect waves-light breadcrumbs-btn right green white-text" onClick={handleShowInviteCode} style={{ marginLeft: 20 }}>
								Click here to invite other Managers and Drivers
							</button>
						)}

						<button type="button" className="btn waves-effect waves-light breadcrumbs-btn right" onClick={onStartRouter} style={{ marginLeft: 20 }} disabled={userData.length === 0}>
							{/* <i className="material-icons">add</i> */}
							<span className="">Start Map Router</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div className="col s12">
			<section className="users-list-wrapper section">
				{adminRoles.includes(user.role) && (
					<div className="users-list-filter">
						<div className="card-panel">
							<div className="row">
								<div className="col s12 m6 hide">
									<div className="input-field">
										<label htmlFor="users-list-verified" className="active">
											Filter Shown Can Services
										</label>
										<input placeholder="Search here" onChange={onSearch} name="query" id="query" defaultValue={query} />
									</div>
								</div>
								{adminRoles.includes(user.role) > -1 && (
									<div className="srow" style={{ display: 'flex', flexDirection: 'column', marginTop: 0, justifyContent: 'space-between' }}>
										<div style={{ display: 'flex' }}>
											<div style={{ height: 36, lineHeight: '36px', margin: '0 10px' }}>Showing Results for </div>
											<div style={{ margin: '0 10px' }}>
												<button type="button" className="btn btn-flat" onClick={onChangeShowing}>
													{showing}
												</button>
											</div>
											<div className="input-field" style={{ margin: '0 10px' }}>
												{users && (
													<select style={{ height: 36 }} className="browser-default" id="assignedTo_wrapper" name="assignedTo_wrapper" onChange={onChangeUsers}>
														<option value="">Show All Users</option>
														{users.map((u) => (
															<option key={u}>{u}</option>
														))}
													</select>
												)}
											</div>
										</div>
										<div className="input-field" style={{ margin: '10px 10px 0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 36 }}>
											<div className="switch">
												<label style={{ color: 'rgba(0,0,0,.7)', fontSize: 14 }}>
													Hide Archived
													<input type="checkbox" onChange={onShowArchived} checked={showArchived} />
													<span className="lever"></span>
													Show Archived
												</label>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				<div className="users-list-table">
					<div className="card" style={{ marginTop: 0 }}>
						<div className="card-content">{children}</div>
					</div>
				</div>
			</section>
		</div>
	</>
);

export default MainScreen;
