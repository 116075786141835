import React, { useState, useContext, useEffect } from 'react';
import M from 'materialize-css';
import { SignUpContext } from './context/signup';
import { useStyles } from './elementStyles';
import { v4 as uuidv4 } from 'uuid';

const CardCans = ({ cans, service, onSelect = () => console.log('onSelect on cans') }) => {
	const { hauler, getStepAnswers } = useContext(SignUpContext);
	const { primaryColor, primaryTextColor } = hauler.theme;

	const { styles } = useStyles();
	const [form, setForm] = useState(null);

	useEffect(() => M.AutoInit(), []);

	const frequencyOptions = service.prices.filter((el) => el.cans === cans);

	if (frequencyOptions.length === 0) {
		return <div>Your service will be picked up </div>;
	}

	const primaryBgColor = {
		backgroundColor: primaryColor,
		color: primaryTextColor,
	};

	const optionInactive = {
		color: 'black',
		marginRight: 0,
	};

	const optionActive = {
		...optionInactive,
		...primaryBgColor,
	};

	const s = {
		square_container: {
			position: 'relative',
			width: '20px',
			height: '20px',
			marginTop: '1px',
			border: `1px solid ${primaryColor}`,
			borderRadius: '50%',
		},
		square_container_active: {
			position: 'relative',
			width: '20px',
			height: '20px',
			marginTop: '1px',
			border: `1px solid ${primaryColor}`,
			borderRadius: '50%',
			background: primaryColor,
		},
	};

	const themedClass = {
		color: 'black',
		marginBottom: '10px',
		borderRadius: '20px',
		border: '1px solid black',
	};

	const handlePreferredPickupChange = (preferred) => {
		const max = parseInt(form.frequency.frequency.replace(/[^0-9]/g, '')) || 1;

		if (preferred.length > max) preferred = preferred.slice(1);

		setForm({ ...form, preferred });
	};

	const onClickFrequency = (frequency) => {
		setForm({ ...form, frequency, preferred: [] });
	};

	const checkCanProceed = () => {
		if (!form || !form.frequency) return false;

		if (getStepAnswers(0).toLowerCase() === 'commercial') {
			const preferredCount = form.preferred.length;
			const preferredRequired = parseInt(form.frequency.frequency.replace(/[^0-9]/g, '')) || 1;

			if (preferredCount < preferredRequired) return false;
		}

		return true;
	};

	const checkForceAll = () => {
		const preferredRequired = parseInt(form.frequency.frequency.replace(/[^0-9]/g, '')) || 1;
		const preferredAllowed = form.frequency.enabled.length;

		return preferredRequired === preferredAllowed;
	};

	return (
		<React.Fragment>
			<div className="row" style={{ margin: 0 }}>
				{frequencyOptions.length > 0 ? (
					<div>
						<h5 style={{ marginTop: '0', color: primaryColor }}>{'Pickup frequency'.toUpperCase()}</h5>
						<br />
						<div className="row small">
							<div className="col s12 ">
								{/* <input style={{ textAlign: 'center' }} onChange={(el) => setForm({ ...form, cans: el.target.value })} name="cansNeeded" type="number" step="1" min="0" placeholder="How many more cans do you need? (1)" defaultValue="" /> */}
								<div className="collection" style={{ margin: '0 auto', border: '0px solid #e0e0e0' }}>
									{frequencyOptions.map((frequency, i) => (
										<a style={themedClass} key={i} href="#!" className={`collection-item`} onClick={() => onClickFrequency(frequency)}>
											<span className="black-text">{frequency.frequency}</span>
											<span style={form && form.frequency && form.frequency.id === frequency.id ? s.square_container_active : s.square_container} className="right selected-indicator"></span>
										</a>
									))}
									{form && form.frequency && form.frequency.enabled && (
										<div style={{ minWidth: '200px' }}>
											<WeekDisplay hide={false} preferred={form.preferred ? form.preferred : []} enabled={form.frequency.enabled} handleEnabledChange={handlePreferredPickupChange} forceAll={checkForceAll()} />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						Service pickup is {frequencyOptions[0].frequency} for ${frequencyOptions[0].price} /month
					</div>
				)}
				<div className="col s10 offset-s1">
					<button disabled={!checkCanProceed()} style={styles.button.enabled} onClick={() => onSelect(form)} className="btn">
						Confirm
					</button>
				</div>
			</div>
		</React.Fragment>
	);
};

// button for week selection
const WeekdayButton = ({ children, className, ...props }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', margin: '0 10px' }}>
			<button type="button" className={`btn btn-small ${className}`} {...props}>
				{children}
			</button>
		</div>
	);
};

const WeekDisplay = ({ hide = true, preferred = [], enabled, handleEnabledChange, forceAll = false }) => {
	const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	useEffect(() => {
		if (forceAll) handleEnabledChange(enabled);
	}, [forceAll, handleEnabledChange]);

	const addOrRemove = (arr, item) => (arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]);

	if (hide) return null;

	const doChange = (x) => {
		if (!forceAll) handleEnabledChange(addOrRemove(preferred, x));
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
			{weekdays.map(
				(x, i) =>
					enabled.includes(x) && (
						<WeekdayButton style={{ padding: '0 8px' }} key={uuidv4()} onClick={() => doChange(x)} className={preferred.includes(x) ? 'green' : 'grey'}>
							{x}
						</WeekdayButton>
					)
			)}
		</div>
	);
};

export default CardCans;
