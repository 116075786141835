import React, { useRef, useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SignUpContext } from './context/signup';
import M from 'materialize-css';

import './cardSlider.scss';

const variants = {
	enter: (direction) => {
		return {
			x: direction > 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction) => {
		return {
			zIndex: 0,
			x: direction < 0 ? 1000 : -1000,
			opacity: 0,
		};
	},
};

const HaulerPage = (props) => {
	const stepIndexes = ['intro', 'address', 'services', 'commercialservices', 'rolloffservices', 'customerdetails', 'billingdetails', 'review', 'deliverydate', 'confirmation'];

	const { hauler, stepAnswers, steps } = useContext(SignUpContext);

	const refs = useRef([]);
	const [[stepName, direction], setStep] = useState(['intro', 0]);

	const goToStep = (step, direction = 1) => {
		if (stepName === step) return;
		setStep([step, direction]);
		return step;
	};

	const goToNextStep = (i) => {
		if (stepAnswers[i]) {
			const error = steps[i].requiredFn ? steps[i].requiredFn(stepAnswers[i]) : '';
			if (error.length > 0) {
				M.toast({ html: error });
				return;
			}

			setStep([stepIndexes[i + 1], 1]);
		} else {
			M.toast({ html: 'Please complete the slide' });
			return;
		}
	};

	const goToPreviousStep = (i) => {
		setStep([stepIndexes[i - 1], -1]);
	};

	const step = steps.filter((el) => el.name === stepName)[0];

	return (
		<div className="card-slider-wrapper" ref={(el) => (refs.current['wrapper'] = el)}>
			<div className="scroller">
				<AnimatePresence initial={false} custom={direction}>
					<motion.div
						className="scroller-block"
						key={step.id}
						custom={direction}
						variants={variants}
						initial="enter"
						animate="center"
						exit="exit"
						transition={{
							x: { type: 'spring', stiffness: 500, damping: 100 },
							opacity: { duration: 1 },
						}}
						// transition={{
						// 	x: { type: 'spring', stiffness: 300, damping: 200 },
						// 	opacity: { duration: 0.2 },
						// }}
					>
						{step.content({ goToNextStep, goToPreviousStep, goToStep, step })}
						{/* <div className="card container">
							<div className="card-content" style={{ maxHeight: '600px', maxHeight: '100%' }}>
								<span className="card-title">{step.title}</span>
							</div>
							<div className="card-action">
								{stepName !== 'intro' && (
									<button className="btn btn-small" onClick={() => goToPreviousStep(step.index)}>
										Previous Step
									</button>
								)}
								{stepName !== 'thanks' && (
									<button className="btn btn-small" onClick={() => goToNextStep(step.index)}>
										Next Step
									</button>
								)}
							</div>
						</div> */}
					</motion.div>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default HaulerPage;
