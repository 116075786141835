import React from 'react';
import List from './list';
import Edit from './edit';
import './style.css';

const PageWrapper = ({ match, ...rest }) => {
	if (match.path.indexOf('/add') > -1) {
		return <Edit u={match.params.priceId} {...rest} />;
	}
	if (match.params.priceId) {
		return <Edit u={match.params.priceId} {...rest} />;
	}
	return <List {...rest} />;
};
export default PageWrapper;
