import React from 'react';
// import Header from '../components/Header/admin';
// import SideNav from '../components/Header/adminSideNav';
// import withAuthentication from '../components/auth-provider';

const HaulerLayout = (props) => {
	// const HeaderWithAuth = withAuthentication(Header);
	// const SideWithAuth = withAuthentication(SideNav);

	return (
		<div id="wrapper" className="hauler-customer-signup">
			<div id="main">
				<div className="row" style={{ margin: '0' }}>
					{/* <div className="content-wrapper-before gradient-45deg-indigo-purple"></div> */}
					<div id="content">{props.children}</div>
				</div>
			</div>
		</div>
	);
};

export default HaulerLayout;
