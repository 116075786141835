import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../../../components/Loader';
import Axios from 'axios';
import { appendScript, removeScript, appendLink, removeLink } from '../../../utils/scripts';
import Quill from 'quill';
import M from 'materialize-css';
// import ImageUploader from 'quill.imageUploader.js';
import ImageUploader from 'quill-image-uploader';
import postService from '../../../services/posts';
import moment from 'moment';

import { api } from '../../../config';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

Quill.register('modules/imageUploader', ImageUploader);

const Form = ({ form, auth }) => {
	const { register, handleSubmit, errors, setValue } = useForm({
		mode: 'onChange',
		defaultValues: form,
	});
	const [scriptsLoaded, setScriptsLoaded] = React.useState([]);
	const [body, setBody] = React.useState('');
	const hiddenFileInput = useRef(null);
	const [featureImage, setFeatureImage] = useState('');
	const history = useHistory();

	useEffect(() => {
		M.AutoInit();

		setFeatureImage(form.featureimage ? form.featureimage : '');
		setBody(form.body ? form.body : '');

		appendScript('//cdn.quilljs.com/1.0.0/quill.min.js', (e) => {
			const s = scriptsLoaded.concat(['quill']);
			setScriptsLoaded(s);
		});
		appendLink('//cdn.quilljs.com/1.0.0/quill.snow.css');
		return () => {
			removeScript('//cdn.quilljs.com/1.0.0/quill.min.js');
			removeLink('//cdn.quilljs.com/1.0.0/quill.snow.css');
		};
	}, []);

	useEffect(() => {
		if (scriptsLoaded.length === 1) {
			const fullToolbarOptions = [[{ header: [1, 2, 3, false] }], ['bold', 'italic', 'link'], ['clean'], [{ list: 'ordered' }, { list: 'bullet' }], ['image']];

			let Editor = new Quill('#editor', {
				modules: {
					// table: true,
					toolbar: {
						container: fullToolbarOptions,
					},
					imageUploader: {
						upload: (file) => {
							if (file) {
								const fileTypes = ['jpg', 'jpeg', 'gif', 'png'];
								const ext = file.name.split('.').pop().toLowerCase();
								const isAccepted = fileTypes.indexOf(ext) > -1;

								if (isAccepted) {
									return new Promise((resolve, reject) => {
										const formData = new FormData();
										const postUrl = `${api}image.cfc?method=upload`;

										formData.append('image', file);

										Axios.post(postUrl, formData, {
											onUploadProgress: (e) => {
												// setUploadProgress();
											},
										})
											.then((result) => {
												resolve(result.data.file);
											})
											.catch((error) => {
												reject('Upload failed');
												console.error('Error:', error);
											});
									});
								}
							}
						},
					},
				},
				theme: 'snow',
			});

			Editor.root.innerHTML = form && form.body && form.body.length > 0 ? form.body : 'enter your description here';

			Editor.on('text-change', (e, source) => {
				setBody(Editor.root.innerHTML);
				setValue('body', Editor.root.innerHTML);
			});
		}
	}, [scriptsLoaded]);

	const updateSlug = (val) => {
		setValue('metatitle', val);
		setValue('url', slugify(val));
	};

	const slugify = (string) => {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	};

	const onImageFileUpload = (e) => {
		const file = e.target.files[0];

		if (file) {
			const fileTypes = ['jpg', 'jpeg', 'gif', 'png'];
			const ext = file.name.split('.').pop().toLowerCase();
			const isAccepted = fileTypes.indexOf(ext) > -1;

			if (isAccepted) {
				const fd = new FormData();
				const postUrl = `${URL}posts.cfc?method=upload`;
				const Authorization = ` Bearer ${btoa(auth.user.token)}`;

				fd.append('image', file, file.name);

				Axios({
					url: postUrl,
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization,
					},
					data: fd,
				})
					.then((e) => {
						// if (form.id > 0) {
						// 	new postService().update({ featureimage: e.data.file, id: form.id }, auth.user.token).then((e) => {});
						// }
						e && e.data && e.data.file && setValue('featureImage', e.data.file);
						e && e.data && e.data.file && setFeatureImage(e.data.file);
					})
					.catch((e) => {
						console.log({ e });
					})
					.finally((e) => {});
			}
		}
	};

	const onSubmit = (values) => {
		values.id = Number(form.id);
		values.locked = parseInt(values.locked);
		values.body = body;
		values.featureimage = featureImage;
		new postService()
			.update(values, auth.user.token)
			.then((json) => {
				if (json.success && json.post) {
					M.toast({ html: 'Post Saved!' });

					history.push(`/admin/posts/e/${json.post.id}`);
				} else {
					M.toast({ html: 'Error saving post' });
				}
			})
			.catch((e) => M.toast({ html: 'Error saving post, unique slug required.' }));

		return true;
	};

	const deletePost = () => {
		const id = Number(form.id);
		const Authorization = ` Bearer ${btoa(auth.user.token)}`;
		Axios({
			url: `${URL}posts.cfc?method=deletePost&id=${id}`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: Authorization,
			},
		})
			.then((d) => d.status === 200 && d.data)
			.then((d) => d.success && M.toast({ html: 'Post was deleted' }))
			.catch((e) => M.toast({ html: 'Error delete the post' }))
			.finally((e) => history.push('/admin/posts'));
	};

	return (
		<>
			<form id="postForm" onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col s12 m6">
						<div className="row">
							<div className="col s12 input-field">
								<input name="title" ref={register({ required: 'Title is required' })} onChange={(e) => updateSlug(e.target.value)} />
								<label className="active" htmlFor="title">
									Post Title
								</label>
								<span className="red-text helper-text">{errors.title && errors.title.message}</span>
							</div>
						</div>
					</div>
					<div className="col s12 m6">
						<div className="row">
							<div className="col s12 input-field">
								<input id="url" name="url" type="text" ref={register({ required: 'Slug is required!' })} />
								<label className="active" htmlFor="url">
									Slug
								</label>
								<span className="red-text helper-text">{errors.url && errors.url.message}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12 m6">
						<div className="row">
							<div className="col s12 input-field">
								<input name="metatitle" ref={register({ required: 'Meta Title is required', max: 60 })} />
								<label className="active" htmlFor="metatitle">
									Meta Title
								</label>
								<span className="red-text helper-text">{errors.metatitle && errors.metatitle.message}</span>
							</div>
						</div>
					</div>

					<div className="col s12 m6">
						<div className="row">
							<div className="col s12 input-field">
								{/* <input id="state" name="state" type="text" maxLength={120} ref={register({ required: 'A State is required!' })} /> */}
								<select ref={register({ required: 'State is required' })} name="state" id="state">
									<option>draft</option>
									<option>published</option>
								</select>
								<label className="active" htmlFor="state">
									State
								</label>
								<span className="red-text helper-text">{errors.state && errors.state.message}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<div className="row">
							<div className="col s12 input-field">
								<input id="metadescription" name="metadescription" type="text" maxLength={120} ref={register({ required: 'Meta Description is required!' })} />
								<label className="active" htmlFor="metadescription">
									Meta Description
								</label>
								<span className="red-text helper-text">{errors.metadescription && errors.metadescription.message}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<div className="row">
							<div className="col s12 input-field" style={{ margin: 0 }}>
								<textarea style={{ border: '1px solid #9e9e9e', padding: 8 }} id="excerpt" name="excerpt" ref={register}></textarea>
								<label className="active" htmlFor="excerpt">
									Excerpt
								</label>
								<span className="red-text helper-text">{errors.excerpt && errors.excerpt.message}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<div className="row">
							<div className="col s12 input-field" style={{ marginTop: 0 }}>
								<div id="editor" style={{ minHeight: '100px' }}>
									{form && form.body && form.body.length > 0 ? form.body : 'enter your description here'}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12 m4">
						<div className="row">
							<div className="col s12 input-field">
								<input id="author" name="author" type="text" ref={register({ required: 'Author is required!' })} />
								<label className="active" htmlFor="author">
									Author
								</label>
								<span className="red-text helper-text">{errors.author && errors.author.message}</span>
							</div>
						</div>
					</div>
					<div className="col s12 m4">
						<div className="row">
							<div className="col s12 input-field">
								<input name="publishDate" defaultValue={moment(form.publishdate).format('MMM D, YYYY')} className="datepicker" id="publishDate" ref={register({ required: 'Publish date is required' })} />
								<label className="active" htmlFor="publishDate">
									Published Date
								</label>
								<span className="red-text helper-text">{errors.publishDate && errors.publishDate.message}</span>
							</div>
						</div>
					</div>
					<div className="col s12 m4">
						<div className="row">
							<div className="col s12 input-field">
								{/* <input id="state" name="state" type="text" maxLength={120} ref={register({ required: 'A State is required!' })} /> */}
								<label className="active" htmlFor="locked">
									Members Only
								</label>
								<select ref={register({ required: 'Members Only is required' })} name="locked" id="locked">
									<option value={1}>Members Only</option>
									<option value={0}>Public</option>
								</select>
								<span className="red-text helper-text">{errors.locked && errors.locked.message}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<div className="row">
							<div className="col s12">
								<label onClick={() => hiddenFileInput.current.click()}>Feature Image {form.featureimage !== featureImage && <span style={{ textTransform: 'uppercase', color: 'green' }}>Please remember to save</span>}</label>
								<i onClick={() => hiddenFileInput.current.click()} style={{ cursor: 'pointer' }} className="left material-icons prefix">
									cloud_upload
								</i>
								{featureImage && featureImage.length > 0 && (
									<div>
										<img src={featureImage} alt="" style={{ height: '50px' }} />
									</div>
								)}

								<input ref={hiddenFileInput} type="file" onChange={onImageFileUpload} style={{ position: 'fixed', left: '9000px' }} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12 display-flex justify-content-end mt-3">
						<Link to="/admin/posts" style={{ marginRight: 'auto' }} className="btn btn-light">
							Cancel
						</Link>
						<button type="submit" className="btn indigo">
							Save changes
						</button>
						<button type="button" onClick={deletePost} className="btn red white-text">
							Delete
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

const Page = ({ id, ...props }) => {
	const [form, setForm] = React.useState(null);

	useEffect(() => {
		const fetch = () => {
			Axios.get(`${URL}posts.cfc?method=get&i=${id}`)
				.then((resp) => resp.status === 200 && resp.data)
				.then((form) => setForm({ ...form }));
		};

		fetch();
	}, [id]);

	return (
		<>
			<div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Post edit</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<Link to="/admin">Home</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to="/admin/company">Post</Link>
								</li>
								<li className="breadcrumb-item active">Post Edit</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<div className="container">
					<div className="section users-edit">
						<div className="card">
							<div className="card-content">
								<div className="row">
									<div className="col s12" id="account"></div>
									{form ? <Form form={form} auth={props.auth} /> : <Loading />}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Page;
