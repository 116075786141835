import { useState } from 'react';

const useSendFeedback = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const sendFeedback = async (slug, name, account, feedback) => {
		setLoading(true);
		setError(null);
		setSuccess(false);

		try {
			const response = await fetch('/cfc/api/reviews.cfc?method=sendFeedback', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					slug,
					name,
					account,
					feedback,
				}),
			});

			const result = await response.json();

			console.log({ result });
			if (response.ok) {
				setSuccess(result.success);
			} else {
				throw new Error(result.messages?.join(', ') || 'Failed to send feedback.');
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	return { sendFeedback, loading, error, success };
};

export default useSendFeedback;
