import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
// import M from 'materialize-css';
import servicesService from '../../../services/services';
import Loading from '../../../components/Loader';
// import X from './index.x';
import Table from './table';
import { useCompanies, Select } from '../../../hooks/useCompanies';

export default function List({ auth }) {
	const [services, setServices] = useState({ data: [], fetching: false });
	const [filter, setFilter] = useState({});
	const { token } = auth.user;

	const [companyId, setCompanyId] = useState(auth.user.user.company.id);

	const [companies] = useCompanies({ token });
	const adminCompanySelect = companies.map((el) => ({ value: el.id, name: el.name }));

	const columns = useMemo(
		() => [
			{
				Header: 'Services',
				columns: [
					{
						Header: 'ID',
						accessor: 'id',
					},
					{
						Header: 'Service Name',
						accessor: 'name',
					},
					{
						Header: 'Service Label',
						accessor: 'label',
					},
					{
						Header: 'Service Type',
						accessor: 'type',
					},
					{
						Header: 'Map Name',
						accessor: 'map',
					},
				],
			},
		],
		[]
	);

	useEffect(() => {
		setServices({ ...services, fetching: true });
		new servicesService()
			.getServicesByHaulerId(companyId, token)
			.then((json) => {
				setServices({ ...services, data: json, fetching: false });
			})
			.catch((e) => {
				setServices({ ...services, fetching: false });
			});

		// [ {
		// 		id: 0,
		// 		name: 'name',
		// 		companyid: 'companyid',
		// 	}, ]
	}, [token, companyId]);

	if (services.data.length === 0 && services.fetching === true) {
		return <Loading />;
	}

	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	const filteredServices = services.data.filter((el) => {
		if (filter.query && filter.query.length > 0) return `${el.name} ${el.label} ${el.map}  ${el.type}`.toLowerCase().includes(filter.query.toLowerCase());
		return true;
	});

	return (
		<React.Fragment>
			<div className="breadcrumbs-dark pb-0 pt-1" id="breadcrumbs-wrapper">
				<div className="container">
					<div className="row">
						<div className="col s10 m6 l6">
							<h5 className="breadcrumbs-title mt-0 mb-0">
								<span>Services List</span>
							</h5>
							<ol className="breadcrumbs mb-0">
								<li className="breadcrumb-item">
									<a href="index.html">Home</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#!">Service</a>
								</li>
								<li className="breadcrumb-item active">Services List</li>
							</ol>
						</div>
						<div className="col s2 m6 l6">
							<a className="btn waves-effect waves-light breadcrumbs-btn right" href="/admin/services/e/0">
								<i className="material-icons hide-on-med-and-up">add</i>
								<span className="hide-on-small-onl">Add</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="col s12">
				<section className="users-list-wrapper section">
					<div className="users-list-filter">
						<div className="card-panel">
							<div className="row">
								<div className="col s12 m6 l3">
									<div className="input-field">
										<label htmlFor="users-list-verified" className="active">
											Search
										</label>
										<input placeholder="Search here" onChange={(e) => setFilter({ ...filter, query: e.target.value })} name="query" id="query" defaultValue={filter.query} />
									</div>
								</div>
								{auth.user.user.role === 'admin' && (
									<div className="col s12 m6 l9">
										<div className="input-field">
											<Select onChange={(e) => setCompanyId(e.target.value)} options={adminCompanySelect} value={companyId} />
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="users-list-table">
						<div className="card">
							<div className="card-content">
								<div className="responsive-table">{!services.fetching ? <Table columns={columns} data={filteredServices} /> : <Loading />}</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
}
