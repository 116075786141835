import React, { useEffect } from 'react';
import M from 'materialize-css';

export default function Admin(props) {
	const { user } = props.auth.user;
	useEffect(() => {
		M.AutoInit();
	});

	const getRandomInt = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	return (
		<header className="page-topbar" id="header">
			<div className="navbar navbar-fixed">
				<nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-indigo-purple no-shadow">
					<div className="nav-wrapper">
						<ul className="navbar-list right">
							<li>
								<a className="waves-effect waves-block waves-light profile-button" href="#!" data-target="profile-dropdown">
									<span className="avatar-status avatar-online">
										<img src={user.profileimage ? user.profileimage : `/assets/images/avatar/avatar-${getRandomInt(1, 17)}.png`} alt="avatar" />
										<i></i>
									</span>
								</a>
							</li>
						</ul>
						{/* <!-- translation-button--> */}

						{/* <!-- notifications-dropdown--> */}

						{/* <!-- profile-dropdown--> */}
					</div>
					<nav className="display-none search-sm">
						<div className="nav-wrapper">
							<form id="navbarForm">
								<div className="input-field search-input-sm">
									<input className="search-box-sm mb-0" type="search" required="" id="search" placeholder="Explore Materialize" data-search="template-list" />
									<label className="label-icon active" htmlFor="search">
										<i className="material-icons search-sm-icon">search</i>
									</label>
									<i className="material-icons search-sm-close">close</i>
									<ul className="search-list collection search-list-sm display-none ps">
										<div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
											<div className="ps__thumb-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div>
										</div>
										<div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
											<div className="ps__thumb-y" tabIndex="0" style={{ top: '0px', height: '0px' }}></div>
										</div>
									</ul>
								</div>
							</form>
						</div>
					</nav>
				</nav>
			</div>
		</header>
	);
}
