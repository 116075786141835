import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const WhyJoeLayout = (props) => {
	return (
		<div id="wrapper">
			<Header template="default" {...props} />
			{props.children}
			<Footer template="home" />
		</div>
	);
};

export default WhyJoeLayout;
