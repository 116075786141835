import React from 'react';
import { Link } from 'react-router-dom';
import monitor from './TJ-ControlPanel.png';
import trashMan from './TrashJoes-man.png';
import hero from './TJ-Hero.jpg';
import Resources from '../ResourcesPage';
import { Helmet } from 'react-helmet';

const HomePage = () => {
	return (
		<div className="home">
			<Helmet>
				<title>Trash Joes - GET MORE CUSTOMERS AND KEEP THEM</title>
			</Helmet>
			<div className="container">
				<div className="row center">
					<img className="responsive-img" src={hero} alt="" />
					<h2 className="red-text" style={{ margin: '0' }}>
						GET MORE CUSTOMERS AND KEEP THEM
					</h2>
					<p className="col s12 m12 l10 offset-l1">Trash Joe's partners with independent waste haulers to acquire new customers and retain existing customers. It's simple - more customers equals more revenue and better night's sleep.</p>
					<div>
						<Link className="btn-large" to="/Demo" style={{ margin: '0 10px' }}>
							View Our Demo
						</Link>
						<Link className="btn-large" to="/can-manager/register" style={{ margin: '0 10px' }}>
							Can Manager Registration
						</Link>
					</div>
				</div>
				{/* <div className="row">
					<div className="s12 center">
						<p>
							You get the tools to find new customers where you need them for route density. Training your customers to put their can out the night before and pay when they are supposed too. To reduce trucking and labor costs, increase customer happiness, and give people a real reason to refer others to you. Link customers to your payment portal so that you get paid, you have the
							cash you need to operate, grow and sleep at night. Your customers are tied of having to remember when to put their trash out and when they need to pay their trash bill. (Develop research shows that people respond to better to a text then a call, or letter and you are responding to your customers
						</p>
					</div>
				</div> */}
			</div>
			{/* <div className="grey white-text demoRibon">
				<div className="container">
					<div className="row displayAdj">
						<div className="col s12 l6 push-l6 padAdj center-align">
							<img className="responsive-img" src={monitor} alt="" style={{ marginTop: '20px' }} />
						</div>
						<div className="col s12 l6 pull-l6 padAdj alignAdj">
							<p>
								Onboard customers and easily send reminders to customers about trash day and payments. No more forgotten cans, no more call backs and no more overdue invoices. Trash Joes is here to help independent trash haulers win the battle against the big guys or the other guy across town. Our customers get the information, guidance and resources they need to grow and succeed.
							</p>
							<Link className="btn-large" to="/Services">
								TAKE THE TOUR
							</Link>
						</div>
					</div>
				</div>
			</div> */}
			<div className="grey white-text demoRibbon" style={{ marginTop: '80px' }}>
				<div className="container">
					<div className="row">
						<div className="col s12 l6 push-l6">
							<div className="mac-displaced" style={{ position: 'absolute', top: '-50px' }}>
								<img className="responsive-img" src={monitor} alt="" style={{ marginTop: '20px' }} />
							</div>
							<div style={{ visibility: 'hidden' }}>
								<img className="responsive-img" src={monitor} alt="" style={{ marginTop: '20px' }} />
							</div>
						</div>
						<div className="col s12 l6 pull-l6 right-align" style={{ paddingBottom: '40px' }}>
							<p style={{ fontSize: '23px', fontWeight: '600', marginTop: '45px' }}>Onboard customers and easily send reminders to customers about trash day and payments. </p>
							<p>No more forgotten cans, no more call backs and no more overdue invoices. Trash Joes is here to help independent trash haulers win the battle against the big guys or the other guy across town. Our customers get the information, guidance and resources they need to grow and succeed.</p>
							<p>Trash Joes helps any hauler no matter the stage of their business by sharing critical and insightful information to take the next step. Our goal is to help you “win” the battle against the big guys or the guy across town that seems to always have your number. Our customers get the information, guidance and products they need to move forward.</p>
							{/* <Link className="btn-large" to="/Services">
								TAKE THE TOUR
							</Link> */}
						</div>
						{/* <div className="col s12 m6 l6 push-m6 push-l6 padAdj center-align animatedParent animateOnce"> */}
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row center">
					<div className="col s12 m10 offset-m1">
						<p className="red-text" style={{ fontSize: '24px' }}>
							Trash Joes provides the tools for independent trash haulers to bring in new customers and improve route density. And the tools to retain customers through improved communication. What does it all mean? More satisfied customers, less phone calls, reduced trucking and labor costs <span style={{ whiteSpace: 'nowrap' }}>and more referrals.</span>
						</p>
					</div>
				</div>
			</div>
			<div className="grey lighten-3">
				<div className="row container">
					<div className="col s12 home-blog-intro">
						<h1 className="center-align red-text cursive" style={{ fontSize: '4.5rem', fontWeight: 'bold', marginBottom: '0' }}>
							YOU AND JOE GETTING TO WORK
						</h1>
						<p style={{ marginTop: '0' }}>
							Tired of not getting a good night's sleep because you have to reinvent the wheel every day at work? Joe delivers the information you need to become a great hauler that is excited to meet with the banker, smiles when passing out payroll checks, and can afford a killer Christmas party for the crew. You have the want; Joe is your partner to get there.
						</p>
					</div>
					<div className="col hide-on-small-only m3 center-align">
						<img className="responsive-img" style={{ width: '100%', marginTop: '20px' }} src={trashMan} alt="" />
					</div>
					<div className="col s12 m9">
						<Resources home />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
